import { NgModule } from "@angular/core";

import { FilterByIdPipe } from "./filter-by-id.pipe";

@NgModule({
  imports: [],
  exports: [FilterByIdPipe],
  declarations: [FilterByIdPipe],
  providers: [],
})
export class FilterByIdPipeModule {}

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `DateTime` scalar type represents a point in time. */
  DateTime: any;
  /** The `Number` scalar type represents a number that can be a float, an integer or a null value. */
  Number: any;
  /**
   * The `QueryArgument` scalar type represents a value to be using in Craft element
   * queries. It can be an integer, a string, or a boolean value.
   */
  QueryArgument: any;
  _text: any;
  float8: any;
  jsonb: any;
  timestamptz: any;
  uuid: any;
};




export type AssetCriteriaInput = {
  /** Narrows the query results to only elements that have been archived. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the assets’ files’ last-modified dates. */
  dateModified?: Maybe<Scalars['String']>;
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Narrows the query results based on whether the elements are enabled in the
   * site they’re being queried in, per the `site` argument.
   */
  enabledForSite?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the assets’ filenames. */
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the folders the assets belong to, per the folders’ IDs. */
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the assets’ image heights. */
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ IDs. */
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Causes the query results to be returned in reverse order. */
  inReverse?: Maybe<Scalars['Boolean']>;
  /** Broadens the query results to include assets from any of the subfolders of the folder specified by `folderId`. */
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the assets’ file kinds. */
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Sets the limit for paginated results. */
  limit?: Maybe<Scalars['Int']>;
  /** Sets the offset for paginated results. */
  offset?: Maybe<Scalars['Int']>;
  /** Sets the field the returned elements should be ordered by. */
  orderBy?: Maybe<Scalars['String']>;
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on a reference string. */
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Narrows the query results to elements that relate to the provided element IDs.
   * This argument is ignored, if `relatedToAll` is also used.
   */
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /**
   * Narrows the query results to elements that relate to *all* of the provided
   * element IDs. Using this argument will cause `relatedTo` argument to be
   * ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should
   * be used instead.
   */
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  /** Narrows the query results to only elements that match a search query. */
  search?: Maybe<Scalars['String']>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the assets’ file sizes (in bytes). */
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ slugs. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ statuses. */
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ titles. */
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results to only elements that have been soft-deleted. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ UIDs. */
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the user the assets were uploaded by, per the user’s ID. */
  uploader?: Maybe<Scalars['QueryArgument']>;
  /** Narrows the query results based on the elements’ URIs. */
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the volumes the assets belong to, per the volumes’ handles. */
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the volumes the assets belong to, per the volumes’ IDs. */
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the assets’ image widths. */
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A list of transform handles to preload. */
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** This is the interface implemented by all assets. */
export type AssetInterface = {
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the asset file was last modified. */
  dateModified?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** This queries for a single embedded asset. */
  embeddedAsset?: Maybe<EmbeddedAssetInterface>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The file extension for the asset file. */
  extension?: Maybe<Scalars['String']>;
  /** The filename of the asset file. */
  filename?: Maybe<Scalars['String']>;
  /** The focal point represented as an array with `x` and `y` keys, or null if it's not an image. */
  focalPoint?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** The ID of the folder that the asset belongs to. */
  folderId?: Maybe<Scalars['Int']>;
  /** Whether a user-defined focal point is set on the asset. */
  hasFocalPoint?: Maybe<Scalars['Boolean']>;
  /** The height in pixels or null if it's not an image. */
  height?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** An `<img>` tag based on this asset. */
  img?: Maybe<Scalars['String']>;
  /** The file kind. */
  kind?: Maybe<Scalars['String']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The file’s MIME type, if it can be determined. */
  mimeType?: Maybe<Scalars['String']>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<AssetInterface>;
  /** The asset's path in the volume. */
  path?: Maybe<Scalars['String']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<AssetInterface>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The file size in bytes. */
  size?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** Returns a `srcset` attribute value based on the given widths or x-descriptors. */
  srcset?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The full URL of the asset. This field accepts the same fields as the `transform` directive. */
  url?: Maybe<Scalars['String']>;
  /** The ID of the volume that the asset belongs to. */
  volumeId?: Maybe<Scalars['Int']>;
  /** The width in pixels or null if it's not an image. */
  width?: Maybe<Scalars['Int']>;
};


/** This is the interface implemented by all assets. */
export type AssetInterface_CountArgs = {
  field: Scalars['String'];
};


/** This is the interface implemented by all assets. */
export type AssetInterfaceHeightArgs = {
  format?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  immediately?: Maybe<Scalars['Boolean']>;
  interlace?: Maybe<Scalars['String']>;
  mode?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  quality?: Maybe<Scalars['Int']>;
  transform?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};


/** This is the interface implemented by all assets. */
export type AssetInterfaceNextArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** This is the interface implemented by all assets. */
export type AssetInterfacePrevArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** This is the interface implemented by all assets. */
export type AssetInterfaceSrcsetArgs = {
  sizes: Array<Scalars['String']>;
};


/** This is the interface implemented by all assets. */
export type AssetInterfaceUrlArgs = {
  format?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  immediately?: Maybe<Scalars['Boolean']>;
  interlace?: Maybe<Scalars['String']>;
  mode?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  quality?: Maybe<Scalars['Int']>;
  transform?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};


/** This is the interface implemented by all assets. */
export type AssetInterfaceWidthArgs = {
  format?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  immediately?: Maybe<Scalars['Boolean']>;
  interlace?: Maybe<Scalars['String']>;
  mode?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  quality?: Maybe<Scalars['Int']>;
  transform?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type CategoryCriteriaInput = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /**
   * Narrows the query results to only elements that are up to a certain distance
   * away from the element in its structure specified by `ancestorOf`.
   */
  ancestorDist?: Maybe<Scalars['Int']>;
  /** Narrows the query results to only elements that are ancestors of another element in its structure, provided by its ID. */
  ancestorOf?: Maybe<Scalars['Int']>;
  /** Narrows the query results to only elements that have been archived. */
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Narrows the query results to only elements that are up to a certain distance
   * away from the element in its structure specified by `descendantOf`.
   */
  descendantDist?: Maybe<Scalars['Int']>;
  /** Narrows the query results to only elements that are descendants of another element in its structure provided by its ID. */
  descendantOf?: Maybe<Scalars['Int']>;
  /** Whether to only return categories that the user has permission to edit. */
  editable?: Maybe<Scalars['Boolean']>;
  /**
   * Narrows the query results based on whether the elements are enabled in the
   * site they’re being queried in, per the `site` argument.
   */
  enabledForSite?: Maybe<Scalars['Boolean']>;
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the category groups the categories belong to per the group’s handles. */
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the category groups the categories belong to, per the groups’ IDs. */
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on whether the elements have any descendants in their structure. */
  hasDescendants?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ IDs. */
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Causes the query results to be returned in reverse order. */
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on whether the elements are “leaves” in their structure (element with no descendants). */
  leaves?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ level within the structure. */
  level?: Maybe<Scalars['Int']>;
  /** Sets the limit for paginated results. */
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /**
   * Narrows the query results to only the entry that comes immediately after
   * another element in its structure, provided by its ID.
   */
  nextSiblingOf?: Maybe<Scalars['Int']>;
  /** Sets the offset for paginated results. */
  offset?: Maybe<Scalars['Int']>;
  /** Sets the field the returned elements should be ordered by. */
  orderBy?: Maybe<Scalars['String']>;
  /** Narrows the query results to only entries that are positioned after another element in its structure, provided by its ID. */
  positionedAfter?: Maybe<Scalars['Int']>;
  /** Narrows the query results to only entries that are positioned before another element in its structure, provided by its ID. */
  positionedBefore?: Maybe<Scalars['Int']>;
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /**
   * Narrows the query results to only the entry that comes immediately before
   * another element in its structure, provided by its ID.
   */
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on a reference string. */
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /**
   * Narrows the query results to elements that relate to the provided element IDs.
   * This argument is ignored, if `relatedToAll` is also used.
   */
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /**
   * Narrows the query results to elements that relate to *all* of the provided
   * element IDs. Using this argument will cause `relatedTo` argument to be
   * ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should
   * be used instead.
   */
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  /** Narrows the query results to only elements that match a search query. */
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the elements’ slugs. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ statuses. */
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Determines which structure data should be joined into the query. */
  structureId?: Maybe<Scalars['Int']>;
  /** Narrows the query results based on the elements’ titles. */
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results to only elements that have been soft-deleted. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ UIDs. */
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ URIs. */
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Explicitly determines whether the query should join in the structure data. */
  withStructure?: Maybe<Scalars['Boolean']>;
};

/** This is the interface implemented by all categories. */
export type CategoryInterface = {
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The category’s children. */
  children?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The handle of the group that contains the category. */
  groupHandle?: Maybe<Scalars['String']>;
  /** The ID of the group that contains the category. */
  groupId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<CategoryInterface>;
  /** The category’s parent. */
  parent?: Maybe<CategoryInterface>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<CategoryInterface>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic?: Maybe<SeomaticInterface>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
};


/** This is the interface implemented by all categories. */
export type CategoryInterface_CountArgs = {
  field: Scalars['String'];
};


/** This is the interface implemented by all categories. */
export type CategoryInterfaceChildrenArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


/** This is the interface implemented by all categories. */
export type CategoryInterfaceLocalizedArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


/** This is the interface implemented by all categories. */
export type CategoryInterfaceNextArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


/** This is the interface implemented by all categories. */
export type CategoryInterfaceParentArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


/** This is the interface implemented by all categories. */
export type CategoryInterfacePrevArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


/** This is the interface implemented by all categories. */
export type CategoryInterfaceSeomaticArgs = {
  asArray?: Maybe<Scalars['Boolean']>;
  environment?: Maybe<SeomaticEnvironment>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  uri?: Maybe<Scalars['String']>;
};


export type Element = ElementInterface & {
  __typename?: 'Element';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type Element_CountArgs = {
  field: Scalars['String'];
};

/** This is the interface implemented by all elements. */
export type ElementInterface = {
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


/** This is the interface implemented by all elements. */
export type ElementInterface_CountArgs = {
  field: Scalars['String'];
};

export type EmbeddedAsset = EmbeddedAssetInterface & {
  __typename?: 'EmbeddedAsset';
  /** The aspect ratio for the embedded asset. */
  aspectRatio?: Maybe<Scalars['Float']>;
  /** The author name for the embedded asset. */
  authorName?: Maybe<Scalars['String']>;
  /** The author URL for the embedded asset. */
  authorUrl?: Maybe<Scalars['String']>;
  /** The code for the embedded asset. */
  code?: Maybe<Scalars['String']>;
  /** The description of the embedded asset. */
  description?: Maybe<Scalars['String']>;
  /** The feeds for the embedded asset. */
  feeds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The height for the embedded asset. */
  height?: Maybe<Scalars['Int']>;
  /** The embed HTML for the embedded asset. */
  html?: Maybe<Scalars['String']>;
  /** The embed code for the embedded asset, if the embedded asset's code is an iframe. */
  iframeCode?: Maybe<Scalars['String']>;
  /** The embed URL for the embedded asset, if the embedded asset's code is an iframe. */
  iframeSrc?: Maybe<Scalars['String']>;
  /** The image for the embedded asset. */
  image?: Maybe<Scalars['String']>;
  /** The image height for the embedded asset. */
  imageHeight?: Maybe<Scalars['Int']>;
  /** The image width for the embedded asset. */
  imageWidth?: Maybe<Scalars['Int']>;
  /** The images for the embedded asset. */
  images?: Maybe<Array<Maybe<Image>>>;
  /** Whether or not the the embed code is safe to use. */
  isSafe?: Maybe<Scalars['Boolean']>;
  /** The license for the embedded asset. */
  license?: Maybe<Scalars['String']>;
  /** The provider icon for the embedded asset. */
  providerIcon?: Maybe<Scalars['String']>;
  /** The provider icons for the embedded asset. */
  providerIcons?: Maybe<Array<Maybe<Image>>>;
  /** The provider name for the embedded asset. */
  providerName?: Maybe<Scalars['String']>;
  /** The provider URL for the embedded asset. */
  providerUrl?: Maybe<Scalars['String']>;
  /** The published time of the embedded asset. */
  publishedTime?: Maybe<Scalars['String']>;
  /** The tags for the embedded asset. */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The title of the embedded asset. */
  title?: Maybe<Scalars['String']>;
  /** The type of the embedded asset. */
  type?: Maybe<Scalars['String']>;
  /** The url of the embedded asset. */
  url?: Maybe<Scalars['String']>;
  /** The width for the embedded asset. */
  width?: Maybe<Scalars['Int']>;
};


export type EmbeddedAssetIframeCodeArgs = {
  params: Array<Maybe<Scalars['String']>>;
};


export type EmbeddedAssetIframeSrcArgs = {
  params: Array<Maybe<Scalars['String']>>;
};

/** This is the interface implemented by all embedded assets. */
export type EmbeddedAssetInterface = {
  /** The aspect ratio for the embedded asset. */
  aspectRatio?: Maybe<Scalars['Float']>;
  /** The author name for the embedded asset. */
  authorName?: Maybe<Scalars['String']>;
  /** The author URL for the embedded asset. */
  authorUrl?: Maybe<Scalars['String']>;
  /** The code for the embedded asset. */
  code?: Maybe<Scalars['String']>;
  /** The description of the embedded asset. */
  description?: Maybe<Scalars['String']>;
  /** The feeds for the embedded asset. */
  feeds?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The height for the embedded asset. */
  height?: Maybe<Scalars['Int']>;
  /** The embed HTML for the embedded asset. */
  html?: Maybe<Scalars['String']>;
  /** The embed code for the embedded asset, if the embedded asset's code is an iframe. */
  iframeCode?: Maybe<Scalars['String']>;
  /** The embed URL for the embedded asset, if the embedded asset's code is an iframe. */
  iframeSrc?: Maybe<Scalars['String']>;
  /** The image for the embedded asset. */
  image?: Maybe<Scalars['String']>;
  /** The image height for the embedded asset. */
  imageHeight?: Maybe<Scalars['Int']>;
  /** The image width for the embedded asset. */
  imageWidth?: Maybe<Scalars['Int']>;
  /** The images for the embedded asset. */
  images?: Maybe<Array<Maybe<Image>>>;
  /** Whether or not the the embed code is safe to use. */
  isSafe?: Maybe<Scalars['Boolean']>;
  /** The license for the embedded asset. */
  license?: Maybe<Scalars['String']>;
  /** The provider icon for the embedded asset. */
  providerIcon?: Maybe<Scalars['String']>;
  /** The provider icons for the embedded asset. */
  providerIcons?: Maybe<Array<Maybe<Image>>>;
  /** The provider name for the embedded asset. */
  providerName?: Maybe<Scalars['String']>;
  /** The provider URL for the embedded asset. */
  providerUrl?: Maybe<Scalars['String']>;
  /** The published time of the embedded asset. */
  publishedTime?: Maybe<Scalars['String']>;
  /** The tags for the embedded asset. */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The title of the embedded asset. */
  title?: Maybe<Scalars['String']>;
  /** The type of the embedded asset. */
  type?: Maybe<Scalars['String']>;
  /** The url of the embedded asset. */
  url?: Maybe<Scalars['String']>;
  /** The width for the embedded asset. */
  width?: Maybe<Scalars['Int']>;
};


/** This is the interface implemented by all embedded assets. */
export type EmbeddedAssetInterfaceIframeCodeArgs = {
  params: Array<Maybe<Scalars['String']>>;
};


/** This is the interface implemented by all embedded assets. */
export type EmbeddedAssetInterfaceIframeSrcArgs = {
  params: Array<Maybe<Scalars['String']>>;
};

export type EntryCriteriaInput = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results to only entries that were posted on or after a certain date. */
  after?: Maybe<Scalars['String']>;
  /**
   * Narrows the query results to only elements that are up to a certain distance
   * away from the element in its structure specified by `ancestorOf`.
   */
  ancestorDist?: Maybe<Scalars['Int']>;
  /** Narrows the query results to only elements that are ancestors of another element in its structure, provided by its ID. */
  ancestorOf?: Maybe<Scalars['Int']>;
  /** Narrows the query results to only elements that have been archived. */
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the user group the entries’ authors belong to. */
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the user group the entries’ authors belong to, per the groups’ IDs. */
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the entries’ authors. */
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results to only entries that were posted before a certain date. */
  before?: Maybe<Scalars['String']>;
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Narrows the query results to only elements that are up to a certain distance
   * away from the element in its structure specified by `descendantOf`.
   */
  descendantDist?: Maybe<Scalars['Int']>;
  /** Narrows the query results to only elements that are descendants of another element in its structure provided by its ID. */
  descendantOf?: Maybe<Scalars['Int']>;
  /** The drafts’ creator ID */
  draftCreator?: Maybe<Scalars['Int']>;
  /** The ID of the draft to return (from the `drafts` table) */
  draftId?: Maybe<Scalars['Int']>;
  /** Narrows the query results to only drafts of a given element.  Set to `false` to fetch unpublished drafts. */
  draftOf?: Maybe<Scalars['QueryArgument']>;
  /** Whether draft elements should be returned. */
  drafts?: Maybe<Scalars['Boolean']>;
  /** Whether to only return entries that the user has permission to edit. */
  editable?: Maybe<Scalars['Boolean']>;
  /**
   * Narrows the query results based on whether the elements are enabled in the
   * site they’re being queried in, per the `site` argument.
   */
  enabledForSite?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the entries’ expiry dates. */
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on whether the elements have any descendants in their structure. */
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the elements’ IDs. */
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Causes the query results to be returned in reverse order. */
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on whether the elements are “leaves” in their structure (element with no descendants). */
  leaves?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ level within the structure. */
  level?: Maybe<Scalars['Int']>;
  /** Sets the limit for paginated results. */
  limit?: Maybe<Scalars['Int']>;
  /**
   * Narrows the query results to only the entry that comes immediately after
   * another element in its structure, provided by its ID.
   */
  nextSiblingOf?: Maybe<Scalars['Int']>;
  /** Sets the offset for paginated results. */
  offset?: Maybe<Scalars['Int']>;
  /** Sets the field the returned elements should be ordered by. */
  orderBy?: Maybe<Scalars['String']>;
  /** Narrows the query results to only entries that are positioned after another element in its structure, provided by its ID. */
  positionedAfter?: Maybe<Scalars['Int']>;
  /** Narrows the query results to only entries that are positioned before another element in its structure, provided by its ID. */
  positionedBefore?: Maybe<Scalars['Int']>;
  /** Narrows the query results based on the entries’ post dates. */
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /**
   * Narrows the query results to only the entry that comes immediately before
   * another element in its structure, provided by its ID.
   */
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Whether provisional drafts should be returned. */
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on a reference string. */
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /**
   * Narrows the query results to elements that relate to the provided element IDs.
   * This argument is ignored, if `relatedToAll` is also used.
   */
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /**
   * Narrows the query results to elements that relate to *all* of the provided
   * element IDs. Using this argument will cause `relatedTo` argument to be
   * ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should
   * be used instead.
   */
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  /** The revisions’ creator ID */
  revisionCreator?: Maybe<Scalars['Int']>;
  /** The ID of the revision to return (from the `revisions` table) */
  revisionId?: Maybe<Scalars['Int']>;
  /** The source element ID that revisions should be returned for */
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  /** Whether revision elements should be returned. */
  revisions?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results to only elements that match a search query. */
  search?: Maybe<Scalars['String']>;
  /** Narrows the query results based on the section handles the entries belong to. */
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the sections the entries belong to, per the sections’ IDs. */
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the elements’ slugs. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ statuses. */
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Determines which structure data should be joined into the query. */
  structureId?: Maybe<Scalars['Int']>;
  /** Narrows the query results based on the elements’ titles. */
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results to only elements that have been soft-deleted. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the entries’ entry type handles. */
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the entries’ entry types, per the types’ IDs. */
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the elements’ UIDs. */
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ URIs. */
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Explicitly determines whether the query should join in the structure data. */
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};

/** This is the interface implemented by all entries. */
export type EntryInterface = {
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Returns the entry’s canonical ID. */
  canonicalId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid?: Maybe<Scalars['String']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The current revision for the entry. */
  currentRevision?: Maybe<EntryInterface>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The creator of a given draft. */
  draftCreator?: Maybe<UserInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId?: Maybe<Scalars['Int']>;
  /** The name of the draft. */
  draftName?: Maybe<Scalars['String']>;
  /** The notes for the draft. */
  draftNotes?: Maybe<Scalars['String']>;
  /** The drafts for the entry. */
  drafts?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** Returns whether this is a draft. */
  isDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is a revision. */
  isRevision?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
  isUnsavedDraft?: Maybe<Scalars['Boolean']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>;
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<EntryInterface>;
  /** The creator of a given revision. */
  revisionCreator?: Maybe<UserInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId?: Maybe<Scalars['Int']>;
  /** The revisions for the entry. */
  revisions?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>;
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic?: Maybe<SeomaticInterface>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** Returns the entry’s canonical ID. */
  sourceId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  sourceUid?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
};


/** This is the interface implemented by all entries. */
export type EntryInterface_CountArgs = {
  field: Scalars['String'];
};


/** This is the interface implemented by all entries. */
export type EntryInterfaceAncestorsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


/** This is the interface implemented by all entries. */
export type EntryInterfaceChildrenArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


/** This is the interface implemented by all entries. */
export type EntryInterfaceDescendantsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


/** This is the interface implemented by all entries. */
export type EntryInterfaceDraftsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


/** This is the interface implemented by all entries. */
export type EntryInterfaceLocalizedArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


/** This is the interface implemented by all entries. */
export type EntryInterfaceNextArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


/** This is the interface implemented by all entries. */
export type EntryInterfaceParentArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


/** This is the interface implemented by all entries. */
export type EntryInterfacePrevArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


/** This is the interface implemented by all entries. */
export type EntryInterfaceRevisionsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


/** This is the interface implemented by all entries. */
export type EntryInterfaceSeomaticArgs = {
  asArray?: Maybe<Scalars['Boolean']>;
  environment?: Maybe<SeomaticEnvironment>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  uri?: Maybe<Scalars['String']>;
};

export type FileInput = {
  /** The contents of the file in Base64 format. If provided, takes precedence over the URL. */
  fileData?: Maybe<Scalars['String']>;
  /** The file name to use (including the extension) data with the `fileData` field. */
  filename?: Maybe<Scalars['String']>;
  /** The URL of the file. */
  url?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all global sets. */
export type GlobalSetInterface = {
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The handle of the global set. */
  handle?: Maybe<Scalars['String']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The name of the global set. */
  name?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


/** This is the interface implemented by all global sets. */
export type GlobalSetInterface_CountArgs = {
  field: Scalars['String'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  error?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all matrix blocks. */
export type MatrixBlockInterface = {
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


/** This is the interface implemented by all matrix blocks. */
export type MatrixBlockInterface_CountArgs = {
  field: Scalars['String'];
};

/** This is the interface implemented by all neo blocks. */
export type NeoBlockInterface = {
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the neo block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The Neo block's level. */
  level?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the neo block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the neo block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the neo block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


/** This is the interface implemented by all neo blocks. */
export type NeoBlockInterface_CountArgs = {
  field: Scalars['String'];
};

export type NodeCustomAttribute = {
  __typename?: 'NodeCustomAttribute';
  attribute?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all nodes. */
export type NodeInterface = {
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The node’s children. Accepts the same arguments as the `nodes` query. */
  children?: Maybe<Array<Maybe<NodeInterface>>>;
  /** Any additional classes for the node. */
  classes?: Maybe<Scalars['String']>;
  /** Any additional custom attributes for the node. */
  customAttributes?: Maybe<Array<Maybe<NodeCustomAttribute>>>;
  /** Any additional data for the node. */
  data?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The element the node links to. */
  element?: Maybe<ElementInterface>;
  /** The ID of the element this node is linked to. */
  elementId?: Maybe<Scalars['Int']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The handle of the navigation this node belongs to. */
  navHandle?: Maybe<Scalars['String']>;
  /** The ID of the navigation this node belongs to. */
  navId?: Maybe<Scalars['Int']>;
  /** The name of the navigation this node belongs to. */
  navName?: Maybe<Scalars['String']>;
  /** Whether this node should open in a new window. */
  newWindow?: Maybe<Scalars['String']>;
  /** The node’s URI */
  nodeUri?: Maybe<Scalars['String']>;
  /** The node’s parent. */
  parent?: Maybe<NodeInterface>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The type of node this is. */
  type?: Maybe<Scalars['String']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The node’s full URL */
  url?: Maybe<Scalars['String']>;
  /** The URL for this navigation item. */
  urlSuffix?: Maybe<Scalars['String']>;
};


/** This is the interface implemented by all nodes. */
export type NodeInterface_CountArgs = {
  field: Scalars['String'];
};


/** This is the interface implemented by all nodes. */
export type NodeInterfaceChildrenArgs = {
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nav?: Maybe<Array<Maybe<Scalars['String']>>>;
  navHandle?: Maybe<Scalars['String']>;
  navId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};



/** Optional - The SEOmatic environment that should be used */
export enum SeomaticEnvironment {
  /** Live production environment, with indexing enabled */
  Live = 'live',
  /** Local Development environment, with debugging enabled and indexing disabled */
  Local = 'local',
  /** Staging environment, with indexing disabled */
  Staging = 'staging'
}

/** This is the interface implemented by SEOmatic. */
export type SeomaticInterface = {
  /** The FrontendTemplateContainer SEOmatic container. */
  frontendTemplateContainer?: Maybe<Scalars['String']>;
  /** The MetaJsonLdContainer SEOmatic container. */
  metaJsonLdContainer?: Maybe<Scalars['String']>;
  /** The MetaLinkContainer SEOmatic container. */
  metaLinkContainer?: Maybe<Scalars['String']>;
  /** The MetaScriptContainer SEOmatic container. */
  metaScriptContainer?: Maybe<Scalars['String']>;
  /** The MetaSiteVarsContainer SEOmatic container. */
  metaSiteVarsContainer?: Maybe<Scalars['String']>;
  /** The MetaTagContainer SEOmatic container. */
  metaTagContainer?: Maybe<Scalars['String']>;
  /** The MetaTitleContainer SEOmatic container. */
  metaTitleContainer?: Maybe<Scalars['String']>;
};

/** This entity has all the SEOmatic fields */
export type SeomaticType = SeomaticInterface & {
  __typename?: 'SeomaticType';
  /** The FrontendTemplateContainer SEOmatic container. */
  frontendTemplateContainer?: Maybe<Scalars['String']>;
  /** The MetaJsonLdContainer SEOmatic container. */
  metaJsonLdContainer?: Maybe<Scalars['String']>;
  /** The MetaLinkContainer SEOmatic container. */
  metaLinkContainer?: Maybe<Scalars['String']>;
  /** The MetaScriptContainer SEOmatic container. */
  metaScriptContainer?: Maybe<Scalars['String']>;
  /** The MetaSiteVarsContainer SEOmatic container. */
  metaSiteVarsContainer?: Maybe<Scalars['String']>;
  /** The MetaTagContainer SEOmatic container. */
  metaTagContainer?: Maybe<Scalars['String']>;
  /** The MetaTitleContainer SEOmatic container. */
  metaTitleContainer?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

/** This is the interface implemented by all Super Table blocks. */
export type SuperTableBlockInterface = {
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the Super Table block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The ID of the element that owns the Super Table block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The sort order of the Super Table block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The ID of the Super Table block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


/** This is the interface implemented by all Super Table blocks. */
export type SuperTableBlockInterface_CountArgs = {
  field: Scalars['String'];
};

export type TagCriteriaInput = {
  /** Narrows the query results to only elements that have been archived. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Narrows the query results based on whether the elements are enabled in the
   * site they’re being queried in, per the `site` argument.
   */
  enabledForSite?: Maybe<Scalars['Boolean']>;
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the tag groups the tags belong to per the group’s handles. */
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the tag groups the tags belong to, per the groups’ IDs. */
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the elements’ IDs. */
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Causes the query results to be returned in reverse order. */
  inReverse?: Maybe<Scalars['Boolean']>;
  /** Sets the limit for paginated results. */
  limit?: Maybe<Scalars['Int']>;
  /** Sets the offset for paginated results. */
  offset?: Maybe<Scalars['Int']>;
  /** Sets the field the returned elements should be ordered by. */
  orderBy?: Maybe<Scalars['String']>;
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on a reference string. */
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Narrows the query results to elements that relate to the provided element IDs.
   * This argument is ignored, if `relatedToAll` is also used.
   */
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /**
   * Narrows the query results to elements that relate to *all* of the provided
   * element IDs. Using this argument will cause `relatedTo` argument to be
   * ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should
   * be used instead.
   */
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  /** Narrows the query results to only elements that match a search query. */
  search?: Maybe<Scalars['String']>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the elements’ slugs. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ statuses. */
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ titles. */
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results to only elements that have been soft-deleted. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ UIDs. */
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ URIs. */
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type User = ElementInterface & UserInterface & {
  __typename?: 'User';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The user's email. */
  email?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The user's first name. */
  firstName?: Maybe<Scalars['String']>;
  /** The user's first name or username. */
  friendlyName?: Maybe<Scalars['String']>;
  /** The user's full name. */
  fullName?: Maybe<Scalars['String']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The user's last name. */
  lastName?: Maybe<Scalars['String']>;
  /** The user's full name or username. */
  name?: Maybe<Scalars['String']>;
  /** The user’s preferences. */
  preferences?: Maybe<Scalars['String']>;
  /** The user’s preferred language. */
  preferredLanguage?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The username. */
  username?: Maybe<Scalars['String']>;
};


export type User_CountArgs = {
  field: Scalars['String'];
};


export type UserAssociatedProducerArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};

export type UserCriteriaInput = {
  /** Narrows the query results to only elements that have been archived. */
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the elements’ creation dates. */
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ last-updated dates. */
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the users’ email addresses. */
  email?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Narrows the query results based on whether the elements are enabled in the
   * site they’re being queried in, per the `site` argument.
   */
  enabledForSite?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the users’ first names. */
  firstName?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Causes the query results to be returned in the order specified by the `id` argument. */
  fixedOrder?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the user group the users belong to. */
  group?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the user group the users belong to, per the groups’ IDs. */
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results to only users that have (or don’t have) a user photo. */
  hasPhoto?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ IDs. */
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Causes the query results to be returned in reverse order. */
  inReverse?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the users’ last names. */
  lastName?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Sets the limit for paginated results. */
  limit?: Maybe<Scalars['Int']>;
  /** Sets the offset for paginated results. */
  offset?: Maybe<Scalars['Int']>;
  /** Sets the field the returned elements should be ordered by. */
  orderBy?: Maybe<Scalars['String']>;
  /** Determines which site should be selected when querying multi-site elements. */
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on a reference string. */
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  /**
   * Narrows the query results to elements that relate to the provided element IDs.
   * This argument is ignored, if `relatedToAll` is also used.
   */
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /**
   * Narrows the query results to elements that relate to *all* of the provided
   * element IDs. Using this argument will cause `relatedTo` argument to be
   * ignored. **This argument is deprecated.** `relatedTo: ["and", ...ids]` should
   * be used instead.
   */
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results to elements that relate to an asset list defined with this argument. */
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a category list defined with this argument. */
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to an entry list defined with this argument. */
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a tag list defined with this argument. */
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  /** Narrows the query results to elements that relate to a use list defined with this argument. */
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  /** Narrows the query results to only elements that match a search query. */
  search?: Maybe<Scalars['String']>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Determines which site(s) the elements should be queried in. Defaults to the current (requested) site. */
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  /** Narrows the query results based on the elements’ slugs. */
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ statuses. */
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the elements’ titles. */
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results to only elements that have been soft-deleted. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ UIDs. */
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Determines whether only elements with unique IDs should be returned by the query. */
  unique?: Maybe<Scalars['Boolean']>;
  /** Narrows the query results based on the elements’ URIs. */
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Narrows the query results based on the users’ usernames. */
  username?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** This is the interface implemented by all users. */
export type UserInterface = {
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The user's email. */
  email?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The user's first name. */
  firstName?: Maybe<Scalars['String']>;
  /** The user's first name or username. */
  friendlyName?: Maybe<Scalars['String']>;
  /** The user's full name. */
  fullName?: Maybe<Scalars['String']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The user's last name. */
  lastName?: Maybe<Scalars['String']>;
  /** The user's full name or username. */
  name?: Maybe<Scalars['String']>;
  /** The user’s preferences. */
  preferences?: Maybe<Scalars['String']>;
  /** The user’s preferred language. */
  preferredLanguage?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The username. */
  username?: Maybe<Scalars['String']>;
};


/** This is the interface implemented by all users. */
export type UserInterface_CountArgs = {
  field: Scalars['String'];
};

export type ValidateToken = {
  __typename?: 'ValidateToken';
  last_checked: Scalars['timestamptz'];
};


/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export type _Text_Comparison_Exp = {
  _eq?: Maybe<Scalars['_text']>;
  _gt?: Maybe<Scalars['_text']>;
  _gte?: Maybe<Scalars['_text']>;
  _in?: Maybe<Array<Scalars['_text']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['_text']>;
  _lte?: Maybe<Scalars['_text']>;
  _neq?: Maybe<Scalars['_text']>;
  _nin?: Maybe<Array<Scalars['_text']>>;
};

/** columns and relationships of "actions" */
export type Actions = {
  __typename?: 'actions';
  /** An array relationship */
  action_resources: Array<Actions_Resources>;
  /** An aggregate relationship */
  action_resources_aggregate: Actions_Resources_Aggregate;
  /** An array relationship */
  actions_category_items: Array<Actions_Category_Items>;
  /** An aggregate relationship */
  actions_category_items_aggregate: Actions_Category_Items_Aggregate;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  /** An array relationship */
  datafields: Array<Datafields>;
  /** An aggregate relationship */
  datafields_aggregate: Datafields_Aggregate;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An array relationship */
  logs: Array<Logs>;
  /** An aggregate relationship */
  logs_aggregate: Logs_Aggregate;
  /** An object relationship */
  main_action_of_product?: Maybe<Products>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  producer?: Maybe<CategoryInterface>;
  producer_id: Scalars['String'];
  /** An object relationship */
  product?: Maybe<Products>;
  related_to?: Maybe<Scalars['uuid']>;
  result_base_qty: Scalars['Int'];
  /** An object relationship */
  result_packaging?: Maybe<Resources>;
  result_packaging_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  result_resource?: Maybe<Resources>;
  result_resource_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  /** An array relationship */
  tasks_flatten: Array<Tasks_Flatten>;
  /** An aggregate relationship */
  tasks_flatten_aggregate: Tasks_Flatten_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "actions" */
export type ActionsAction_ResourcesArgs = {
  distinct_on?: Maybe<Array<Actions_Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Actions_Resources_Order_By>>;
  where?: Maybe<Actions_Resources_Bool_Exp>;
};


/** columns and relationships of "actions" */
export type ActionsAction_Resources_AggregateArgs = {
  distinct_on?: Maybe<Array<Actions_Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Actions_Resources_Order_By>>;
  where?: Maybe<Actions_Resources_Bool_Exp>;
};


/** columns and relationships of "actions" */
export type ActionsActions_Category_ItemsArgs = {
  distinct_on?: Maybe<Array<Actions_Category_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Actions_Category_Items_Order_By>>;
  where?: Maybe<Actions_Category_Items_Bool_Exp>;
};


/** columns and relationships of "actions" */
export type ActionsActions_Category_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Actions_Category_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Actions_Category_Items_Order_By>>;
  where?: Maybe<Actions_Category_Items_Bool_Exp>;
};


/** columns and relationships of "actions" */
export type ActionsDatafieldsArgs = {
  distinct_on?: Maybe<Array<Datafields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Datafields_Order_By>>;
  where?: Maybe<Datafields_Bool_Exp>;
};


/** columns and relationships of "actions" */
export type ActionsDatafields_AggregateArgs = {
  distinct_on?: Maybe<Array<Datafields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Datafields_Order_By>>;
  where?: Maybe<Datafields_Bool_Exp>;
};


/** columns and relationships of "actions" */
export type ActionsLogsArgs = {
  distinct_on?: Maybe<Array<Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Order_By>>;
  where?: Maybe<Logs_Bool_Exp>;
};


/** columns and relationships of "actions" */
export type ActionsLogs_AggregateArgs = {
  distinct_on?: Maybe<Array<Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Order_By>>;
  where?: Maybe<Logs_Bool_Exp>;
};


/** columns and relationships of "actions" */
export type ActionsProducerArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


/** columns and relationships of "actions" */
export type ActionsTasksArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


/** columns and relationships of "actions" */
export type ActionsTasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


/** columns and relationships of "actions" */
export type ActionsTasks_FlattenArgs = {
  distinct_on?: Maybe<Array<Tasks_Flatten_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Flatten_Order_By>>;
  where?: Maybe<Tasks_Flatten_Bool_Exp>;
};


/** columns and relationships of "actions" */
export type ActionsTasks_Flatten_AggregateArgs = {
  distinct_on?: Maybe<Array<Tasks_Flatten_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Flatten_Order_By>>;
  where?: Maybe<Tasks_Flatten_Bool_Exp>;
};

/** aggregated selection of "actions" */
export type Actions_Aggregate = {
  __typename?: 'actions_aggregate';
  aggregate?: Maybe<Actions_Aggregate_Fields>;
  nodes: Array<Actions>;
};

/** aggregate fields of "actions" */
export type Actions_Aggregate_Fields = {
  __typename?: 'actions_aggregate_fields';
  avg?: Maybe<Actions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Actions_Max_Fields>;
  min?: Maybe<Actions_Min_Fields>;
  stddev?: Maybe<Actions_Stddev_Fields>;
  stddev_pop?: Maybe<Actions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Actions_Stddev_Samp_Fields>;
  sum?: Maybe<Actions_Sum_Fields>;
  var_pop?: Maybe<Actions_Var_Pop_Fields>;
  var_samp?: Maybe<Actions_Var_Samp_Fields>;
  variance?: Maybe<Actions_Variance_Fields>;
};


/** aggregate fields of "actions" */
export type Actions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Actions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "actions" */
export type Actions_Aggregate_Order_By = {
  avg?: Maybe<Actions_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Actions_Max_Order_By>;
  min?: Maybe<Actions_Min_Order_By>;
  stddev?: Maybe<Actions_Stddev_Order_By>;
  stddev_pop?: Maybe<Actions_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Actions_Stddev_Samp_Order_By>;
  sum?: Maybe<Actions_Sum_Order_By>;
  var_pop?: Maybe<Actions_Var_Pop_Order_By>;
  var_samp?: Maybe<Actions_Var_Samp_Order_By>;
  variance?: Maybe<Actions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "actions" */
export type Actions_Arr_Rel_Insert_Input = {
  data: Array<Actions_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Actions_On_Conflict>;
};

/** aggregate avg on columns */
export type Actions_Avg_Fields = {
  __typename?: 'actions_avg_fields';
  order?: Maybe<Scalars['Float']>;
  result_base_qty?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "actions" */
export type Actions_Avg_Order_By = {
  order?: Maybe<Order_By>;
  result_base_qty?: Maybe<Order_By>;
};

export type Actions_Blueprint_Entry = ElementInterface & EntryInterface & {
  __typename?: 'actions_blueprint_Entry';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Returns the entry’s canonical ID. */
  canonicalId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid?: Maybe<Scalars['String']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The current revision for the entry. */
  currentRevision?: Maybe<EntryInterface>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The creator of a given draft. */
  draftCreator?: Maybe<UserInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId?: Maybe<Scalars['Int']>;
  /** The name of the draft. */
  draftName?: Maybe<Scalars['String']>;
  /** The notes for the draft. */
  draftNotes?: Maybe<Scalars['String']>;
  /** The drafts for the entry. */
  drafts?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** Returns whether this is a draft. */
  isDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is a revision. */
  isRevision?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
  isUnsavedDraft?: Maybe<Scalars['Boolean']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>;
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<EntryInterface>;
  /** The creator of a given revision. */
  revisionCreator?: Maybe<UserInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId?: Maybe<Scalars['Int']>;
  /** The revisions for the entry. */
  revisions?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>;
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic?: Maybe<SeomaticInterface>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** Returns the entry’s canonical ID. */
  sourceId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  sourceUid?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  tasks?: Maybe<Array<Maybe<Tasks_NeoField>>>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
};


export type Actions_Blueprint_Entry_CountArgs = {
  field: Scalars['String'];
};


export type Actions_Blueprint_EntryAncestorsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Actions_Blueprint_EntryChildrenArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Actions_Blueprint_EntryDescendantsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Actions_Blueprint_EntryDraftsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Actions_Blueprint_EntryLocalizedArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Actions_Blueprint_EntryNextArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Actions_Blueprint_EntryParentArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Actions_Blueprint_EntryPrevArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Actions_Blueprint_EntryRevisionsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Actions_Blueprint_EntrySeomaticArgs = {
  asArray?: Maybe<Scalars['Boolean']>;
  environment?: Maybe<SeomaticEnvironment>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  uri?: Maybe<Scalars['String']>;
};


export type Actions_Blueprint_EntryTasksArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** Boolean expression to filter rows from the table "actions". All fields are combined with a logical 'AND'. */
export type Actions_Bool_Exp = {
  _and?: Maybe<Array<Actions_Bool_Exp>>;
  _not?: Maybe<Actions_Bool_Exp>;
  _or?: Maybe<Array<Actions_Bool_Exp>>;
  action_resources?: Maybe<Actions_Resources_Bool_Exp>;
  actions_category_items?: Maybe<Actions_Category_Items_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<String_Comparison_Exp>;
  datafields?: Maybe<Datafields_Bool_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  logs?: Maybe<Logs_Bool_Exp>;
  main_action_of_product?: Maybe<Products_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  note?: Maybe<String_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  producer_id?: Maybe<String_Comparison_Exp>;
  product?: Maybe<Products_Bool_Exp>;
  related_to?: Maybe<Uuid_Comparison_Exp>;
  result_base_qty?: Maybe<Int_Comparison_Exp>;
  result_packaging?: Maybe<Resources_Bool_Exp>;
  result_packaging_id?: Maybe<Uuid_Comparison_Exp>;
  result_resource?: Maybe<Resources_Bool_Exp>;
  result_resource_id?: Maybe<Uuid_Comparison_Exp>;
  tasks?: Maybe<Tasks_Bool_Exp>;
  tasks_flatten?: Maybe<Tasks_Flatten_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "actions_category_items" */
export type Actions_Category_Items = {
  __typename?: 'actions_category_items';
  /** An object relationship */
  action: Actions;
  action_id: Scalars['uuid'];
  /** An object relationship */
  category: Category_Items;
  category_id: Scalars['uuid'];
};

/** aggregated selection of "actions_category_items" */
export type Actions_Category_Items_Aggregate = {
  __typename?: 'actions_category_items_aggregate';
  aggregate?: Maybe<Actions_Category_Items_Aggregate_Fields>;
  nodes: Array<Actions_Category_Items>;
};

/** aggregate fields of "actions_category_items" */
export type Actions_Category_Items_Aggregate_Fields = {
  __typename?: 'actions_category_items_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Actions_Category_Items_Max_Fields>;
  min?: Maybe<Actions_Category_Items_Min_Fields>;
};


/** aggregate fields of "actions_category_items" */
export type Actions_Category_Items_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Actions_Category_Items_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "actions_category_items" */
export type Actions_Category_Items_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Actions_Category_Items_Max_Order_By>;
  min?: Maybe<Actions_Category_Items_Min_Order_By>;
};

/** input type for inserting array relation for remote table "actions_category_items" */
export type Actions_Category_Items_Arr_Rel_Insert_Input = {
  data: Array<Actions_Category_Items_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Actions_Category_Items_On_Conflict>;
};

/** Boolean expression to filter rows from the table "actions_category_items". All fields are combined with a logical 'AND'. */
export type Actions_Category_Items_Bool_Exp = {
  _and?: Maybe<Array<Actions_Category_Items_Bool_Exp>>;
  _not?: Maybe<Actions_Category_Items_Bool_Exp>;
  _or?: Maybe<Array<Actions_Category_Items_Bool_Exp>>;
  action?: Maybe<Actions_Bool_Exp>;
  action_id?: Maybe<Uuid_Comparison_Exp>;
  category?: Maybe<Category_Items_Bool_Exp>;
  category_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "actions_category_items" */
export enum Actions_Category_Items_Constraint {
  /** unique or primary key constraint */
  ActionsCategoryItemsPkey = 'actions_category_items_pkey'
}

/** input type for inserting data into table "actions_category_items" */
export type Actions_Category_Items_Insert_Input = {
  action?: Maybe<Actions_Obj_Rel_Insert_Input>;
  action_id?: Maybe<Scalars['uuid']>;
  category?: Maybe<Category_Items_Obj_Rel_Insert_Input>;
  category_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Actions_Category_Items_Max_Fields = {
  __typename?: 'actions_category_items_max_fields';
  action_id?: Maybe<Scalars['uuid']>;
  category_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "actions_category_items" */
export type Actions_Category_Items_Max_Order_By = {
  action_id?: Maybe<Order_By>;
  category_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Actions_Category_Items_Min_Fields = {
  __typename?: 'actions_category_items_min_fields';
  action_id?: Maybe<Scalars['uuid']>;
  category_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "actions_category_items" */
export type Actions_Category_Items_Min_Order_By = {
  action_id?: Maybe<Order_By>;
  category_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "actions_category_items" */
export type Actions_Category_Items_Mutation_Response = {
  __typename?: 'actions_category_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Actions_Category_Items>;
};

/** input type for inserting object relation for remote table "actions_category_items" */
export type Actions_Category_Items_Obj_Rel_Insert_Input = {
  data: Actions_Category_Items_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Actions_Category_Items_On_Conflict>;
};

/** on conflict condition type for table "actions_category_items" */
export type Actions_Category_Items_On_Conflict = {
  constraint: Actions_Category_Items_Constraint;
  update_columns?: Array<Actions_Category_Items_Update_Column>;
  where?: Maybe<Actions_Category_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "actions_category_items". */
export type Actions_Category_Items_Order_By = {
  action?: Maybe<Actions_Order_By>;
  action_id?: Maybe<Order_By>;
  category?: Maybe<Category_Items_Order_By>;
  category_id?: Maybe<Order_By>;
};

/** primary key columns input for table: actions_category_items */
export type Actions_Category_Items_Pk_Columns_Input = {
  action_id: Scalars['uuid'];
  category_id: Scalars['uuid'];
};

/** select columns of table "actions_category_items" */
export enum Actions_Category_Items_Select_Column {
  /** column name */
  ActionId = 'action_id',
  /** column name */
  CategoryId = 'category_id'
}

/** input type for updating data in table "actions_category_items" */
export type Actions_Category_Items_Set_Input = {
  action_id?: Maybe<Scalars['uuid']>;
  category_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "actions_category_items" */
export enum Actions_Category_Items_Update_Column {
  /** column name */
  ActionId = 'action_id',
  /** column name */
  CategoryId = 'category_id'
}

/** unique or primary key constraints on table "actions" */
export enum Actions_Constraint {
  /** unique or primary key constraint */
  ActionsPkey = 'actions_pkey'
}

/** input type for incrementing numeric columns in table "actions" */
export type Actions_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
  result_base_qty?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "actions" */
export type Actions_Insert_Input = {
  action_resources?: Maybe<Actions_Resources_Arr_Rel_Insert_Input>;
  actions_category_items?: Maybe<Actions_Category_Items_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  datafields?: Maybe<Datafields_Arr_Rel_Insert_Input>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  logs?: Maybe<Logs_Arr_Rel_Insert_Input>;
  main_action_of_product?: Maybe<Products_Obj_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  producer_id?: Maybe<Scalars['String']>;
  product?: Maybe<Products_Obj_Rel_Insert_Input>;
  related_to?: Maybe<Scalars['uuid']>;
  result_base_qty?: Maybe<Scalars['Int']>;
  result_packaging?: Maybe<Resources_Obj_Rel_Insert_Input>;
  result_packaging_id?: Maybe<Scalars['uuid']>;
  result_resource?: Maybe<Resources_Obj_Rel_Insert_Input>;
  result_resource_id?: Maybe<Scalars['uuid']>;
  tasks?: Maybe<Tasks_Arr_Rel_Insert_Input>;
  tasks_flatten?: Maybe<Tasks_Flatten_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Actions_Max_Fields = {
  __typename?: 'actions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  producer_id?: Maybe<Scalars['String']>;
  related_to?: Maybe<Scalars['uuid']>;
  result_base_qty?: Maybe<Scalars['Int']>;
  result_packaging_id?: Maybe<Scalars['uuid']>;
  result_resource_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "actions" */
export type Actions_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
  related_to?: Maybe<Order_By>;
  result_base_qty?: Maybe<Order_By>;
  result_packaging_id?: Maybe<Order_By>;
  result_resource_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Actions_Min_Fields = {
  __typename?: 'actions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  producer_id?: Maybe<Scalars['String']>;
  related_to?: Maybe<Scalars['uuid']>;
  result_base_qty?: Maybe<Scalars['Int']>;
  result_packaging_id?: Maybe<Scalars['uuid']>;
  result_resource_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "actions" */
export type Actions_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
  related_to?: Maybe<Order_By>;
  result_base_qty?: Maybe<Order_By>;
  result_packaging_id?: Maybe<Order_By>;
  result_resource_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "actions" */
export type Actions_Mutation_Response = {
  __typename?: 'actions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Actions>;
};

/** input type for inserting object relation for remote table "actions" */
export type Actions_Obj_Rel_Insert_Input = {
  data: Actions_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Actions_On_Conflict>;
};

/** on conflict condition type for table "actions" */
export type Actions_On_Conflict = {
  constraint: Actions_Constraint;
  update_columns?: Array<Actions_Update_Column>;
  where?: Maybe<Actions_Bool_Exp>;
};

/** Ordering options when selecting data from "actions". */
export type Actions_Order_By = {
  action_resources_aggregate?: Maybe<Actions_Resources_Aggregate_Order_By>;
  actions_category_items_aggregate?: Maybe<Actions_Category_Items_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  datafields_aggregate?: Maybe<Datafields_Aggregate_Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logs_aggregate?: Maybe<Logs_Aggregate_Order_By>;
  main_action_of_product?: Maybe<Products_Order_By>;
  name?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
  product?: Maybe<Products_Order_By>;
  related_to?: Maybe<Order_By>;
  result_base_qty?: Maybe<Order_By>;
  result_packaging?: Maybe<Resources_Order_By>;
  result_packaging_id?: Maybe<Order_By>;
  result_resource?: Maybe<Resources_Order_By>;
  result_resource_id?: Maybe<Order_By>;
  tasks_aggregate?: Maybe<Tasks_Aggregate_Order_By>;
  tasks_flatten_aggregate?: Maybe<Tasks_Flatten_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: actions */
export type Actions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "actions_resources" */
export type Actions_Resources = {
  __typename?: 'actions_resources';
  /** An object relationship */
  action: Actions;
  action_id: Scalars['uuid'];
  /** An object relationship */
  resource: Resources;
  resource_id: Scalars['uuid'];
  unit?: Maybe<Scalars['String']>;
  usage: Usage_Types_Enum;
};

/** aggregated selection of "actions_resources" */
export type Actions_Resources_Aggregate = {
  __typename?: 'actions_resources_aggregate';
  aggregate?: Maybe<Actions_Resources_Aggregate_Fields>;
  nodes: Array<Actions_Resources>;
};

/** aggregate fields of "actions_resources" */
export type Actions_Resources_Aggregate_Fields = {
  __typename?: 'actions_resources_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Actions_Resources_Max_Fields>;
  min?: Maybe<Actions_Resources_Min_Fields>;
};


/** aggregate fields of "actions_resources" */
export type Actions_Resources_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Actions_Resources_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "actions_resources" */
export type Actions_Resources_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Actions_Resources_Max_Order_By>;
  min?: Maybe<Actions_Resources_Min_Order_By>;
};

/** input type for inserting array relation for remote table "actions_resources" */
export type Actions_Resources_Arr_Rel_Insert_Input = {
  data: Array<Actions_Resources_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Actions_Resources_On_Conflict>;
};

/** Boolean expression to filter rows from the table "actions_resources". All fields are combined with a logical 'AND'. */
export type Actions_Resources_Bool_Exp = {
  _and?: Maybe<Array<Actions_Resources_Bool_Exp>>;
  _not?: Maybe<Actions_Resources_Bool_Exp>;
  _or?: Maybe<Array<Actions_Resources_Bool_Exp>>;
  action?: Maybe<Actions_Bool_Exp>;
  action_id?: Maybe<Uuid_Comparison_Exp>;
  resource?: Maybe<Resources_Bool_Exp>;
  resource_id?: Maybe<Uuid_Comparison_Exp>;
  unit?: Maybe<String_Comparison_Exp>;
  usage?: Maybe<Usage_Types_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "actions_resources" */
export enum Actions_Resources_Constraint {
  /** unique or primary key constraint */
  ActionsResourcesActionIdResourceIdUsageKey = 'actions_resources_action_id_resource_id_usage_key',
  /** unique or primary key constraint */
  ActionsResourcesPkey = 'actions_resources_pkey'
}

/** input type for inserting data into table "actions_resources" */
export type Actions_Resources_Insert_Input = {
  action?: Maybe<Actions_Obj_Rel_Insert_Input>;
  action_id?: Maybe<Scalars['uuid']>;
  resource?: Maybe<Resources_Obj_Rel_Insert_Input>;
  resource_id?: Maybe<Scalars['uuid']>;
  unit?: Maybe<Scalars['String']>;
  usage?: Maybe<Usage_Types_Enum>;
};

/** aggregate max on columns */
export type Actions_Resources_Max_Fields = {
  __typename?: 'actions_resources_max_fields';
  action_id?: Maybe<Scalars['uuid']>;
  resource_id?: Maybe<Scalars['uuid']>;
  unit?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "actions_resources" */
export type Actions_Resources_Max_Order_By = {
  action_id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  unit?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Actions_Resources_Min_Fields = {
  __typename?: 'actions_resources_min_fields';
  action_id?: Maybe<Scalars['uuid']>;
  resource_id?: Maybe<Scalars['uuid']>;
  unit?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "actions_resources" */
export type Actions_Resources_Min_Order_By = {
  action_id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  unit?: Maybe<Order_By>;
};

/** response of any mutation on the table "actions_resources" */
export type Actions_Resources_Mutation_Response = {
  __typename?: 'actions_resources_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Actions_Resources>;
};

/** input type for inserting object relation for remote table "actions_resources" */
export type Actions_Resources_Obj_Rel_Insert_Input = {
  data: Actions_Resources_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Actions_Resources_On_Conflict>;
};

/** on conflict condition type for table "actions_resources" */
export type Actions_Resources_On_Conflict = {
  constraint: Actions_Resources_Constraint;
  update_columns?: Array<Actions_Resources_Update_Column>;
  where?: Maybe<Actions_Resources_Bool_Exp>;
};

/** Ordering options when selecting data from "actions_resources". */
export type Actions_Resources_Order_By = {
  action?: Maybe<Actions_Order_By>;
  action_id?: Maybe<Order_By>;
  resource?: Maybe<Resources_Order_By>;
  resource_id?: Maybe<Order_By>;
  unit?: Maybe<Order_By>;
  usage?: Maybe<Order_By>;
};

/** primary key columns input for table: actions_resources */
export type Actions_Resources_Pk_Columns_Input = {
  action_id: Scalars['uuid'];
  resource_id: Scalars['uuid'];
  usage: Usage_Types_Enum;
};

/** select columns of table "actions_resources" */
export enum Actions_Resources_Select_Column {
  /** column name */
  ActionId = 'action_id',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  Unit = 'unit',
  /** column name */
  Usage = 'usage'
}

/** input type for updating data in table "actions_resources" */
export type Actions_Resources_Set_Input = {
  action_id?: Maybe<Scalars['uuid']>;
  resource_id?: Maybe<Scalars['uuid']>;
  unit?: Maybe<Scalars['String']>;
  usage?: Maybe<Usage_Types_Enum>;
};

/** update columns of table "actions_resources" */
export enum Actions_Resources_Update_Column {
  /** column name */
  ActionId = 'action_id',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  Unit = 'unit',
  /** column name */
  Usage = 'usage'
}

/** select columns of table "actions" */
export enum Actions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  Order = 'order',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  RelatedTo = 'related_to',
  /** column name */
  ResultBaseQty = 'result_base_qty',
  /** column name */
  ResultPackagingId = 'result_packaging_id',
  /** column name */
  ResultResourceId = 'result_resource_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "actions" */
export type Actions_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  producer_id?: Maybe<Scalars['String']>;
  related_to?: Maybe<Scalars['uuid']>;
  result_base_qty?: Maybe<Scalars['Int']>;
  result_packaging_id?: Maybe<Scalars['uuid']>;
  result_resource_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Actions_Stddev_Fields = {
  __typename?: 'actions_stddev_fields';
  order?: Maybe<Scalars['Float']>;
  result_base_qty?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "actions" */
export type Actions_Stddev_Order_By = {
  order?: Maybe<Order_By>;
  result_base_qty?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Actions_Stddev_Pop_Fields = {
  __typename?: 'actions_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
  result_base_qty?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "actions" */
export type Actions_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>;
  result_base_qty?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Actions_Stddev_Samp_Fields = {
  __typename?: 'actions_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
  result_base_qty?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "actions" */
export type Actions_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>;
  result_base_qty?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Actions_Sum_Fields = {
  __typename?: 'actions_sum_fields';
  order?: Maybe<Scalars['Int']>;
  result_base_qty?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "actions" */
export type Actions_Sum_Order_By = {
  order?: Maybe<Order_By>;
  result_base_qty?: Maybe<Order_By>;
};

/** update columns of table "actions" */
export enum Actions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  Order = 'order',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  RelatedTo = 'related_to',
  /** column name */
  ResultBaseQty = 'result_base_qty',
  /** column name */
  ResultPackagingId = 'result_packaging_id',
  /** column name */
  ResultResourceId = 'result_resource_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Actions_Var_Pop_Fields = {
  __typename?: 'actions_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
  result_base_qty?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "actions" */
export type Actions_Var_Pop_Order_By = {
  order?: Maybe<Order_By>;
  result_base_qty?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Actions_Var_Samp_Fields = {
  __typename?: 'actions_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
  result_base_qty?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "actions" */
export type Actions_Var_Samp_Order_By = {
  order?: Maybe<Order_By>;
  result_base_qty?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Actions_Variance_Fields = {
  __typename?: 'actions_variance_fields';
  order?: Maybe<Scalars['Float']>;
  result_base_qty?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "actions" */
export type Actions_Variance_Order_By = {
  order?: Maybe<Order_By>;
  result_base_qty?: Maybe<Order_By>;
};

export type AtAGlance_BlockType = ElementInterface & SuperTableBlockInterface & {
  __typename?: 'atAGlance_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the Super Table block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Array<Maybe<AssetInterface>>>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The ID of the element that owns the Super Table block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The sort order of the Super Table block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The ID of the Super Table block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type AtAGlance_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type AtAGlance_BlockTypeImageArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type AtAGlance_SuperTableBlockContainerInput = {
  atAGlance_0?: Maybe<AtAGlance_AtAGlance_0_SuperTableBlockInput>;
};

export type AtAGlance_SuperTableField = AtAGlance_BlockType;

export type AtAGlance_SuperTableInput = {
  blocks?: Maybe<Array<Maybe<AtAGlance_SuperTableBlockContainerInput>>>;
  sortOrder?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};

export type AtAGlance_AtAGlance_0_SuperTableBlockInput = {
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Array<Maybe<Scalars['Int']>>>;
  text?: Maybe<Scalars['String']>;
};

export type Background_Background_Entry = ElementInterface & EntryInterface & {
  __typename?: 'background_background_Entry';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  callToAction?: Maybe<Array<Maybe<CallToAction_NeoField>>>;
  /** Returns the entry’s canonical ID. */
  canonicalId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid?: Maybe<Scalars['String']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>;
  contentBlocks?: Maybe<Array<Maybe<ContentBlocks_NeoField>>>;
  /** The current revision for the entry. */
  currentRevision?: Maybe<EntryInterface>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The creator of a given draft. */
  draftCreator?: Maybe<UserInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId?: Maybe<Scalars['Int']>;
  /** The name of the draft. */
  draftName?: Maybe<Scalars['String']>;
  /** The notes for the draft. */
  draftNotes?: Maybe<Scalars['String']>;
  /** The drafts for the entry. */
  drafts?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  intro?: Maybe<Scalars['String']>;
  introImage?: Maybe<Array<Maybe<AssetInterface>>>;
  /** Returns whether this is a draft. */
  isDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is a revision. */
  isRevision?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
  isUnsavedDraft?: Maybe<Scalars['Boolean']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>;
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<EntryInterface>;
  /** The creator of a given revision. */
  revisionCreator?: Maybe<UserInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId?: Maybe<Scalars['Int']>;
  /** The revisions for the entry. */
  revisions?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>;
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>;
  seoFields?: Maybe<Scalars['String']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic?: Maybe<SeomaticInterface>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** Returns the entry’s canonical ID. */
  sourceId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  sourceUid?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
};


export type Background_Background_Entry_CountArgs = {
  field: Scalars['String'];
};


export type Background_Background_EntryAncestorsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Background_Background_EntryCallToActionArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Background_Background_EntryChildrenArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Background_Background_EntryContentBlocksArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Background_Background_EntryDescendantsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Background_Background_EntryDraftsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Background_Background_EntryIntroImageArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Background_Background_EntryLocalizedArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Background_Background_EntryNextArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Background_Background_EntryParentArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Background_Background_EntryPrevArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Background_Background_EntryRevisionsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Background_Background_EntrySeomaticArgs = {
  asArray?: Maybe<Scalars['Boolean']>;
  environment?: Maybe<SeomaticEnvironment>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  uri?: Maybe<Scalars['String']>;
};

export type BlogOverview_BlogOverview_Entry = ElementInterface & EntryInterface & {
  __typename?: 'blogOverview_blogOverview_Entry';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Returns the entry’s canonical ID. */
  canonicalId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid?: Maybe<Scalars['String']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The current revision for the entry. */
  currentRevision?: Maybe<EntryInterface>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The creator of a given draft. */
  draftCreator?: Maybe<UserInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId?: Maybe<Scalars['Int']>;
  /** The name of the draft. */
  draftName?: Maybe<Scalars['String']>;
  /** The notes for the draft. */
  draftNotes?: Maybe<Scalars['String']>;
  /** The drafts for the entry. */
  drafts?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  intro?: Maybe<Scalars['String']>;
  introImage?: Maybe<Array<Maybe<AssetInterface>>>;
  /** Returns whether this is a draft. */
  isDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is a revision. */
  isRevision?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
  isUnsavedDraft?: Maybe<Scalars['Boolean']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>;
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<EntryInterface>;
  /** The creator of a given revision. */
  revisionCreator?: Maybe<UserInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId?: Maybe<Scalars['Int']>;
  /** The revisions for the entry. */
  revisions?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>;
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic?: Maybe<SeomaticInterface>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** Returns the entry’s canonical ID. */
  sourceId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  sourceUid?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
};


export type BlogOverview_BlogOverview_Entry_CountArgs = {
  field: Scalars['String'];
};


export type BlogOverview_BlogOverview_EntryAncestorsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type BlogOverview_BlogOverview_EntryChildrenArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type BlogOverview_BlogOverview_EntryDescendantsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type BlogOverview_BlogOverview_EntryDraftsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type BlogOverview_BlogOverview_EntryIntroImageArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type BlogOverview_BlogOverview_EntryLocalizedArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type BlogOverview_BlogOverview_EntryNextArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type BlogOverview_BlogOverview_EntryParentArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type BlogOverview_BlogOverview_EntryPrevArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type BlogOverview_BlogOverview_EntryRevisionsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type BlogOverview_BlogOverview_EntrySeomaticArgs = {
  asArray?: Maybe<Scalars['Boolean']>;
  environment?: Maybe<SeomaticEnvironment>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  uri?: Maybe<Scalars['String']>;
};

export type Blog_Article_Entry = ElementInterface & EntryInterface & {
  __typename?: 'blog_article_Entry';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** Returns the entry’s canonical ID. */
  canonicalId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid?: Maybe<Scalars['String']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The current revision for the entry. */
  currentRevision?: Maybe<EntryInterface>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The creator of a given draft. */
  draftCreator?: Maybe<UserInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId?: Maybe<Scalars['Int']>;
  /** The name of the draft. */
  draftName?: Maybe<Scalars['String']>;
  /** The notes for the draft. */
  draftNotes?: Maybe<Scalars['String']>;
  /** The drafts for the entry. */
  drafts?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** Returns whether this is a draft. */
  isDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is a revision. */
  isRevision?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
  isUnsavedDraft?: Maybe<Scalars['Boolean']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>;
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<EntryInterface>;
  /** The creator of a given revision. */
  revisionCreator?: Maybe<UserInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId?: Maybe<Scalars['Int']>;
  /** The revisions for the entry. */
  revisions?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>;
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic?: Maybe<SeomaticInterface>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** Returns the entry’s canonical ID. */
  sourceId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  sourceUid?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
};


export type Blog_Article_Entry_CountArgs = {
  field: Scalars['String'];
};


export type Blog_Article_EntryAncestorsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Blog_Article_EntryChildrenArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Blog_Article_EntryDescendantsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Blog_Article_EntryDraftsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Blog_Article_EntryLocalizedArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Blog_Article_EntryNextArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Blog_Article_EntryParentArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Blog_Article_EntryPrevArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Blog_Article_EntryRevisionsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Blog_Article_EntrySeomaticArgs = {
  asArray?: Maybe<Scalars['Boolean']>;
  environment?: Maybe<SeomaticEnvironment>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  uri?: Maybe<Scalars['String']>;
};

export type BrowseMoreLink_MatrixBlockContainerInput = {
  linkBlock?: Maybe<BrowseMoreLink_LinkBlock_MatrixBlockInput>;
};

export type BrowseMoreLink_MatrixField = BrowseMoreLink_LinkBlock_BlockType;

export type BrowseMoreLink_MatrixInput = {
  blocks?: Maybe<Array<Maybe<BrowseMoreLink_MatrixBlockContainerInput>>>;
  sortOrder?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};

export type BrowseMoreLink_LinkBlock_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'browseMoreLink_linkBlock_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  target?: Maybe<LinkField_Link>;
  target_deprecated?: Maybe<Array<Maybe<EntryInterface>>>;
  text?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type BrowseMoreLink_LinkBlock_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type BrowseMoreLink_LinkBlock_BlockTypeTarget_DeprecatedArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};

export type BrowseMoreLink_LinkBlock_MatrixBlockInput = {
  id?: Maybe<Scalars['ID']>;
  target?: Maybe<Scalars['String']>;
  /** (!) __Dieses Feld wird gelöscht__. Bitte die hier gemachte Verlinkung auf das neue "Content" Feld oben migrieren! */
  target_deprecated?: Maybe<Array<Maybe<Scalars['Int']>>>;
  text?: Maybe<Scalars['String']>;
};

export type CallToAction_NeoBlockContainerInput = {
  ctaLink?: Maybe<CallToAction_CtaLink_NeoBlockInput>;
};

export type CallToAction_NeoField = CallToAction_CtaLink_BlockType;

export type CallToAction_NeoInput = {
  blocks?: Maybe<Array<Maybe<CallToAction_NeoBlockContainerInput>>>;
  sortOrder?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};

export type CallToAction_CtaLink_BlockType = ElementInterface & NeoBlockInterface & {
  __typename?: 'callToAction_ctaLink_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  callToActionLink?: Maybe<LinkField_Link>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the neo block. */
  fieldId?: Maybe<Scalars['Int']>;
  headline?: Maybe<Scalars['String']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The Neo block's level. */
  level?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the neo block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the neo block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the neo block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type CallToAction_CtaLink_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type CallToAction_CtaLink_NeoBlockInput = {
  callToActionLink?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  level?: Maybe<Scalars['Int']>;
};

export type Catalog_Catalog_Entry = ElementInterface & EntryInterface & {
  __typename?: 'catalog_catalog_Entry';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  callToAction?: Maybe<Array<Maybe<CallToAction_NeoField>>>;
  /** Returns the entry’s canonical ID. */
  canonicalId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid?: Maybe<Scalars['String']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>;
  contentBlocks?: Maybe<Array<Maybe<ContentBlocks_NeoField>>>;
  /** The current revision for the entry. */
  currentRevision?: Maybe<EntryInterface>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The creator of a given draft. */
  draftCreator?: Maybe<UserInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId?: Maybe<Scalars['Int']>;
  /** The name of the draft. */
  draftName?: Maybe<Scalars['String']>;
  /** The notes for the draft. */
  draftNotes?: Maybe<Scalars['String']>;
  /** The drafts for the entry. */
  drafts?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>;
  featuredContentBlock?: Maybe<Array<Maybe<FeaturedContentBlock_NeoField>>>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  intro?: Maybe<Scalars['String']>;
  introImage?: Maybe<Array<Maybe<AssetInterface>>>;
  /** Returns whether this is a draft. */
  isDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is a revision. */
  isRevision?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
  isUnsavedDraft?: Maybe<Scalars['Boolean']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>;
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<EntryInterface>;
  /** The creator of a given revision. */
  revisionCreator?: Maybe<UserInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId?: Maybe<Scalars['Int']>;
  /** The revisions for the entry. */
  revisions?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>;
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>;
  seoFields?: Maybe<Scalars['String']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic?: Maybe<SeomaticInterface>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** Returns the entry’s canonical ID. */
  sourceId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  sourceUid?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
};


export type Catalog_Catalog_Entry_CountArgs = {
  field: Scalars['String'];
};


export type Catalog_Catalog_EntryAncestorsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Catalog_Catalog_EntryCallToActionArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Catalog_Catalog_EntryChildrenArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Catalog_Catalog_EntryContentBlocksArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Catalog_Catalog_EntryDescendantsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Catalog_Catalog_EntryDraftsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Catalog_Catalog_EntryFeaturedContentBlockArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Catalog_Catalog_EntryIntroImageArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Catalog_Catalog_EntryLocalizedArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Catalog_Catalog_EntryNextArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Catalog_Catalog_EntryParentArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Catalog_Catalog_EntryPrevArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Catalog_Catalog_EntryRevisionsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Catalog_Catalog_EntrySeomaticArgs = {
  asArray?: Maybe<Scalars['Boolean']>;
  environment?: Maybe<SeomaticEnvironment>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  uri?: Maybe<Scalars['String']>;
};

/** columns and relationships of "category_default_types" */
export type Category_Default_Types = {
  __typename?: 'category_default_types';
  _used: Scalars['Boolean'];
  comment: Scalars['String'];
  /** An array relationship */
  usage: Array<Category_Types>;
  /** An aggregate relationship */
  usage_aggregate: Category_Types_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "category_default_types" */
export type Category_Default_TypesUsageArgs = {
  distinct_on?: Maybe<Array<Category_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Types_Order_By>>;
  where?: Maybe<Category_Types_Bool_Exp>;
};


/** columns and relationships of "category_default_types" */
export type Category_Default_TypesUsage_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Types_Order_By>>;
  where?: Maybe<Category_Types_Bool_Exp>;
};

/** aggregated selection of "category_default_types" */
export type Category_Default_Types_Aggregate = {
  __typename?: 'category_default_types_aggregate';
  aggregate?: Maybe<Category_Default_Types_Aggregate_Fields>;
  nodes: Array<Category_Default_Types>;
};

/** aggregate fields of "category_default_types" */
export type Category_Default_Types_Aggregate_Fields = {
  __typename?: 'category_default_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Category_Default_Types_Max_Fields>;
  min?: Maybe<Category_Default_Types_Min_Fields>;
};


/** aggregate fields of "category_default_types" */
export type Category_Default_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Category_Default_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "category_default_types". All fields are combined with a logical 'AND'. */
export type Category_Default_Types_Bool_Exp = {
  _and?: Maybe<Array<Category_Default_Types_Bool_Exp>>;
  _not?: Maybe<Category_Default_Types_Bool_Exp>;
  _or?: Maybe<Array<Category_Default_Types_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  usage?: Maybe<Category_Types_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "category_default_types" */
export enum Category_Default_Types_Constraint {
  /** unique or primary key constraint */
  CategoryDefaultTypesPkey = 'category_default_types_pkey'
}

export enum Category_Default_Types_Enum {
  /** Orte */
  Locations = 'locations',
  /** Maschinen */
  Machines = 'machines',
  /** Produktionshilfsmittel */
  Tools = 'tools'
}

/** Boolean expression to compare columns of type "category_default_types_enum". All fields are combined with logical 'AND'. */
export type Category_Default_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Category_Default_Types_Enum>;
  _in?: Maybe<Array<Category_Default_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Category_Default_Types_Enum>;
  _nin?: Maybe<Array<Category_Default_Types_Enum>>;
};

/** input type for inserting data into table "category_default_types" */
export type Category_Default_Types_Insert_Input = {
  comment?: Maybe<Scalars['String']>;
  usage?: Maybe<Category_Types_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Category_Default_Types_Max_Fields = {
  __typename?: 'category_default_types_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Category_Default_Types_Min_Fields = {
  __typename?: 'category_default_types_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "category_default_types" */
export type Category_Default_Types_Mutation_Response = {
  __typename?: 'category_default_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Category_Default_Types>;
};

/** on conflict condition type for table "category_default_types" */
export type Category_Default_Types_On_Conflict = {
  constraint: Category_Default_Types_Constraint;
  update_columns?: Array<Category_Default_Types_Update_Column>;
  where?: Maybe<Category_Default_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "category_default_types". */
export type Category_Default_Types_Order_By = {
  comment?: Maybe<Order_By>;
  usage_aggregate?: Maybe<Category_Types_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: category_default_types */
export type Category_Default_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "category_default_types" */
export enum Category_Default_Types_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "category_default_types" */
export type Category_Default_Types_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "category_default_types" */
export enum Category_Default_Types_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "category_items" */
export type Category_Items = {
  __typename?: 'category_items';
  _deleted: Scalars['Boolean'];
  /** An array relationship */
  category_item_actions: Array<Actions_Category_Items>;
  /** An aggregate relationship */
  category_item_actions_aggregate: Actions_Category_Items_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  name_normalized?: Maybe<Scalars['String']>;
  producer_id: Scalars['uuid'];
  /** An object relationship */
  type?: Maybe<Category_Types>;
  type_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  usage_in_tasks: Array<Category_Usage_In_Tasks>;
  /** An aggregate relationship */
  usage_in_tasks_aggregate: Category_Usage_In_Tasks_Aggregate;
};


/** columns and relationships of "category_items" */
export type Category_ItemsCategory_Item_ActionsArgs = {
  distinct_on?: Maybe<Array<Actions_Category_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Actions_Category_Items_Order_By>>;
  where?: Maybe<Actions_Category_Items_Bool_Exp>;
};


/** columns and relationships of "category_items" */
export type Category_ItemsCategory_Item_Actions_AggregateArgs = {
  distinct_on?: Maybe<Array<Actions_Category_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Actions_Category_Items_Order_By>>;
  where?: Maybe<Actions_Category_Items_Bool_Exp>;
};


/** columns and relationships of "category_items" */
export type Category_ItemsUsage_In_TasksArgs = {
  distinct_on?: Maybe<Array<Category_Usage_In_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Usage_In_Tasks_Order_By>>;
  where?: Maybe<Category_Usage_In_Tasks_Bool_Exp>;
};


/** columns and relationships of "category_items" */
export type Category_ItemsUsage_In_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Usage_In_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Usage_In_Tasks_Order_By>>;
  where?: Maybe<Category_Usage_In_Tasks_Bool_Exp>;
};

/** aggregated selection of "category_items" */
export type Category_Items_Aggregate = {
  __typename?: 'category_items_aggregate';
  aggregate?: Maybe<Category_Items_Aggregate_Fields>;
  nodes: Array<Category_Items>;
};

/** aggregate fields of "category_items" */
export type Category_Items_Aggregate_Fields = {
  __typename?: 'category_items_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Category_Items_Max_Fields>;
  min?: Maybe<Category_Items_Min_Fields>;
};


/** aggregate fields of "category_items" */
export type Category_Items_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Category_Items_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "category_items" */
export type Category_Items_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Category_Items_Max_Order_By>;
  min?: Maybe<Category_Items_Min_Order_By>;
};

/** input type for inserting array relation for remote table "category_items" */
export type Category_Items_Arr_Rel_Insert_Input = {
  data: Array<Category_Items_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Category_Items_On_Conflict>;
};

/** Boolean expression to filter rows from the table "category_items". All fields are combined with a logical 'AND'. */
export type Category_Items_Bool_Exp = {
  _and?: Maybe<Array<Category_Items_Bool_Exp>>;
  _not?: Maybe<Category_Items_Bool_Exp>;
  _or?: Maybe<Array<Category_Items_Bool_Exp>>;
  category_item_actions?: Maybe<Actions_Category_Items_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  name_normalized?: Maybe<String_Comparison_Exp>;
  producer_id?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<Category_Types_Bool_Exp>;
  type_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  usage_in_tasks?: Maybe<Category_Usage_In_Tasks_Bool_Exp>;
};

/** unique or primary key constraints on table "category_items" */
export enum Category_Items_Constraint {
  /** unique or primary key constraint */
  CategoriesByProducersPkey = 'categories_by_producers_pkey',
  /** unique or primary key constraint */
  CategoryItemsProducerIdNameNormalizedKey = 'category_items_producer_id_name_normalized_key'
}

/** input type for inserting data into table "category_items" */
export type Category_Items_Insert_Input = {
  category_item_actions?: Maybe<Actions_Category_Items_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_normalized?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Category_Types_Obj_Rel_Insert_Input>;
  type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  usage_in_tasks?: Maybe<Category_Usage_In_Tasks_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Category_Items_Max_Fields = {
  __typename?: 'category_items_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_normalized?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "category_items" */
export type Category_Items_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  name_normalized?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
  type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Category_Items_Min_Fields = {
  __typename?: 'category_items_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_normalized?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "category_items" */
export type Category_Items_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  name_normalized?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
  type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "category_items" */
export type Category_Items_Mutation_Response = {
  __typename?: 'category_items_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Category_Items>;
};

/** input type for inserting object relation for remote table "category_items" */
export type Category_Items_Obj_Rel_Insert_Input = {
  data: Category_Items_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Category_Items_On_Conflict>;
};

/** on conflict condition type for table "category_items" */
export type Category_Items_On_Conflict = {
  constraint: Category_Items_Constraint;
  update_columns?: Array<Category_Items_Update_Column>;
  where?: Maybe<Category_Items_Bool_Exp>;
};

/** Ordering options when selecting data from "category_items". */
export type Category_Items_Order_By = {
  category_item_actions_aggregate?: Maybe<Actions_Category_Items_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  name_normalized?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
  type?: Maybe<Category_Types_Order_By>;
  type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  usage_in_tasks_aggregate?: Maybe<Category_Usage_In_Tasks_Aggregate_Order_By>;
};

/** primary key columns input for table: category_items */
export type Category_Items_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "category_items" */
export enum Category_Items_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NameNormalized = 'name_normalized',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "category_items" */
export type Category_Items_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_normalized?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "category_items" */
export enum Category_Items_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NameNormalized = 'name_normalized',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "category_locations" */
export type Category_Locations = {
  __typename?: 'category_locations';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  producer_id: Scalars['uuid'];
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "category_locations" */
export type Category_LocationsTasksArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


/** columns and relationships of "category_locations" */
export type Category_LocationsTasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};

/** aggregated selection of "category_locations" */
export type Category_Locations_Aggregate = {
  __typename?: 'category_locations_aggregate';
  aggregate?: Maybe<Category_Locations_Aggregate_Fields>;
  nodes: Array<Category_Locations>;
};

/** aggregate fields of "category_locations" */
export type Category_Locations_Aggregate_Fields = {
  __typename?: 'category_locations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Category_Locations_Max_Fields>;
  min?: Maybe<Category_Locations_Min_Fields>;
};


/** aggregate fields of "category_locations" */
export type Category_Locations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Category_Locations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "category_locations". All fields are combined with a logical 'AND'. */
export type Category_Locations_Bool_Exp = {
  _and?: Maybe<Array<Category_Locations_Bool_Exp>>;
  _not?: Maybe<Category_Locations_Bool_Exp>;
  _or?: Maybe<Array<Category_Locations_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  producer_id?: Maybe<Uuid_Comparison_Exp>;
  tasks?: Maybe<Tasks_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "category_locations" */
export enum Category_Locations_Constraint {
  /** unique or primary key constraint */
  CategoryLocationsPkey = 'category_locations_pkey',
  /** unique or primary key constraint */
  CategoryLocationsProducerIdNameKey = 'category_locations_producer_id_name_key'
}

/** input type for inserting data into table "category_locations" */
export type Category_Locations_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['uuid']>;
  tasks?: Maybe<Tasks_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Category_Locations_Max_Fields = {
  __typename?: 'category_locations_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Category_Locations_Min_Fields = {
  __typename?: 'category_locations_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "category_locations" */
export type Category_Locations_Mutation_Response = {
  __typename?: 'category_locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Category_Locations>;
};

/** input type for inserting object relation for remote table "category_locations" */
export type Category_Locations_Obj_Rel_Insert_Input = {
  data: Category_Locations_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Category_Locations_On_Conflict>;
};

/** on conflict condition type for table "category_locations" */
export type Category_Locations_On_Conflict = {
  constraint: Category_Locations_Constraint;
  update_columns?: Array<Category_Locations_Update_Column>;
  where?: Maybe<Category_Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "category_locations". */
export type Category_Locations_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
  tasks_aggregate?: Maybe<Tasks_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: category_locations */
export type Category_Locations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "category_locations" */
export enum Category_Locations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "category_locations" */
export type Category_Locations_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "category_locations" */
export enum Category_Locations_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "category_machines" */
export type Category_Machines = {
  __typename?: 'category_machines';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  producer_id: Scalars['uuid'];
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "category_machines" */
export type Category_MachinesTasksArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


/** columns and relationships of "category_machines" */
export type Category_MachinesTasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};

/** aggregated selection of "category_machines" */
export type Category_Machines_Aggregate = {
  __typename?: 'category_machines_aggregate';
  aggregate?: Maybe<Category_Machines_Aggregate_Fields>;
  nodes: Array<Category_Machines>;
};

/** aggregate fields of "category_machines" */
export type Category_Machines_Aggregate_Fields = {
  __typename?: 'category_machines_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Category_Machines_Max_Fields>;
  min?: Maybe<Category_Machines_Min_Fields>;
};


/** aggregate fields of "category_machines" */
export type Category_Machines_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Category_Machines_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "category_machines". All fields are combined with a logical 'AND'. */
export type Category_Machines_Bool_Exp = {
  _and?: Maybe<Array<Category_Machines_Bool_Exp>>;
  _not?: Maybe<Category_Machines_Bool_Exp>;
  _or?: Maybe<Array<Category_Machines_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  producer_id?: Maybe<Uuid_Comparison_Exp>;
  tasks?: Maybe<Tasks_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "category_machines" */
export enum Category_Machines_Constraint {
  /** unique or primary key constraint */
  CategoryMachinesProducerIdNameKey = 'category_machines_producer_id_name_key',
  /** unique or primary key constraint */
  MachinesPkey = 'machines_pkey'
}

/** input type for inserting data into table "category_machines" */
export type Category_Machines_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['uuid']>;
  tasks?: Maybe<Tasks_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Category_Machines_Max_Fields = {
  __typename?: 'category_machines_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Category_Machines_Min_Fields = {
  __typename?: 'category_machines_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "category_machines" */
export type Category_Machines_Mutation_Response = {
  __typename?: 'category_machines_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Category_Machines>;
};

/** input type for inserting object relation for remote table "category_machines" */
export type Category_Machines_Obj_Rel_Insert_Input = {
  data: Category_Machines_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Category_Machines_On_Conflict>;
};

/** on conflict condition type for table "category_machines" */
export type Category_Machines_On_Conflict = {
  constraint: Category_Machines_Constraint;
  update_columns?: Array<Category_Machines_Update_Column>;
  where?: Maybe<Category_Machines_Bool_Exp>;
};

/** Ordering options when selecting data from "category_machines". */
export type Category_Machines_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
  tasks_aggregate?: Maybe<Tasks_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: category_machines */
export type Category_Machines_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "category_machines" */
export enum Category_Machines_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "category_machines" */
export type Category_Machines_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "category_machines" */
export enum Category_Machines_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "category_types" */
export type Category_Types = {
  __typename?: 'category_types';
  _deleted: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  default_type?: Maybe<Category_Default_Types_Enum>;
  id: Scalars['uuid'];
  /** An array relationship */
  items: Array<Category_Items>;
  /** An aggregate relationship */
  items_aggregate: Category_Items_Aggregate;
  name: Scalars['String'];
  name_normalized?: Maybe<Scalars['String']>;
  producer_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "category_types" */
export type Category_TypesItemsArgs = {
  distinct_on?: Maybe<Array<Category_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Items_Order_By>>;
  where?: Maybe<Category_Items_Bool_Exp>;
};


/** columns and relationships of "category_types" */
export type Category_TypesItems_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Items_Order_By>>;
  where?: Maybe<Category_Items_Bool_Exp>;
};

/** aggregated selection of "category_types" */
export type Category_Types_Aggregate = {
  __typename?: 'category_types_aggregate';
  aggregate?: Maybe<Category_Types_Aggregate_Fields>;
  nodes: Array<Category_Types>;
};

/** aggregate fields of "category_types" */
export type Category_Types_Aggregate_Fields = {
  __typename?: 'category_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Category_Types_Max_Fields>;
  min?: Maybe<Category_Types_Min_Fields>;
};


/** aggregate fields of "category_types" */
export type Category_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Category_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "category_types" */
export type Category_Types_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Category_Types_Max_Order_By>;
  min?: Maybe<Category_Types_Min_Order_By>;
};

/** input type for inserting array relation for remote table "category_types" */
export type Category_Types_Arr_Rel_Insert_Input = {
  data: Array<Category_Types_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Category_Types_On_Conflict>;
};

/** Boolean expression to filter rows from the table "category_types". All fields are combined with a logical 'AND'. */
export type Category_Types_Bool_Exp = {
  _and?: Maybe<Array<Category_Types_Bool_Exp>>;
  _not?: Maybe<Category_Types_Bool_Exp>;
  _or?: Maybe<Array<Category_Types_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  default_type?: Maybe<Category_Default_Types_Enum_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  items?: Maybe<Category_Items_Bool_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  name_normalized?: Maybe<String_Comparison_Exp>;
  producer_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "category_types" */
export enum Category_Types_Constraint {
  /** unique or primary key constraint */
  CategoryTypesNameNormalizedProducerIdKey = 'category_types_name_normalized_producer_id_key',
  /** unique or primary key constraint */
  CategoryTypesPkey = 'category_types_pkey',
  /** unique or primary key constraint */
  CategoryTypesProducerIdDefaultTypeKey = 'category_types_producer_id_default_type_key'
}

/** input type for inserting data into table "category_types" */
export type Category_Types_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  default_type?: Maybe<Category_Default_Types_Enum>;
  id?: Maybe<Scalars['uuid']>;
  items?: Maybe<Category_Items_Arr_Rel_Insert_Input>;
  name?: Maybe<Scalars['String']>;
  name_normalized?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Category_Types_Max_Fields = {
  __typename?: 'category_types_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_normalized?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "category_types" */
export type Category_Types_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  name_normalized?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Category_Types_Min_Fields = {
  __typename?: 'category_types_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_normalized?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "category_types" */
export type Category_Types_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  name_normalized?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "category_types" */
export type Category_Types_Mutation_Response = {
  __typename?: 'category_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Category_Types>;
};

/** input type for inserting object relation for remote table "category_types" */
export type Category_Types_Obj_Rel_Insert_Input = {
  data: Category_Types_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Category_Types_On_Conflict>;
};

/** on conflict condition type for table "category_types" */
export type Category_Types_On_Conflict = {
  constraint: Category_Types_Constraint;
  update_columns?: Array<Category_Types_Update_Column>;
  where?: Maybe<Category_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "category_types". */
export type Category_Types_Order_By = {
  created_at?: Maybe<Order_By>;
  default_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  items_aggregate?: Maybe<Category_Items_Aggregate_Order_By>;
  name?: Maybe<Order_By>;
  name_normalized?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: category_types */
export type Category_Types_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "category_types" */
export enum Category_Types_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultType = 'default_type',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NameNormalized = 'name_normalized',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "category_types" */
export type Category_Types_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  default_type?: Maybe<Category_Default_Types_Enum>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_normalized?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "category_types" */
export enum Category_Types_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultType = 'default_type',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NameNormalized = 'name_normalized',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "category_usage_in_tasks" */
export type Category_Usage_In_Tasks = {
  __typename?: 'category_usage_in_tasks';
  /** An object relationship */
  action: Actions;
  action_id: Scalars['uuid'];
  /** An object relationship */
  actions_category?: Maybe<Actions_Category_Items>;
  /** An object relationship */
  category: Category_Items;
  category_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid'];
  usage?: Maybe<Scalars['String']>;
};

/** aggregated selection of "category_usage_in_tasks" */
export type Category_Usage_In_Tasks_Aggregate = {
  __typename?: 'category_usage_in_tasks_aggregate';
  aggregate?: Maybe<Category_Usage_In_Tasks_Aggregate_Fields>;
  nodes: Array<Category_Usage_In_Tasks>;
};

/** aggregate fields of "category_usage_in_tasks" */
export type Category_Usage_In_Tasks_Aggregate_Fields = {
  __typename?: 'category_usage_in_tasks_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Category_Usage_In_Tasks_Max_Fields>;
  min?: Maybe<Category_Usage_In_Tasks_Min_Fields>;
};


/** aggregate fields of "category_usage_in_tasks" */
export type Category_Usage_In_Tasks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Category_Usage_In_Tasks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "category_usage_in_tasks" */
export type Category_Usage_In_Tasks_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Category_Usage_In_Tasks_Max_Order_By>;
  min?: Maybe<Category_Usage_In_Tasks_Min_Order_By>;
};

/** input type for inserting array relation for remote table "category_usage_in_tasks" */
export type Category_Usage_In_Tasks_Arr_Rel_Insert_Input = {
  data: Array<Category_Usage_In_Tasks_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Category_Usage_In_Tasks_On_Conflict>;
};

/** Boolean expression to filter rows from the table "category_usage_in_tasks". All fields are combined with a logical 'AND'. */
export type Category_Usage_In_Tasks_Bool_Exp = {
  _and?: Maybe<Array<Category_Usage_In_Tasks_Bool_Exp>>;
  _not?: Maybe<Category_Usage_In_Tasks_Bool_Exp>;
  _or?: Maybe<Array<Category_Usage_In_Tasks_Bool_Exp>>;
  action?: Maybe<Actions_Bool_Exp>;
  action_id?: Maybe<Uuid_Comparison_Exp>;
  actions_category?: Maybe<Actions_Category_Items_Bool_Exp>;
  category?: Maybe<Category_Items_Bool_Exp>;
  category_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  task?: Maybe<Tasks_Bool_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
  usage?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "category_usage_in_tasks" */
export enum Category_Usage_In_Tasks_Constraint {
  /** unique or primary key constraint */
  CategoryUsageInTasksCategoryIdTaskIdKey = 'category_usage_in_tasks_category_id_task_id_key',
  /** unique or primary key constraint */
  CategoryUsageInTasksPkey = 'category_usage_in_tasks_pkey'
}

/** input type for inserting data into table "category_usage_in_tasks" */
export type Category_Usage_In_Tasks_Insert_Input = {
  action?: Maybe<Actions_Obj_Rel_Insert_Input>;
  action_id?: Maybe<Scalars['uuid']>;
  actions_category?: Maybe<Actions_Category_Items_Obj_Rel_Insert_Input>;
  category?: Maybe<Category_Items_Obj_Rel_Insert_Input>;
  category_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  task?: Maybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: Maybe<Scalars['uuid']>;
  usage?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Category_Usage_In_Tasks_Max_Fields = {
  __typename?: 'category_usage_in_tasks_max_fields';
  action_id?: Maybe<Scalars['uuid']>;
  category_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  usage?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "category_usage_in_tasks" */
export type Category_Usage_In_Tasks_Max_Order_By = {
  action_id?: Maybe<Order_By>;
  category_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  usage?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Category_Usage_In_Tasks_Min_Fields = {
  __typename?: 'category_usage_in_tasks_min_fields';
  action_id?: Maybe<Scalars['uuid']>;
  category_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  usage?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "category_usage_in_tasks" */
export type Category_Usage_In_Tasks_Min_Order_By = {
  action_id?: Maybe<Order_By>;
  category_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  usage?: Maybe<Order_By>;
};

/** response of any mutation on the table "category_usage_in_tasks" */
export type Category_Usage_In_Tasks_Mutation_Response = {
  __typename?: 'category_usage_in_tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Category_Usage_In_Tasks>;
};

/** on conflict condition type for table "category_usage_in_tasks" */
export type Category_Usage_In_Tasks_On_Conflict = {
  constraint: Category_Usage_In_Tasks_Constraint;
  update_columns?: Array<Category_Usage_In_Tasks_Update_Column>;
  where?: Maybe<Category_Usage_In_Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "category_usage_in_tasks". */
export type Category_Usage_In_Tasks_Order_By = {
  action?: Maybe<Actions_Order_By>;
  action_id?: Maybe<Order_By>;
  actions_category?: Maybe<Actions_Category_Items_Order_By>;
  category?: Maybe<Category_Items_Order_By>;
  category_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  task?: Maybe<Tasks_Order_By>;
  task_id?: Maybe<Order_By>;
  usage?: Maybe<Order_By>;
};

/** primary key columns input for table: category_usage_in_tasks */
export type Category_Usage_In_Tasks_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "category_usage_in_tasks" */
export enum Category_Usage_In_Tasks_Select_Column {
  /** column name */
  ActionId = 'action_id',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Usage = 'usage'
}

/** input type for updating data in table "category_usage_in_tasks" */
export type Category_Usage_In_Tasks_Set_Input = {
  action_id?: Maybe<Scalars['uuid']>;
  category_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  usage?: Maybe<Scalars['String']>;
};

/** update columns of table "category_usage_in_tasks" */
export enum Category_Usage_In_Tasks_Update_Column {
  /** column name */
  ActionId = 'action_id',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Usage = 'usage'
}

export type Certificates_Category = CategoryInterface & ElementInterface & {
  __typename?: 'certificates_Category';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The category’s children. */
  children?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The handle of the group that contains the category. */
  groupHandle?: Maybe<Scalars['String']>;
  /** The ID of the group that contains the category. */
  groupId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<CategoryInterface>;
  /** The category’s parent. */
  parent?: Maybe<CategoryInterface>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<CategoryInterface>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic?: Maybe<SeomaticInterface>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
};


export type Certificates_Category_CountArgs = {
  field: Scalars['String'];
};


export type Certificates_CategoryChildrenArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Certificates_CategoryLocalizedArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Certificates_CategoryNextArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Certificates_CategoryParentArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Certificates_CategoryPrevArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Certificates_CategorySeomaticArgs = {
  asArray?: Maybe<Scalars['Boolean']>;
  environment?: Maybe<SeomaticEnvironment>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  uri?: Maybe<Scalars['String']>;
};

export type ContentBlocks_NeoBlockContainerInput = {
  block?: Maybe<ContentBlocks_Block_NeoBlockInput>;
  card?: Maybe<ContentBlocks_Card_NeoBlockInput>;
  cards?: Maybe<ContentBlocks_Cards_NeoBlockInput>;
  graphicSwitch?: Maybe<ContentBlocks_GraphicSwitch_NeoBlockInput>;
  item?: Maybe<ContentBlocks_Item_NeoBlockInput>;
  sideBySide?: Maybe<ContentBlocks_SideBySide_NeoBlockInput>;
  singleGraphic?: Maybe<ContentBlocks_SingleGraphic_NeoBlockInput>;
  textWithImage?: Maybe<ContentBlocks_TextWithImage_NeoBlockInput>;
};

export type ContentBlocks_NeoField = ContentBlocks_Block_BlockType | ContentBlocks_Card_BlockType | ContentBlocks_Cards_BlockType | ContentBlocks_GraphicSwitch_BlockType | ContentBlocks_Item_BlockType | ContentBlocks_SideBySide_BlockType | ContentBlocks_SingleGraphic_BlockType | ContentBlocks_TextWithImage_BlockType;

export type ContentBlocks_NeoInput = {
  blocks?: Maybe<Array<Maybe<ContentBlocks_NeoBlockContainerInput>>>;
  sortOrder?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};

export type ContentBlocks_Block_BlockType = ElementInterface & NeoBlockInterface & {
  __typename?: 'contentBlocks_block_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The children block types for this block */
  children?: Maybe<Array<Maybe<NeoBlockInterface>>>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the neo block. */
  fieldId?: Maybe<Scalars['Int']>;
  headline?: Maybe<Scalars['String']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The Neo block's level. */
  level?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the neo block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the neo block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the neo block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type ContentBlocks_Block_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type ContentBlocks_Block_NeoBlockInput = {
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  level?: Maybe<Scalars['Int']>;
};

export type ContentBlocks_Card_BlockType = ElementInterface & NeoBlockInterface & {
  __typename?: 'contentBlocks_card_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  browseMoreLink?: Maybe<Array<Maybe<BrowseMoreLink_MatrixField>>>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the neo block. */
  fieldId?: Maybe<Scalars['Int']>;
  headline?: Maybe<Scalars['String']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<Maybe<AssetInterface>>>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The Neo block's level. */
  level?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the neo block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the neo block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the neo block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  wysiwyg?: Maybe<Scalars['String']>;
};


export type ContentBlocks_Card_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type ContentBlocks_Card_BlockTypeBrowseMoreLinkArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type ContentBlocks_Card_BlockTypeImagesArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ContentBlocks_Card_NeoBlockInput = {
  browseMoreLink?: Maybe<BrowseMoreLink_MatrixInput>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<Maybe<Scalars['Int']>>>;
  level?: Maybe<Scalars['Int']>;
  wysiwyg?: Maybe<Scalars['String']>;
};

export type ContentBlocks_Cards_BlockType = ElementInterface & NeoBlockInterface & {
  __typename?: 'contentBlocks_cards_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The children block types for this block */
  children?: Maybe<Array<Maybe<NeoBlockInterface>>>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the neo block. */
  fieldId?: Maybe<Scalars['Int']>;
  heightOfImages?: Maybe<Scalars['Boolean']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The Neo block's level. */
  level?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the neo block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the neo block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the neo block's type. */
  typeId?: Maybe<Scalars['Int']>;
  typeOfImages?: Maybe<Scalars['String']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type ContentBlocks_Cards_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type ContentBlocks_Cards_BlockTypeTypeOfImagesArgs = {
  label?: Maybe<Scalars['Boolean']>;
};

export type ContentBlocks_Cards_NeoBlockInput = {
  heightOfImages?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  level?: Maybe<Scalars['Int']>;
  /** Die erlaubten Werte sind [“image”, “graphic”] */
  typeOfImages?: Maybe<Scalars['String']>;
};

export type ContentBlocks_GraphicSwitch_BlockType = ElementInterface & NeoBlockInterface & {
  __typename?: 'contentBlocks_graphicSwitch_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The children block types for this block */
  children?: Maybe<Array<Maybe<NeoBlockInterface>>>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the neo block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The Neo block's level. */
  level?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the neo block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the neo block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the neo block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type ContentBlocks_GraphicSwitch_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type ContentBlocks_GraphicSwitch_NeoBlockInput = {
  id?: Maybe<Scalars['ID']>;
  level?: Maybe<Scalars['Int']>;
};

export type ContentBlocks_Item_BlockType = ElementInterface & NeoBlockInterface & {
  __typename?: 'contentBlocks_item_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the neo block. */
  fieldId?: Maybe<Scalars['Int']>;
  graphic?: Maybe<Array<Maybe<Graphic_SuperTableField>>>;
  headline?: Maybe<Scalars['String']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The Neo block's level. */
  level?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the neo block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the neo block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the neo block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type ContentBlocks_Item_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type ContentBlocks_Item_BlockTypeGraphicArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ContentBlocks_Item_NeoBlockInput = {
  graphic?: Maybe<Graphic_SuperTableInput>;
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  level?: Maybe<Scalars['Int']>;
};

export type ContentBlocks_SideBySide_BlockType = ElementInterface & NeoBlockInterface & {
  __typename?: 'contentBlocks_sideBySide_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The children block types for this block */
  children?: Maybe<Array<Maybe<NeoBlockInterface>>>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the neo block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The Neo block's level. */
  level?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the neo block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the neo block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the neo block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type ContentBlocks_SideBySide_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type ContentBlocks_SideBySide_NeoBlockInput = {
  id?: Maybe<Scalars['ID']>;
  level?: Maybe<Scalars['Int']>;
};

export type ContentBlocks_SingleGraphic_BlockType = ElementInterface & NeoBlockInterface & {
  __typename?: 'contentBlocks_singleGraphic_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the neo block. */
  fieldId?: Maybe<Scalars['Int']>;
  graphic?: Maybe<Array<Maybe<Graphic_SuperTableField>>>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The Neo block's level. */
  level?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the neo block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the neo block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the neo block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type ContentBlocks_SingleGraphic_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type ContentBlocks_SingleGraphic_BlockTypeGraphicArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ContentBlocks_SingleGraphic_NeoBlockInput = {
  graphic?: Maybe<Graphic_SuperTableInput>;
  id?: Maybe<Scalars['ID']>;
  level?: Maybe<Scalars['Int']>;
};

export type ContentBlocks_TextWithImage_BlockType = ElementInterface & NeoBlockInterface & {
  __typename?: 'contentBlocks_textWithImage_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the neo block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<Maybe<AssetInterface>>>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The Neo block's level. */
  level?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the neo block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the neo block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the neo block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  wysiwyg?: Maybe<Scalars['String']>;
};


export type ContentBlocks_TextWithImage_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type ContentBlocks_TextWithImage_BlockTypeImagesArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ContentBlocks_TextWithImage_NeoBlockInput = {
  id?: Maybe<Scalars['ID']>;
  images?: Maybe<Array<Maybe<Scalars['Int']>>>;
  level?: Maybe<Scalars['Int']>;
  wysiwyg?: Maybe<Scalars['String']>;
};

/** columns and relationships of "datafield_types" */
export type Datafield_Types = {
  __typename?: 'datafield_types';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "datafield_types" */
export type Datafield_Types_Aggregate = {
  __typename?: 'datafield_types_aggregate';
  aggregate?: Maybe<Datafield_Types_Aggregate_Fields>;
  nodes: Array<Datafield_Types>;
};

/** aggregate fields of "datafield_types" */
export type Datafield_Types_Aggregate_Fields = {
  __typename?: 'datafield_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Datafield_Types_Max_Fields>;
  min?: Maybe<Datafield_Types_Min_Fields>;
};


/** aggregate fields of "datafield_types" */
export type Datafield_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Datafield_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "datafield_types". All fields are combined with a logical 'AND'. */
export type Datafield_Types_Bool_Exp = {
  _and?: Maybe<Array<Datafield_Types_Bool_Exp>>;
  _not?: Maybe<Datafield_Types_Bool_Exp>;
  _or?: Maybe<Array<Datafield_Types_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "datafield_types" */
export enum Datafield_Types_Constraint {
  /** unique or primary key constraint */
  DatafieldTypesPkey = 'datafield_types_pkey'
}

export enum Datafield_Types_Enum {
  /** Category data field */
  Category = 'category',
  /** Currency data field */
  Currency = 'currency',
  /** Timestamp data field */
  Default = 'default',
  /** File data field */
  File = 'file',
  /** Location data field */
  Location = 'location',
  /** Measurement data field (ex. distance, area, volume) */
  Measurement = 'measurement',
  /** Relationship field to connect task to related items */
  Relationship = 'relationship',
  /** Text data field to make notes or other comments */
  Text = 'text',
  /** Track data field to record duration of a task */
  Track = 'track'
}

/** Boolean expression to compare columns of type "datafield_types_enum". All fields are combined with logical 'AND'. */
export type Datafield_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Datafield_Types_Enum>;
  _in?: Maybe<Array<Datafield_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Datafield_Types_Enum>;
  _nin?: Maybe<Array<Datafield_Types_Enum>>;
};

/** input type for inserting data into table "datafield_types" */
export type Datafield_Types_Insert_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Datafield_Types_Max_Fields = {
  __typename?: 'datafield_types_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Datafield_Types_Min_Fields = {
  __typename?: 'datafield_types_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "datafield_types" */
export type Datafield_Types_Mutation_Response = {
  __typename?: 'datafield_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Datafield_Types>;
};

/** on conflict condition type for table "datafield_types" */
export type Datafield_Types_On_Conflict = {
  constraint: Datafield_Types_Constraint;
  update_columns?: Array<Datafield_Types_Update_Column>;
  where?: Maybe<Datafield_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "datafield_types". */
export type Datafield_Types_Order_By = {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: datafield_types */
export type Datafield_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "datafield_types" */
export enum Datafield_Types_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "datafield_types" */
export type Datafield_Types_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "datafield_types" */
export enum Datafield_Types_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "datafields" */
export type Datafields = {
  __typename?: 'datafields';
  config: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  logs: Array<Logs>;
  /** An aggregate relationship */
  logs_aggregate: Logs_Aggregate;
  order?: Maybe<Scalars['Int']>;
  /** An object relationship */
  related_action: Actions;
  /** An object relationship */
  related_task: Tasks;
  related_to: Scalars['uuid'];
  /** An object relationship */
  resource_usage_in_task?: Maybe<Resource_Usage_In_Tasks>;
  task_id: Scalars['uuid'];
  type: Datafield_Types_Enum;
  updated_at: Scalars['timestamptz'];
  used_resource_id?: Maybe<Scalars['uuid']>;
  version: Scalars['String'];
};


/** columns and relationships of "datafields" */
export type DatafieldsConfigArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "datafields" */
export type DatafieldsLogsArgs = {
  distinct_on?: Maybe<Array<Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Order_By>>;
  where?: Maybe<Logs_Bool_Exp>;
};


/** columns and relationships of "datafields" */
export type DatafieldsLogs_AggregateArgs = {
  distinct_on?: Maybe<Array<Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Order_By>>;
  where?: Maybe<Logs_Bool_Exp>;
};

/** aggregated selection of "datafields" */
export type Datafields_Aggregate = {
  __typename?: 'datafields_aggregate';
  aggregate?: Maybe<Datafields_Aggregate_Fields>;
  nodes: Array<Datafields>;
};

/** aggregate fields of "datafields" */
export type Datafields_Aggregate_Fields = {
  __typename?: 'datafields_aggregate_fields';
  avg?: Maybe<Datafields_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Datafields_Max_Fields>;
  min?: Maybe<Datafields_Min_Fields>;
  stddev?: Maybe<Datafields_Stddev_Fields>;
  stddev_pop?: Maybe<Datafields_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Datafields_Stddev_Samp_Fields>;
  sum?: Maybe<Datafields_Sum_Fields>;
  var_pop?: Maybe<Datafields_Var_Pop_Fields>;
  var_samp?: Maybe<Datafields_Var_Samp_Fields>;
  variance?: Maybe<Datafields_Variance_Fields>;
};


/** aggregate fields of "datafields" */
export type Datafields_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Datafields_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "datafields" */
export type Datafields_Aggregate_Order_By = {
  avg?: Maybe<Datafields_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Datafields_Max_Order_By>;
  min?: Maybe<Datafields_Min_Order_By>;
  stddev?: Maybe<Datafields_Stddev_Order_By>;
  stddev_pop?: Maybe<Datafields_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Datafields_Stddev_Samp_Order_By>;
  sum?: Maybe<Datafields_Sum_Order_By>;
  var_pop?: Maybe<Datafields_Var_Pop_Order_By>;
  var_samp?: Maybe<Datafields_Var_Samp_Order_By>;
  variance?: Maybe<Datafields_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Datafields_Append_Input = {
  config?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "datafields" */
export type Datafields_Arr_Rel_Insert_Input = {
  data: Array<Datafields_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Datafields_On_Conflict>;
};

/** aggregate avg on columns */
export type Datafields_Avg_Fields = {
  __typename?: 'datafields_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "datafields" */
export type Datafields_Avg_Order_By = {
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "datafields". All fields are combined with a logical 'AND'. */
export type Datafields_Bool_Exp = {
  _and?: Maybe<Array<Datafields_Bool_Exp>>;
  _not?: Maybe<Datafields_Bool_Exp>;
  _or?: Maybe<Array<Datafields_Bool_Exp>>;
  config?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  logs?: Maybe<Logs_Bool_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  related_action?: Maybe<Actions_Bool_Exp>;
  related_task?: Maybe<Tasks_Bool_Exp>;
  related_to?: Maybe<Uuid_Comparison_Exp>;
  resource_usage_in_task?: Maybe<Resource_Usage_In_Tasks_Bool_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<Datafield_Types_Enum_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  used_resource_id?: Maybe<Uuid_Comparison_Exp>;
  version?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "datafields" */
export enum Datafields_Constraint {
  /** unique or primary key constraint */
  DatafieldsPkey = 'datafields_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Datafields_Delete_At_Path_Input = {
  config?: Maybe<Array<Scalars['String']>>;
};

/**
 * delete the array element with specified index (negative integers count from the
 * end). throws an error if top level container is not an array
 */
export type Datafields_Delete_Elem_Input = {
  config?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Datafields_Delete_Key_Input = {
  config?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "datafields" */
export type Datafields_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "datafields" */
export type Datafields_Insert_Input = {
  config?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  logs?: Maybe<Logs_Arr_Rel_Insert_Input>;
  order?: Maybe<Scalars['Int']>;
  related_action?: Maybe<Actions_Obj_Rel_Insert_Input>;
  related_task?: Maybe<Tasks_Obj_Rel_Insert_Input>;
  related_to?: Maybe<Scalars['uuid']>;
  resource_usage_in_task?: Maybe<Resource_Usage_In_Tasks_Obj_Rel_Insert_Input>;
  task_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Datafield_Types_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  used_resource_id?: Maybe<Scalars['uuid']>;
  version?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Datafields_Max_Fields = {
  __typename?: 'datafields_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  related_to?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  used_resource_id?: Maybe<Scalars['uuid']>;
  version?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "datafields" */
export type Datafields_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  related_to?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  used_resource_id?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Datafields_Min_Fields = {
  __typename?: 'datafields_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  related_to?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  used_resource_id?: Maybe<Scalars['uuid']>;
  version?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "datafields" */
export type Datafields_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  related_to?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  used_resource_id?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** response of any mutation on the table "datafields" */
export type Datafields_Mutation_Response = {
  __typename?: 'datafields_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Datafields>;
};

/** input type for inserting object relation for remote table "datafields" */
export type Datafields_Obj_Rel_Insert_Input = {
  data: Datafields_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Datafields_On_Conflict>;
};

/** on conflict condition type for table "datafields" */
export type Datafields_On_Conflict = {
  constraint: Datafields_Constraint;
  update_columns?: Array<Datafields_Update_Column>;
  where?: Maybe<Datafields_Bool_Exp>;
};

/** Ordering options when selecting data from "datafields". */
export type Datafields_Order_By = {
  config?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logs_aggregate?: Maybe<Logs_Aggregate_Order_By>;
  order?: Maybe<Order_By>;
  related_action?: Maybe<Actions_Order_By>;
  related_task?: Maybe<Tasks_Order_By>;
  related_to?: Maybe<Order_By>;
  resource_usage_in_task?: Maybe<Resource_Usage_In_Tasks_Order_By>;
  task_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  used_resource_id?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** primary key columns input for table: datafields */
export type Datafields_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Datafields_Prepend_Input = {
  config?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "datafields" */
export enum Datafields_Select_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  RelatedTo = 'related_to',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsedResourceId = 'used_resource_id',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "datafields" */
export type Datafields_Set_Input = {
  config?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  order?: Maybe<Scalars['Int']>;
  related_to?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Datafield_Types_Enum>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  used_resource_id?: Maybe<Scalars['uuid']>;
  version?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Datafields_Stddev_Fields = {
  __typename?: 'datafields_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "datafields" */
export type Datafields_Stddev_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Datafields_Stddev_Pop_Fields = {
  __typename?: 'datafields_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "datafields" */
export type Datafields_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Datafields_Stddev_Samp_Fields = {
  __typename?: 'datafields_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "datafields" */
export type Datafields_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Datafields_Sum_Fields = {
  __typename?: 'datafields_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "datafields" */
export type Datafields_Sum_Order_By = {
  order?: Maybe<Order_By>;
};

/** update columns of table "datafields" */
export enum Datafields_Update_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Order = 'order',
  /** column name */
  RelatedTo = 'related_to',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UsedResourceId = 'used_resource_id',
  /** column name */
  Version = 'version'
}

/** aggregate var_pop on columns */
export type Datafields_Var_Pop_Fields = {
  __typename?: 'datafields_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "datafields" */
export type Datafields_Var_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Datafields_Var_Samp_Fields = {
  __typename?: 'datafields_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "datafields" */
export type Datafields_Var_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Datafields_Variance_Fields = {
  __typename?: 'datafields_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "datafields" */
export type Datafields_Variance_Order_By = {
  order?: Maybe<Order_By>;
};

export type FeaturedContentBlock_NeoField = FeaturedContentBlock_CtaLink_BlockType | FeaturedContentBlock_FeaturedContent_BlockType | FeaturedContentBlock_FeaturedLatestProducers_BlockType | FeaturedContentBlock_FeaturedLatestProducts_BlockType | FeaturedContentBlock_Producer_BlockType | FeaturedContentBlock_Product_BlockType;

export type FeaturedContentBlock_CtaLink_BlockType = ElementInterface & NeoBlockInterface & {
  __typename?: 'featuredContentBlock_ctaLink_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  callToActionLink?: Maybe<LinkField_Link>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the neo block. */
  fieldId?: Maybe<Scalars['Int']>;
  headline?: Maybe<Scalars['String']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The Neo block's level. */
  level?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the neo block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the neo block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the neo block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type FeaturedContentBlock_CtaLink_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type FeaturedContentBlock_FeaturedContent_BlockType = ElementInterface & NeoBlockInterface & {
  __typename?: 'featuredContentBlock_featuredContent_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The children block types for this block */
  children?: Maybe<Array<Maybe<NeoBlockInterface>>>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the neo block. */
  fieldId?: Maybe<Scalars['Int']>;
  headline?: Maybe<Scalars['String']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The Neo block's level. */
  level?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the neo block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the neo block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the neo block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type FeaturedContentBlock_FeaturedContent_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type FeaturedContentBlock_FeaturedLatestProducers_BlockType = ElementInterface & NeoBlockInterface & {
  __typename?: 'featuredContentBlock_featuredLatestProducers_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the neo block. */
  fieldId?: Maybe<Scalars['Int']>;
  headline?: Maybe<Scalars['String']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The Neo block's level. */
  level?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the neo block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the neo block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the neo block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type FeaturedContentBlock_FeaturedLatestProducers_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type FeaturedContentBlock_FeaturedLatestProducts_BlockType = ElementInterface & NeoBlockInterface & {
  __typename?: 'featuredContentBlock_featuredLatestProducts_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the neo block. */
  fieldId?: Maybe<Scalars['Int']>;
  headline?: Maybe<Scalars['String']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The Neo block's level. */
  level?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the neo block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the neo block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the neo block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type FeaturedContentBlock_FeaturedLatestProducts_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type FeaturedContentBlock_Producer_BlockType = ElementInterface & NeoBlockInterface & {
  __typename?: 'featuredContentBlock_producer_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  abstract?: Maybe<Scalars['String']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  featuredProducer?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** The ID of the field that owns the neo block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Array<Maybe<AssetInterface>>>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The Neo block's level. */
  level?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the neo block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the neo block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the neo block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type FeaturedContentBlock_Producer_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type FeaturedContentBlock_Producer_BlockTypeFeaturedProducerArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type FeaturedContentBlock_Producer_BlockTypeImageArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type FeaturedContentBlock_Product_BlockType = ElementInterface & NeoBlockInterface & {
  __typename?: 'featuredContentBlock_product_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  abstract?: Maybe<Scalars['String']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  featuredProduct?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The ID of the field that owns the neo block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Array<Maybe<AssetInterface>>>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The Neo block's level. */
  level?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the neo block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the neo block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the neo block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type FeaturedContentBlock_Product_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type FeaturedContentBlock_Product_BlockTypeFeaturedProductArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type FeaturedContentBlock_Product_BlockTypeImageArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};

/** columns and relationships of "files" */
export type Files = {
  __typename?: 'files';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  key: Scalars['String'];
  /** An object relationship */
  log?: Maybe<Logs>;
  mimetype: Scalars['String'];
  originalname: Scalars['String'];
  producer?: Maybe<CategoryInterface>;
  producer_id?: Maybe<Scalars['String']>;
  related_to?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "files" */
export type FilesProducerArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};

/** aggregated selection of "files" */
export type Files_Aggregate = {
  __typename?: 'files_aggregate';
  aggregate?: Maybe<Files_Aggregate_Fields>;
  nodes: Array<Files>;
};

/** aggregate fields of "files" */
export type Files_Aggregate_Fields = {
  __typename?: 'files_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Files_Max_Fields>;
  min?: Maybe<Files_Min_Fields>;
};


/** aggregate fields of "files" */
export type Files_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Files_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "files". All fields are combined with a logical 'AND'. */
export type Files_Bool_Exp = {
  _and?: Maybe<Array<Files_Bool_Exp>>;
  _not?: Maybe<Files_Bool_Exp>;
  _or?: Maybe<Array<Files_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  key?: Maybe<String_Comparison_Exp>;
  log?: Maybe<Logs_Bool_Exp>;
  mimetype?: Maybe<String_Comparison_Exp>;
  originalname?: Maybe<String_Comparison_Exp>;
  producer_id?: Maybe<String_Comparison_Exp>;
  related_to?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "files" */
export enum Files_Constraint {
  /** unique or primary key constraint */
  FilesIdKey = 'files_id_key',
  /** unique or primary key constraint */
  FilesKeyKey = 'files_key_key',
  /** unique or primary key constraint */
  FilesPkey = 'files_pkey'
}

/** input type for inserting data into table "files" */
export type Files_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  log?: Maybe<Logs_Obj_Rel_Insert_Input>;
  mimetype?: Maybe<Scalars['String']>;
  originalname?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['String']>;
  related_to?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Files_Max_Fields = {
  __typename?: 'files_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  originalname?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['String']>;
  related_to?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Files_Min_Fields = {
  __typename?: 'files_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  originalname?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['String']>;
  related_to?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "files" */
export type Files_Mutation_Response = {
  __typename?: 'files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Files>;
};

/** on conflict condition type for table "files" */
export type Files_On_Conflict = {
  constraint: Files_Constraint;
  update_columns?: Array<Files_Update_Column>;
  where?: Maybe<Files_Bool_Exp>;
};

/** Ordering options when selecting data from "files". */
export type Files_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  key?: Maybe<Order_By>;
  log?: Maybe<Logs_Order_By>;
  mimetype?: Maybe<Order_By>;
  originalname?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
  related_to?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: files */
export type Files_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "files" */
export enum Files_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Mimetype = 'mimetype',
  /** column name */
  Originalname = 'originalname',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  RelatedTo = 'related_to',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "files" */
export type Files_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  mimetype?: Maybe<Scalars['String']>;
  originalname?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['String']>;
  related_to?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "files" */
export enum Files_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  Mimetype = 'mimetype',
  /** column name */
  Originalname = 'originalname',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  RelatedTo = 'related_to',
  /** column name */
  UpdatedAt = 'updated_at'
}


/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
};

export type FooterNavigation_Node = ElementInterface & NodeInterface & {
  __typename?: 'footerNavigation_Node';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The node’s children. Accepts the same arguments as the `nodes` query. */
  children?: Maybe<Array<Maybe<NodeInterface>>>;
  /** Any additional classes for the node. */
  classes?: Maybe<Scalars['String']>;
  /** Any additional custom attributes for the node. */
  customAttributes?: Maybe<Array<Maybe<NodeCustomAttribute>>>;
  /** Any additional data for the node. */
  data?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The element the node links to. */
  element?: Maybe<ElementInterface>;
  /** The ID of the element this node is linked to. */
  elementId?: Maybe<Scalars['Int']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The handle of the navigation this node belongs to. */
  navHandle?: Maybe<Scalars['String']>;
  /** The ID of the navigation this node belongs to. */
  navId?: Maybe<Scalars['Int']>;
  /** The name of the navigation this node belongs to. */
  navName?: Maybe<Scalars['String']>;
  /** Whether this node should open in a new window. */
  newWindow?: Maybe<Scalars['String']>;
  /** The node’s URI */
  nodeUri?: Maybe<Scalars['String']>;
  /** The node’s parent. */
  parent?: Maybe<NodeInterface>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The type of node this is. */
  type?: Maybe<Scalars['String']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The node’s full URL */
  url?: Maybe<Scalars['String']>;
  /** The URL for this navigation item. */
  urlSuffix?: Maybe<Scalars['String']>;
};


export type FooterNavigation_Node_CountArgs = {
  field: Scalars['String'];
};


export type FooterNavigation_NodeChildrenArgs = {
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nav?: Maybe<Array<Maybe<Scalars['String']>>>;
  navHandle?: Maybe<Scalars['String']>;
  navId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};

export type Frontpage_Frontpage_Entry = ElementInterface & EntryInterface & {
  __typename?: 'frontpage_frontpage_Entry';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  callToAction?: Maybe<Array<Maybe<CallToAction_NeoField>>>;
  /** Returns the entry’s canonical ID. */
  canonicalId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid?: Maybe<Scalars['String']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>;
  contentBlocks?: Maybe<Array<Maybe<ContentBlocks_NeoField>>>;
  /** The current revision for the entry. */
  currentRevision?: Maybe<EntryInterface>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The creator of a given draft. */
  draftCreator?: Maybe<UserInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId?: Maybe<Scalars['Int']>;
  /** The name of the draft. */
  draftName?: Maybe<Scalars['String']>;
  /** The notes for the draft. */
  draftNotes?: Maybe<Scalars['String']>;
  /** The drafts for the entry. */
  drafts?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>;
  featuredContentBlock?: Maybe<Array<Maybe<FeaturedContentBlock_NeoField>>>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  intro?: Maybe<Scalars['String']>;
  introImage?: Maybe<Array<Maybe<AssetInterface>>>;
  /** Returns whether this is a draft. */
  isDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is a revision. */
  isRevision?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
  isUnsavedDraft?: Maybe<Scalars['Boolean']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>;
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<EntryInterface>;
  /** The creator of a given revision. */
  revisionCreator?: Maybe<UserInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId?: Maybe<Scalars['Int']>;
  /** The revisions for the entry. */
  revisions?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>;
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>;
  seoFields?: Maybe<Scalars['String']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic?: Maybe<SeomaticInterface>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** Returns the entry’s canonical ID. */
  sourceId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  sourceUid?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
};


export type Frontpage_Frontpage_Entry_CountArgs = {
  field: Scalars['String'];
};


export type Frontpage_Frontpage_EntryAncestorsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Frontpage_Frontpage_EntryCallToActionArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Frontpage_Frontpage_EntryChildrenArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Frontpage_Frontpage_EntryContentBlocksArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Frontpage_Frontpage_EntryDescendantsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Frontpage_Frontpage_EntryDraftsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Frontpage_Frontpage_EntryFeaturedContentBlockArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Frontpage_Frontpage_EntryIntroImageArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Frontpage_Frontpage_EntryLocalizedArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Frontpage_Frontpage_EntryNextArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Frontpage_Frontpage_EntryParentArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Frontpage_Frontpage_EntryPrevArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Frontpage_Frontpage_EntryRevisionsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Frontpage_Frontpage_EntrySeomaticArgs = {
  asArray?: Maybe<Scalars['Boolean']>;
  environment?: Maybe<SeomaticEnvironment>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  uri?: Maybe<Scalars['String']>;
};

export type Graphic_BlockType = ElementInterface & SuperTableBlockInterface & {
  __typename?: 'graphic_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the Super Table block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Array<Maybe<AssetInterface>>>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The ID of the element that owns the Super Table block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The sort order of the Super Table block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The ID of the Super Table block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type Graphic_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type Graphic_BlockTypeImageArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Graphic_SuperTableBlockContainerInput = {
  graphic_0?: Maybe<Graphic_Graphic_0_SuperTableBlockInput>;
};

export type Graphic_SuperTableField = Graphic_BlockType;

export type Graphic_SuperTableInput = {
  blocks?: Maybe<Array<Maybe<Graphic_SuperTableBlockContainerInput>>>;
  sortOrder?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};

export type Graphic_Graphic_0_SuperTableBlockInput = {
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Array<Maybe<Scalars['Int']>>>;
};

export type HomeNavigation_Node = ElementInterface & NodeInterface & {
  __typename?: 'homeNavigation_Node';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The node’s children. Accepts the same arguments as the `nodes` query. */
  children?: Maybe<Array<Maybe<NodeInterface>>>;
  /** Any additional classes for the node. */
  classes?: Maybe<Scalars['String']>;
  /** Any additional custom attributes for the node. */
  customAttributes?: Maybe<Array<Maybe<NodeCustomAttribute>>>;
  /** Any additional data for the node. */
  data?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The element the node links to. */
  element?: Maybe<ElementInterface>;
  /** The ID of the element this node is linked to. */
  elementId?: Maybe<Scalars['Int']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The handle of the navigation this node belongs to. */
  navHandle?: Maybe<Scalars['String']>;
  /** The ID of the navigation this node belongs to. */
  navId?: Maybe<Scalars['Int']>;
  /** The name of the navigation this node belongs to. */
  navName?: Maybe<Scalars['String']>;
  /** Whether this node should open in a new window. */
  newWindow?: Maybe<Scalars['String']>;
  /** The node’s URI */
  nodeUri?: Maybe<Scalars['String']>;
  /** The node’s parent. */
  parent?: Maybe<NodeInterface>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The type of node this is. */
  type?: Maybe<Scalars['String']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The node’s full URL */
  url?: Maybe<Scalars['String']>;
  /** The URL for this navigation item. */
  urlSuffix?: Maybe<Scalars['String']>;
};


export type HomeNavigation_Node_CountArgs = {
  field: Scalars['String'];
};


export type HomeNavigation_NodeChildrenArgs = {
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nav?: Maybe<Array<Maybe<Scalars['String']>>>;
  navHandle?: Maybe<Scalars['String']>;
  navId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};

/** Image data */
export type Image = {
  __typename?: 'image';
  /** The height of the image. */
  height?: Maybe<Scalars['Int']>;
  /** The MIME type of the image. */
  mime?: Maybe<Scalars['String']>;
  /** The size of the image. */
  size?: Maybe<Scalars['Int']>;
  /** The URL of the image. */
  url?: Maybe<Scalars['String']>;
  /** The width of the image. */
  width?: Maybe<Scalars['Int']>;
};

export type ImportantNotice_GlobalSet = ElementInterface & GlobalSetInterface & {
  __typename?: 'importantNotice_GlobalSet';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The handle of the global set. */
  handle?: Maybe<Scalars['String']>;
  headline?: Maybe<Scalars['String']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The name of the global set. */
  name?: Maybe<Scalars['String']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  showImportantNotice?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type ImportantNotice_GlobalSet_CountArgs = {
  field: Scalars['String'];
};


/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** This is the interface implemented by all links. */
export type LinkField_Link = {
  __typename?: 'linkField_Link';
  ariaLabel?: Maybe<Scalars['String']>;
  customText?: Maybe<Scalars['String']>;
  element?: Maybe<ElementInterface>;
  target?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

/** This entity has all the EntryCoordinates properties */
export type Location_EntryCoordinateField = {
  __typename?: 'location_EntryCoordinateField';
  coordinates?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  searchQuery?: Maybe<Scalars['String']>;
};

/**
 * individual logs by users
 *
 *
 * columns and relationships of "logs"
 */
export type Logs = {
  __typename?: 'logs';
  /** An array relationship */
  children: Array<Logs>;
  /** An aggregate relationship */
  children_aggregate: Logs_Aggregate;
  completed: Scalars['Boolean'];
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by: Scalars['String'];
  data?: Maybe<Scalars['jsonb']>;
  datafield_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  parent?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  parent_log?: Maybe<Logs>;
  /** An object relationship */
  related_action?: Maybe<Actions>;
  /** An object relationship */
  related_datafield?: Maybe<Datafields>;
  /** An object relationship */
  related_task?: Maybe<Tasks>;
  /** the action that this log is related to */
  related_to?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  type: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};


/**
 * individual logs by users
 *
 *
 * columns and relationships of "logs"
 */
export type LogsChildrenArgs = {
  distinct_on?: Maybe<Array<Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Order_By>>;
  where?: Maybe<Logs_Bool_Exp>;
};


/**
 * individual logs by users
 *
 *
 * columns and relationships of "logs"
 */
export type LogsChildren_AggregateArgs = {
  distinct_on?: Maybe<Array<Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Order_By>>;
  where?: Maybe<Logs_Bool_Exp>;
};


/**
 * individual logs by users
 *
 *
 * columns and relationships of "logs"
 */
export type LogsDataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "logs" */
export type Logs_Aggregate = {
  __typename?: 'logs_aggregate';
  aggregate?: Maybe<Logs_Aggregate_Fields>;
  nodes: Array<Logs>;
};

/** aggregate fields of "logs" */
export type Logs_Aggregate_Fields = {
  __typename?: 'logs_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Logs_Max_Fields>;
  min?: Maybe<Logs_Min_Fields>;
};


/** aggregate fields of "logs" */
export type Logs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Logs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "logs" */
export type Logs_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Logs_Max_Order_By>;
  min?: Maybe<Logs_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Logs_Append_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "logs" */
export type Logs_Arr_Rel_Insert_Input = {
  data: Array<Logs_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Logs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "logs". All fields are combined with a logical 'AND'. */
export type Logs_Bool_Exp = {
  _and?: Maybe<Array<Logs_Bool_Exp>>;
  _not?: Maybe<Logs_Bool_Exp>;
  _or?: Maybe<Array<Logs_Bool_Exp>>;
  children?: Maybe<Logs_Bool_Exp>;
  completed?: Maybe<Boolean_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<String_Comparison_Exp>;
  data?: Maybe<Jsonb_Comparison_Exp>;
  datafield_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  parent?: Maybe<Uuid_Comparison_Exp>;
  parent_log?: Maybe<Logs_Bool_Exp>;
  related_action?: Maybe<Actions_Bool_Exp>;
  related_datafield?: Maybe<Datafields_Bool_Exp>;
  related_task?: Maybe<Tasks_Bool_Exp>;
  related_to?: Maybe<Uuid_Comparison_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "logs" */
export enum Logs_Constraint {
  /** unique or primary key constraint */
  LogsParentTaskIdRelatedToTypeKey = 'logs_parent_task_id_related_to_type_key',
  /** unique or primary key constraint */
  LogsPkey = 'logs_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Logs_Delete_At_Path_Input = {
  data?: Maybe<Array<Scalars['String']>>;
};

/**
 * delete the array element with specified index (negative integers count from the
 * end). throws an error if top level container is not an array
 */
export type Logs_Delete_Elem_Input = {
  data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Logs_Delete_Key_Input = {
  data?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "logs" */
export type Logs_Insert_Input = {
  children?: Maybe<Logs_Arr_Rel_Insert_Input>;
  completed?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['jsonb']>;
  datafield_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  parent?: Maybe<Scalars['uuid']>;
  parent_log?: Maybe<Logs_Obj_Rel_Insert_Input>;
  related_action?: Maybe<Actions_Obj_Rel_Insert_Input>;
  related_datafield?: Maybe<Datafields_Obj_Rel_Insert_Input>;
  related_task?: Maybe<Tasks_Obj_Rel_Insert_Input>;
  /** the action that this log is related to */
  related_to?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Logs_Max_Fields = {
  __typename?: 'logs_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  datafield_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  parent?: Maybe<Scalars['uuid']>;
  /** the action that this log is related to */
  related_to?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "logs" */
export type Logs_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  datafield_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  parent?: Maybe<Order_By>;
  /** the action that this log is related to */
  related_to?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Logs_Min_Fields = {
  __typename?: 'logs_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  datafield_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  parent?: Maybe<Scalars['uuid']>;
  /** the action that this log is related to */
  related_to?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "logs" */
export type Logs_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  datafield_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  parent?: Maybe<Order_By>;
  /** the action that this log is related to */
  related_to?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "logs" */
export type Logs_Mutation_Response = {
  __typename?: 'logs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Logs>;
};

/** input type for inserting object relation for remote table "logs" */
export type Logs_Obj_Rel_Insert_Input = {
  data: Logs_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Logs_On_Conflict>;
};

/** on conflict condition type for table "logs" */
export type Logs_On_Conflict = {
  constraint: Logs_Constraint;
  update_columns?: Array<Logs_Update_Column>;
  where?: Maybe<Logs_Bool_Exp>;
};

/** Ordering options when selecting data from "logs". */
export type Logs_Order_By = {
  children_aggregate?: Maybe<Logs_Aggregate_Order_By>;
  completed?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  data?: Maybe<Order_By>;
  datafield_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  parent?: Maybe<Order_By>;
  parent_log?: Maybe<Logs_Order_By>;
  related_action?: Maybe<Actions_Order_By>;
  related_datafield?: Maybe<Datafields_Order_By>;
  related_task?: Maybe<Tasks_Order_By>;
  related_to?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: logs */
export type Logs_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Logs_Prepend_Input = {
  data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "logs" */
export enum Logs_Select_Column {
  /** column name */
  Completed = 'completed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Data = 'data',
  /** column name */
  DatafieldId = 'datafield_id',
  /** column name */
  Id = 'id',
  /** column name */
  Parent = 'parent',
  /** column name */
  RelatedTo = 'related_to',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "logs" */
export type Logs_Set_Input = {
  completed?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['jsonb']>;
  datafield_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  parent?: Maybe<Scalars['uuid']>;
  /** the action that this log is related to */
  related_to?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "logs" */
export enum Logs_Update_Column {
  /** column name */
  Completed = 'completed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Data = 'data',
  /** column name */
  DatafieldId = 'datafield_id',
  /** column name */
  Id = 'id',
  /** column name */
  Parent = 'parent',
  /** column name */
  RelatedTo = 'related_to',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Machines_Category = CategoryInterface & ElementInterface & {
  __typename?: 'machines_Category';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The category’s children. */
  children?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The handle of the group that contains the category. */
  groupHandle?: Maybe<Scalars['String']>;
  /** The ID of the group that contains the category. */
  groupId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<CategoryInterface>;
  /** The category’s parent. */
  parent?: Maybe<CategoryInterface>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<CategoryInterface>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic?: Maybe<SeomaticInterface>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
};


export type Machines_Category_CountArgs = {
  field: Scalars['String'];
};


export type Machines_CategoryChildrenArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Machines_CategoryLocalizedArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Machines_CategoryNextArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Machines_CategoryParentArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Machines_CategoryPrevArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Machines_CategorySeomaticArgs = {
  asArray?: Maybe<Scalars['Boolean']>;
  environment?: Maybe<SeomaticEnvironment>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  uri?: Maybe<Scalars['String']>;
};

export type MainNavigation_Node = ElementInterface & NodeInterface & {
  __typename?: 'mainNavigation_Node';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The node’s children. Accepts the same arguments as the `nodes` query. */
  children?: Maybe<Array<Maybe<NodeInterface>>>;
  /** Any additional classes for the node. */
  classes?: Maybe<Scalars['String']>;
  /** Any additional custom attributes for the node. */
  customAttributes?: Maybe<Array<Maybe<NodeCustomAttribute>>>;
  /** Any additional data for the node. */
  data?: Maybe<Scalars['String']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The element the node links to. */
  element?: Maybe<ElementInterface>;
  /** The ID of the element this node is linked to. */
  elementId?: Maybe<Scalars['Int']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The handle of the navigation this node belongs to. */
  navHandle?: Maybe<Scalars['String']>;
  /** The ID of the navigation this node belongs to. */
  navId?: Maybe<Scalars['Int']>;
  /** The name of the navigation this node belongs to. */
  navName?: Maybe<Scalars['String']>;
  /** Whether this node should open in a new window. */
  newWindow?: Maybe<Scalars['String']>;
  /** The node’s URI */
  nodeUri?: Maybe<Scalars['String']>;
  /** The node’s parent. */
  parent?: Maybe<NodeInterface>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The type of node this is. */
  type?: Maybe<Scalars['String']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The node’s full URL */
  url?: Maybe<Scalars['String']>;
  /** The URL for this navigation item. */
  urlSuffix?: Maybe<Scalars['String']>;
};


export type MainNavigation_Node_CountArgs = {
  field: Scalars['String'];
};


export type MainNavigation_NodeChildrenArgs = {
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nav?: Maybe<Array<Maybe<Scalars['String']>>>;
  navHandle?: Maybe<Scalars['String']>;
  navId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};

export type MediaByProducers_Asset = AssetInterface & ElementInterface & {
  __typename?: 'mediaByProducers_Asset';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the asset file was last modified. */
  dateModified?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** This queries for a single embedded asset. */
  embeddedAsset?: Maybe<EmbeddedAssetInterface>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The file extension for the asset file. */
  extension?: Maybe<Scalars['String']>;
  /** The filename of the asset file. */
  filename?: Maybe<Scalars['String']>;
  /** The focal point represented as an array with `x` and `y` keys, or null if it's not an image. */
  focalPoint?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** The ID of the folder that the asset belongs to. */
  folderId?: Maybe<Scalars['Int']>;
  /** Whether a user-defined focal point is set on the asset. */
  hasFocalPoint?: Maybe<Scalars['Boolean']>;
  /** The height in pixels or null if it's not an image. */
  height?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** An `<img>` tag based on this asset. */
  img?: Maybe<Scalars['String']>;
  /** The file kind. */
  kind?: Maybe<Scalars['String']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The file’s MIME type, if it can be determined. */
  mimeType?: Maybe<Scalars['String']>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<AssetInterface>;
  /** The asset's path in the volume. */
  path?: Maybe<Scalars['String']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<AssetInterface>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The file size in bytes. */
  size?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** Returns a `srcset` attribute value based on the given widths or x-descriptors. */
  srcset?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The full URL of the asset. This field accepts the same fields as the `transform` directive. */
  url?: Maybe<Scalars['String']>;
  /** The ID of the volume that the asset belongs to. */
  volumeId?: Maybe<Scalars['Int']>;
  /** The width in pixels or null if it's not an image. */
  width?: Maybe<Scalars['Int']>;
};


export type MediaByProducers_Asset_CountArgs = {
  field: Scalars['String'];
};


export type MediaByProducers_AssetHeightArgs = {
  format?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  immediately?: Maybe<Scalars['Boolean']>;
  interlace?: Maybe<Scalars['String']>;
  mode?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  quality?: Maybe<Scalars['Int']>;
  transform?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};


export type MediaByProducers_AssetNextArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MediaByProducers_AssetPrevArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type MediaByProducers_AssetSrcsetArgs = {
  sizes: Array<Scalars['String']>;
};


export type MediaByProducers_AssetUrlArgs = {
  format?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  immediately?: Maybe<Scalars['Boolean']>;
  interlace?: Maybe<Scalars['String']>;
  mode?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  quality?: Maybe<Scalars['Int']>;
  transform?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};


export type MediaByProducers_AssetWidthArgs = {
  format?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  immediately?: Maybe<Scalars['Boolean']>;
  interlace?: Maybe<Scalars['String']>;
  mode?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  quality?: Maybe<Scalars['Int']>;
  transform?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

export type Media_Asset = AssetInterface & ElementInterface & {
  __typename?: 'media_Asset';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the asset file was last modified. */
  dateModified?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** This queries for a single embedded asset. */
  embeddedAsset?: Maybe<EmbeddedAssetInterface>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The file extension for the asset file. */
  extension?: Maybe<Scalars['String']>;
  /** The filename of the asset file. */
  filename?: Maybe<Scalars['String']>;
  /** The focal point represented as an array with `x` and `y` keys, or null if it's not an image. */
  focalPoint?: Maybe<Array<Maybe<Scalars['Float']>>>;
  /** The ID of the folder that the asset belongs to. */
  folderId?: Maybe<Scalars['Int']>;
  /** Whether a user-defined focal point is set on the asset. */
  hasFocalPoint?: Maybe<Scalars['Boolean']>;
  /** The height in pixels or null if it's not an image. */
  height?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** An `<img>` tag based on this asset. */
  img?: Maybe<Scalars['String']>;
  /** The file kind. */
  kind?: Maybe<Scalars['String']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The file’s MIME type, if it can be determined. */
  mimeType?: Maybe<Scalars['String']>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<AssetInterface>;
  /** The asset's path in the volume. */
  path?: Maybe<Scalars['String']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<AssetInterface>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The file size in bytes. */
  size?: Maybe<Scalars['String']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** Returns a `srcset` attribute value based on the given widths or x-descriptors. */
  srcset?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The full URL of the asset. This field accepts the same fields as the `transform` directive. */
  url?: Maybe<Scalars['String']>;
  /** The ID of the volume that the asset belongs to. */
  volumeId?: Maybe<Scalars['Int']>;
  /** The width in pixels or null if it's not an image. */
  width?: Maybe<Scalars['Int']>;
};


export type Media_Asset_CountArgs = {
  field: Scalars['String'];
};


export type Media_AssetHeightArgs = {
  format?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  immediately?: Maybe<Scalars['Boolean']>;
  interlace?: Maybe<Scalars['String']>;
  mode?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  quality?: Maybe<Scalars['Int']>;
  transform?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};


export type Media_AssetNextArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Media_AssetPrevArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Media_AssetSrcsetArgs = {
  sizes: Array<Scalars['String']>;
};


export type Media_AssetUrlArgs = {
  format?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  immediately?: Maybe<Scalars['Boolean']>;
  interlace?: Maybe<Scalars['String']>;
  mode?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  quality?: Maybe<Scalars['Int']>;
  transform?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};


export type Media_AssetWidthArgs = {
  format?: Maybe<Scalars['String']>;
  handle?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Int']>;
  immediately?: Maybe<Scalars['Boolean']>;
  interlace?: Maybe<Scalars['String']>;
  mode?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  quality?: Maybe<Scalars['Int']>;
  transform?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Int']>;
};

/**
 * KEEP FOR TESTING
 *
 *
 * columns and relationships of "messages"
 */
export type Messages = {
  __typename?: 'messages';
  id: Scalars['Int'];
  message: Scalars['String'];
  producer_uid?: Maybe<Scalars['String']>;
  timestamp: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "messages" */
export type Messages_Aggregate = {
  __typename?: 'messages_aggregate';
  aggregate?: Maybe<Messages_Aggregate_Fields>;
  nodes: Array<Messages>;
};

/** aggregate fields of "messages" */
export type Messages_Aggregate_Fields = {
  __typename?: 'messages_aggregate_fields';
  avg?: Maybe<Messages_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Messages_Max_Fields>;
  min?: Maybe<Messages_Min_Fields>;
  stddev?: Maybe<Messages_Stddev_Fields>;
  stddev_pop?: Maybe<Messages_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Messages_Stddev_Samp_Fields>;
  sum?: Maybe<Messages_Sum_Fields>;
  var_pop?: Maybe<Messages_Var_Pop_Fields>;
  var_samp?: Maybe<Messages_Var_Samp_Fields>;
  variance?: Maybe<Messages_Variance_Fields>;
};


/** aggregate fields of "messages" */
export type Messages_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Messages_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Messages_Avg_Fields = {
  __typename?: 'messages_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "messages". All fields are combined with a logical 'AND'. */
export type Messages_Bool_Exp = {
  _and?: Maybe<Array<Messages_Bool_Exp>>;
  _not?: Maybe<Messages_Bool_Exp>;
  _or?: Maybe<Array<Messages_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  producer_uid?: Maybe<String_Comparison_Exp>;
  timestamp?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
  user_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "messages" */
export enum Messages_Constraint {
  /** unique or primary key constraint */
  MessagesLogIdKey = 'messages_log_id_key',
  /** unique or primary key constraint */
  TestLoggingPkey = 'test_logging_pkey'
}

/** input type for incrementing numeric columns in table "messages" */
export type Messages_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "messages" */
export type Messages_Insert_Input = {
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  producer_uid?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Messages_Max_Fields = {
  __typename?: 'messages_max_fields';
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  producer_uid?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Messages_Min_Fields = {
  __typename?: 'messages_min_fields';
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  producer_uid?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "messages" */
export type Messages_Mutation_Response = {
  __typename?: 'messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Messages>;
};

/** on conflict condition type for table "messages" */
export type Messages_On_Conflict = {
  constraint: Messages_Constraint;
  update_columns?: Array<Messages_Update_Column>;
  where?: Maybe<Messages_Bool_Exp>;
};

/**
 * KEEP FOR TESTING
 *
 *
 * columns and relationships of "messages_online"
 */
export type Messages_Online = {
  __typename?: 'messages_online';
  log_id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "messages_online" */
export type Messages_Online_Aggregate = {
  __typename?: 'messages_online_aggregate';
  aggregate?: Maybe<Messages_Online_Aggregate_Fields>;
  nodes: Array<Messages_Online>;
};

/** aggregate fields of "messages_online" */
export type Messages_Online_Aggregate_Fields = {
  __typename?: 'messages_online_aggregate_fields';
  avg?: Maybe<Messages_Online_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Messages_Online_Max_Fields>;
  min?: Maybe<Messages_Online_Min_Fields>;
  stddev?: Maybe<Messages_Online_Stddev_Fields>;
  stddev_pop?: Maybe<Messages_Online_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Messages_Online_Stddev_Samp_Fields>;
  sum?: Maybe<Messages_Online_Sum_Fields>;
  var_pop?: Maybe<Messages_Online_Var_Pop_Fields>;
  var_samp?: Maybe<Messages_Online_Var_Samp_Fields>;
  variance?: Maybe<Messages_Online_Variance_Fields>;
};


/** aggregate fields of "messages_online" */
export type Messages_Online_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Messages_Online_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Messages_Online_Avg_Fields = {
  __typename?: 'messages_online_avg_fields';
  log_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "messages_online". All fields are combined with a logical 'AND'. */
export type Messages_Online_Bool_Exp = {
  _and?: Maybe<Array<Messages_Online_Bool_Exp>>;
  _not?: Maybe<Messages_Online_Bool_Exp>;
  _or?: Maybe<Array<Messages_Online_Bool_Exp>>;
  log_id?: Maybe<Int_Comparison_Exp>;
  message?: Maybe<String_Comparison_Exp>;
  timestamp?: Maybe<Timestamptz_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "messages_online" */
export type Messages_Online_Inc_Input = {
  log_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "messages_online" */
export type Messages_Online_Insert_Input = {
  log_id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Messages_Online_Max_Fields = {
  __typename?: 'messages_online_max_fields';
  log_id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Messages_Online_Min_Fields = {
  __typename?: 'messages_online_min_fields';
  log_id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "messages_online" */
export type Messages_Online_Mutation_Response = {
  __typename?: 'messages_online_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Messages_Online>;
};

/** Ordering options when selecting data from "messages_online". */
export type Messages_Online_Order_By = {
  log_id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  timestamp?: Maybe<Order_By>;
};

/** select columns of table "messages_online" */
export enum Messages_Online_Select_Column {
  /** column name */
  LogId = 'log_id',
  /** column name */
  Message = 'message',
  /** column name */
  Timestamp = 'timestamp'
}

/** input type for updating data in table "messages_online" */
export type Messages_Online_Set_Input = {
  log_id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Messages_Online_Stddev_Fields = {
  __typename?: 'messages_online_stddev_fields';
  log_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Messages_Online_Stddev_Pop_Fields = {
  __typename?: 'messages_online_stddev_pop_fields';
  log_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Messages_Online_Stddev_Samp_Fields = {
  __typename?: 'messages_online_stddev_samp_fields';
  log_id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Messages_Online_Sum_Fields = {
  __typename?: 'messages_online_sum_fields';
  log_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Messages_Online_Var_Pop_Fields = {
  __typename?: 'messages_online_var_pop_fields';
  log_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Messages_Online_Var_Samp_Fields = {
  __typename?: 'messages_online_var_samp_fields';
  log_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Messages_Online_Variance_Fields = {
  __typename?: 'messages_online_variance_fields';
  log_id?: Maybe<Scalars['Float']>;
};

/** Ordering options when selecting data from "messages". */
export type Messages_Order_By = {
  id?: Maybe<Order_By>;
  message?: Maybe<Order_By>;
  producer_uid?: Maybe<Order_By>;
  timestamp?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
  user_name?: Maybe<Order_By>;
};

/** primary key columns input for table: messages */
export type Messages_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "messages" */
export enum Messages_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  ProducerUid = 'producer_uid',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

/** input type for updating data in table "messages" */
export type Messages_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  producer_uid?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  user_name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Messages_Stddev_Fields = {
  __typename?: 'messages_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Messages_Stddev_Pop_Fields = {
  __typename?: 'messages_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Messages_Stddev_Samp_Fields = {
  __typename?: 'messages_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Messages_Sum_Fields = {
  __typename?: 'messages_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "messages" */
export enum Messages_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  ProducerUid = 'producer_uid',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  UserId = 'user_id',
  /** column name */
  UserName = 'user_name'
}

/** aggregate var_pop on columns */
export type Messages_Var_Pop_Fields = {
  __typename?: 'messages_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Messages_Var_Samp_Fields = {
  __typename?: 'messages_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Messages_Variance_Fields = {
  __typename?: 'messages_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** Create a draft for an entry and return the draft ID. */
  createDraft?: Maybe<Scalars['ID']>;
  /** Delete an asset. */
  deleteAsset?: Maybe<Scalars['Boolean']>;
  /** Delete a category. */
  deleteCategory?: Maybe<Scalars['Boolean']>;
  /** Delete an entry. */
  deleteEntry?: Maybe<Scalars['Boolean']>;
  /** delete data from the table: "actions" */
  delete_actions?: Maybe<Actions_Mutation_Response>;
  /** delete single row from the table: "actions" */
  delete_actions_by_pk?: Maybe<Actions>;
  /** delete data from the table: "actions_category_items" */
  delete_actions_category_items?: Maybe<Actions_Category_Items_Mutation_Response>;
  /** delete single row from the table: "actions_category_items" */
  delete_actions_category_items_by_pk?: Maybe<Actions_Category_Items>;
  /** delete data from the table: "actions_resources" */
  delete_actions_resources?: Maybe<Actions_Resources_Mutation_Response>;
  /** delete single row from the table: "actions_resources" */
  delete_actions_resources_by_pk?: Maybe<Actions_Resources>;
  /** delete data from the table: "category_default_types" */
  delete_category_default_types?: Maybe<Category_Default_Types_Mutation_Response>;
  /** delete single row from the table: "category_default_types" */
  delete_category_default_types_by_pk?: Maybe<Category_Default_Types>;
  /** delete data from the table: "category_items" */
  delete_category_items?: Maybe<Category_Items_Mutation_Response>;
  /** delete single row from the table: "category_items" */
  delete_category_items_by_pk?: Maybe<Category_Items>;
  /** delete data from the table: "category_locations" */
  delete_category_locations?: Maybe<Category_Locations_Mutation_Response>;
  /** delete single row from the table: "category_locations" */
  delete_category_locations_by_pk?: Maybe<Category_Locations>;
  /** delete data from the table: "category_machines" */
  delete_category_machines?: Maybe<Category_Machines_Mutation_Response>;
  /** delete single row from the table: "category_machines" */
  delete_category_machines_by_pk?: Maybe<Category_Machines>;
  /** delete data from the table: "category_types" */
  delete_category_types?: Maybe<Category_Types_Mutation_Response>;
  /** delete single row from the table: "category_types" */
  delete_category_types_by_pk?: Maybe<Category_Types>;
  /** delete data from the table: "category_usage_in_tasks" */
  delete_category_usage_in_tasks?: Maybe<Category_Usage_In_Tasks_Mutation_Response>;
  /** delete single row from the table: "category_usage_in_tasks" */
  delete_category_usage_in_tasks_by_pk?: Maybe<Category_Usage_In_Tasks>;
  /** delete data from the table: "datafield_types" */
  delete_datafield_types?: Maybe<Datafield_Types_Mutation_Response>;
  /** delete single row from the table: "datafield_types" */
  delete_datafield_types_by_pk?: Maybe<Datafield_Types>;
  /** delete data from the table: "datafields" */
  delete_datafields?: Maybe<Datafields_Mutation_Response>;
  /** delete single row from the table: "datafields" */
  delete_datafields_by_pk?: Maybe<Datafields>;
  /** delete data from the table: "files" */
  delete_files?: Maybe<Files_Mutation_Response>;
  /** delete single row from the table: "files" */
  delete_files_by_pk?: Maybe<Files>;
  /** delete data from the table: "logs" */
  delete_logs?: Maybe<Logs_Mutation_Response>;
  /** delete single row from the table: "logs" */
  delete_logs_by_pk?: Maybe<Logs>;
  /** delete data from the table: "messages" */
  delete_messages?: Maybe<Messages_Mutation_Response>;
  /** delete single row from the table: "messages" */
  delete_messages_by_pk?: Maybe<Messages>;
  /** delete data from the table: "messages_online" */
  delete_messages_online?: Maybe<Messages_Online_Mutation_Response>;
  /** delete data from the table: "product_resources" */
  delete_product_resources?: Maybe<Product_Resources_Mutation_Response>;
  /** delete single row from the table: "product_resources" */
  delete_product_resources_by_pk?: Maybe<Product_Resources>;
  /** delete data from the table: "products" */
  delete_products?: Maybe<Products_Mutation_Response>;
  /** delete single row from the table: "products" */
  delete_products_by_pk?: Maybe<Products>;
  /** delete data from the table: "products_preliminary" */
  delete_products_preliminary?: Maybe<Products_Preliminary_Mutation_Response>;
  /** delete single row from the table: "products_preliminary" */
  delete_products_preliminary_by_pk?: Maybe<Products_Preliminary>;
  /** delete data from the table: "products_recoverable" */
  delete_products_recoverable?: Maybe<Products_Recoverable_Mutation_Response>;
  /** delete data from the table: "resource_batches" */
  delete_resource_batches?: Maybe<Resource_Batches_Mutation_Response>;
  /** delete single row from the table: "resource_batches" */
  delete_resource_batches_by_pk?: Maybe<Resource_Batches>;
  /** delete data from the table: "resource_default_types" */
  delete_resource_default_types?: Maybe<Resource_Default_Types_Mutation_Response>;
  /** delete single row from the table: "resource_default_types" */
  delete_resource_default_types_by_pk?: Maybe<Resource_Default_Types>;
  /** delete data from the table: "resource_types" */
  delete_resource_types?: Maybe<Resource_Types_Mutation_Response>;
  /** delete single row from the table: "resource_types" */
  delete_resource_types_by_pk?: Maybe<Resource_Types>;
  /** delete data from the table: "resource_usage_in_tasks" */
  delete_resource_usage_in_tasks?: Maybe<Resource_Usage_In_Tasks_Mutation_Response>;
  /** delete single row from the table: "resource_usage_in_tasks" */
  delete_resource_usage_in_tasks_by_pk?: Maybe<Resource_Usage_In_Tasks>;
  /** delete data from the table: "resources" */
  delete_resources?: Maybe<Resources_Mutation_Response>;
  /** delete single row from the table: "resources" */
  delete_resources_by_pk?: Maybe<Resources>;
  /** delete data from the table: "suppliers" */
  delete_suppliers?: Maybe<Suppliers_Mutation_Response>;
  /** delete single row from the table: "suppliers" */
  delete_suppliers_by_pk?: Maybe<Suppliers>;
  /** delete data from the table: "tasks" */
  delete_tasks?: Maybe<Tasks_Mutation_Response>;
  /** delete single row from the table: "tasks" */
  delete_tasks_by_pk?: Maybe<Tasks>;
  /** delete data from the table: "usage_types" */
  delete_usage_types?: Maybe<Usage_Types_Mutation_Response>;
  /** delete single row from the table: "usage_types" */
  delete_usage_types_by_pk?: Maybe<Usage_Types>;
  /** insert data into the table: "actions" */
  insert_actions?: Maybe<Actions_Mutation_Response>;
  /** insert data into the table: "actions_category_items" */
  insert_actions_category_items?: Maybe<Actions_Category_Items_Mutation_Response>;
  /** insert a single row into the table: "actions_category_items" */
  insert_actions_category_items_one?: Maybe<Actions_Category_Items>;
  /** insert a single row into the table: "actions" */
  insert_actions_one?: Maybe<Actions>;
  /** insert data into the table: "actions_resources" */
  insert_actions_resources?: Maybe<Actions_Resources_Mutation_Response>;
  /** insert a single row into the table: "actions_resources" */
  insert_actions_resources_one?: Maybe<Actions_Resources>;
  /** insert data into the table: "category_default_types" */
  insert_category_default_types?: Maybe<Category_Default_Types_Mutation_Response>;
  /** insert a single row into the table: "category_default_types" */
  insert_category_default_types_one?: Maybe<Category_Default_Types>;
  /** insert data into the table: "category_items" */
  insert_category_items?: Maybe<Category_Items_Mutation_Response>;
  /** insert a single row into the table: "category_items" */
  insert_category_items_one?: Maybe<Category_Items>;
  /** insert data into the table: "category_locations" */
  insert_category_locations?: Maybe<Category_Locations_Mutation_Response>;
  /** insert a single row into the table: "category_locations" */
  insert_category_locations_one?: Maybe<Category_Locations>;
  /** insert data into the table: "category_machines" */
  insert_category_machines?: Maybe<Category_Machines_Mutation_Response>;
  /** insert a single row into the table: "category_machines" */
  insert_category_machines_one?: Maybe<Category_Machines>;
  /** insert data into the table: "category_types" */
  insert_category_types?: Maybe<Category_Types_Mutation_Response>;
  /** insert a single row into the table: "category_types" */
  insert_category_types_one?: Maybe<Category_Types>;
  /** insert data into the table: "category_usage_in_tasks" */
  insert_category_usage_in_tasks?: Maybe<Category_Usage_In_Tasks_Mutation_Response>;
  /** insert a single row into the table: "category_usage_in_tasks" */
  insert_category_usage_in_tasks_one?: Maybe<Category_Usage_In_Tasks>;
  /** insert data into the table: "datafield_types" */
  insert_datafield_types?: Maybe<Datafield_Types_Mutation_Response>;
  /** insert a single row into the table: "datafield_types" */
  insert_datafield_types_one?: Maybe<Datafield_Types>;
  /** insert data into the table: "datafields" */
  insert_datafields?: Maybe<Datafields_Mutation_Response>;
  /** insert a single row into the table: "datafields" */
  insert_datafields_one?: Maybe<Datafields>;
  /** insert data into the table: "files" */
  insert_files?: Maybe<Files_Mutation_Response>;
  /** insert a single row into the table: "files" */
  insert_files_one?: Maybe<Files>;
  /** insert data into the table: "logs" */
  insert_logs?: Maybe<Logs_Mutation_Response>;
  /** insert a single row into the table: "logs" */
  insert_logs_one?: Maybe<Logs>;
  /** insert data into the table: "messages" */
  insert_messages?: Maybe<Messages_Mutation_Response>;
  /** insert a single row into the table: "messages" */
  insert_messages_one?: Maybe<Messages>;
  /** insert data into the table: "messages_online" */
  insert_messages_online?: Maybe<Messages_Online_Mutation_Response>;
  /** insert a single row into the table: "messages_online" */
  insert_messages_online_one?: Maybe<Messages_Online>;
  /** insert data into the table: "product_resources" */
  insert_product_resources?: Maybe<Product_Resources_Mutation_Response>;
  /** insert a single row into the table: "product_resources" */
  insert_product_resources_one?: Maybe<Product_Resources>;
  /** insert data into the table: "products" */
  insert_products?: Maybe<Products_Mutation_Response>;
  /** insert a single row into the table: "products" */
  insert_products_one?: Maybe<Products>;
  /** insert data into the table: "products_preliminary" */
  insert_products_preliminary?: Maybe<Products_Preliminary_Mutation_Response>;
  /** insert a single row into the table: "products_preliminary" */
  insert_products_preliminary_one?: Maybe<Products_Preliminary>;
  /** insert data into the table: "products_recoverable" */
  insert_products_recoverable?: Maybe<Products_Recoverable_Mutation_Response>;
  /** insert a single row into the table: "products_recoverable" */
  insert_products_recoverable_one?: Maybe<Products_Recoverable>;
  /** insert data into the table: "resource_batches" */
  insert_resource_batches?: Maybe<Resource_Batches_Mutation_Response>;
  /** insert a single row into the table: "resource_batches" */
  insert_resource_batches_one?: Maybe<Resource_Batches>;
  /** insert data into the table: "resource_default_types" */
  insert_resource_default_types?: Maybe<Resource_Default_Types_Mutation_Response>;
  /** insert a single row into the table: "resource_default_types" */
  insert_resource_default_types_one?: Maybe<Resource_Default_Types>;
  /** insert data into the table: "resource_types" */
  insert_resource_types?: Maybe<Resource_Types_Mutation_Response>;
  /** insert a single row into the table: "resource_types" */
  insert_resource_types_one?: Maybe<Resource_Types>;
  /** insert data into the table: "resource_usage_in_tasks" */
  insert_resource_usage_in_tasks?: Maybe<Resource_Usage_In_Tasks_Mutation_Response>;
  /** insert a single row into the table: "resource_usage_in_tasks" */
  insert_resource_usage_in_tasks_one?: Maybe<Resource_Usage_In_Tasks>;
  /** insert data into the table: "resources" */
  insert_resources?: Maybe<Resources_Mutation_Response>;
  /** insert a single row into the table: "resources" */
  insert_resources_one?: Maybe<Resources>;
  /** insert data into the table: "suppliers" */
  insert_suppliers?: Maybe<Suppliers_Mutation_Response>;
  /** insert a single row into the table: "suppliers" */
  insert_suppliers_one?: Maybe<Suppliers>;
  /** insert data into the table: "tasks" */
  insert_tasks?: Maybe<Tasks_Mutation_Response>;
  /** insert a single row into the table: "tasks" */
  insert_tasks_one?: Maybe<Tasks>;
  /** insert data into the table: "usage_types" */
  insert_usage_types?: Maybe<Usage_Types_Mutation_Response>;
  /** insert a single row into the table: "usage_types" */
  insert_usage_types_one?: Maybe<Usage_Types>;
  login?: Maybe<LoginResponse>;
  ping?: Maybe<Scalars['String']>;
  /** Publish a draft for the entry and return the entry ID. */
  publishDraft?: Maybe<Scalars['ID']>;
  /** Save the “Zertifikate” category. */
  save_certificates_Category?: Maybe<Certificates_Category>;
  /** Save the “Maschinen” category. */
  save_machines_Category?: Maybe<Machines_Category>;
  /** Save an asset. */
  save_mediaByProducers_Asset?: Maybe<MediaByProducers_Asset>;
  /** Save the “Producers” category. */
  save_producers_Category?: Maybe<Producers_Category>;
  /** Save a “Product” entry draft in the “Product” section. */
  save_product_product_Draft?: Maybe<Product_Product_Entry>;
  /** Save a “Product” entry in the “Product” section. */
  save_product_product_Entry?: Maybe<Product_Product_Entry>;
  /** update data of the table: "actions" */
  update_actions?: Maybe<Actions_Mutation_Response>;
  /** update single row of the table: "actions" */
  update_actions_by_pk?: Maybe<Actions>;
  /** update data of the table: "actions_category_items" */
  update_actions_category_items?: Maybe<Actions_Category_Items_Mutation_Response>;
  /** update single row of the table: "actions_category_items" */
  update_actions_category_items_by_pk?: Maybe<Actions_Category_Items>;
  /** update data of the table: "actions_resources" */
  update_actions_resources?: Maybe<Actions_Resources_Mutation_Response>;
  /** update single row of the table: "actions_resources" */
  update_actions_resources_by_pk?: Maybe<Actions_Resources>;
  /** update data of the table: "category_default_types" */
  update_category_default_types?: Maybe<Category_Default_Types_Mutation_Response>;
  /** update single row of the table: "category_default_types" */
  update_category_default_types_by_pk?: Maybe<Category_Default_Types>;
  /** update data of the table: "category_items" */
  update_category_items?: Maybe<Category_Items_Mutation_Response>;
  /** update single row of the table: "category_items" */
  update_category_items_by_pk?: Maybe<Category_Items>;
  /** update data of the table: "category_locations" */
  update_category_locations?: Maybe<Category_Locations_Mutation_Response>;
  /** update single row of the table: "category_locations" */
  update_category_locations_by_pk?: Maybe<Category_Locations>;
  /** update data of the table: "category_machines" */
  update_category_machines?: Maybe<Category_Machines_Mutation_Response>;
  /** update single row of the table: "category_machines" */
  update_category_machines_by_pk?: Maybe<Category_Machines>;
  /** update data of the table: "category_types" */
  update_category_types?: Maybe<Category_Types_Mutation_Response>;
  /** update single row of the table: "category_types" */
  update_category_types_by_pk?: Maybe<Category_Types>;
  /** update data of the table: "category_usage_in_tasks" */
  update_category_usage_in_tasks?: Maybe<Category_Usage_In_Tasks_Mutation_Response>;
  /** update single row of the table: "category_usage_in_tasks" */
  update_category_usage_in_tasks_by_pk?: Maybe<Category_Usage_In_Tasks>;
  /** update data of the table: "datafield_types" */
  update_datafield_types?: Maybe<Datafield_Types_Mutation_Response>;
  /** update single row of the table: "datafield_types" */
  update_datafield_types_by_pk?: Maybe<Datafield_Types>;
  /** update data of the table: "datafields" */
  update_datafields?: Maybe<Datafields_Mutation_Response>;
  /** update single row of the table: "datafields" */
  update_datafields_by_pk?: Maybe<Datafields>;
  /** update data of the table: "files" */
  update_files?: Maybe<Files_Mutation_Response>;
  /** update single row of the table: "files" */
  update_files_by_pk?: Maybe<Files>;
  /** update data of the table: "logs" */
  update_logs?: Maybe<Logs_Mutation_Response>;
  /** update single row of the table: "logs" */
  update_logs_by_pk?: Maybe<Logs>;
  /** update data of the table: "messages" */
  update_messages?: Maybe<Messages_Mutation_Response>;
  /** update single row of the table: "messages" */
  update_messages_by_pk?: Maybe<Messages>;
  /** update data of the table: "messages_online" */
  update_messages_online?: Maybe<Messages_Online_Mutation_Response>;
  /** update data of the table: "product_resources" */
  update_product_resources?: Maybe<Product_Resources_Mutation_Response>;
  /** update single row of the table: "product_resources" */
  update_product_resources_by_pk?: Maybe<Product_Resources>;
  /** update data of the table: "products" */
  update_products?: Maybe<Products_Mutation_Response>;
  /** update single row of the table: "products" */
  update_products_by_pk?: Maybe<Products>;
  /** update data of the table: "products_preliminary" */
  update_products_preliminary?: Maybe<Products_Preliminary_Mutation_Response>;
  /** update single row of the table: "products_preliminary" */
  update_products_preliminary_by_pk?: Maybe<Products_Preliminary>;
  /** update data of the table: "products_recoverable" */
  update_products_recoverable?: Maybe<Products_Recoverable_Mutation_Response>;
  /** update data of the table: "resource_batches" */
  update_resource_batches?: Maybe<Resource_Batches_Mutation_Response>;
  /** update single row of the table: "resource_batches" */
  update_resource_batches_by_pk?: Maybe<Resource_Batches>;
  /** update data of the table: "resource_default_types" */
  update_resource_default_types?: Maybe<Resource_Default_Types_Mutation_Response>;
  /** update single row of the table: "resource_default_types" */
  update_resource_default_types_by_pk?: Maybe<Resource_Default_Types>;
  /** update data of the table: "resource_types" */
  update_resource_types?: Maybe<Resource_Types_Mutation_Response>;
  /** update single row of the table: "resource_types" */
  update_resource_types_by_pk?: Maybe<Resource_Types>;
  /** update data of the table: "resource_usage_in_tasks" */
  update_resource_usage_in_tasks?: Maybe<Resource_Usage_In_Tasks_Mutation_Response>;
  /** update single row of the table: "resource_usage_in_tasks" */
  update_resource_usage_in_tasks_by_pk?: Maybe<Resource_Usage_In_Tasks>;
  /** update data of the table: "resources" */
  update_resources?: Maybe<Resources_Mutation_Response>;
  /** update single row of the table: "resources" */
  update_resources_by_pk?: Maybe<Resources>;
  /** update data of the table: "suppliers" */
  update_suppliers?: Maybe<Suppliers_Mutation_Response>;
  /** update single row of the table: "suppliers" */
  update_suppliers_by_pk?: Maybe<Suppliers>;
  /** update data of the table: "tasks" */
  update_tasks?: Maybe<Tasks_Mutation_Response>;
  /** update single row of the table: "tasks" */
  update_tasks_by_pk?: Maybe<Tasks>;
  /** update data of the table: "usage_types" */
  update_usage_types?: Maybe<Usage_Types_Mutation_Response>;
  /** update single row of the table: "usage_types" */
  update_usage_types_by_pk?: Maybe<Usage_Types>;
};


/** mutation root */
export type Mutation_RootCreateDraftArgs = {
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  provisional?: Maybe<Scalars['Boolean']>;
};


/** mutation root */
export type Mutation_RootDeleteAssetArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteCategoryArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDeleteEntryArgs = {
  id: Scalars['Int'];
  siteId?: Maybe<Scalars['Int']>;
};


/** mutation root */
export type Mutation_RootDelete_ActionsArgs = {
  where: Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Actions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Actions_Category_ItemsArgs = {
  where: Actions_Category_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Actions_Category_Items_By_PkArgs = {
  action_id: Scalars['uuid'];
  category_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Actions_ResourcesArgs = {
  where: Actions_Resources_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Actions_Resources_By_PkArgs = {
  action_id: Scalars['uuid'];
  resource_id: Scalars['uuid'];
  usage: Usage_Types_Enum;
};


/** mutation root */
export type Mutation_RootDelete_Category_Default_TypesArgs = {
  where: Category_Default_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Category_Default_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Category_ItemsArgs = {
  where: Category_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Category_Items_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Category_LocationsArgs = {
  where: Category_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Category_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Category_MachinesArgs = {
  where: Category_Machines_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Category_Machines_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Category_TypesArgs = {
  where: Category_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Category_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Category_Usage_In_TasksArgs = {
  where: Category_Usage_In_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Category_Usage_In_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Datafield_TypesArgs = {
  where: Datafield_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Datafield_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_DatafieldsArgs = {
  where: Datafields_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Datafields_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FilesArgs = {
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Files_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LogsArgs = {
  where: Logs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Logs_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_MessagesArgs = {
  where: Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Messages_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Messages_OnlineArgs = {
  where: Messages_Online_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_ResourcesArgs = {
  where: Product_Resources_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_Resources_By_PkArgs = {
  product_id: Scalars['uuid'];
  resource_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ProductsArgs = {
  where: Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Products_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Products_PreliminaryArgs = {
  where: Products_Preliminary_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Products_Preliminary_By_PkArgs = {
  id: Scalars['uuid'];
  preliminary_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Products_RecoverableArgs = {
  where: Products_Recoverable_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Resource_BatchesArgs = {
  where: Resource_Batches_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Resource_Batches_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Resource_Default_TypesArgs = {
  where: Resource_Default_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Resource_Default_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Resource_TypesArgs = {
  where: Resource_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Resource_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Resource_Usage_In_TasksArgs = {
  where: Resource_Usage_In_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Resource_Usage_In_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ResourcesArgs = {
  where: Resources_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Resources_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_SuppliersArgs = {
  where: Suppliers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Suppliers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TasksArgs = {
  where: Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Usage_TypesArgs = {
  where: Usage_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Usage_Types_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootInsert_ActionsArgs = {
  objects: Array<Actions_Insert_Input>;
  on_conflict?: Maybe<Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Actions_Category_ItemsArgs = {
  objects: Array<Actions_Category_Items_Insert_Input>;
  on_conflict?: Maybe<Actions_Category_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Actions_Category_Items_OneArgs = {
  object: Actions_Category_Items_Insert_Input;
  on_conflict?: Maybe<Actions_Category_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Actions_OneArgs = {
  object: Actions_Insert_Input;
  on_conflict?: Maybe<Actions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Actions_ResourcesArgs = {
  objects: Array<Actions_Resources_Insert_Input>;
  on_conflict?: Maybe<Actions_Resources_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Actions_Resources_OneArgs = {
  object: Actions_Resources_Insert_Input;
  on_conflict?: Maybe<Actions_Resources_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Category_Default_TypesArgs = {
  objects: Array<Category_Default_Types_Insert_Input>;
  on_conflict?: Maybe<Category_Default_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Category_Default_Types_OneArgs = {
  object: Category_Default_Types_Insert_Input;
  on_conflict?: Maybe<Category_Default_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Category_ItemsArgs = {
  objects: Array<Category_Items_Insert_Input>;
  on_conflict?: Maybe<Category_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Category_Items_OneArgs = {
  object: Category_Items_Insert_Input;
  on_conflict?: Maybe<Category_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Category_LocationsArgs = {
  objects: Array<Category_Locations_Insert_Input>;
  on_conflict?: Maybe<Category_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Category_Locations_OneArgs = {
  object: Category_Locations_Insert_Input;
  on_conflict?: Maybe<Category_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Category_MachinesArgs = {
  objects: Array<Category_Machines_Insert_Input>;
  on_conflict?: Maybe<Category_Machines_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Category_Machines_OneArgs = {
  object: Category_Machines_Insert_Input;
  on_conflict?: Maybe<Category_Machines_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Category_TypesArgs = {
  objects: Array<Category_Types_Insert_Input>;
  on_conflict?: Maybe<Category_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Category_Types_OneArgs = {
  object: Category_Types_Insert_Input;
  on_conflict?: Maybe<Category_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Category_Usage_In_TasksArgs = {
  objects: Array<Category_Usage_In_Tasks_Insert_Input>;
  on_conflict?: Maybe<Category_Usage_In_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Category_Usage_In_Tasks_OneArgs = {
  object: Category_Usage_In_Tasks_Insert_Input;
  on_conflict?: Maybe<Category_Usage_In_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Datafield_TypesArgs = {
  objects: Array<Datafield_Types_Insert_Input>;
  on_conflict?: Maybe<Datafield_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Datafield_Types_OneArgs = {
  object: Datafield_Types_Insert_Input;
  on_conflict?: Maybe<Datafield_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DatafieldsArgs = {
  objects: Array<Datafields_Insert_Input>;
  on_conflict?: Maybe<Datafields_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Datafields_OneArgs = {
  object: Datafields_Insert_Input;
  on_conflict?: Maybe<Datafields_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FilesArgs = {
  objects: Array<Files_Insert_Input>;
  on_conflict?: Maybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Files_OneArgs = {
  object: Files_Insert_Input;
  on_conflict?: Maybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LogsArgs = {
  objects: Array<Logs_Insert_Input>;
  on_conflict?: Maybe<Logs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Logs_OneArgs = {
  object: Logs_Insert_Input;
  on_conflict?: Maybe<Logs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MessagesArgs = {
  objects: Array<Messages_Insert_Input>;
  on_conflict?: Maybe<Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Messages_OneArgs = {
  object: Messages_Insert_Input;
  on_conflict?: Maybe<Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Messages_OnlineArgs = {
  objects: Array<Messages_Online_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Messages_Online_OneArgs = {
  object: Messages_Online_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Product_ResourcesArgs = {
  objects: Array<Product_Resources_Insert_Input>;
  on_conflict?: Maybe<Product_Resources_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Resources_OneArgs = {
  object: Product_Resources_Insert_Input;
  on_conflict?: Maybe<Product_Resources_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductsArgs = {
  objects: Array<Products_Insert_Input>;
  on_conflict?: Maybe<Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Products_OneArgs = {
  object: Products_Insert_Input;
  on_conflict?: Maybe<Products_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Products_PreliminaryArgs = {
  objects: Array<Products_Preliminary_Insert_Input>;
  on_conflict?: Maybe<Products_Preliminary_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Products_Preliminary_OneArgs = {
  object: Products_Preliminary_Insert_Input;
  on_conflict?: Maybe<Products_Preliminary_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Products_RecoverableArgs = {
  objects: Array<Products_Recoverable_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Products_Recoverable_OneArgs = {
  object: Products_Recoverable_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Resource_BatchesArgs = {
  objects: Array<Resource_Batches_Insert_Input>;
  on_conflict?: Maybe<Resource_Batches_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Resource_Batches_OneArgs = {
  object: Resource_Batches_Insert_Input;
  on_conflict?: Maybe<Resource_Batches_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Resource_Default_TypesArgs = {
  objects: Array<Resource_Default_Types_Insert_Input>;
  on_conflict?: Maybe<Resource_Default_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Resource_Default_Types_OneArgs = {
  object: Resource_Default_Types_Insert_Input;
  on_conflict?: Maybe<Resource_Default_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Resource_TypesArgs = {
  objects: Array<Resource_Types_Insert_Input>;
  on_conflict?: Maybe<Resource_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Resource_Types_OneArgs = {
  object: Resource_Types_Insert_Input;
  on_conflict?: Maybe<Resource_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Resource_Usage_In_TasksArgs = {
  objects: Array<Resource_Usage_In_Tasks_Insert_Input>;
  on_conflict?: Maybe<Resource_Usage_In_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Resource_Usage_In_Tasks_OneArgs = {
  object: Resource_Usage_In_Tasks_Insert_Input;
  on_conflict?: Maybe<Resource_Usage_In_Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ResourcesArgs = {
  objects: Array<Resources_Insert_Input>;
  on_conflict?: Maybe<Resources_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Resources_OneArgs = {
  object: Resources_Insert_Input;
  on_conflict?: Maybe<Resources_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SuppliersArgs = {
  objects: Array<Suppliers_Insert_Input>;
  on_conflict?: Maybe<Suppliers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Suppliers_OneArgs = {
  object: Suppliers_Insert_Input;
  on_conflict?: Maybe<Suppliers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TasksArgs = {
  objects: Array<Tasks_Insert_Input>;
  on_conflict?: Maybe<Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tasks_OneArgs = {
  object: Tasks_Insert_Input;
  on_conflict?: Maybe<Tasks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Usage_TypesArgs = {
  objects: Array<Usage_Types_Insert_Input>;
  on_conflict?: Maybe<Usage_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Usage_Types_OneArgs = {
  object: Usage_Types_Insert_Input;
  on_conflict?: Maybe<Usage_Types_On_Conflict>;
};


/** mutation root */
export type Mutation_RootLoginArgs = {
  loginName: Scalars['String'];
  password: Scalars['String'];
};


/** mutation root */
export type Mutation_RootPublishDraftArgs = {
  id: Scalars['Int'];
  provisional?: Maybe<Scalars['Boolean']>;
};


/** mutation root */
export type Mutation_RootSave_Certificates_CategoryArgs = {
  appendTo?: Maybe<Scalars['ID']>;
  appendToRoot?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  insertAfter?: Maybe<Scalars['ID']>;
  insertBefore?: Maybe<Scalars['ID']>;
  prependTo?: Maybe<Scalars['ID']>;
  prependToRoot?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootSave_Machines_CategoryArgs = {
  appendTo?: Maybe<Scalars['ID']>;
  appendToRoot?: Maybe<Scalars['Boolean']>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  insertAfter?: Maybe<Scalars['ID']>;
  insertBefore?: Maybe<Scalars['ID']>;
  prependTo?: Maybe<Scalars['ID']>;
  prependToRoot?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootSave_MediaByProducers_AssetArgs = {
  _file?: Maybe<FileInput>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  newFolderId?: Maybe<Scalars['ID']>;
  title?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  uploaderId?: Maybe<Scalars['ID']>;
};


/** mutation root */
export type Mutation_RootSave_Producers_CategoryArgs = {
  abstract?: Maybe<Scalars['String']>;
  appendTo?: Maybe<Scalars['ID']>;
  appendToRoot?: Maybe<Scalars['Boolean']>;
  atAGlance?: Maybe<AtAGlance_SuperTableInput>;
  callToAction?: Maybe<CallToAction_NeoInput>;
  companyName?: Maybe<Scalars['String']>;
  contentBlocks?: Maybe<ContentBlocks_NeoInput>;
  enabled?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Array<Maybe<Scalars['Int']>>>;
  insertAfter?: Maybe<Scalars['ID']>;
  insertBefore?: Maybe<Scalars['ID']>;
  intro?: Maybe<Scalars['String']>;
  introImage?: Maybe<Array<Maybe<Scalars['Int']>>>;
  prependTo?: Maybe<Scalars['ID']>;
  prependToRoot?: Maybe<Scalars['Boolean']>;
  producerDetails?: Maybe<ProducerDetails_SuperTableInput>;
  producerLogo?: Maybe<Array<Maybe<Scalars['Int']>>>;
  productsTotalCount?: Maybe<Scalars['Number']>;
  region?: Maybe<Array<Maybe<Scalars['Int']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['Int']>>>;
  seoFields?: Maybe<Scalars['String']>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootSave_Product_Product_DraftArgs = {
  abstract?: Maybe<Scalars['String']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['Int']>>>;
  atAGlance?: Maybe<AtAGlance_SuperTableInput>;
  authorId?: Maybe<Scalars['ID']>;
  callToAction?: Maybe<CallToAction_NeoInput>;
  draftId: Scalars['ID'];
  draftName?: Maybe<Scalars['String']>;
  draftNotes?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  hasuraProductId?: Maybe<Scalars['String']>;
  image?: Maybe<Array<Maybe<Scalars['Int']>>>;
  intro?: Maybe<Scalars['String']>;
  introImage?: Maybe<Array<Maybe<Scalars['Int']>>>;
  postDate?: Maybe<Scalars['DateTime']>;
  productDetails?: Maybe<ProductDetails_SuperTableInput>;
  productProcess?: Maybe<ProductProcess_NeoInput>;
  productType?: Maybe<Array<Maybe<Scalars['Int']>>>;
  provisional?: Maybe<Scalars['Boolean']>;
  region?: Maybe<Array<Maybe<Scalars['Int']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['Int']>>>;
  seoFields?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootSave_Product_Product_EntryArgs = {
  abstract?: Maybe<Scalars['String']>;
  appendTo?: Maybe<Scalars['ID']>;
  appendToRoot?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['Int']>>>;
  atAGlance?: Maybe<AtAGlance_SuperTableInput>;
  authorId?: Maybe<Scalars['ID']>;
  callToAction?: Maybe<CallToAction_NeoInput>;
  enabled?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  hasuraProductId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Array<Maybe<Scalars['Int']>>>;
  insertAfter?: Maybe<Scalars['ID']>;
  insertBefore?: Maybe<Scalars['ID']>;
  intro?: Maybe<Scalars['String']>;
  introImage?: Maybe<Array<Maybe<Scalars['Int']>>>;
  postDate?: Maybe<Scalars['DateTime']>;
  prependTo?: Maybe<Scalars['ID']>;
  prependToRoot?: Maybe<Scalars['Boolean']>;
  productDetails?: Maybe<ProductDetails_SuperTableInput>;
  productProcess?: Maybe<ProductProcess_NeoInput>;
  productType?: Maybe<Array<Maybe<Scalars['Int']>>>;
  region?: Maybe<Array<Maybe<Scalars['Int']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['Int']>>>;
  seoFields?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uid?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootUpdate_ActionsArgs = {
  _inc?: Maybe<Actions_Inc_Input>;
  _set?: Maybe<Actions_Set_Input>;
  where: Actions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Actions_By_PkArgs = {
  _inc?: Maybe<Actions_Inc_Input>;
  _set?: Maybe<Actions_Set_Input>;
  pk_columns: Actions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Actions_Category_ItemsArgs = {
  _set?: Maybe<Actions_Category_Items_Set_Input>;
  where: Actions_Category_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Actions_Category_Items_By_PkArgs = {
  _set?: Maybe<Actions_Category_Items_Set_Input>;
  pk_columns: Actions_Category_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Actions_ResourcesArgs = {
  _set?: Maybe<Actions_Resources_Set_Input>;
  where: Actions_Resources_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Actions_Resources_By_PkArgs = {
  _set?: Maybe<Actions_Resources_Set_Input>;
  pk_columns: Actions_Resources_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Category_Default_TypesArgs = {
  _set?: Maybe<Category_Default_Types_Set_Input>;
  where: Category_Default_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Category_Default_Types_By_PkArgs = {
  _set?: Maybe<Category_Default_Types_Set_Input>;
  pk_columns: Category_Default_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Category_ItemsArgs = {
  _set?: Maybe<Category_Items_Set_Input>;
  where: Category_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Category_Items_By_PkArgs = {
  _set?: Maybe<Category_Items_Set_Input>;
  pk_columns: Category_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Category_LocationsArgs = {
  _set?: Maybe<Category_Locations_Set_Input>;
  where: Category_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Category_Locations_By_PkArgs = {
  _set?: Maybe<Category_Locations_Set_Input>;
  pk_columns: Category_Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Category_MachinesArgs = {
  _set?: Maybe<Category_Machines_Set_Input>;
  where: Category_Machines_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Category_Machines_By_PkArgs = {
  _set?: Maybe<Category_Machines_Set_Input>;
  pk_columns: Category_Machines_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Category_TypesArgs = {
  _set?: Maybe<Category_Types_Set_Input>;
  where: Category_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Category_Types_By_PkArgs = {
  _set?: Maybe<Category_Types_Set_Input>;
  pk_columns: Category_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Category_Usage_In_TasksArgs = {
  _set?: Maybe<Category_Usage_In_Tasks_Set_Input>;
  where: Category_Usage_In_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Category_Usage_In_Tasks_By_PkArgs = {
  _set?: Maybe<Category_Usage_In_Tasks_Set_Input>;
  pk_columns: Category_Usage_In_Tasks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Datafield_TypesArgs = {
  _set?: Maybe<Datafield_Types_Set_Input>;
  where: Datafield_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Datafield_Types_By_PkArgs = {
  _set?: Maybe<Datafield_Types_Set_Input>;
  pk_columns: Datafield_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_DatafieldsArgs = {
  _append?: Maybe<Datafields_Append_Input>;
  _delete_at_path?: Maybe<Datafields_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Datafields_Delete_Elem_Input>;
  _delete_key?: Maybe<Datafields_Delete_Key_Input>;
  _inc?: Maybe<Datafields_Inc_Input>;
  _prepend?: Maybe<Datafields_Prepend_Input>;
  _set?: Maybe<Datafields_Set_Input>;
  where: Datafields_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Datafields_By_PkArgs = {
  _append?: Maybe<Datafields_Append_Input>;
  _delete_at_path?: Maybe<Datafields_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Datafields_Delete_Elem_Input>;
  _delete_key?: Maybe<Datafields_Delete_Key_Input>;
  _inc?: Maybe<Datafields_Inc_Input>;
  _prepend?: Maybe<Datafields_Prepend_Input>;
  _set?: Maybe<Datafields_Set_Input>;
  pk_columns: Datafields_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_FilesArgs = {
  _set?: Maybe<Files_Set_Input>;
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Files_By_PkArgs = {
  _set?: Maybe<Files_Set_Input>;
  pk_columns: Files_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_LogsArgs = {
  _append?: Maybe<Logs_Append_Input>;
  _delete_at_path?: Maybe<Logs_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Logs_Delete_Elem_Input>;
  _delete_key?: Maybe<Logs_Delete_Key_Input>;
  _prepend?: Maybe<Logs_Prepend_Input>;
  _set?: Maybe<Logs_Set_Input>;
  where: Logs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Logs_By_PkArgs = {
  _append?: Maybe<Logs_Append_Input>;
  _delete_at_path?: Maybe<Logs_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Logs_Delete_Elem_Input>;
  _delete_key?: Maybe<Logs_Delete_Key_Input>;
  _prepend?: Maybe<Logs_Prepend_Input>;
  _set?: Maybe<Logs_Set_Input>;
  pk_columns: Logs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_MessagesArgs = {
  _inc?: Maybe<Messages_Inc_Input>;
  _set?: Maybe<Messages_Set_Input>;
  where: Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Messages_By_PkArgs = {
  _inc?: Maybe<Messages_Inc_Input>;
  _set?: Maybe<Messages_Set_Input>;
  pk_columns: Messages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Messages_OnlineArgs = {
  _inc?: Maybe<Messages_Online_Inc_Input>;
  _set?: Maybe<Messages_Online_Set_Input>;
  where: Messages_Online_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_ResourcesArgs = {
  _set?: Maybe<Product_Resources_Set_Input>;
  where: Product_Resources_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Resources_By_PkArgs = {
  _set?: Maybe<Product_Resources_Set_Input>;
  pk_columns: Product_Resources_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProductsArgs = {
  _set?: Maybe<Products_Set_Input>;
  where: Products_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Products_By_PkArgs = {
  _set?: Maybe<Products_Set_Input>;
  pk_columns: Products_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Products_PreliminaryArgs = {
  _set?: Maybe<Products_Preliminary_Set_Input>;
  where: Products_Preliminary_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Products_Preliminary_By_PkArgs = {
  _set?: Maybe<Products_Preliminary_Set_Input>;
  pk_columns: Products_Preliminary_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Products_RecoverableArgs = {
  _set?: Maybe<Products_Recoverable_Set_Input>;
  where: Products_Recoverable_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Resource_BatchesArgs = {
  _set?: Maybe<Resource_Batches_Set_Input>;
  where: Resource_Batches_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Resource_Batches_By_PkArgs = {
  _set?: Maybe<Resource_Batches_Set_Input>;
  pk_columns: Resource_Batches_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Resource_Default_TypesArgs = {
  _set?: Maybe<Resource_Default_Types_Set_Input>;
  where: Resource_Default_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Resource_Default_Types_By_PkArgs = {
  _set?: Maybe<Resource_Default_Types_Set_Input>;
  pk_columns: Resource_Default_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Resource_TypesArgs = {
  _set?: Maybe<Resource_Types_Set_Input>;
  where: Resource_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Resource_Types_By_PkArgs = {
  _set?: Maybe<Resource_Types_Set_Input>;
  pk_columns: Resource_Types_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Resource_Usage_In_TasksArgs = {
  _inc?: Maybe<Resource_Usage_In_Tasks_Inc_Input>;
  _set?: Maybe<Resource_Usage_In_Tasks_Set_Input>;
  where: Resource_Usage_In_Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Resource_Usage_In_Tasks_By_PkArgs = {
  _inc?: Maybe<Resource_Usage_In_Tasks_Inc_Input>;
  _set?: Maybe<Resource_Usage_In_Tasks_Set_Input>;
  pk_columns: Resource_Usage_In_Tasks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ResourcesArgs = {
  _set?: Maybe<Resources_Set_Input>;
  where: Resources_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Resources_By_PkArgs = {
  _set?: Maybe<Resources_Set_Input>;
  pk_columns: Resources_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_SuppliersArgs = {
  _set?: Maybe<Suppliers_Set_Input>;
  where: Suppliers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Suppliers_By_PkArgs = {
  _set?: Maybe<Suppliers_Set_Input>;
  pk_columns: Suppliers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_TasksArgs = {
  _inc?: Maybe<Tasks_Inc_Input>;
  _set?: Maybe<Tasks_Set_Input>;
  where: Tasks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tasks_By_PkArgs = {
  _inc?: Maybe<Tasks_Inc_Input>;
  _set?: Maybe<Tasks_Set_Input>;
  pk_columns: Tasks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Usage_TypesArgs = {
  _set?: Maybe<Usage_Types_Set_Input>;
  where: Usage_Types_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Usage_Types_By_PkArgs = {
  _set?: Maybe<Usage_Types_Set_Input>;
  pk_columns: Usage_Types_Pk_Columns_Input;
};

export type Offer_Offer_Entry = ElementInterface & EntryInterface & {
  __typename?: 'offer_offer_Entry';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  callToAction?: Maybe<Array<Maybe<CallToAction_NeoField>>>;
  /** Returns the entry’s canonical ID. */
  canonicalId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid?: Maybe<Scalars['String']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>;
  contentBlocks?: Maybe<Array<Maybe<ContentBlocks_NeoField>>>;
  /** The current revision for the entry. */
  currentRevision?: Maybe<EntryInterface>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The creator of a given draft. */
  draftCreator?: Maybe<UserInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId?: Maybe<Scalars['Int']>;
  /** The name of the draft. */
  draftName?: Maybe<Scalars['String']>;
  /** The notes for the draft. */
  draftNotes?: Maybe<Scalars['String']>;
  /** The drafts for the entry. */
  drafts?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  intro?: Maybe<Scalars['String']>;
  introImage?: Maybe<Array<Maybe<AssetInterface>>>;
  /** Returns whether this is a draft. */
  isDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is a revision. */
  isRevision?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
  isUnsavedDraft?: Maybe<Scalars['Boolean']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>;
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<EntryInterface>;
  /** The creator of a given revision. */
  revisionCreator?: Maybe<UserInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId?: Maybe<Scalars['Int']>;
  /** The revisions for the entry. */
  revisions?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>;
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>;
  seoFields?: Maybe<Scalars['String']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic?: Maybe<SeomaticInterface>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** Returns the entry’s canonical ID. */
  sourceId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  sourceUid?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
};


export type Offer_Offer_Entry_CountArgs = {
  field: Scalars['String'];
};


export type Offer_Offer_EntryAncestorsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Offer_Offer_EntryCallToActionArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Offer_Offer_EntryChildrenArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Offer_Offer_EntryContentBlocksArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Offer_Offer_EntryDescendantsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Offer_Offer_EntryDraftsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Offer_Offer_EntryIntroImageArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Offer_Offer_EntryLocalizedArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Offer_Offer_EntryNextArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Offer_Offer_EntryParentArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Offer_Offer_EntryPrevArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Offer_Offer_EntryRevisionsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Offer_Offer_EntrySeomaticArgs = {
  asArray?: Maybe<Scalars['Boolean']>;
  environment?: Maybe<SeomaticEnvironment>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  uri?: Maybe<Scalars['String']>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type Pages_Cookie_Entry = ElementInterface & EntryInterface & {
  __typename?: 'pages_cookie_Entry';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  callToAction?: Maybe<Array<Maybe<CallToAction_NeoField>>>;
  /** Returns the entry’s canonical ID. */
  canonicalId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid?: Maybe<Scalars['String']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>;
  contentBlocks?: Maybe<Array<Maybe<ContentBlocks_NeoField>>>;
  /** The current revision for the entry. */
  currentRevision?: Maybe<EntryInterface>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The creator of a given draft. */
  draftCreator?: Maybe<UserInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId?: Maybe<Scalars['Int']>;
  /** The name of the draft. */
  draftName?: Maybe<Scalars['String']>;
  /** The notes for the draft. */
  draftNotes?: Maybe<Scalars['String']>;
  /** The drafts for the entry. */
  drafts?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>;
  headline?: Maybe<Scalars['String']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** Returns whether this is a draft. */
  isDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is a revision. */
  isRevision?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
  isUnsavedDraft?: Maybe<Scalars['Boolean']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>;
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<EntryInterface>;
  /** The creator of a given revision. */
  revisionCreator?: Maybe<UserInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId?: Maybe<Scalars['Int']>;
  /** The revisions for the entry. */
  revisions?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>;
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic?: Maybe<SeomaticInterface>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** Returns the entry’s canonical ID. */
  sourceId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  sourceUid?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
  wysiwyg?: Maybe<Scalars['String']>;
};


export type Pages_Cookie_Entry_CountArgs = {
  field: Scalars['String'];
};


export type Pages_Cookie_EntryAncestorsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Pages_Cookie_EntryCallToActionArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Pages_Cookie_EntryChildrenArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Pages_Cookie_EntryContentBlocksArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Pages_Cookie_EntryDescendantsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Pages_Cookie_EntryDraftsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Pages_Cookie_EntryLocalizedArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Pages_Cookie_EntryNextArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Pages_Cookie_EntryParentArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Pages_Cookie_EntryPrevArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Pages_Cookie_EntryRevisionsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Pages_Cookie_EntrySeomaticArgs = {
  asArray?: Maybe<Scalars['Boolean']>;
  environment?: Maybe<SeomaticEnvironment>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  uri?: Maybe<Scalars['String']>;
};

export type Pages_Default_Entry = ElementInterface & EntryInterface & {
  __typename?: 'pages_default_Entry';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  callToAction?: Maybe<Array<Maybe<CallToAction_NeoField>>>;
  /** Returns the entry’s canonical ID. */
  canonicalId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid?: Maybe<Scalars['String']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>;
  contentBlocks?: Maybe<Array<Maybe<ContentBlocks_NeoField>>>;
  /** The current revision for the entry. */
  currentRevision?: Maybe<EntryInterface>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The creator of a given draft. */
  draftCreator?: Maybe<UserInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId?: Maybe<Scalars['Int']>;
  /** The name of the draft. */
  draftName?: Maybe<Scalars['String']>;
  /** The notes for the draft. */
  draftNotes?: Maybe<Scalars['String']>;
  /** The drafts for the entry. */
  drafts?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>;
  headline?: Maybe<Scalars['String']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** Returns whether this is a draft. */
  isDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is a revision. */
  isRevision?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
  isUnsavedDraft?: Maybe<Scalars['Boolean']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>;
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<EntryInterface>;
  /** The creator of a given revision. */
  revisionCreator?: Maybe<UserInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId?: Maybe<Scalars['Int']>;
  /** The revisions for the entry. */
  revisions?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>;
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>;
  seoFields?: Maybe<Scalars['String']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic?: Maybe<SeomaticInterface>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** Returns the entry’s canonical ID. */
  sourceId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  sourceUid?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
  wysiwyg?: Maybe<Scalars['String']>;
};


export type Pages_Default_Entry_CountArgs = {
  field: Scalars['String'];
};


export type Pages_Default_EntryAncestorsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Pages_Default_EntryCallToActionArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Pages_Default_EntryChildrenArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Pages_Default_EntryContentBlocksArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Pages_Default_EntryDescendantsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Pages_Default_EntryDraftsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Pages_Default_EntryLocalizedArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Pages_Default_EntryNextArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Pages_Default_EntryParentArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Pages_Default_EntryPrevArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Pages_Default_EntryRevisionsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Pages_Default_EntrySeomaticArgs = {
  asArray?: Maybe<Scalars['Boolean']>;
  environment?: Maybe<SeomaticEnvironment>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  uri?: Maybe<Scalars['String']>;
};

export type ProducerDetails_BlockType = ElementInterface & SuperTableBlockInterface & {
  __typename?: 'producerDetails_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the Super Table block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The ID of the element that owns the Super Table block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The sort order of the Super Table block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The ID of the Super Table block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type ProducerDetails_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type ProducerDetails_SuperTableBlockContainerInput = {
  producerDetails_0?: Maybe<ProducerDetails_ProducerDetails_0_SuperTableBlockInput>;
};

export type ProducerDetails_SuperTableField = ProducerDetails_BlockType;

export type ProducerDetails_SuperTableInput = {
  blocks?: Maybe<Array<Maybe<ProducerDetails_SuperTableBlockContainerInput>>>;
  sortOrder?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};

export type ProducerDetails_ProducerDetails_0_SuperTableBlockInput = {
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type ProducersOverview_ProducersOverview_Entry = ElementInterface & EntryInterface & {
  __typename?: 'producersOverview_producersOverview_Entry';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  callToAction?: Maybe<Array<Maybe<CallToAction_NeoField>>>;
  /** Returns the entry’s canonical ID. */
  canonicalId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid?: Maybe<Scalars['String']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>;
  contentBlocks?: Maybe<Array<Maybe<ContentBlocks_NeoField>>>;
  /** The current revision for the entry. */
  currentRevision?: Maybe<EntryInterface>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The creator of a given draft. */
  draftCreator?: Maybe<UserInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId?: Maybe<Scalars['Int']>;
  /** The name of the draft. */
  draftName?: Maybe<Scalars['String']>;
  /** The notes for the draft. */
  draftNotes?: Maybe<Scalars['String']>;
  /** The drafts for the entry. */
  drafts?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  intro?: Maybe<Scalars['String']>;
  introImage?: Maybe<Array<Maybe<AssetInterface>>>;
  /** Returns whether this is a draft. */
  isDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is a revision. */
  isRevision?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
  isUnsavedDraft?: Maybe<Scalars['Boolean']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>;
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<EntryInterface>;
  /** The creator of a given revision. */
  revisionCreator?: Maybe<UserInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId?: Maybe<Scalars['Int']>;
  /** The revisions for the entry. */
  revisions?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>;
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>;
  seoFields?: Maybe<Scalars['String']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic?: Maybe<SeomaticInterface>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** Returns the entry’s canonical ID. */
  sourceId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  sourceUid?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
};


export type ProducersOverview_ProducersOverview_Entry_CountArgs = {
  field: Scalars['String'];
};


export type ProducersOverview_ProducersOverview_EntryAncestorsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type ProducersOverview_ProducersOverview_EntryCallToActionArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type ProducersOverview_ProducersOverview_EntryChildrenArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type ProducersOverview_ProducersOverview_EntryContentBlocksArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type ProducersOverview_ProducersOverview_EntryDescendantsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type ProducersOverview_ProducersOverview_EntryDraftsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type ProducersOverview_ProducersOverview_EntryIntroImageArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type ProducersOverview_ProducersOverview_EntryLocalizedArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type ProducersOverview_ProducersOverview_EntryNextArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type ProducersOverview_ProducersOverview_EntryParentArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type ProducersOverview_ProducersOverview_EntryPrevArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type ProducersOverview_ProducersOverview_EntryRevisionsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type ProducersOverview_ProducersOverview_EntrySeomaticArgs = {
  asArray?: Maybe<Scalars['Boolean']>;
  environment?: Maybe<SeomaticEnvironment>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  uri?: Maybe<Scalars['String']>;
};

export type Producers_Category = CategoryInterface & ElementInterface & {
  __typename?: 'producers_Category';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  abstract?: Maybe<Scalars['String']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  atAGlance?: Maybe<Array<Maybe<AtAGlance_SuperTableField>>>;
  callToAction?: Maybe<Array<Maybe<CallToAction_NeoField>>>;
  /** The category’s children. */
  children?: Maybe<Array<Maybe<CategoryInterface>>>;
  companyName?: Maybe<Scalars['String']>;
  contentBlocks?: Maybe<Array<Maybe<ContentBlocks_NeoField>>>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The handle of the group that contains the category. */
  groupHandle?: Maybe<Scalars['String']>;
  /** The ID of the group that contains the category. */
  groupId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Array<Maybe<AssetInterface>>>;
  intro?: Maybe<Scalars['String']>;
  introImage?: Maybe<Array<Maybe<AssetInterface>>>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<CategoryInterface>;
  /** The category’s parent. */
  parent?: Maybe<CategoryInterface>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<CategoryInterface>;
  producerDetails?: Maybe<Array<Maybe<ProducerDetails_SuperTableField>>>;
  producerLogo?: Maybe<Array<Maybe<AssetInterface>>>;
  productsTotalCount?: Maybe<Scalars['Number']>;
  region?: Maybe<Array<Maybe<CategoryInterface>>>;
  relatedDocumentation?: Maybe<Array<Maybe<AssetInterface>>>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Scalars['String']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic?: Maybe<SeomaticInterface>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};


export type Producers_Category_CountArgs = {
  field: Scalars['String'];
};


export type Producers_CategoryAtAGlanceArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Producers_CategoryCallToActionArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Producers_CategoryChildrenArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Producers_CategoryContentBlocksArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Producers_CategoryImageArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Producers_CategoryIntroImageArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Producers_CategoryLocalizedArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Producers_CategoryNextArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Producers_CategoryParentArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Producers_CategoryPrevArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Producers_CategoryProducerDetailsArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Producers_CategoryProducerLogoArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Producers_CategoryRegionArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Producers_CategoryRelatedDocumentationArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Producers_CategorySeomaticArgs = {
  asArray?: Maybe<Scalars['Boolean']>;
  environment?: Maybe<SeomaticEnvironment>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  uri?: Maybe<Scalars['String']>;
};

export type ProductDetails_BlockType = ElementInterface & SuperTableBlockInterface & {
  __typename?: 'productDetails_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the Super Table block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The ID of the element that owns the Super Table block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The sort order of the Super Table block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The ID of the Super Table block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type ProductDetails_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type ProductDetails_SuperTableBlockContainerInput = {
  productDetails_0?: Maybe<ProductDetails_ProductDetails_0_SuperTableBlockInput>;
};

export type ProductDetails_SuperTableField = ProductDetails_BlockType;

export type ProductDetails_SuperTableInput = {
  blocks?: Maybe<Array<Maybe<ProductDetails_SuperTableBlockContainerInput>>>;
  sortOrder?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};

export type ProductDetails_ProductDetails_0_SuperTableBlockInput = {
  id?: Maybe<Scalars['ID']>;
  label?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
};

export type ProductProcess_NeoBlockContainerInput = {
  process?: Maybe<ProductProcess_Process_NeoBlockInput>;
  processGroup?: Maybe<ProductProcess_ProcessGroup_NeoBlockInput>;
  timelineBreak?: Maybe<ProductProcess_TimelineBreak_NeoBlockInput>;
  timelineItem?: Maybe<ProductProcess_TimelineItem_NeoBlockInput>;
};

export type ProductProcess_NeoField = ProductProcess_ProcessGroup_BlockType | ProductProcess_Process_BlockType | ProductProcess_TimelineBreak_BlockType | ProductProcess_TimelineItem_BlockType;

export type ProductProcess_NeoInput = {
  blocks?: Maybe<Array<Maybe<ProductProcess_NeoBlockContainerInput>>>;
  sortOrder?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};

export type ProductProcess_ProcessGroup_BlockType = ElementInterface & NeoBlockInterface & {
  __typename?: 'productProcess_processGroup_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The children block types for this block */
  children?: Maybe<Array<Maybe<NeoBlockInterface>>>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  details?: Maybe<Scalars['String']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the neo block. */
  fieldId?: Maybe<Scalars['Int']>;
  generalInformation?: Maybe<Scalars['String']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The Neo block's level. */
  level?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the neo block. */
  ownerId?: Maybe<Scalars['Int']>;
  relatedDocumentation?: Maybe<Array<Maybe<AssetInterface>>>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  stepContent?: Maybe<Array<Maybe<StepContent_MatrixField>>>;
  stepDescription?: Maybe<Scalars['String']>;
  stepImages?: Maybe<Array<Maybe<AssetInterface>>>;
  stepPreviewImage?: Maybe<Array<Maybe<AssetInterface>>>;
  stepTitle?: Maybe<Scalars['String']>;
  stepType?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the neo block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the neo block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type ProductProcess_ProcessGroup_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type ProductProcess_ProcessGroup_BlockTypeRelatedDocumentationArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type ProductProcess_ProcessGroup_BlockTypeStepContentArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type ProductProcess_ProcessGroup_BlockTypeStepImagesArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type ProductProcess_ProcessGroup_BlockTypeStepPreviewImageArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type ProductProcess_ProcessGroup_BlockTypeStepTypeArgs = {
  label?: Maybe<Scalars['Boolean']>;
};

export type ProductProcess_ProcessGroup_NeoBlockInput = {
  /** Process Group Details */
  details?: Maybe<Scalars['String']>;
  /** Process Group General Information */
  generalInformation?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  level?: Maybe<Scalars['Int']>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['Int']>>>;
  stepContent?: Maybe<StepContent_MatrixInput>;
  /** will be visible below the step overview boxes */
  stepDescription?: Maybe<Scalars['String']>;
  stepImages?: Maybe<Array<Maybe<Scalars['Int']>>>;
  stepPreviewImage?: Maybe<Array<Maybe<Scalars['Int']>>>;
  stepTitle?: Maybe<Scalars['String']>;
  /** Die erlaubten Werte sind [“step”, “day”] */
  stepType?: Maybe<Scalars['String']>;
};

export type ProductProcess_Process_BlockType = ElementInterface & NeoBlockInterface & {
  __typename?: 'productProcess_process_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The children block types for this block */
  children?: Maybe<Array<Maybe<NeoBlockInterface>>>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the neo block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The Neo block's level. */
  level?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the neo block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  taskInformation?: Maybe<Scalars['String']>;
  taskName?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the neo block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the neo block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type ProductProcess_Process_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type ProductProcess_Process_NeoBlockInput = {
  id?: Maybe<Scalars['ID']>;
  level?: Maybe<Scalars['Int']>;
  taskInformation?: Maybe<Scalars['String']>;
  taskName?: Maybe<Scalars['String']>;
};

export type ProductProcess_TimelineBreak_BlockType = ElementInterface & NeoBlockInterface & {
  __typename?: 'productProcess_timelineBreak_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the neo block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The Neo block's level. */
  level?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the neo block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  timelineItemHeadline?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the neo block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the neo block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type ProductProcess_TimelineBreak_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type ProductProcess_TimelineBreak_NeoBlockInput = {
  id?: Maybe<Scalars['ID']>;
  level?: Maybe<Scalars['Int']>;
  timelineItemHeadline?: Maybe<Scalars['String']>;
};

export type ProductProcess_TimelineItem_BlockType = ElementInterface & NeoBlockInterface & {
  __typename?: 'productProcess_timelineItem_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the neo block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The Neo block's level. */
  level?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the neo block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  timelineItemHeadline?: Maybe<Scalars['String']>;
  timelineItemImages?: Maybe<Array<Maybe<AssetInterface>>>;
  timelineItemText?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the neo block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the neo block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type ProductProcess_TimelineItem_BlockType_CountArgs = {
  field: Scalars['String'];
};


export type ProductProcess_TimelineItem_BlockTypeTimelineItemImagesArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProductProcess_TimelineItem_NeoBlockInput = {
  id?: Maybe<Scalars['ID']>;
  level?: Maybe<Scalars['Int']>;
  timelineItemHeadline?: Maybe<Scalars['String']>;
  timelineItemImages?: Maybe<Array<Maybe<Scalars['Int']>>>;
  timelineItemText?: Maybe<Scalars['String']>;
};

export type ProductType_Category = CategoryInterface & ElementInterface & {
  __typename?: 'productType_Category';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The category’s children. */
  children?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The handle of the group that contains the category. */
  groupHandle?: Maybe<Scalars['String']>;
  /** The ID of the group that contains the category. */
  groupId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<CategoryInterface>;
  /** The category’s parent. */
  parent?: Maybe<CategoryInterface>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<CategoryInterface>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic?: Maybe<SeomaticInterface>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
};


export type ProductType_Category_CountArgs = {
  field: Scalars['String'];
};


export type ProductType_CategoryChildrenArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type ProductType_CategoryLocalizedArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type ProductType_CategoryNextArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type ProductType_CategoryParentArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type ProductType_CategoryPrevArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type ProductType_CategorySeomaticArgs = {
  asArray?: Maybe<Scalars['Boolean']>;
  environment?: Maybe<SeomaticEnvironment>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  uri?: Maybe<Scalars['String']>;
};

export type Product_Product_Entry = ElementInterface & EntryInterface & {
  __typename?: 'product_product_Entry';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  abstract?: Maybe<Scalars['String']>;
  /** The entry’s ancestors, if the section is a structure. Accepts the same arguments as the `entries` query. */
  ancestors?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<CategoryInterface>>>;
  atAGlance?: Maybe<Array<Maybe<AtAGlance_SuperTableField>>>;
  callToAction?: Maybe<Array<Maybe<CallToAction_NeoField>>>;
  /** Returns the entry’s canonical ID. */
  canonicalId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  canonicalUid?: Maybe<Scalars['String']>;
  /** The entry’s children, if the section is a structure. Accepts the same arguments as the `entries` query. */
  children?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The current revision for the entry. */
  currentRevision?: Maybe<EntryInterface>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** The entry’s descendants, if the section is a structure. Accepts the same arguments as the `entries` query. */
  descendants?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The creator of a given draft. */
  draftCreator?: Maybe<UserInterface>;
  /** The draft ID (from the `drafts` table). */
  draftId?: Maybe<Scalars['Int']>;
  /** The name of the draft. */
  draftName?: Maybe<Scalars['String']>;
  /** The notes for the draft. */
  draftNotes?: Maybe<Scalars['String']>;
  /** The drafts for the entry. */
  drafts?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The expiry date of the entry. */
  expiryDate?: Maybe<Scalars['DateTime']>;
  hasuraProductId?: Maybe<Scalars['String']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  image?: Maybe<Array<Maybe<AssetInterface>>>;
  intro?: Maybe<Scalars['String']>;
  introImage?: Maybe<Array<Maybe<AssetInterface>>>;
  /** Returns whether this is a draft. */
  isDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is a revision. */
  isRevision?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. */
  isUnpublishedDraft?: Maybe<Scalars['Boolean']>;
  /** Returns whether this is an unpublished draft. **This field is deprecated.** `isUnpublishedDraft` should be used instead. */
  isUnsavedDraft?: Maybe<Scalars['Boolean']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<EntryInterface>>>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<EntryInterface>;
  /** The entry’s parent, if the section is a structure. */
  parent?: Maybe<EntryInterface>;
  /** The entry's post date. */
  postDate?: Maybe<Scalars['DateTime']>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<EntryInterface>;
  productDetails?: Maybe<Array<Maybe<ProductDetails_SuperTableField>>>;
  productProcess?: Maybe<Array<Maybe<ProductProcess_NeoField>>>;
  productType?: Maybe<Array<Maybe<CategoryInterface>>>;
  region?: Maybe<Array<Maybe<CategoryInterface>>>;
  relatedDocumentation?: Maybe<Array<Maybe<AssetInterface>>>;
  /** The creator of a given revision. */
  revisionCreator?: Maybe<UserInterface>;
  /** The revision ID (from the `revisions` table). */
  revisionId?: Maybe<Scalars['Int']>;
  /** The revisions for the entry. */
  revisions?: Maybe<Array<Maybe<EntryInterface>>>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The handle of the section that contains the entry. */
  sectionHandle?: Maybe<Scalars['String']>;
  /** The ID of the section that contains the entry. */
  sectionId?: Maybe<Scalars['Int']>;
  seoFields?: Maybe<Scalars['String']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic?: Maybe<SeomaticInterface>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** Returns the entry’s canonical ID. */
  sourceId?: Maybe<Scalars['Int']>;
  /** Returns the entry’s canonical UUID. */
  sourceUid?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the entry type that contains the entry. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the entry type that contains the entry. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
};


export type Product_Product_Entry_CountArgs = {
  field: Scalars['String'];
};


export type Product_Product_EntryAncestorsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Product_Product_EntryAssociatedProducerArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Product_Product_EntryAtAGlanceArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Product_Product_EntryCallToActionArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Product_Product_EntryChildrenArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Product_Product_EntryDescendantsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Product_Product_EntryDraftsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Product_Product_EntryImageArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Product_Product_EntryIntroImageArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Product_Product_EntryLocalizedArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Product_Product_EntryNextArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Product_Product_EntryParentArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Product_Product_EntryPrevArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Product_Product_EntryProductDetailsArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Product_Product_EntryProductProcessArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fieldId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['Int']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Product_Product_EntryProductTypeArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Product_Product_EntryRegionArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Product_Product_EntryRelatedDocumentationArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Product_Product_EntryRevisionsArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Product_Product_EntrySeomaticArgs = {
  asArray?: Maybe<Scalars['Boolean']>;
  environment?: Maybe<SeomaticEnvironment>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  uri?: Maybe<Scalars['String']>;
};

/** columns and relationships of "product_resources" */
export type Product_Resources = {
  __typename?: 'product_resources';
  /** An object relationship */
  product: Products;
  product_id: Scalars['uuid'];
  /** An object relationship */
  resource: Resources;
  resource_id: Scalars['uuid'];
};

/** aggregated selection of "product_resources" */
export type Product_Resources_Aggregate = {
  __typename?: 'product_resources_aggregate';
  aggregate?: Maybe<Product_Resources_Aggregate_Fields>;
  nodes: Array<Product_Resources>;
};

/** aggregate fields of "product_resources" */
export type Product_Resources_Aggregate_Fields = {
  __typename?: 'product_resources_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Product_Resources_Max_Fields>;
  min?: Maybe<Product_Resources_Min_Fields>;
};


/** aggregate fields of "product_resources" */
export type Product_Resources_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Resources_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_resources" */
export type Product_Resources_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Product_Resources_Max_Order_By>;
  min?: Maybe<Product_Resources_Min_Order_By>;
};

/** input type for inserting array relation for remote table "product_resources" */
export type Product_Resources_Arr_Rel_Insert_Input = {
  data: Array<Product_Resources_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Product_Resources_On_Conflict>;
};

/** Boolean expression to filter rows from the table "product_resources". All fields are combined with a logical 'AND'. */
export type Product_Resources_Bool_Exp = {
  _and?: Maybe<Array<Product_Resources_Bool_Exp>>;
  _not?: Maybe<Product_Resources_Bool_Exp>;
  _or?: Maybe<Array<Product_Resources_Bool_Exp>>;
  product?: Maybe<Products_Bool_Exp>;
  product_id?: Maybe<Uuid_Comparison_Exp>;
  resource?: Maybe<Resources_Bool_Exp>;
  resource_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_resources" */
export enum Product_Resources_Constraint {
  /** unique or primary key constraint */
  ProductResourcesPkey = 'product_resources_pkey'
}

/** input type for inserting data into table "product_resources" */
export type Product_Resources_Insert_Input = {
  product?: Maybe<Products_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars['uuid']>;
  resource?: Maybe<Resources_Obj_Rel_Insert_Input>;
  resource_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Product_Resources_Max_Fields = {
  __typename?: 'product_resources_max_fields';
  product_id?: Maybe<Scalars['uuid']>;
  resource_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "product_resources" */
export type Product_Resources_Max_Order_By = {
  product_id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Resources_Min_Fields = {
  __typename?: 'product_resources_min_fields';
  product_id?: Maybe<Scalars['uuid']>;
  resource_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "product_resources" */
export type Product_Resources_Min_Order_By = {
  product_id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "product_resources" */
export type Product_Resources_Mutation_Response = {
  __typename?: 'product_resources_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Resources>;
};

/** on conflict condition type for table "product_resources" */
export type Product_Resources_On_Conflict = {
  constraint: Product_Resources_Constraint;
  update_columns?: Array<Product_Resources_Update_Column>;
  where?: Maybe<Product_Resources_Bool_Exp>;
};

/** Ordering options when selecting data from "product_resources". */
export type Product_Resources_Order_By = {
  product?: Maybe<Products_Order_By>;
  product_id?: Maybe<Order_By>;
  resource?: Maybe<Resources_Order_By>;
  resource_id?: Maybe<Order_By>;
};

/** primary key columns input for table: product_resources */
export type Product_Resources_Pk_Columns_Input = {
  product_id: Scalars['uuid'];
  resource_id: Scalars['uuid'];
};

/** select columns of table "product_resources" */
export enum Product_Resources_Select_Column {
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ResourceId = 'resource_id'
}

/** input type for updating data in table "product_resources" */
export type Product_Resources_Set_Input = {
  product_id?: Maybe<Scalars['uuid']>;
  resource_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "product_resources" */
export enum Product_Resources_Update_Column {
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ResourceId = 'resource_id'
}

/** columns and relationships of "products" */
export type Products = {
  __typename?: 'products';
  /** fetch data from the table: "actions" */
  actions: Array<Actions>;
  /** An aggregate relationship */
  actions_aggregate: Actions_Aggregate;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  is_preliminary_product_of: Array<Products_Preliminary>;
  /** An aggregate relationship */
  is_preliminary_product_of_aggregate: Products_Preliminary_Aggregate;
  /** An object relationship */
  main_action?: Maybe<Actions>;
  main_action_id?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  /** An array relationship */
  preliminary_products: Array<Products_Preliminary>;
  /** An aggregate relationship */
  preliminary_products_aggregate: Products_Preliminary_Aggregate;
  producer?: Maybe<CategoryInterface>;
  producer_id: Scalars['String'];
  /** An array relationship */
  product_resources: Array<Product_Resources>;
  /** An aggregate relationship */
  product_resources_aggregate: Product_Resources_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "products" */
export type ProductsActionsArgs = {
  distinct_on?: Maybe<Array<Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Actions_Order_By>>;
  where?: Maybe<Actions_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsActions_AggregateArgs = {
  distinct_on?: Maybe<Array<Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Actions_Order_By>>;
  where?: Maybe<Actions_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsIs_Preliminary_Product_OfArgs = {
  distinct_on?: Maybe<Array<Products_Preliminary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Preliminary_Order_By>>;
  where?: Maybe<Products_Preliminary_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsIs_Preliminary_Product_Of_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Preliminary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Preliminary_Order_By>>;
  where?: Maybe<Products_Preliminary_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsPreliminary_ProductsArgs = {
  distinct_on?: Maybe<Array<Products_Preliminary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Preliminary_Order_By>>;
  where?: Maybe<Products_Preliminary_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsPreliminary_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Preliminary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Preliminary_Order_By>>;
  where?: Maybe<Products_Preliminary_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProducerArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


/** columns and relationships of "products" */
export type ProductsProduct_ResourcesArgs = {
  distinct_on?: Maybe<Array<Product_Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Resources_Order_By>>;
  where?: Maybe<Product_Resources_Bool_Exp>;
};


/** columns and relationships of "products" */
export type ProductsProduct_Resources_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Resources_Order_By>>;
  where?: Maybe<Product_Resources_Bool_Exp>;
};

/** aggregated selection of "products" */
export type Products_Aggregate = {
  __typename?: 'products_aggregate';
  aggregate?: Maybe<Products_Aggregate_Fields>;
  nodes: Array<Products>;
};

/** aggregate fields of "products" */
export type Products_Aggregate_Fields = {
  __typename?: 'products_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Products_Max_Fields>;
  min?: Maybe<Products_Min_Fields>;
};


/** aggregate fields of "products" */
export type Products_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Products_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "products". All fields are combined with a logical 'AND'. */
export type Products_Bool_Exp = {
  _and?: Maybe<Array<Products_Bool_Exp>>;
  _not?: Maybe<Products_Bool_Exp>;
  _or?: Maybe<Array<Products_Bool_Exp>>;
  actions?: Maybe<Actions_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<String_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_preliminary_product_of?: Maybe<Products_Preliminary_Bool_Exp>;
  main_action?: Maybe<Actions_Bool_Exp>;
  main_action_id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  preliminary_products?: Maybe<Products_Preliminary_Bool_Exp>;
  producer_id?: Maybe<String_Comparison_Exp>;
  product_resources?: Maybe<Product_Resources_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "products" */
export enum Products_Constraint {
  /** unique or primary key constraint */
  ProductsMainProcessKey = 'products_main_process_key',
  /** unique or primary key constraint */
  ProductsPkey = 'products_pkey'
}

/** input type for inserting data into table "products" */
export type Products_Insert_Input = {
  actions?: Maybe<Actions_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  is_preliminary_product_of?: Maybe<Products_Preliminary_Arr_Rel_Insert_Input>;
  main_action?: Maybe<Actions_Obj_Rel_Insert_Input>;
  main_action_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  preliminary_products?: Maybe<Products_Preliminary_Arr_Rel_Insert_Input>;
  producer_id?: Maybe<Scalars['String']>;
  product_resources?: Maybe<Product_Resources_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Products_Max_Fields = {
  __typename?: 'products_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  main_action_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Products_Min_Fields = {
  __typename?: 'products_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  main_action_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "products" */
export type Products_Mutation_Response = {
  __typename?: 'products_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Products>;
};

/** input type for inserting object relation for remote table "products" */
export type Products_Obj_Rel_Insert_Input = {
  data: Products_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Products_On_Conflict>;
};

/** on conflict condition type for table "products" */
export type Products_On_Conflict = {
  constraint: Products_Constraint;
  update_columns?: Array<Products_Update_Column>;
  where?: Maybe<Products_Bool_Exp>;
};

/** Ordering options when selecting data from "products". */
export type Products_Order_By = {
  actions_aggregate?: Maybe<Actions_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_preliminary_product_of_aggregate?: Maybe<Products_Preliminary_Aggregate_Order_By>;
  main_action?: Maybe<Actions_Order_By>;
  main_action_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  preliminary_products_aggregate?: Maybe<Products_Preliminary_Aggregate_Order_By>;
  producer_id?: Maybe<Order_By>;
  product_resources_aggregate?: Maybe<Product_Resources_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: products */
export type Products_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "products_preliminary" */
export type Products_Preliminary = {
  __typename?: 'products_preliminary';
  /** Product UUID */
  id: Scalars['uuid'];
  /** Preliminary Product UUID */
  preliminary_id: Scalars['uuid'];
  /** An object relationship */
  preliminary_product: Products;
  /** An object relationship */
  product: Products;
};

/** aggregated selection of "products_preliminary" */
export type Products_Preliminary_Aggregate = {
  __typename?: 'products_preliminary_aggregate';
  aggregate?: Maybe<Products_Preliminary_Aggregate_Fields>;
  nodes: Array<Products_Preliminary>;
};

/** aggregate fields of "products_preliminary" */
export type Products_Preliminary_Aggregate_Fields = {
  __typename?: 'products_preliminary_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Products_Preliminary_Max_Fields>;
  min?: Maybe<Products_Preliminary_Min_Fields>;
};


/** aggregate fields of "products_preliminary" */
export type Products_Preliminary_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Products_Preliminary_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "products_preliminary" */
export type Products_Preliminary_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Products_Preliminary_Max_Order_By>;
  min?: Maybe<Products_Preliminary_Min_Order_By>;
};

/** input type for inserting array relation for remote table "products_preliminary" */
export type Products_Preliminary_Arr_Rel_Insert_Input = {
  data: Array<Products_Preliminary_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Products_Preliminary_On_Conflict>;
};

/** Boolean expression to filter rows from the table "products_preliminary". All fields are combined with a logical 'AND'. */
export type Products_Preliminary_Bool_Exp = {
  _and?: Maybe<Array<Products_Preliminary_Bool_Exp>>;
  _not?: Maybe<Products_Preliminary_Bool_Exp>;
  _or?: Maybe<Array<Products_Preliminary_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  preliminary_id?: Maybe<Uuid_Comparison_Exp>;
  preliminary_product?: Maybe<Products_Bool_Exp>;
  product?: Maybe<Products_Bool_Exp>;
};

/** unique or primary key constraints on table "products_preliminary" */
export enum Products_Preliminary_Constraint {
  /** unique or primary key constraint */
  ProductsPreliminaryPkey = 'products_preliminary_pkey'
}

/** input type for inserting data into table "products_preliminary" */
export type Products_Preliminary_Insert_Input = {
  /** Product UUID */
  id?: Maybe<Scalars['uuid']>;
  /** Preliminary Product UUID */
  preliminary_id?: Maybe<Scalars['uuid']>;
  preliminary_product?: Maybe<Products_Obj_Rel_Insert_Input>;
  product?: Maybe<Products_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Products_Preliminary_Max_Fields = {
  __typename?: 'products_preliminary_max_fields';
  /** Product UUID */
  id?: Maybe<Scalars['uuid']>;
  /** Preliminary Product UUID */
  preliminary_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "products_preliminary" */
export type Products_Preliminary_Max_Order_By = {
  /** Product UUID */
  id?: Maybe<Order_By>;
  /** Preliminary Product UUID */
  preliminary_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Products_Preliminary_Min_Fields = {
  __typename?: 'products_preliminary_min_fields';
  /** Product UUID */
  id?: Maybe<Scalars['uuid']>;
  /** Preliminary Product UUID */
  preliminary_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "products_preliminary" */
export type Products_Preliminary_Min_Order_By = {
  /** Product UUID */
  id?: Maybe<Order_By>;
  /** Preliminary Product UUID */
  preliminary_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "products_preliminary" */
export type Products_Preliminary_Mutation_Response = {
  __typename?: 'products_preliminary_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Products_Preliminary>;
};

/** on conflict condition type for table "products_preliminary" */
export type Products_Preliminary_On_Conflict = {
  constraint: Products_Preliminary_Constraint;
  update_columns?: Array<Products_Preliminary_Update_Column>;
  where?: Maybe<Products_Preliminary_Bool_Exp>;
};

/** Ordering options when selecting data from "products_preliminary". */
export type Products_Preliminary_Order_By = {
  id?: Maybe<Order_By>;
  preliminary_id?: Maybe<Order_By>;
  preliminary_product?: Maybe<Products_Order_By>;
  product?: Maybe<Products_Order_By>;
};

/** primary key columns input for table: products_preliminary */
export type Products_Preliminary_Pk_Columns_Input = {
  /** Product UUID */
  id: Scalars['uuid'];
  /** Preliminary Product UUID */
  preliminary_id: Scalars['uuid'];
};

/** select columns of table "products_preliminary" */
export enum Products_Preliminary_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PreliminaryId = 'preliminary_id'
}

/** input type for updating data in table "products_preliminary" */
export type Products_Preliminary_Set_Input = {
  /** Product UUID */
  id?: Maybe<Scalars['uuid']>;
  /** Preliminary Product UUID */
  preliminary_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "products_preliminary" */
export enum Products_Preliminary_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  PreliminaryId = 'preliminary_id'
}

/** columns and relationships of "products_recoverable" */
export type Products_Recoverable = {
  __typename?: 'products_recoverable';
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "products_recoverable" */
export type Products_Recoverable_Aggregate = {
  __typename?: 'products_recoverable_aggregate';
  aggregate?: Maybe<Products_Recoverable_Aggregate_Fields>;
  nodes: Array<Products_Recoverable>;
};

/** aggregate fields of "products_recoverable" */
export type Products_Recoverable_Aggregate_Fields = {
  __typename?: 'products_recoverable_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Products_Recoverable_Max_Fields>;
  min?: Maybe<Products_Recoverable_Min_Fields>;
};


/** aggregate fields of "products_recoverable" */
export type Products_Recoverable_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Products_Recoverable_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "products_recoverable". All fields are combined with a logical 'AND'. */
export type Products_Recoverable_Bool_Exp = {
  _and?: Maybe<Array<Products_Recoverable_Bool_Exp>>;
  _not?: Maybe<Products_Recoverable_Bool_Exp>;
  _or?: Maybe<Array<Products_Recoverable_Bool_Exp>>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  producer_id?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "products_recoverable" */
export type Products_Recoverable_Insert_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Products_Recoverable_Max_Fields = {
  __typename?: 'products_recoverable_max_fields';
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Products_Recoverable_Min_Fields = {
  __typename?: 'products_recoverable_min_fields';
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "products_recoverable" */
export type Products_Recoverable_Mutation_Response = {
  __typename?: 'products_recoverable_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Products_Recoverable>;
};

/** Ordering options when selecting data from "products_recoverable". */
export type Products_Recoverable_Order_By = {
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
};

/** select columns of table "products_recoverable" */
export enum Products_Recoverable_Select_Column {
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProducerId = 'producer_id'
}

/** input type for updating data in table "products_recoverable" */
export type Products_Recoverable_Set_Input = {
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['String']>;
};

/** select columns of table "products" */
export enum Products_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  MainActionId = 'main_action_id',
  /** column name */
  Name = 'name',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "products" */
export type Products_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  main_action_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "products" */
export enum Products_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  MainActionId = 'main_action_id',
  /** column name */
  Name = 'name',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "actions" */
  actions: Array<Actions>;
  /** An aggregate relationship */
  actions_aggregate: Actions_Aggregate;
  /** fetch data from the table: "actions" using primary key columns */
  actions_by_pk?: Maybe<Actions>;
  /** An array relationship */
  actions_category_items: Array<Actions_Category_Items>;
  /** An aggregate relationship */
  actions_category_items_aggregate: Actions_Category_Items_Aggregate;
  /** fetch data from the table: "actions_category_items" using primary key columns */
  actions_category_items_by_pk?: Maybe<Actions_Category_Items>;
  /** fetch data from the table: "actions_resources" */
  actions_resources: Array<Actions_Resources>;
  /** fetch aggregated fields from the table: "actions_resources" */
  actions_resources_aggregate: Actions_Resources_Aggregate;
  /** fetch data from the table: "actions_resources" using primary key columns */
  actions_resources_by_pk?: Maybe<Actions_Resources>;
  /** This query is used to query for a single asset. */
  asset?: Maybe<AssetInterface>;
  /** This query is used to return the number of assets. */
  assetCount: Scalars['Int'];
  /** This query is used to query for assets. */
  assets?: Maybe<Array<Maybe<AssetInterface>>>;
  /** This query is used to query for categories. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** This query is used to query for a single category. */
  category?: Maybe<CategoryInterface>;
  /** This query is used to return the number of categories. */
  categoryCount: Scalars['Int'];
  /** fetch data from the table: "category_default_types" */
  category_default_types: Array<Category_Default_Types>;
  /** fetch aggregated fields from the table: "category_default_types" */
  category_default_types_aggregate: Category_Default_Types_Aggregate;
  /** fetch data from the table: "category_default_types" using primary key columns */
  category_default_types_by_pk?: Maybe<Category_Default_Types>;
  /** fetch data from the table: "category_items" */
  category_items: Array<Category_Items>;
  /** fetch aggregated fields from the table: "category_items" */
  category_items_aggregate: Category_Items_Aggregate;
  /** fetch data from the table: "category_items" using primary key columns */
  category_items_by_pk?: Maybe<Category_Items>;
  /** fetch data from the table: "category_locations" */
  category_locations: Array<Category_Locations>;
  /** fetch aggregated fields from the table: "category_locations" */
  category_locations_aggregate: Category_Locations_Aggregate;
  /** fetch data from the table: "category_locations" using primary key columns */
  category_locations_by_pk?: Maybe<Category_Locations>;
  /** fetch data from the table: "category_machines" */
  category_machines: Array<Category_Machines>;
  /** fetch aggregated fields from the table: "category_machines" */
  category_machines_aggregate: Category_Machines_Aggregate;
  /** fetch data from the table: "category_machines" using primary key columns */
  category_machines_by_pk?: Maybe<Category_Machines>;
  /** fetch data from the table: "category_types" */
  category_types: Array<Category_Types>;
  /** fetch aggregated fields from the table: "category_types" */
  category_types_aggregate: Category_Types_Aggregate;
  /** fetch data from the table: "category_types" using primary key columns */
  category_types_by_pk?: Maybe<Category_Types>;
  /** An array relationship */
  category_usage_in_tasks: Array<Category_Usage_In_Tasks>;
  /** An aggregate relationship */
  category_usage_in_tasks_aggregate: Category_Usage_In_Tasks_Aggregate;
  /** fetch data from the table: "category_usage_in_tasks" using primary key columns */
  category_usage_in_tasks_by_pk?: Maybe<Category_Usage_In_Tasks>;
  /** fetch data from the table: "datafield_types" */
  datafield_types: Array<Datafield_Types>;
  /** fetch aggregated fields from the table: "datafield_types" */
  datafield_types_aggregate: Datafield_Types_Aggregate;
  /** fetch data from the table: "datafield_types" using primary key columns */
  datafield_types_by_pk?: Maybe<Datafield_Types>;
  /** An array relationship */
  datafields: Array<Datafields>;
  /** An aggregate relationship */
  datafields_aggregate: Datafields_Aggregate;
  /** fetch data from the table: "datafields" using primary key columns */
  datafields_by_pk?: Maybe<Datafields>;
  /** This query is used to query for entries. */
  entries?: Maybe<Array<Maybe<EntryInterface>>>;
  /** This query is used to query for a single entry. */
  entry?: Maybe<EntryInterface>;
  /** This query is used to return the number of entries. */
  entryCount: Scalars['Int'];
  /** fetch data from the table: "files" */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "files" */
  files_aggregate: Files_Aggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  /** This query is used to query for a single global set. */
  globalSet?: Maybe<GlobalSetInterface>;
  /** This query is used to query for global sets. */
  globalSets?: Maybe<Array<Maybe<GlobalSetInterface>>>;
  /** An array relationship */
  logs: Array<Logs>;
  /** An aggregate relationship */
  logs_aggregate: Logs_Aggregate;
  /** fetch data from the table: "logs" using primary key columns */
  logs_by_pk?: Maybe<Logs>;
  /** fetch data from the table: "messages" */
  messages: Array<Messages>;
  /** fetch aggregated fields from the table: "messages" */
  messages_aggregate: Messages_Aggregate;
  /** fetch data from the table: "messages" using primary key columns */
  messages_by_pk?: Maybe<Messages>;
  /** fetch data from the table: "messages_online" */
  messages_online: Array<Messages_Online>;
  /** fetch aggregated fields from the table: "messages_online" */
  messages_online_aggregate: Messages_Online_Aggregate;
  /** This query is used to query for a single node. */
  node?: Maybe<NodeInterface>;
  /** This query is used to query for nodes. */
  nodes?: Maybe<Array<Maybe<NodeInterface>>>;
  ping?: Maybe<Scalars['String']>;
  /** An array relationship */
  product_resources: Array<Product_Resources>;
  /** An aggregate relationship */
  product_resources_aggregate: Product_Resources_Aggregate;
  /** fetch data from the table: "product_resources" using primary key columns */
  product_resources_by_pk?: Maybe<Product_Resources>;
  /** fetch data from the table: "products" */
  products: Array<Products>;
  /** fetch aggregated fields from the table: "products" */
  products_aggregate: Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** fetch data from the table: "products_preliminary" */
  products_preliminary: Array<Products_Preliminary>;
  /** fetch aggregated fields from the table: "products_preliminary" */
  products_preliminary_aggregate: Products_Preliminary_Aggregate;
  /** fetch data from the table: "products_preliminary" using primary key columns */
  products_preliminary_by_pk?: Maybe<Products_Preliminary>;
  /** fetch data from the table: "products_recoverable" */
  products_recoverable: Array<Products_Recoverable>;
  /** fetch aggregated fields from the table: "products_recoverable" */
  products_recoverable_aggregate: Products_Recoverable_Aggregate;
  /** An array relationship */
  resource_batches: Array<Resource_Batches>;
  /** An aggregate relationship */
  resource_batches_aggregate: Resource_Batches_Aggregate;
  /** fetch data from the table: "resource_batches" using primary key columns */
  resource_batches_by_pk?: Maybe<Resource_Batches>;
  /** fetch data from the table: "resource_default_types" */
  resource_default_types: Array<Resource_Default_Types>;
  /** fetch aggregated fields from the table: "resource_default_types" */
  resource_default_types_aggregate: Resource_Default_Types_Aggregate;
  /** fetch data from the table: "resource_default_types" using primary key columns */
  resource_default_types_by_pk?: Maybe<Resource_Default_Types>;
  /** fetch data from the table: "resource_types" */
  resource_types: Array<Resource_Types>;
  /** fetch aggregated fields from the table: "resource_types" */
  resource_types_aggregate: Resource_Types_Aggregate;
  /** fetch data from the table: "resource_types" using primary key columns */
  resource_types_by_pk?: Maybe<Resource_Types>;
  /** An array relationship */
  resource_usage_in_tasks: Array<Resource_Usage_In_Tasks>;
  /** An aggregate relationship */
  resource_usage_in_tasks_aggregate: Resource_Usage_In_Tasks_Aggregate;
  /** fetch data from the table: "resource_usage_in_tasks" using primary key columns */
  resource_usage_in_tasks_by_pk?: Maybe<Resource_Usage_In_Tasks>;
  /** fetch data from the table: "resource_usage_in_tasks_flatten" */
  resource_usage_in_tasks_flatten: Array<Resource_Usage_In_Tasks_Flatten>;
  /** fetch aggregated fields from the table: "resource_usage_in_tasks_flatten" */
  resource_usage_in_tasks_flatten_aggregate: Resource_Usage_In_Tasks_Flatten_Aggregate;
  /** An array relationship */
  resources: Array<Resources>;
  /** An aggregate relationship */
  resources_aggregate: Resources_Aggregate;
  /** fetch data from the table: "resources" using primary key columns */
  resources_by_pk?: Maybe<Resources>;
  /** execute function "search_products" which returns "products" */
  search_products: Array<Products>;
  /** execute function "search_products" and query aggregates on result of table type "products" */
  search_products_aggregate: Products_Aggregate;
  /** This query is used to query for SEOmatic meta data. */
  seomatic?: Maybe<SeomaticInterface>;
  /** fetch data from the table: "suppliers" */
  suppliers: Array<Suppliers>;
  /** fetch aggregated fields from the table: "suppliers" */
  suppliers_aggregate: Suppliers_Aggregate;
  /** fetch data from the table: "suppliers" using primary key columns */
  suppliers_by_pk?: Maybe<Suppliers>;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  /** fetch data from the table: "tasks" using primary key columns */
  tasks_by_pk?: Maybe<Tasks>;
  /** An array relationship */
  tasks_flatten: Array<Tasks_Flatten>;
  /** An aggregate relationship */
  tasks_flatten_aggregate: Tasks_Flatten_Aggregate;
  /** fetch data from the table: "usage_types" */
  usage_types: Array<Usage_Types>;
  /** fetch aggregated fields from the table: "usage_types" */
  usage_types_aggregate: Usage_Types_Aggregate;
  /** fetch data from the table: "usage_types" using primary key columns */
  usage_types_by_pk?: Maybe<Usage_Types>;
  validateToken?: Maybe<ValidateToken>;
};


export type Query_RootActionsArgs = {
  distinct_on?: Maybe<Array<Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Actions_Order_By>>;
  where?: Maybe<Actions_Bool_Exp>;
};


export type Query_RootActions_AggregateArgs = {
  distinct_on?: Maybe<Array<Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Actions_Order_By>>;
  where?: Maybe<Actions_Bool_Exp>;
};


export type Query_RootActions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootActions_Category_ItemsArgs = {
  distinct_on?: Maybe<Array<Actions_Category_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Actions_Category_Items_Order_By>>;
  where?: Maybe<Actions_Category_Items_Bool_Exp>;
};


export type Query_RootActions_Category_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Actions_Category_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Actions_Category_Items_Order_By>>;
  where?: Maybe<Actions_Category_Items_Bool_Exp>;
};


export type Query_RootActions_Category_Items_By_PkArgs = {
  action_id: Scalars['uuid'];
  category_id: Scalars['uuid'];
};


export type Query_RootActions_ResourcesArgs = {
  distinct_on?: Maybe<Array<Actions_Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Actions_Resources_Order_By>>;
  where?: Maybe<Actions_Resources_Bool_Exp>;
};


export type Query_RootActions_Resources_AggregateArgs = {
  distinct_on?: Maybe<Array<Actions_Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Actions_Resources_Order_By>>;
  where?: Maybe<Actions_Resources_Bool_Exp>;
};


export type Query_RootActions_Resources_By_PkArgs = {
  action_id: Scalars['uuid'];
  resource_id: Scalars['uuid'];
  usage: Usage_Types_Enum;
};


export type Query_RootAssetArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootAssetCountArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootAssetsArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateModified?: Maybe<Scalars['String']>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  filename?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  folderId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  height?: Maybe<Array<Maybe<Scalars['String']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  includeSubfolders?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<Array<Maybe<Scalars['String']>>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  size?: Maybe<Array<Maybe<Scalars['String']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uploader?: Maybe<Scalars['QueryArgument']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  volume?: Maybe<Array<Maybe<Scalars['String']>>>;
  volumeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  width?: Maybe<Array<Maybe<Scalars['String']>>>;
  withTransforms?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootCategoriesArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Query_RootCategoryArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Query_RootCategoryCountArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Query_RootCategory_Default_TypesArgs = {
  distinct_on?: Maybe<Array<Category_Default_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Default_Types_Order_By>>;
  where?: Maybe<Category_Default_Types_Bool_Exp>;
};


export type Query_RootCategory_Default_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Default_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Default_Types_Order_By>>;
  where?: Maybe<Category_Default_Types_Bool_Exp>;
};


export type Query_RootCategory_Default_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootCategory_ItemsArgs = {
  distinct_on?: Maybe<Array<Category_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Items_Order_By>>;
  where?: Maybe<Category_Items_Bool_Exp>;
};


export type Query_RootCategory_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Items_Order_By>>;
  where?: Maybe<Category_Items_Bool_Exp>;
};


export type Query_RootCategory_Items_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCategory_LocationsArgs = {
  distinct_on?: Maybe<Array<Category_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Locations_Order_By>>;
  where?: Maybe<Category_Locations_Bool_Exp>;
};


export type Query_RootCategory_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Locations_Order_By>>;
  where?: Maybe<Category_Locations_Bool_Exp>;
};


export type Query_RootCategory_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCategory_MachinesArgs = {
  distinct_on?: Maybe<Array<Category_Machines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Machines_Order_By>>;
  where?: Maybe<Category_Machines_Bool_Exp>;
};


export type Query_RootCategory_Machines_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Machines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Machines_Order_By>>;
  where?: Maybe<Category_Machines_Bool_Exp>;
};


export type Query_RootCategory_Machines_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCategory_TypesArgs = {
  distinct_on?: Maybe<Array<Category_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Types_Order_By>>;
  where?: Maybe<Category_Types_Bool_Exp>;
};


export type Query_RootCategory_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Types_Order_By>>;
  where?: Maybe<Category_Types_Bool_Exp>;
};


export type Query_RootCategory_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCategory_Usage_In_TasksArgs = {
  distinct_on?: Maybe<Array<Category_Usage_In_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Usage_In_Tasks_Order_By>>;
  where?: Maybe<Category_Usage_In_Tasks_Bool_Exp>;
};


export type Query_RootCategory_Usage_In_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Usage_In_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Usage_In_Tasks_Order_By>>;
  where?: Maybe<Category_Usage_In_Tasks_Bool_Exp>;
};


export type Query_RootCategory_Usage_In_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDatafield_TypesArgs = {
  distinct_on?: Maybe<Array<Datafield_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Datafield_Types_Order_By>>;
  where?: Maybe<Datafield_Types_Bool_Exp>;
};


export type Query_RootDatafield_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Datafield_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Datafield_Types_Order_By>>;
  where?: Maybe<Datafield_Types_Bool_Exp>;
};


export type Query_RootDatafield_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootDatafieldsArgs = {
  distinct_on?: Maybe<Array<Datafields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Datafields_Order_By>>;
  where?: Maybe<Datafields_Bool_Exp>;
};


export type Query_RootDatafields_AggregateArgs = {
  distinct_on?: Maybe<Array<Datafields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Datafields_Order_By>>;
  where?: Maybe<Datafields_Bool_Exp>;
};


export type Query_RootDatafields_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootEntriesArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Query_RootEntryArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Query_RootEntryCountArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  after?: Maybe<Scalars['String']>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  associatedProducer?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorGroup?: Maybe<Array<Maybe<Scalars['String']>>>;
  authorGroupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  authorId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  before?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  hasuraProductId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  postDate?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  productType?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  section?: Maybe<Array<Maybe<Scalars['String']>>>;
  sectionId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  typeId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
  wysiwyg?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};


export type Query_RootFilesArgs = {
  distinct_on?: Maybe<Array<Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Order_By>>;
  where?: Maybe<Files_Bool_Exp>;
};


export type Query_RootFiles_AggregateArgs = {
  distinct_on?: Maybe<Array<Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Order_By>>;
  where?: Maybe<Files_Bool_Exp>;
};


export type Query_RootFiles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGlobalSetArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  handle?: Maybe<Array<Maybe<Scalars['String']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  showImportantNotice?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootGlobalSetsArgs = {
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  description?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  handle?: Maybe<Array<Maybe<Scalars['String']>>>;
  headline?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  showImportantNotice?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Query_RootLogsArgs = {
  distinct_on?: Maybe<Array<Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Order_By>>;
  where?: Maybe<Logs_Bool_Exp>;
};


export type Query_RootLogs_AggregateArgs = {
  distinct_on?: Maybe<Array<Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Order_By>>;
  where?: Maybe<Logs_Bool_Exp>;
};


export type Query_RootLogs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootMessagesArgs = {
  distinct_on?: Maybe<Array<Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Order_By>>;
  where?: Maybe<Messages_Bool_Exp>;
};


export type Query_RootMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Order_By>>;
  where?: Maybe<Messages_Bool_Exp>;
};


export type Query_RootMessages_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootMessages_OnlineArgs = {
  distinct_on?: Maybe<Array<Messages_Online_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Online_Order_By>>;
  where?: Maybe<Messages_Online_Bool_Exp>;
};


export type Query_RootMessages_Online_AggregateArgs = {
  distinct_on?: Maybe<Array<Messages_Online_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Online_Order_By>>;
  where?: Maybe<Messages_Online_Bool_Exp>;
};


export type Query_RootNodeArgs = {
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nav?: Maybe<Array<Maybe<Scalars['String']>>>;
  navHandle?: Maybe<Scalars['String']>;
  navId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Query_RootNodesArgs = {
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  draftCreator?: Maybe<Scalars['Int']>;
  draftId?: Maybe<Scalars['Int']>;
  draftOf?: Maybe<Scalars['QueryArgument']>;
  drafts?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  nav?: Maybe<Array<Maybe<Scalars['String']>>>;
  navHandle?: Maybe<Scalars['String']>;
  navId?: Maybe<Scalars['Int']>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  provisionalDrafts?: Maybe<Scalars['Boolean']>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  revisionCreator?: Maybe<Scalars['Int']>;
  revisionId?: Maybe<Scalars['Int']>;
  revisionOf?: Maybe<Scalars['QueryArgument']>;
  revisions?: Maybe<Scalars['Boolean']>;
  search?: Maybe<Scalars['String']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Array<Maybe<Scalars['String']>>>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Query_RootProduct_ResourcesArgs = {
  distinct_on?: Maybe<Array<Product_Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Resources_Order_By>>;
  where?: Maybe<Product_Resources_Bool_Exp>;
};


export type Query_RootProduct_Resources_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Resources_Order_By>>;
  where?: Maybe<Product_Resources_Bool_Exp>;
};


export type Query_RootProduct_Resources_By_PkArgs = {
  product_id: Scalars['uuid'];
  resource_id: Scalars['uuid'];
};


export type Query_RootProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Query_RootProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Query_RootProducts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProducts_PreliminaryArgs = {
  distinct_on?: Maybe<Array<Products_Preliminary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Preliminary_Order_By>>;
  where?: Maybe<Products_Preliminary_Bool_Exp>;
};


export type Query_RootProducts_Preliminary_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Preliminary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Preliminary_Order_By>>;
  where?: Maybe<Products_Preliminary_Bool_Exp>;
};


export type Query_RootProducts_Preliminary_By_PkArgs = {
  id: Scalars['uuid'];
  preliminary_id: Scalars['uuid'];
};


export type Query_RootProducts_RecoverableArgs = {
  distinct_on?: Maybe<Array<Products_Recoverable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Recoverable_Order_By>>;
  where?: Maybe<Products_Recoverable_Bool_Exp>;
};


export type Query_RootProducts_Recoverable_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Recoverable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Recoverable_Order_By>>;
  where?: Maybe<Products_Recoverable_Bool_Exp>;
};


export type Query_RootResource_BatchesArgs = {
  distinct_on?: Maybe<Array<Resource_Batches_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Batches_Order_By>>;
  where?: Maybe<Resource_Batches_Bool_Exp>;
};


export type Query_RootResource_Batches_AggregateArgs = {
  distinct_on?: Maybe<Array<Resource_Batches_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Batches_Order_By>>;
  where?: Maybe<Resource_Batches_Bool_Exp>;
};


export type Query_RootResource_Batches_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootResource_Default_TypesArgs = {
  distinct_on?: Maybe<Array<Resource_Default_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Default_Types_Order_By>>;
  where?: Maybe<Resource_Default_Types_Bool_Exp>;
};


export type Query_RootResource_Default_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Resource_Default_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Default_Types_Order_By>>;
  where?: Maybe<Resource_Default_Types_Bool_Exp>;
};


export type Query_RootResource_Default_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Query_RootResource_TypesArgs = {
  distinct_on?: Maybe<Array<Resource_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Types_Order_By>>;
  where?: Maybe<Resource_Types_Bool_Exp>;
};


export type Query_RootResource_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Resource_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Types_Order_By>>;
  where?: Maybe<Resource_Types_Bool_Exp>;
};


export type Query_RootResource_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootResource_Usage_In_TasksArgs = {
  distinct_on?: Maybe<Array<Resource_Usage_In_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Usage_In_Tasks_Order_By>>;
  where?: Maybe<Resource_Usage_In_Tasks_Bool_Exp>;
};


export type Query_RootResource_Usage_In_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Resource_Usage_In_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Usage_In_Tasks_Order_By>>;
  where?: Maybe<Resource_Usage_In_Tasks_Bool_Exp>;
};


export type Query_RootResource_Usage_In_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootResource_Usage_In_Tasks_FlattenArgs = {
  distinct_on?: Maybe<Array<Resource_Usage_In_Tasks_Flatten_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Usage_In_Tasks_Flatten_Order_By>>;
  where?: Maybe<Resource_Usage_In_Tasks_Flatten_Bool_Exp>;
};


export type Query_RootResource_Usage_In_Tasks_Flatten_AggregateArgs = {
  distinct_on?: Maybe<Array<Resource_Usage_In_Tasks_Flatten_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Usage_In_Tasks_Flatten_Order_By>>;
  where?: Maybe<Resource_Usage_In_Tasks_Flatten_Bool_Exp>;
};


export type Query_RootResourcesArgs = {
  distinct_on?: Maybe<Array<Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resources_Order_By>>;
  where?: Maybe<Resources_Bool_Exp>;
};


export type Query_RootResources_AggregateArgs = {
  distinct_on?: Maybe<Array<Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resources_Order_By>>;
  where?: Maybe<Resources_Bool_Exp>;
};


export type Query_RootResources_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootSearch_ProductsArgs = {
  args: Search_Products_Args;
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Query_RootSearch_Products_AggregateArgs = {
  args: Search_Products_Args;
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Query_RootSeomaticArgs = {
  asArray?: Maybe<Scalars['Boolean']>;
  environment?: Maybe<SeomaticEnvironment>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  uri?: Maybe<Scalars['String']>;
};


export type Query_RootSuppliersArgs = {
  distinct_on?: Maybe<Array<Suppliers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Suppliers_Order_By>>;
  where?: Maybe<Suppliers_Bool_Exp>;
};


export type Query_RootSuppliers_AggregateArgs = {
  distinct_on?: Maybe<Array<Suppliers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Suppliers_Order_By>>;
  where?: Maybe<Suppliers_Bool_Exp>;
};


export type Query_RootSuppliers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTasksArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


export type Query_RootTasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


export type Query_RootTasks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTasks_FlattenArgs = {
  distinct_on?: Maybe<Array<Tasks_Flatten_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Flatten_Order_By>>;
  where?: Maybe<Tasks_Flatten_Bool_Exp>;
};


export type Query_RootTasks_Flatten_AggregateArgs = {
  distinct_on?: Maybe<Array<Tasks_Flatten_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Flatten_Order_By>>;
  where?: Maybe<Tasks_Flatten_Bool_Exp>;
};


export type Query_RootUsage_TypesArgs = {
  distinct_on?: Maybe<Array<Usage_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Usage_Types_Order_By>>;
  where?: Maybe<Usage_Types_Bool_Exp>;
};


export type Query_RootUsage_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Usage_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Usage_Types_Order_By>>;
  where?: Maybe<Usage_Types_Bool_Exp>;
};


export type Query_RootUsage_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Regions_Category = CategoryInterface & ElementInterface & {
  __typename?: 'regions_Category';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The category’s children. */
  children?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The handle of the group that contains the category. */
  groupHandle?: Maybe<Scalars['String']>;
  /** The ID of the group that contains the category. */
  groupId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The element’s level within its structure */
  level?: Maybe<Scalars['Int']>;
  /** The element’s left position within its structure. */
  lft?: Maybe<Scalars['Int']>;
  /** The same element in other locales. */
  localized?: Maybe<Array<Maybe<CategoryInterface>>>;
  /** Entry Coordinates field */
  location?: Maybe<Location_EntryCoordinateField>;
  /** Returns the next element relative to this one, from a given set of criteria. */
  next?: Maybe<CategoryInterface>;
  /** The category’s parent. */
  parent?: Maybe<CategoryInterface>;
  /** Returns the previous element relative to this one, from a given set of criteria. */
  prev?: Maybe<CategoryInterface>;
  /** The element’s right position within its structure. */
  rgt?: Maybe<Scalars['Int']>;
  /** The element’s structure’s root ID */
  root?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** This query is used to query for SEOmatic meta data. */
  seomatic?: Maybe<SeomaticInterface>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  /** The element’s structure ID. */
  structureId?: Maybe<Scalars['Int']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
  /** The element’s full URL */
  url?: Maybe<Scalars['String']>;
};


export type Regions_Category_CountArgs = {
  field: Scalars['String'];
};


export type Regions_CategoryChildrenArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Regions_CategoryLocalizedArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Regions_CategoryNextArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Regions_CategoryParentArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Regions_CategoryPrevArgs = {
  abstract?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ancestorDist?: Maybe<Scalars['Int']>;
  ancestorOf?: Maybe<Scalars['Int']>;
  archived?: Maybe<Scalars['Boolean']>;
  companyName?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  dateCreated?: Maybe<Array<Maybe<Scalars['String']>>>;
  dateUpdated?: Maybe<Array<Maybe<Scalars['String']>>>;
  descendantDist?: Maybe<Scalars['Int']>;
  descendantOf?: Maybe<Scalars['Int']>;
  editable?: Maybe<Scalars['Boolean']>;
  enabledForSite?: Maybe<Scalars['Boolean']>;
  fixedOrder?: Maybe<Scalars['Boolean']>;
  group?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  hasDescendants?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  image?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  inReverse?: Maybe<Scalars['Boolean']>;
  intro?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  introImage?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  leaves?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  location?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  nextSiblingOf?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  orderBy?: Maybe<Scalars['String']>;
  positionedAfter?: Maybe<Scalars['Int']>;
  positionedBefore?: Maybe<Scalars['Int']>;
  preferSites?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  prevSiblingOf?: Maybe<Scalars['Int']>;
  producerLogo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  productsTotalCount?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  ref?: Maybe<Array<Maybe<Scalars['String']>>>;
  region?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedDocumentation?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedTo?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAll?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  relatedToAssets?: Maybe<Array<Maybe<AssetCriteriaInput>>>;
  relatedToCategories?: Maybe<Array<Maybe<CategoryCriteriaInput>>>;
  relatedToEntries?: Maybe<Array<Maybe<EntryCriteriaInput>>>;
  relatedToTags?: Maybe<Array<Maybe<TagCriteriaInput>>>;
  relatedToUsers?: Maybe<Array<Maybe<UserCriteriaInput>>>;
  search?: Maybe<Scalars['String']>;
  seoFields?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  showAtAGlance?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Array<Maybe<Scalars['String']>>>;
  siteId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  siteSettingsId?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  slug?: Maybe<Array<Maybe<Scalars['String']>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  structureId?: Maybe<Scalars['Int']>;
  title?: Maybe<Array<Maybe<Scalars['String']>>>;
  trashed?: Maybe<Scalars['Boolean']>;
  uid?: Maybe<Array<Maybe<Scalars['String']>>>;
  unique?: Maybe<Scalars['Boolean']>;
  uri?: Maybe<Array<Maybe<Scalars['String']>>>;
  website?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
  withStructure?: Maybe<Scalars['Boolean']>;
};


export type Regions_CategorySeomaticArgs = {
  asArray?: Maybe<Scalars['Boolean']>;
  environment?: Maybe<SeomaticEnvironment>;
  site?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['Int']>;
  uri?: Maybe<Scalars['String']>;
};

/** columns and relationships of "resource_batches" */
export type Resource_Batches = {
  __typename?: 'resource_batches';
  created_at: Scalars['timestamptz'];
  id: Scalars['String'];
  /** An object relationship */
  resource: Resources;
  resource_id: Scalars['uuid'];
  /** An object relationship */
  supplier: Suppliers;
  supplier_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "resource_batches" */
export type Resource_Batches_Aggregate = {
  __typename?: 'resource_batches_aggregate';
  aggregate?: Maybe<Resource_Batches_Aggregate_Fields>;
  nodes: Array<Resource_Batches>;
};

/** aggregate fields of "resource_batches" */
export type Resource_Batches_Aggregate_Fields = {
  __typename?: 'resource_batches_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Resource_Batches_Max_Fields>;
  min?: Maybe<Resource_Batches_Min_Fields>;
};


/** aggregate fields of "resource_batches" */
export type Resource_Batches_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Resource_Batches_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "resource_batches" */
export type Resource_Batches_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Resource_Batches_Max_Order_By>;
  min?: Maybe<Resource_Batches_Min_Order_By>;
};

/** input type for inserting array relation for remote table "resource_batches" */
export type Resource_Batches_Arr_Rel_Insert_Input = {
  data: Array<Resource_Batches_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Resource_Batches_On_Conflict>;
};

/** Boolean expression to filter rows from the table "resource_batches". All fields are combined with a logical 'AND'. */
export type Resource_Batches_Bool_Exp = {
  _and?: Maybe<Array<Resource_Batches_Bool_Exp>>;
  _not?: Maybe<Resource_Batches_Bool_Exp>;
  _or?: Maybe<Array<Resource_Batches_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  resource?: Maybe<Resources_Bool_Exp>;
  resource_id?: Maybe<Uuid_Comparison_Exp>;
  supplier?: Maybe<Suppliers_Bool_Exp>;
  supplier_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "resource_batches" */
export enum Resource_Batches_Constraint {
  /** unique or primary key constraint */
  ResourceBatchesIdResourceIdKey = 'resource_batches_id_resource_id_key',
  /** unique or primary key constraint */
  ResourceBatchesPkey = 'resource_batches_pkey'
}

/** input type for inserting data into table "resource_batches" */
export type Resource_Batches_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  resource?: Maybe<Resources_Obj_Rel_Insert_Input>;
  resource_id?: Maybe<Scalars['uuid']>;
  supplier?: Maybe<Suppliers_Obj_Rel_Insert_Input>;
  supplier_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Resource_Batches_Max_Fields = {
  __typename?: 'resource_batches_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['uuid']>;
  supplier_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "resource_batches" */
export type Resource_Batches_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Resource_Batches_Min_Fields = {
  __typename?: 'resource_batches_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['uuid']>;
  supplier_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "resource_batches" */
export type Resource_Batches_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  supplier_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "resource_batches" */
export type Resource_Batches_Mutation_Response = {
  __typename?: 'resource_batches_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Resource_Batches>;
};

/** on conflict condition type for table "resource_batches" */
export type Resource_Batches_On_Conflict = {
  constraint: Resource_Batches_Constraint;
  update_columns?: Array<Resource_Batches_Update_Column>;
  where?: Maybe<Resource_Batches_Bool_Exp>;
};

/** Ordering options when selecting data from "resource_batches". */
export type Resource_Batches_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  resource?: Maybe<Resources_Order_By>;
  resource_id?: Maybe<Order_By>;
  supplier?: Maybe<Suppliers_Order_By>;
  supplier_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: resource_batches */
export type Resource_Batches_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "resource_batches" */
export enum Resource_Batches_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  SupplierId = 'supplier_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "resource_batches" */
export type Resource_Batches_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  resource_id?: Maybe<Scalars['uuid']>;
  supplier_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "resource_batches" */
export enum Resource_Batches_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  SupplierId = 'supplier_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "resource_default_types" */
export type Resource_Default_Types = {
  __typename?: 'resource_default_types';
  _used: Scalars['Boolean'];
  comment: Scalars['String'];
  /** An array relationship */
  usage: Array<Resource_Types>;
  /** An aggregate relationship */
  usage_aggregate: Resource_Types_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "resource_default_types" */
export type Resource_Default_TypesUsageArgs = {
  distinct_on?: Maybe<Array<Resource_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Types_Order_By>>;
  where?: Maybe<Resource_Types_Bool_Exp>;
};


/** columns and relationships of "resource_default_types" */
export type Resource_Default_TypesUsage_AggregateArgs = {
  distinct_on?: Maybe<Array<Resource_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Types_Order_By>>;
  where?: Maybe<Resource_Types_Bool_Exp>;
};

/** aggregated selection of "resource_default_types" */
export type Resource_Default_Types_Aggregate = {
  __typename?: 'resource_default_types_aggregate';
  aggregate?: Maybe<Resource_Default_Types_Aggregate_Fields>;
  nodes: Array<Resource_Default_Types>;
};

/** aggregate fields of "resource_default_types" */
export type Resource_Default_Types_Aggregate_Fields = {
  __typename?: 'resource_default_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Resource_Default_Types_Max_Fields>;
  min?: Maybe<Resource_Default_Types_Min_Fields>;
};


/** aggregate fields of "resource_default_types" */
export type Resource_Default_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Resource_Default_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "resource_default_types". All fields are combined with a logical 'AND'. */
export type Resource_Default_Types_Bool_Exp = {
  _and?: Maybe<Array<Resource_Default_Types_Bool_Exp>>;
  _not?: Maybe<Resource_Default_Types_Bool_Exp>;
  _or?: Maybe<Array<Resource_Default_Types_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  usage?: Maybe<Resource_Types_Bool_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "resource_default_types" */
export enum Resource_Default_Types_Constraint {
  /** unique or primary key constraint */
  ResourceDefaultTypesPkey = 'resource_default_types_pkey'
}

export enum Resource_Default_Types_Enum {
  /** Chemikalien */
  Chemicals = 'chemicals',
  /** Lebensmittel */
  Food = 'food',
  /** Behälter, Verpackungen und Gläser */
  Packaging = 'packaging'
}

/** Boolean expression to compare columns of type "resource_default_types_enum". All fields are combined with logical 'AND'. */
export type Resource_Default_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Resource_Default_Types_Enum>;
  _in?: Maybe<Array<Resource_Default_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Resource_Default_Types_Enum>;
  _nin?: Maybe<Array<Resource_Default_Types_Enum>>;
};

/** input type for inserting data into table "resource_default_types" */
export type Resource_Default_Types_Insert_Input = {
  comment?: Maybe<Scalars['String']>;
  usage?: Maybe<Resource_Types_Arr_Rel_Insert_Input>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Resource_Default_Types_Max_Fields = {
  __typename?: 'resource_default_types_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Resource_Default_Types_Min_Fields = {
  __typename?: 'resource_default_types_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "resource_default_types" */
export type Resource_Default_Types_Mutation_Response = {
  __typename?: 'resource_default_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Resource_Default_Types>;
};

/** on conflict condition type for table "resource_default_types" */
export type Resource_Default_Types_On_Conflict = {
  constraint: Resource_Default_Types_Constraint;
  update_columns?: Array<Resource_Default_Types_Update_Column>;
  where?: Maybe<Resource_Default_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "resource_default_types". */
export type Resource_Default_Types_Order_By = {
  comment?: Maybe<Order_By>;
  usage_aggregate?: Maybe<Resource_Types_Aggregate_Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: resource_default_types */
export type Resource_Default_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "resource_default_types" */
export enum Resource_Default_Types_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "resource_default_types" */
export type Resource_Default_Types_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "resource_default_types" */
export enum Resource_Default_Types_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** columns and relationships of "resource_types" */
export type Resource_Types = {
  __typename?: 'resource_types';
  _deleted: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  default_type?: Maybe<Resource_Default_Types_Enum>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  name_normalized?: Maybe<Scalars['String']>;
  producer_id: Scalars['uuid'];
  /** An array relationship */
  resources: Array<Resources>;
  /** An aggregate relationship */
  resources_aggregate: Resources_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "resource_types" */
export type Resource_TypesResourcesArgs = {
  distinct_on?: Maybe<Array<Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resources_Order_By>>;
  where?: Maybe<Resources_Bool_Exp>;
};


/** columns and relationships of "resource_types" */
export type Resource_TypesResources_AggregateArgs = {
  distinct_on?: Maybe<Array<Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resources_Order_By>>;
  where?: Maybe<Resources_Bool_Exp>;
};

/** aggregated selection of "resource_types" */
export type Resource_Types_Aggregate = {
  __typename?: 'resource_types_aggregate';
  aggregate?: Maybe<Resource_Types_Aggregate_Fields>;
  nodes: Array<Resource_Types>;
};

/** aggregate fields of "resource_types" */
export type Resource_Types_Aggregate_Fields = {
  __typename?: 'resource_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Resource_Types_Max_Fields>;
  min?: Maybe<Resource_Types_Min_Fields>;
};


/** aggregate fields of "resource_types" */
export type Resource_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Resource_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "resource_types" */
export type Resource_Types_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Resource_Types_Max_Order_By>;
  min?: Maybe<Resource_Types_Min_Order_By>;
};

/** input type for inserting array relation for remote table "resource_types" */
export type Resource_Types_Arr_Rel_Insert_Input = {
  data: Array<Resource_Types_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Resource_Types_On_Conflict>;
};

/** Boolean expression to filter rows from the table "resource_types". All fields are combined with a logical 'AND'. */
export type Resource_Types_Bool_Exp = {
  _and?: Maybe<Array<Resource_Types_Bool_Exp>>;
  _not?: Maybe<Resource_Types_Bool_Exp>;
  _or?: Maybe<Array<Resource_Types_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  default_type?: Maybe<Resource_Default_Types_Enum_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  name_normalized?: Maybe<String_Comparison_Exp>;
  producer_id?: Maybe<Uuid_Comparison_Exp>;
  resources?: Maybe<Resources_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "resource_types" */
export enum Resource_Types_Constraint {
  /** unique or primary key constraint */
  ResourceTypesDefaultTypeProducerIdKey = 'resource_types_default_type_producer_id_key',
  /** unique or primary key constraint */
  ResourceTypesPkey = 'resource_types_pkey',
  /** unique or primary key constraint */
  ResourceTypesProducerIdNameNormalizedKey = 'resource_types_producer_id_name_normalized_key'
}

/** input type for inserting data into table "resource_types" */
export type Resource_Types_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  default_type?: Maybe<Resource_Default_Types_Enum>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_normalized?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['uuid']>;
  resources?: Maybe<Resources_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Resource_Types_Max_Fields = {
  __typename?: 'resource_types_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_normalized?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "resource_types" */
export type Resource_Types_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  name_normalized?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Resource_Types_Min_Fields = {
  __typename?: 'resource_types_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_normalized?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "resource_types" */
export type Resource_Types_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  name_normalized?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "resource_types" */
export type Resource_Types_Mutation_Response = {
  __typename?: 'resource_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Resource_Types>;
};

/** input type for inserting object relation for remote table "resource_types" */
export type Resource_Types_Obj_Rel_Insert_Input = {
  data: Resource_Types_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Resource_Types_On_Conflict>;
};

/** on conflict condition type for table "resource_types" */
export type Resource_Types_On_Conflict = {
  constraint: Resource_Types_Constraint;
  update_columns?: Array<Resource_Types_Update_Column>;
  where?: Maybe<Resource_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "resource_types". */
export type Resource_Types_Order_By = {
  created_at?: Maybe<Order_By>;
  default_type?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  name_normalized?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
  resources_aggregate?: Maybe<Resources_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: resource_types */
export type Resource_Types_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "resource_types" */
export enum Resource_Types_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultType = 'default_type',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NameNormalized = 'name_normalized',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "resource_types" */
export type Resource_Types_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  default_type?: Maybe<Resource_Default_Types_Enum>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_normalized?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "resource_types" */
export enum Resource_Types_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultType = 'default_type',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NameNormalized = 'name_normalized',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "resource_usage_in_tasks" */
export type Resource_Usage_In_Tasks = {
  __typename?: 'resource_usage_in_tasks';
  action_id: Scalars['uuid'];
  /** An object relationship */
  actions_resource?: Maybe<Actions_Resources>;
  id: Scalars['uuid'];
  previous_usage_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  resource: Resources;
  resource_id: Scalars['uuid'];
  /** An array relationship */
  resource_next_usages: Array<Resource_Usage_In_Tasks>;
  /** An aggregate relationship */
  resource_next_usages_aggregate: Resource_Usage_In_Tasks_Aggregate;
  /** An object relationship */
  resource_previous_usage?: Maybe<Resource_Usage_In_Tasks>;
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid'];
  unit?: Maybe<Scalars['String']>;
  usage: Usage_Types_Enum;
  value: Scalars['float8'];
};


/** columns and relationships of "resource_usage_in_tasks" */
export type Resource_Usage_In_TasksResource_Next_UsagesArgs = {
  distinct_on?: Maybe<Array<Resource_Usage_In_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Usage_In_Tasks_Order_By>>;
  where?: Maybe<Resource_Usage_In_Tasks_Bool_Exp>;
};


/** columns and relationships of "resource_usage_in_tasks" */
export type Resource_Usage_In_TasksResource_Next_Usages_AggregateArgs = {
  distinct_on?: Maybe<Array<Resource_Usage_In_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Usage_In_Tasks_Order_By>>;
  where?: Maybe<Resource_Usage_In_Tasks_Bool_Exp>;
};

/** aggregated selection of "resource_usage_in_tasks" */
export type Resource_Usage_In_Tasks_Aggregate = {
  __typename?: 'resource_usage_in_tasks_aggregate';
  aggregate?: Maybe<Resource_Usage_In_Tasks_Aggregate_Fields>;
  nodes: Array<Resource_Usage_In_Tasks>;
};

/** aggregate fields of "resource_usage_in_tasks" */
export type Resource_Usage_In_Tasks_Aggregate_Fields = {
  __typename?: 'resource_usage_in_tasks_aggregate_fields';
  avg?: Maybe<Resource_Usage_In_Tasks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Resource_Usage_In_Tasks_Max_Fields>;
  min?: Maybe<Resource_Usage_In_Tasks_Min_Fields>;
  stddev?: Maybe<Resource_Usage_In_Tasks_Stddev_Fields>;
  stddev_pop?: Maybe<Resource_Usage_In_Tasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Resource_Usage_In_Tasks_Stddev_Samp_Fields>;
  sum?: Maybe<Resource_Usage_In_Tasks_Sum_Fields>;
  var_pop?: Maybe<Resource_Usage_In_Tasks_Var_Pop_Fields>;
  var_samp?: Maybe<Resource_Usage_In_Tasks_Var_Samp_Fields>;
  variance?: Maybe<Resource_Usage_In_Tasks_Variance_Fields>;
};


/** aggregate fields of "resource_usage_in_tasks" */
export type Resource_Usage_In_Tasks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Resource_Usage_In_Tasks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "resource_usage_in_tasks" */
export type Resource_Usage_In_Tasks_Aggregate_Order_By = {
  avg?: Maybe<Resource_Usage_In_Tasks_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Resource_Usage_In_Tasks_Max_Order_By>;
  min?: Maybe<Resource_Usage_In_Tasks_Min_Order_By>;
  stddev?: Maybe<Resource_Usage_In_Tasks_Stddev_Order_By>;
  stddev_pop?: Maybe<Resource_Usage_In_Tasks_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Resource_Usage_In_Tasks_Stddev_Samp_Order_By>;
  sum?: Maybe<Resource_Usage_In_Tasks_Sum_Order_By>;
  var_pop?: Maybe<Resource_Usage_In_Tasks_Var_Pop_Order_By>;
  var_samp?: Maybe<Resource_Usage_In_Tasks_Var_Samp_Order_By>;
  variance?: Maybe<Resource_Usage_In_Tasks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "resource_usage_in_tasks" */
export type Resource_Usage_In_Tasks_Arr_Rel_Insert_Input = {
  data: Array<Resource_Usage_In_Tasks_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Resource_Usage_In_Tasks_On_Conflict>;
};

/** aggregate avg on columns */
export type Resource_Usage_In_Tasks_Avg_Fields = {
  __typename?: 'resource_usage_in_tasks_avg_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "resource_usage_in_tasks" */
export type Resource_Usage_In_Tasks_Avg_Order_By = {
  value?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "resource_usage_in_tasks". All fields are combined with a logical 'AND'. */
export type Resource_Usage_In_Tasks_Bool_Exp = {
  _and?: Maybe<Array<Resource_Usage_In_Tasks_Bool_Exp>>;
  _not?: Maybe<Resource_Usage_In_Tasks_Bool_Exp>;
  _or?: Maybe<Array<Resource_Usage_In_Tasks_Bool_Exp>>;
  action_id?: Maybe<Uuid_Comparison_Exp>;
  actions_resource?: Maybe<Actions_Resources_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  previous_usage_id?: Maybe<Uuid_Comparison_Exp>;
  resource?: Maybe<Resources_Bool_Exp>;
  resource_id?: Maybe<Uuid_Comparison_Exp>;
  resource_next_usages?: Maybe<Resource_Usage_In_Tasks_Bool_Exp>;
  resource_previous_usage?: Maybe<Resource_Usage_In_Tasks_Bool_Exp>;
  task?: Maybe<Tasks_Bool_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
  unit?: Maybe<String_Comparison_Exp>;
  usage?: Maybe<Usage_Types_Enum_Comparison_Exp>;
  value?: Maybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "resource_usage_in_tasks" */
export enum Resource_Usage_In_Tasks_Constraint {
  /** unique or primary key constraint */
  ResourceUsageInTasksPkey = 'resource_usage_in_tasks_pkey',
  /** unique or primary key constraint */
  ResourceUsageInTasksTaskIdResourceIdUsageKey = 'resource_usage_in_tasks_task_id_resource_id_usage_key'
}

/** columns and relationships of "resource_usage_in_tasks_flatten" */
export type Resource_Usage_In_Tasks_Flatten = {
  __typename?: 'resource_usage_in_tasks_flatten';
  array_to_string?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_path?: Maybe<Scalars['_text']>;
  order?: Maybe<Scalars['Int']>;
  related_to?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  resource?: Maybe<Resources>;
  resource_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  resource_usage?: Maybe<Resource_Usage_In_Tasks>;
  /** An object relationship */
  task?: Maybe<Tasks>;
  task_id?: Maybe<Scalars['uuid']>;
  usage?: Maybe<Scalars['String']>;
  usage_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "resource_usage_in_tasks_flatten" */
export type Resource_Usage_In_Tasks_Flatten_Aggregate = {
  __typename?: 'resource_usage_in_tasks_flatten_aggregate';
  aggregate?: Maybe<Resource_Usage_In_Tasks_Flatten_Aggregate_Fields>;
  nodes: Array<Resource_Usage_In_Tasks_Flatten>;
};

/** aggregate fields of "resource_usage_in_tasks_flatten" */
export type Resource_Usage_In_Tasks_Flatten_Aggregate_Fields = {
  __typename?: 'resource_usage_in_tasks_flatten_aggregate_fields';
  avg?: Maybe<Resource_Usage_In_Tasks_Flatten_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Resource_Usage_In_Tasks_Flatten_Max_Fields>;
  min?: Maybe<Resource_Usage_In_Tasks_Flatten_Min_Fields>;
  stddev?: Maybe<Resource_Usage_In_Tasks_Flatten_Stddev_Fields>;
  stddev_pop?: Maybe<Resource_Usage_In_Tasks_Flatten_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Resource_Usage_In_Tasks_Flatten_Stddev_Samp_Fields>;
  sum?: Maybe<Resource_Usage_In_Tasks_Flatten_Sum_Fields>;
  var_pop?: Maybe<Resource_Usage_In_Tasks_Flatten_Var_Pop_Fields>;
  var_samp?: Maybe<Resource_Usage_In_Tasks_Flatten_Var_Samp_Fields>;
  variance?: Maybe<Resource_Usage_In_Tasks_Flatten_Variance_Fields>;
};


/** aggregate fields of "resource_usage_in_tasks_flatten" */
export type Resource_Usage_In_Tasks_Flatten_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Resource_Usage_In_Tasks_Flatten_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Resource_Usage_In_Tasks_Flatten_Avg_Fields = {
  __typename?: 'resource_usage_in_tasks_flatten_avg_fields';
  level?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/**
 * Boolean expression to filter rows from the table
 * "resource_usage_in_tasks_flatten". All fields are combined with a logical 'AND'.
 */
export type Resource_Usage_In_Tasks_Flatten_Bool_Exp = {
  _and?: Maybe<Array<Resource_Usage_In_Tasks_Flatten_Bool_Exp>>;
  _not?: Maybe<Resource_Usage_In_Tasks_Flatten_Bool_Exp>;
  _or?: Maybe<Array<Resource_Usage_In_Tasks_Flatten_Bool_Exp>>;
  array_to_string?: Maybe<String_Comparison_Exp>;
  level?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  name_path?: Maybe<_Text_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  related_to?: Maybe<Uuid_Comparison_Exp>;
  resource?: Maybe<Resources_Bool_Exp>;
  resource_id?: Maybe<Uuid_Comparison_Exp>;
  resource_usage?: Maybe<Resource_Usage_In_Tasks_Bool_Exp>;
  task?: Maybe<Tasks_Bool_Exp>;
  task_id?: Maybe<Uuid_Comparison_Exp>;
  usage?: Maybe<String_Comparison_Exp>;
  usage_id?: Maybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Resource_Usage_In_Tasks_Flatten_Max_Fields = {
  __typename?: 'resource_usage_in_tasks_flatten_max_fields';
  array_to_string?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  related_to?: Maybe<Scalars['uuid']>;
  resource_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  usage?: Maybe<Scalars['String']>;
  usage_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Resource_Usage_In_Tasks_Flatten_Min_Fields = {
  __typename?: 'resource_usage_in_tasks_flatten_min_fields';
  array_to_string?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  related_to?: Maybe<Scalars['uuid']>;
  resource_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  usage?: Maybe<Scalars['String']>;
  usage_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "resource_usage_in_tasks_flatten". */
export type Resource_Usage_In_Tasks_Flatten_Order_By = {
  array_to_string?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  name_path?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  related_to?: Maybe<Order_By>;
  resource?: Maybe<Resources_Order_By>;
  resource_id?: Maybe<Order_By>;
  resource_usage?: Maybe<Resource_Usage_In_Tasks_Order_By>;
  task?: Maybe<Tasks_Order_By>;
  task_id?: Maybe<Order_By>;
  usage?: Maybe<Order_By>;
  usage_id?: Maybe<Order_By>;
};

/** select columns of table "resource_usage_in_tasks_flatten" */
export enum Resource_Usage_In_Tasks_Flatten_Select_Column {
  /** column name */
  ArrayToString = 'array_to_string',
  /** column name */
  Level = 'level',
  /** column name */
  Name = 'name',
  /** column name */
  NamePath = 'name_path',
  /** column name */
  Order = 'order',
  /** column name */
  RelatedTo = 'related_to',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Usage = 'usage',
  /** column name */
  UsageId = 'usage_id'
}

/** aggregate stddev on columns */
export type Resource_Usage_In_Tasks_Flatten_Stddev_Fields = {
  __typename?: 'resource_usage_in_tasks_flatten_stddev_fields';
  level?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Resource_Usage_In_Tasks_Flatten_Stddev_Pop_Fields = {
  __typename?: 'resource_usage_in_tasks_flatten_stddev_pop_fields';
  level?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Resource_Usage_In_Tasks_Flatten_Stddev_Samp_Fields = {
  __typename?: 'resource_usage_in_tasks_flatten_stddev_samp_fields';
  level?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Resource_Usage_In_Tasks_Flatten_Sum_Fields = {
  __typename?: 'resource_usage_in_tasks_flatten_sum_fields';
  level?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Resource_Usage_In_Tasks_Flatten_Var_Pop_Fields = {
  __typename?: 'resource_usage_in_tasks_flatten_var_pop_fields';
  level?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Resource_Usage_In_Tasks_Flatten_Var_Samp_Fields = {
  __typename?: 'resource_usage_in_tasks_flatten_var_samp_fields';
  level?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Resource_Usage_In_Tasks_Flatten_Variance_Fields = {
  __typename?: 'resource_usage_in_tasks_flatten_variance_fields';
  level?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** input type for incrementing numeric columns in table "resource_usage_in_tasks" */
export type Resource_Usage_In_Tasks_Inc_Input = {
  value?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "resource_usage_in_tasks" */
export type Resource_Usage_In_Tasks_Insert_Input = {
  action_id?: Maybe<Scalars['uuid']>;
  actions_resource?: Maybe<Actions_Resources_Obj_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  previous_usage_id?: Maybe<Scalars['uuid']>;
  resource?: Maybe<Resources_Obj_Rel_Insert_Input>;
  resource_id?: Maybe<Scalars['uuid']>;
  resource_next_usages?: Maybe<Resource_Usage_In_Tasks_Arr_Rel_Insert_Input>;
  resource_previous_usage?: Maybe<Resource_Usage_In_Tasks_Obj_Rel_Insert_Input>;
  task?: Maybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: Maybe<Scalars['uuid']>;
  unit?: Maybe<Scalars['String']>;
  usage?: Maybe<Usage_Types_Enum>;
  value?: Maybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Resource_Usage_In_Tasks_Max_Fields = {
  __typename?: 'resource_usage_in_tasks_max_fields';
  action_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  previous_usage_id?: Maybe<Scalars['uuid']>;
  resource_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['float8']>;
};

/** order by max() on columns of table "resource_usage_in_tasks" */
export type Resource_Usage_In_Tasks_Max_Order_By = {
  action_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  previous_usage_id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  unit?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Resource_Usage_In_Tasks_Min_Fields = {
  __typename?: 'resource_usage_in_tasks_min_fields';
  action_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  previous_usage_id?: Maybe<Scalars['uuid']>;
  resource_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  unit?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['float8']>;
};

/** order by min() on columns of table "resource_usage_in_tasks" */
export type Resource_Usage_In_Tasks_Min_Order_By = {
  action_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  previous_usage_id?: Maybe<Order_By>;
  resource_id?: Maybe<Order_By>;
  task_id?: Maybe<Order_By>;
  unit?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** response of any mutation on the table "resource_usage_in_tasks" */
export type Resource_Usage_In_Tasks_Mutation_Response = {
  __typename?: 'resource_usage_in_tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Resource_Usage_In_Tasks>;
};

/** input type for inserting object relation for remote table "resource_usage_in_tasks" */
export type Resource_Usage_In_Tasks_Obj_Rel_Insert_Input = {
  data: Resource_Usage_In_Tasks_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Resource_Usage_In_Tasks_On_Conflict>;
};

/** on conflict condition type for table "resource_usage_in_tasks" */
export type Resource_Usage_In_Tasks_On_Conflict = {
  constraint: Resource_Usage_In_Tasks_Constraint;
  update_columns?: Array<Resource_Usage_In_Tasks_Update_Column>;
  where?: Maybe<Resource_Usage_In_Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "resource_usage_in_tasks". */
export type Resource_Usage_In_Tasks_Order_By = {
  action_id?: Maybe<Order_By>;
  actions_resource?: Maybe<Actions_Resources_Order_By>;
  id?: Maybe<Order_By>;
  previous_usage_id?: Maybe<Order_By>;
  resource?: Maybe<Resources_Order_By>;
  resource_id?: Maybe<Order_By>;
  resource_next_usages_aggregate?: Maybe<Resource_Usage_In_Tasks_Aggregate_Order_By>;
  resource_previous_usage?: Maybe<Resource_Usage_In_Tasks_Order_By>;
  task?: Maybe<Tasks_Order_By>;
  task_id?: Maybe<Order_By>;
  unit?: Maybe<Order_By>;
  usage?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: resource_usage_in_tasks */
export type Resource_Usage_In_Tasks_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "resource_usage_in_tasks" */
export enum Resource_Usage_In_Tasks_Select_Column {
  /** column name */
  ActionId = 'action_id',
  /** column name */
  Id = 'id',
  /** column name */
  PreviousUsageId = 'previous_usage_id',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Unit = 'unit',
  /** column name */
  Usage = 'usage',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "resource_usage_in_tasks" */
export type Resource_Usage_In_Tasks_Set_Input = {
  action_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  previous_usage_id?: Maybe<Scalars['uuid']>;
  resource_id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  unit?: Maybe<Scalars['String']>;
  usage?: Maybe<Usage_Types_Enum>;
  value?: Maybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Resource_Usage_In_Tasks_Stddev_Fields = {
  __typename?: 'resource_usage_in_tasks_stddev_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "resource_usage_in_tasks" */
export type Resource_Usage_In_Tasks_Stddev_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Resource_Usage_In_Tasks_Stddev_Pop_Fields = {
  __typename?: 'resource_usage_in_tasks_stddev_pop_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "resource_usage_in_tasks" */
export type Resource_Usage_In_Tasks_Stddev_Pop_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Resource_Usage_In_Tasks_Stddev_Samp_Fields = {
  __typename?: 'resource_usage_in_tasks_stddev_samp_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "resource_usage_in_tasks" */
export type Resource_Usage_In_Tasks_Stddev_Samp_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Resource_Usage_In_Tasks_Sum_Fields = {
  __typename?: 'resource_usage_in_tasks_sum_fields';
  value?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "resource_usage_in_tasks" */
export type Resource_Usage_In_Tasks_Sum_Order_By = {
  value?: Maybe<Order_By>;
};

/** update columns of table "resource_usage_in_tasks" */
export enum Resource_Usage_In_Tasks_Update_Column {
  /** column name */
  ActionId = 'action_id',
  /** column name */
  Id = 'id',
  /** column name */
  PreviousUsageId = 'previous_usage_id',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Unit = 'unit',
  /** column name */
  Usage = 'usage',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type Resource_Usage_In_Tasks_Var_Pop_Fields = {
  __typename?: 'resource_usage_in_tasks_var_pop_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "resource_usage_in_tasks" */
export type Resource_Usage_In_Tasks_Var_Pop_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Resource_Usage_In_Tasks_Var_Samp_Fields = {
  __typename?: 'resource_usage_in_tasks_var_samp_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "resource_usage_in_tasks" */
export type Resource_Usage_In_Tasks_Var_Samp_Order_By = {
  value?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Resource_Usage_In_Tasks_Variance_Fields = {
  __typename?: 'resource_usage_in_tasks_variance_fields';
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "resource_usage_in_tasks" */
export type Resource_Usage_In_Tasks_Variance_Order_By = {
  value?: Maybe<Order_By>;
};

/** columns and relationships of "resources" */
export type Resources = {
  __typename?: 'resources';
  _deleted: Scalars['Boolean'];
  /** An array relationship */
  children: Array<Resources>;
  /** An aggregate relationship */
  children_aggregate: Resources_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  normalized_name?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  parent_resource?: Maybe<Resources>;
  producer_id?: Maybe<Scalars['uuid']>;
  public: Scalars['Boolean'];
  /** An array relationship */
  resource_actions: Array<Actions_Resources>;
  /** An aggregate relationship */
  resource_actions_aggregate: Actions_Resources_Aggregate;
  /** An array relationship */
  resource_batches: Array<Resource_Batches>;
  /** An aggregate relationship */
  resource_batches_aggregate: Resource_Batches_Aggregate;
  /** An array relationship */
  resource_products: Array<Product_Resources>;
  /** An aggregate relationship */
  resource_products_aggregate: Product_Resources_Aggregate;
  /** An object relationship */
  type?: Maybe<Resource_Types>;
  type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  usage_in_tasks: Array<Resource_Usage_In_Tasks>;
  /** An aggregate relationship */
  usage_in_tasks_aggregate: Resource_Usage_In_Tasks_Aggregate;
};


/** columns and relationships of "resources" */
export type ResourcesChildrenArgs = {
  distinct_on?: Maybe<Array<Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resources_Order_By>>;
  where?: Maybe<Resources_Bool_Exp>;
};


/** columns and relationships of "resources" */
export type ResourcesChildren_AggregateArgs = {
  distinct_on?: Maybe<Array<Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resources_Order_By>>;
  where?: Maybe<Resources_Bool_Exp>;
};


/** columns and relationships of "resources" */
export type ResourcesResource_ActionsArgs = {
  distinct_on?: Maybe<Array<Actions_Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Actions_Resources_Order_By>>;
  where?: Maybe<Actions_Resources_Bool_Exp>;
};


/** columns and relationships of "resources" */
export type ResourcesResource_Actions_AggregateArgs = {
  distinct_on?: Maybe<Array<Actions_Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Actions_Resources_Order_By>>;
  where?: Maybe<Actions_Resources_Bool_Exp>;
};


/** columns and relationships of "resources" */
export type ResourcesResource_BatchesArgs = {
  distinct_on?: Maybe<Array<Resource_Batches_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Batches_Order_By>>;
  where?: Maybe<Resource_Batches_Bool_Exp>;
};


/** columns and relationships of "resources" */
export type ResourcesResource_Batches_AggregateArgs = {
  distinct_on?: Maybe<Array<Resource_Batches_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Batches_Order_By>>;
  where?: Maybe<Resource_Batches_Bool_Exp>;
};


/** columns and relationships of "resources" */
export type ResourcesResource_ProductsArgs = {
  distinct_on?: Maybe<Array<Product_Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Resources_Order_By>>;
  where?: Maybe<Product_Resources_Bool_Exp>;
};


/** columns and relationships of "resources" */
export type ResourcesResource_Products_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Resources_Order_By>>;
  where?: Maybe<Product_Resources_Bool_Exp>;
};


/** columns and relationships of "resources" */
export type ResourcesUsage_In_TasksArgs = {
  distinct_on?: Maybe<Array<Resource_Usage_In_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Usage_In_Tasks_Order_By>>;
  where?: Maybe<Resource_Usage_In_Tasks_Bool_Exp>;
};


/** columns and relationships of "resources" */
export type ResourcesUsage_In_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Resource_Usage_In_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Usage_In_Tasks_Order_By>>;
  where?: Maybe<Resource_Usage_In_Tasks_Bool_Exp>;
};

/** aggregated selection of "resources" */
export type Resources_Aggregate = {
  __typename?: 'resources_aggregate';
  aggregate?: Maybe<Resources_Aggregate_Fields>;
  nodes: Array<Resources>;
};

/** aggregate fields of "resources" */
export type Resources_Aggregate_Fields = {
  __typename?: 'resources_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Resources_Max_Fields>;
  min?: Maybe<Resources_Min_Fields>;
};


/** aggregate fields of "resources" */
export type Resources_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Resources_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "resources" */
export type Resources_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Resources_Max_Order_By>;
  min?: Maybe<Resources_Min_Order_By>;
};

/** input type for inserting array relation for remote table "resources" */
export type Resources_Arr_Rel_Insert_Input = {
  data: Array<Resources_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Resources_On_Conflict>;
};

/** Boolean expression to filter rows from the table "resources". All fields are combined with a logical 'AND'. */
export type Resources_Bool_Exp = {
  _and?: Maybe<Array<Resources_Bool_Exp>>;
  _not?: Maybe<Resources_Bool_Exp>;
  _or?: Maybe<Array<Resources_Bool_Exp>>;
  children?: Maybe<Resources_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  normalized_name?: Maybe<String_Comparison_Exp>;
  parent?: Maybe<Uuid_Comparison_Exp>;
  parent_resource?: Maybe<Resources_Bool_Exp>;
  producer_id?: Maybe<Uuid_Comparison_Exp>;
  public?: Maybe<Boolean_Comparison_Exp>;
  resource_actions?: Maybe<Actions_Resources_Bool_Exp>;
  resource_batches?: Maybe<Resource_Batches_Bool_Exp>;
  resource_products?: Maybe<Product_Resources_Bool_Exp>;
  type?: Maybe<Resource_Types_Bool_Exp>;
  type_id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  usage_in_tasks?: Maybe<Resource_Usage_In_Tasks_Bool_Exp>;
};

/** unique or primary key constraints on table "resources" */
export enum Resources_Constraint {
  /** unique or primary key constraint */
  ResourcesNormalizedNameProducerIdKey = 'resources_normalized_name_producer_id_key',
  /** unique or primary key constraint */
  ResourcesPkey = 'resources_pkey'
}

/** input type for inserting data into table "resources" */
export type Resources_Insert_Input = {
  children?: Maybe<Resources_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['uuid']>;
  parent_resource?: Maybe<Resources_Obj_Rel_Insert_Input>;
  producer_id?: Maybe<Scalars['uuid']>;
  public?: Maybe<Scalars['Boolean']>;
  resource_actions?: Maybe<Actions_Resources_Arr_Rel_Insert_Input>;
  resource_batches?: Maybe<Resource_Batches_Arr_Rel_Insert_Input>;
  resource_products?: Maybe<Product_Resources_Arr_Rel_Insert_Input>;
  type?: Maybe<Resource_Types_Obj_Rel_Insert_Input>;
  type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  usage_in_tasks?: Maybe<Resource_Usage_In_Tasks_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Resources_Max_Fields = {
  __typename?: 'resources_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['uuid']>;
  producer_id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "resources" */
export type Resources_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  normalized_name?: Maybe<Order_By>;
  parent?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
  type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Resources_Min_Fields = {
  __typename?: 'resources_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['uuid']>;
  producer_id?: Maybe<Scalars['uuid']>;
  type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "resources" */
export type Resources_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  normalized_name?: Maybe<Order_By>;
  parent?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
  type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "resources" */
export type Resources_Mutation_Response = {
  __typename?: 'resources_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Resources>;
};

/** input type for inserting object relation for remote table "resources" */
export type Resources_Obj_Rel_Insert_Input = {
  data: Resources_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Resources_On_Conflict>;
};

/** on conflict condition type for table "resources" */
export type Resources_On_Conflict = {
  constraint: Resources_Constraint;
  update_columns?: Array<Resources_Update_Column>;
  where?: Maybe<Resources_Bool_Exp>;
};

/** Ordering options when selecting data from "resources". */
export type Resources_Order_By = {
  children_aggregate?: Maybe<Resources_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  normalized_name?: Maybe<Order_By>;
  parent?: Maybe<Order_By>;
  parent_resource?: Maybe<Resources_Order_By>;
  producer_id?: Maybe<Order_By>;
  public?: Maybe<Order_By>;
  resource_actions_aggregate?: Maybe<Actions_Resources_Aggregate_Order_By>;
  resource_batches_aggregate?: Maybe<Resource_Batches_Aggregate_Order_By>;
  resource_products_aggregate?: Maybe<Product_Resources_Aggregate_Order_By>;
  type?: Maybe<Resource_Types_Order_By>;
  type_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  usage_in_tasks_aggregate?: Maybe<Resource_Usage_In_Tasks_Aggregate_Order_By>;
};

/** primary key columns input for table: resources */
export type Resources_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "resources" */
export enum Resources_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NormalizedName = 'normalized_name',
  /** column name */
  Parent = 'parent',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  Public = 'public',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "resources" */
export type Resources_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  parent?: Maybe<Scalars['uuid']>;
  producer_id?: Maybe<Scalars['uuid']>;
  public?: Maybe<Scalars['Boolean']>;
  type_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "resources" */
export enum Resources_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NormalizedName = 'normalized_name',
  /** column name */
  Parent = 'parent',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  Public = 'public',
  /** column name */
  TypeId = 'type_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Search_Products_Args = {
  search?: Maybe<Scalars['String']>;
};

export type StepContent_MatrixBlockContainerInput = {
  contentBlock?: Maybe<StepContent_ContentBlock_MatrixBlockInput>;
};

export type StepContent_MatrixField = StepContent_ContentBlock_BlockType;

export type StepContent_MatrixInput = {
  blocks?: Maybe<Array<Maybe<StepContent_MatrixBlockContainerInput>>>;
  sortOrder?: Maybe<Array<Maybe<Scalars['QueryArgument']>>>;
};

export type StepContent_ContentBlock_BlockType = ElementInterface & MatrixBlockInterface & {
  __typename?: 'stepContent_contentBlock_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the matrix block. */
  fieldId?: Maybe<Scalars['Int']>;
  headline?: Maybe<Scalars['String']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The ID of the element that owns the matrix block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The sort order of the matrix block within the owner element field. */
  sortOrder?: Maybe<Scalars['Int']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  text?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the matrix block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the matrix block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type StepContent_ContentBlock_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type StepContent_ContentBlock_MatrixBlockInput = {
  headline?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  text?: Maybe<Scalars['String']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "actions" */
  actions: Array<Actions>;
  /** An aggregate relationship */
  actions_aggregate: Actions_Aggregate;
  /** fetch data from the table: "actions" using primary key columns */
  actions_by_pk?: Maybe<Actions>;
  /** An array relationship */
  actions_category_items: Array<Actions_Category_Items>;
  /** An aggregate relationship */
  actions_category_items_aggregate: Actions_Category_Items_Aggregate;
  /** fetch data from the table: "actions_category_items" using primary key columns */
  actions_category_items_by_pk?: Maybe<Actions_Category_Items>;
  /** fetch data from the table: "actions_resources" */
  actions_resources: Array<Actions_Resources>;
  /** fetch aggregated fields from the table: "actions_resources" */
  actions_resources_aggregate: Actions_Resources_Aggregate;
  /** fetch data from the table: "actions_resources" using primary key columns */
  actions_resources_by_pk?: Maybe<Actions_Resources>;
  /** fetch data from the table: "category_default_types" */
  category_default_types: Array<Category_Default_Types>;
  /** fetch aggregated fields from the table: "category_default_types" */
  category_default_types_aggregate: Category_Default_Types_Aggregate;
  /** fetch data from the table: "category_default_types" using primary key columns */
  category_default_types_by_pk?: Maybe<Category_Default_Types>;
  /** fetch data from the table: "category_items" */
  category_items: Array<Category_Items>;
  /** fetch aggregated fields from the table: "category_items" */
  category_items_aggregate: Category_Items_Aggregate;
  /** fetch data from the table: "category_items" using primary key columns */
  category_items_by_pk?: Maybe<Category_Items>;
  /** fetch data from the table: "category_locations" */
  category_locations: Array<Category_Locations>;
  /** fetch aggregated fields from the table: "category_locations" */
  category_locations_aggregate: Category_Locations_Aggregate;
  /** fetch data from the table: "category_locations" using primary key columns */
  category_locations_by_pk?: Maybe<Category_Locations>;
  /** fetch data from the table: "category_machines" */
  category_machines: Array<Category_Machines>;
  /** fetch aggregated fields from the table: "category_machines" */
  category_machines_aggregate: Category_Machines_Aggregate;
  /** fetch data from the table: "category_machines" using primary key columns */
  category_machines_by_pk?: Maybe<Category_Machines>;
  /** fetch data from the table: "category_types" */
  category_types: Array<Category_Types>;
  /** fetch aggregated fields from the table: "category_types" */
  category_types_aggregate: Category_Types_Aggregate;
  /** fetch data from the table: "category_types" using primary key columns */
  category_types_by_pk?: Maybe<Category_Types>;
  /** An array relationship */
  category_usage_in_tasks: Array<Category_Usage_In_Tasks>;
  /** An aggregate relationship */
  category_usage_in_tasks_aggregate: Category_Usage_In_Tasks_Aggregate;
  /** fetch data from the table: "category_usage_in_tasks" using primary key columns */
  category_usage_in_tasks_by_pk?: Maybe<Category_Usage_In_Tasks>;
  /** fetch data from the table: "datafield_types" */
  datafield_types: Array<Datafield_Types>;
  /** fetch aggregated fields from the table: "datafield_types" */
  datafield_types_aggregate: Datafield_Types_Aggregate;
  /** fetch data from the table: "datafield_types" using primary key columns */
  datafield_types_by_pk?: Maybe<Datafield_Types>;
  /** An array relationship */
  datafields: Array<Datafields>;
  /** An aggregate relationship */
  datafields_aggregate: Datafields_Aggregate;
  /** fetch data from the table: "datafields" using primary key columns */
  datafields_by_pk?: Maybe<Datafields>;
  /** fetch data from the table: "files" */
  files: Array<Files>;
  /** fetch aggregated fields from the table: "files" */
  files_aggregate: Files_Aggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  /** An array relationship */
  logs: Array<Logs>;
  /** An aggregate relationship */
  logs_aggregate: Logs_Aggregate;
  /** fetch data from the table: "logs" using primary key columns */
  logs_by_pk?: Maybe<Logs>;
  /** fetch data from the table: "messages" */
  messages: Array<Messages>;
  /** fetch aggregated fields from the table: "messages" */
  messages_aggregate: Messages_Aggregate;
  /** fetch data from the table: "messages" using primary key columns */
  messages_by_pk?: Maybe<Messages>;
  /** fetch data from the table: "messages_online" */
  messages_online: Array<Messages_Online>;
  /** fetch aggregated fields from the table: "messages_online" */
  messages_online_aggregate: Messages_Online_Aggregate;
  /** An array relationship */
  product_resources: Array<Product_Resources>;
  /** An aggregate relationship */
  product_resources_aggregate: Product_Resources_Aggregate;
  /** fetch data from the table: "product_resources" using primary key columns */
  product_resources_by_pk?: Maybe<Product_Resources>;
  /** fetch data from the table: "products" */
  products: Array<Products>;
  /** fetch aggregated fields from the table: "products" */
  products_aggregate: Products_Aggregate;
  /** fetch data from the table: "products" using primary key columns */
  products_by_pk?: Maybe<Products>;
  /** fetch data from the table: "products_preliminary" */
  products_preliminary: Array<Products_Preliminary>;
  /** fetch aggregated fields from the table: "products_preliminary" */
  products_preliminary_aggregate: Products_Preliminary_Aggregate;
  /** fetch data from the table: "products_preliminary" using primary key columns */
  products_preliminary_by_pk?: Maybe<Products_Preliminary>;
  /** fetch data from the table: "products_recoverable" */
  products_recoverable: Array<Products_Recoverable>;
  /** fetch aggregated fields from the table: "products_recoverable" */
  products_recoverable_aggregate: Products_Recoverable_Aggregate;
  /** An array relationship */
  resource_batches: Array<Resource_Batches>;
  /** An aggregate relationship */
  resource_batches_aggregate: Resource_Batches_Aggregate;
  /** fetch data from the table: "resource_batches" using primary key columns */
  resource_batches_by_pk?: Maybe<Resource_Batches>;
  /** fetch data from the table: "resource_default_types" */
  resource_default_types: Array<Resource_Default_Types>;
  /** fetch aggregated fields from the table: "resource_default_types" */
  resource_default_types_aggregate: Resource_Default_Types_Aggregate;
  /** fetch data from the table: "resource_default_types" using primary key columns */
  resource_default_types_by_pk?: Maybe<Resource_Default_Types>;
  /** fetch data from the table: "resource_types" */
  resource_types: Array<Resource_Types>;
  /** fetch aggregated fields from the table: "resource_types" */
  resource_types_aggregate: Resource_Types_Aggregate;
  /** fetch data from the table: "resource_types" using primary key columns */
  resource_types_by_pk?: Maybe<Resource_Types>;
  /** An array relationship */
  resource_usage_in_tasks: Array<Resource_Usage_In_Tasks>;
  /** An aggregate relationship */
  resource_usage_in_tasks_aggregate: Resource_Usage_In_Tasks_Aggregate;
  /** fetch data from the table: "resource_usage_in_tasks" using primary key columns */
  resource_usage_in_tasks_by_pk?: Maybe<Resource_Usage_In_Tasks>;
  /** fetch data from the table: "resource_usage_in_tasks_flatten" */
  resource_usage_in_tasks_flatten: Array<Resource_Usage_In_Tasks_Flatten>;
  /** fetch aggregated fields from the table: "resource_usage_in_tasks_flatten" */
  resource_usage_in_tasks_flatten_aggregate: Resource_Usage_In_Tasks_Flatten_Aggregate;
  /** An array relationship */
  resources: Array<Resources>;
  /** An aggregate relationship */
  resources_aggregate: Resources_Aggregate;
  /** fetch data from the table: "resources" using primary key columns */
  resources_by_pk?: Maybe<Resources>;
  /** execute function "search_products" which returns "products" */
  search_products: Array<Products>;
  /** execute function "search_products" and query aggregates on result of table type "products" */
  search_products_aggregate: Products_Aggregate;
  /** fetch data from the table: "suppliers" */
  suppliers: Array<Suppliers>;
  /** fetch aggregated fields from the table: "suppliers" */
  suppliers_aggregate: Suppliers_Aggregate;
  /** fetch data from the table: "suppliers" using primary key columns */
  suppliers_by_pk?: Maybe<Suppliers>;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  /** fetch data from the table: "tasks" using primary key columns */
  tasks_by_pk?: Maybe<Tasks>;
  /** An array relationship */
  tasks_flatten: Array<Tasks_Flatten>;
  /** An aggregate relationship */
  tasks_flatten_aggregate: Tasks_Flatten_Aggregate;
  /** fetch data from the table: "usage_types" */
  usage_types: Array<Usage_Types>;
  /** fetch aggregated fields from the table: "usage_types" */
  usage_types_aggregate: Usage_Types_Aggregate;
  /** fetch data from the table: "usage_types" using primary key columns */
  usage_types_by_pk?: Maybe<Usage_Types>;
};


export type Subscription_RootActionsArgs = {
  distinct_on?: Maybe<Array<Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Actions_Order_By>>;
  where?: Maybe<Actions_Bool_Exp>;
};


export type Subscription_RootActions_AggregateArgs = {
  distinct_on?: Maybe<Array<Actions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Actions_Order_By>>;
  where?: Maybe<Actions_Bool_Exp>;
};


export type Subscription_RootActions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootActions_Category_ItemsArgs = {
  distinct_on?: Maybe<Array<Actions_Category_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Actions_Category_Items_Order_By>>;
  where?: Maybe<Actions_Category_Items_Bool_Exp>;
};


export type Subscription_RootActions_Category_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Actions_Category_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Actions_Category_Items_Order_By>>;
  where?: Maybe<Actions_Category_Items_Bool_Exp>;
};


export type Subscription_RootActions_Category_Items_By_PkArgs = {
  action_id: Scalars['uuid'];
  category_id: Scalars['uuid'];
};


export type Subscription_RootActions_ResourcesArgs = {
  distinct_on?: Maybe<Array<Actions_Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Actions_Resources_Order_By>>;
  where?: Maybe<Actions_Resources_Bool_Exp>;
};


export type Subscription_RootActions_Resources_AggregateArgs = {
  distinct_on?: Maybe<Array<Actions_Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Actions_Resources_Order_By>>;
  where?: Maybe<Actions_Resources_Bool_Exp>;
};


export type Subscription_RootActions_Resources_By_PkArgs = {
  action_id: Scalars['uuid'];
  resource_id: Scalars['uuid'];
  usage: Usage_Types_Enum;
};


export type Subscription_RootCategory_Default_TypesArgs = {
  distinct_on?: Maybe<Array<Category_Default_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Default_Types_Order_By>>;
  where?: Maybe<Category_Default_Types_Bool_Exp>;
};


export type Subscription_RootCategory_Default_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Default_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Default_Types_Order_By>>;
  where?: Maybe<Category_Default_Types_Bool_Exp>;
};


export type Subscription_RootCategory_Default_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootCategory_ItemsArgs = {
  distinct_on?: Maybe<Array<Category_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Items_Order_By>>;
  where?: Maybe<Category_Items_Bool_Exp>;
};


export type Subscription_RootCategory_Items_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Items_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Items_Order_By>>;
  where?: Maybe<Category_Items_Bool_Exp>;
};


export type Subscription_RootCategory_Items_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCategory_LocationsArgs = {
  distinct_on?: Maybe<Array<Category_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Locations_Order_By>>;
  where?: Maybe<Category_Locations_Bool_Exp>;
};


export type Subscription_RootCategory_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Locations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Locations_Order_By>>;
  where?: Maybe<Category_Locations_Bool_Exp>;
};


export type Subscription_RootCategory_Locations_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCategory_MachinesArgs = {
  distinct_on?: Maybe<Array<Category_Machines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Machines_Order_By>>;
  where?: Maybe<Category_Machines_Bool_Exp>;
};


export type Subscription_RootCategory_Machines_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Machines_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Machines_Order_By>>;
  where?: Maybe<Category_Machines_Bool_Exp>;
};


export type Subscription_RootCategory_Machines_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCategory_TypesArgs = {
  distinct_on?: Maybe<Array<Category_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Types_Order_By>>;
  where?: Maybe<Category_Types_Bool_Exp>;
};


export type Subscription_RootCategory_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Types_Order_By>>;
  where?: Maybe<Category_Types_Bool_Exp>;
};


export type Subscription_RootCategory_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootCategory_Usage_In_TasksArgs = {
  distinct_on?: Maybe<Array<Category_Usage_In_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Usage_In_Tasks_Order_By>>;
  where?: Maybe<Category_Usage_In_Tasks_Bool_Exp>;
};


export type Subscription_RootCategory_Usage_In_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Usage_In_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Usage_In_Tasks_Order_By>>;
  where?: Maybe<Category_Usage_In_Tasks_Bool_Exp>;
};


export type Subscription_RootCategory_Usage_In_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootDatafield_TypesArgs = {
  distinct_on?: Maybe<Array<Datafield_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Datafield_Types_Order_By>>;
  where?: Maybe<Datafield_Types_Bool_Exp>;
};


export type Subscription_RootDatafield_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Datafield_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Datafield_Types_Order_By>>;
  where?: Maybe<Datafield_Types_Bool_Exp>;
};


export type Subscription_RootDatafield_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootDatafieldsArgs = {
  distinct_on?: Maybe<Array<Datafields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Datafields_Order_By>>;
  where?: Maybe<Datafields_Bool_Exp>;
};


export type Subscription_RootDatafields_AggregateArgs = {
  distinct_on?: Maybe<Array<Datafields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Datafields_Order_By>>;
  where?: Maybe<Datafields_Bool_Exp>;
};


export type Subscription_RootDatafields_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFilesArgs = {
  distinct_on?: Maybe<Array<Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Order_By>>;
  where?: Maybe<Files_Bool_Exp>;
};


export type Subscription_RootFiles_AggregateArgs = {
  distinct_on?: Maybe<Array<Files_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Files_Order_By>>;
  where?: Maybe<Files_Bool_Exp>;
};


export type Subscription_RootFiles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLogsArgs = {
  distinct_on?: Maybe<Array<Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Order_By>>;
  where?: Maybe<Logs_Bool_Exp>;
};


export type Subscription_RootLogs_AggregateArgs = {
  distinct_on?: Maybe<Array<Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Order_By>>;
  where?: Maybe<Logs_Bool_Exp>;
};


export type Subscription_RootLogs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootMessagesArgs = {
  distinct_on?: Maybe<Array<Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Order_By>>;
  where?: Maybe<Messages_Bool_Exp>;
};


export type Subscription_RootMessages_AggregateArgs = {
  distinct_on?: Maybe<Array<Messages_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Order_By>>;
  where?: Maybe<Messages_Bool_Exp>;
};


export type Subscription_RootMessages_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootMessages_OnlineArgs = {
  distinct_on?: Maybe<Array<Messages_Online_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Online_Order_By>>;
  where?: Maybe<Messages_Online_Bool_Exp>;
};


export type Subscription_RootMessages_Online_AggregateArgs = {
  distinct_on?: Maybe<Array<Messages_Online_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Messages_Online_Order_By>>;
  where?: Maybe<Messages_Online_Bool_Exp>;
};


export type Subscription_RootProduct_ResourcesArgs = {
  distinct_on?: Maybe<Array<Product_Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Resources_Order_By>>;
  where?: Maybe<Product_Resources_Bool_Exp>;
};


export type Subscription_RootProduct_Resources_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Resources_Order_By>>;
  where?: Maybe<Product_Resources_Bool_Exp>;
};


export type Subscription_RootProduct_Resources_By_PkArgs = {
  product_id: Scalars['uuid'];
  resource_id: Scalars['uuid'];
};


export type Subscription_RootProductsArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Subscription_RootProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Subscription_RootProducts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProducts_PreliminaryArgs = {
  distinct_on?: Maybe<Array<Products_Preliminary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Preliminary_Order_By>>;
  where?: Maybe<Products_Preliminary_Bool_Exp>;
};


export type Subscription_RootProducts_Preliminary_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Preliminary_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Preliminary_Order_By>>;
  where?: Maybe<Products_Preliminary_Bool_Exp>;
};


export type Subscription_RootProducts_Preliminary_By_PkArgs = {
  id: Scalars['uuid'];
  preliminary_id: Scalars['uuid'];
};


export type Subscription_RootProducts_RecoverableArgs = {
  distinct_on?: Maybe<Array<Products_Recoverable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Recoverable_Order_By>>;
  where?: Maybe<Products_Recoverable_Bool_Exp>;
};


export type Subscription_RootProducts_Recoverable_AggregateArgs = {
  distinct_on?: Maybe<Array<Products_Recoverable_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Recoverable_Order_By>>;
  where?: Maybe<Products_Recoverable_Bool_Exp>;
};


export type Subscription_RootResource_BatchesArgs = {
  distinct_on?: Maybe<Array<Resource_Batches_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Batches_Order_By>>;
  where?: Maybe<Resource_Batches_Bool_Exp>;
};


export type Subscription_RootResource_Batches_AggregateArgs = {
  distinct_on?: Maybe<Array<Resource_Batches_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Batches_Order_By>>;
  where?: Maybe<Resource_Batches_Bool_Exp>;
};


export type Subscription_RootResource_Batches_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootResource_Default_TypesArgs = {
  distinct_on?: Maybe<Array<Resource_Default_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Default_Types_Order_By>>;
  where?: Maybe<Resource_Default_Types_Bool_Exp>;
};


export type Subscription_RootResource_Default_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Resource_Default_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Default_Types_Order_By>>;
  where?: Maybe<Resource_Default_Types_Bool_Exp>;
};


export type Subscription_RootResource_Default_Types_By_PkArgs = {
  value: Scalars['String'];
};


export type Subscription_RootResource_TypesArgs = {
  distinct_on?: Maybe<Array<Resource_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Types_Order_By>>;
  where?: Maybe<Resource_Types_Bool_Exp>;
};


export type Subscription_RootResource_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Resource_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Types_Order_By>>;
  where?: Maybe<Resource_Types_Bool_Exp>;
};


export type Subscription_RootResource_Types_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootResource_Usage_In_TasksArgs = {
  distinct_on?: Maybe<Array<Resource_Usage_In_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Usage_In_Tasks_Order_By>>;
  where?: Maybe<Resource_Usage_In_Tasks_Bool_Exp>;
};


export type Subscription_RootResource_Usage_In_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Resource_Usage_In_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Usage_In_Tasks_Order_By>>;
  where?: Maybe<Resource_Usage_In_Tasks_Bool_Exp>;
};


export type Subscription_RootResource_Usage_In_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootResource_Usage_In_Tasks_FlattenArgs = {
  distinct_on?: Maybe<Array<Resource_Usage_In_Tasks_Flatten_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Usage_In_Tasks_Flatten_Order_By>>;
  where?: Maybe<Resource_Usage_In_Tasks_Flatten_Bool_Exp>;
};


export type Subscription_RootResource_Usage_In_Tasks_Flatten_AggregateArgs = {
  distinct_on?: Maybe<Array<Resource_Usage_In_Tasks_Flatten_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Usage_In_Tasks_Flatten_Order_By>>;
  where?: Maybe<Resource_Usage_In_Tasks_Flatten_Bool_Exp>;
};


export type Subscription_RootResourcesArgs = {
  distinct_on?: Maybe<Array<Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resources_Order_By>>;
  where?: Maybe<Resources_Bool_Exp>;
};


export type Subscription_RootResources_AggregateArgs = {
  distinct_on?: Maybe<Array<Resources_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resources_Order_By>>;
  where?: Maybe<Resources_Bool_Exp>;
};


export type Subscription_RootResources_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootSearch_ProductsArgs = {
  args: Search_Products_Args;
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Subscription_RootSearch_Products_AggregateArgs = {
  args: Search_Products_Args;
  distinct_on?: Maybe<Array<Products_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Products_Order_By>>;
  where?: Maybe<Products_Bool_Exp>;
};


export type Subscription_RootSuppliersArgs = {
  distinct_on?: Maybe<Array<Suppliers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Suppliers_Order_By>>;
  where?: Maybe<Suppliers_Bool_Exp>;
};


export type Subscription_RootSuppliers_AggregateArgs = {
  distinct_on?: Maybe<Array<Suppliers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Suppliers_Order_By>>;
  where?: Maybe<Suppliers_Bool_Exp>;
};


export type Subscription_RootSuppliers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTasksArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


export type Subscription_RootTasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


export type Subscription_RootTasks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTasks_FlattenArgs = {
  distinct_on?: Maybe<Array<Tasks_Flatten_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Flatten_Order_By>>;
  where?: Maybe<Tasks_Flatten_Bool_Exp>;
};


export type Subscription_RootTasks_Flatten_AggregateArgs = {
  distinct_on?: Maybe<Array<Tasks_Flatten_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Flatten_Order_By>>;
  where?: Maybe<Tasks_Flatten_Bool_Exp>;
};


export type Subscription_RootUsage_TypesArgs = {
  distinct_on?: Maybe<Array<Usage_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Usage_Types_Order_By>>;
  where?: Maybe<Usage_Types_Bool_Exp>;
};


export type Subscription_RootUsage_Types_AggregateArgs = {
  distinct_on?: Maybe<Array<Usage_Types_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Usage_Types_Order_By>>;
  where?: Maybe<Usage_Types_Bool_Exp>;
};


export type Subscription_RootUsage_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** columns and relationships of "suppliers" */
export type Suppliers = {
  __typename?: 'suppliers';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  normalized_name?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "suppliers" */
export type Suppliers_Aggregate = {
  __typename?: 'suppliers_aggregate';
  aggregate?: Maybe<Suppliers_Aggregate_Fields>;
  nodes: Array<Suppliers>;
};

/** aggregate fields of "suppliers" */
export type Suppliers_Aggregate_Fields = {
  __typename?: 'suppliers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Suppliers_Max_Fields>;
  min?: Maybe<Suppliers_Min_Fields>;
};


/** aggregate fields of "suppliers" */
export type Suppliers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Suppliers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "suppliers". All fields are combined with a logical 'AND'. */
export type Suppliers_Bool_Exp = {
  _and?: Maybe<Array<Suppliers_Bool_Exp>>;
  _not?: Maybe<Suppliers_Bool_Exp>;
  _or?: Maybe<Array<Suppliers_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  normalized_name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "suppliers" */
export enum Suppliers_Constraint {
  /** unique or primary key constraint */
  SuplliersPkey = 'suplliers_pkey',
  /** unique or primary key constraint */
  SuppliersNormalizedNameKey = 'suppliers_normalized_name_key'
}

/** input type for inserting data into table "suppliers" */
export type Suppliers_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Suppliers_Max_Fields = {
  __typename?: 'suppliers_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Suppliers_Min_Fields = {
  __typename?: 'suppliers_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "suppliers" */
export type Suppliers_Mutation_Response = {
  __typename?: 'suppliers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Suppliers>;
};

/** input type for inserting object relation for remote table "suppliers" */
export type Suppliers_Obj_Rel_Insert_Input = {
  data: Suppliers_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Suppliers_On_Conflict>;
};

/** on conflict condition type for table "suppliers" */
export type Suppliers_On_Conflict = {
  constraint: Suppliers_Constraint;
  update_columns?: Array<Suppliers_Update_Column>;
  where?: Maybe<Suppliers_Bool_Exp>;
};

/** Ordering options when selecting data from "suppliers". */
export type Suppliers_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  normalized_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: suppliers */
export type Suppliers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "suppliers" */
export enum Suppliers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NormalizedName = 'normalized_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "suppliers" */
export type Suppliers_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  normalized_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "suppliers" */
export enum Suppliers_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NormalizedName = 'normalized_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "tasks" */
export type Tasks = {
  __typename?: 'tasks';
  as_group: Scalars['Boolean'];
  /** An array relationship */
  category_usage_in_tasks: Array<Category_Usage_In_Tasks>;
  /** An aggregate relationship */
  category_usage_in_tasks_aggregate: Category_Usage_In_Tasks_Aggregate;
  /** An array relationship */
  children: Array<Tasks>;
  /** An aggregate relationship */
  children_aggregate: Tasks_Aggregate;
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  datafields: Array<Datafields>;
  /** An aggregate relationship */
  datafields_aggregate: Datafields_Aggregate;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** A computed field, executes function "get_task_hierachy" */
  hierachy?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  location?: Maybe<Category_Locations>;
  location_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  logs: Array<Logs>;
  /** An aggregate relationship */
  logs_aggregate: Logs_Aggregate;
  /** An object relationship */
  machine?: Maybe<Category_Machines>;
  machine_id?: Maybe<Scalars['uuid']>;
  name: Scalars['String'];
  /** A computed field, executes function "get_next_task" */
  next_task_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "get_next_task_level" */
  next_task_level?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_next_task_name" */
  next_task_name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  parent?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  parent_task?: Maybe<Tasks>;
  /** A computed field, executes function "get_prev_task" */
  prev_task_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "get_prev_task_level" */
  prev_task_level?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_prev_task_name" */
  prev_task_name?: Maybe<Scalars['String']>;
  producer_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  related_action: Actions;
  related_to: Scalars['uuid'];
  /** An array relationship */
  resource_usage_in_tasks: Array<Resource_Usage_In_Tasks>;
  /** An aggregate relationship */
  resource_usage_in_tasks_aggregate: Resource_Usage_In_Tasks_Aggregate;
  /** An object relationship */
  task_flatten?: Maybe<Tasks_Flatten>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "tasks" */
export type TasksCategory_Usage_In_TasksArgs = {
  distinct_on?: Maybe<Array<Category_Usage_In_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Usage_In_Tasks_Order_By>>;
  where?: Maybe<Category_Usage_In_Tasks_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksCategory_Usage_In_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Category_Usage_In_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Category_Usage_In_Tasks_Order_By>>;
  where?: Maybe<Category_Usage_In_Tasks_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksChildrenArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksChildren_AggregateArgs = {
  distinct_on?: Maybe<Array<Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Tasks_Order_By>>;
  where?: Maybe<Tasks_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksDatafieldsArgs = {
  distinct_on?: Maybe<Array<Datafields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Datafields_Order_By>>;
  where?: Maybe<Datafields_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksDatafields_AggregateArgs = {
  distinct_on?: Maybe<Array<Datafields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Datafields_Order_By>>;
  where?: Maybe<Datafields_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksLogsArgs = {
  distinct_on?: Maybe<Array<Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Order_By>>;
  where?: Maybe<Logs_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksLogs_AggregateArgs = {
  distinct_on?: Maybe<Array<Logs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logs_Order_By>>;
  where?: Maybe<Logs_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksResource_Usage_In_TasksArgs = {
  distinct_on?: Maybe<Array<Resource_Usage_In_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Usage_In_Tasks_Order_By>>;
  where?: Maybe<Resource_Usage_In_Tasks_Bool_Exp>;
};


/** columns and relationships of "tasks" */
export type TasksResource_Usage_In_Tasks_AggregateArgs = {
  distinct_on?: Maybe<Array<Resource_Usage_In_Tasks_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Resource_Usage_In_Tasks_Order_By>>;
  where?: Maybe<Resource_Usage_In_Tasks_Bool_Exp>;
};

export type Tasks_NeoField = Tasks_TaskGroup_BlockType | Tasks_Task_BlockType;

/** aggregated selection of "tasks" */
export type Tasks_Aggregate = {
  __typename?: 'tasks_aggregate';
  aggregate?: Maybe<Tasks_Aggregate_Fields>;
  nodes: Array<Tasks>;
};

/** aggregate fields of "tasks" */
export type Tasks_Aggregate_Fields = {
  __typename?: 'tasks_aggregate_fields';
  avg?: Maybe<Tasks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tasks_Max_Fields>;
  min?: Maybe<Tasks_Min_Fields>;
  stddev?: Maybe<Tasks_Stddev_Fields>;
  stddev_pop?: Maybe<Tasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tasks_Stddev_Samp_Fields>;
  sum?: Maybe<Tasks_Sum_Fields>;
  var_pop?: Maybe<Tasks_Var_Pop_Fields>;
  var_samp?: Maybe<Tasks_Var_Samp_Fields>;
  variance?: Maybe<Tasks_Variance_Fields>;
};


/** aggregate fields of "tasks" */
export type Tasks_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tasks_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tasks" */
export type Tasks_Aggregate_Order_By = {
  avg?: Maybe<Tasks_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Tasks_Max_Order_By>;
  min?: Maybe<Tasks_Min_Order_By>;
  stddev?: Maybe<Tasks_Stddev_Order_By>;
  stddev_pop?: Maybe<Tasks_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Tasks_Stddev_Samp_Order_By>;
  sum?: Maybe<Tasks_Sum_Order_By>;
  var_pop?: Maybe<Tasks_Var_Pop_Order_By>;
  var_samp?: Maybe<Tasks_Var_Samp_Order_By>;
  variance?: Maybe<Tasks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tasks" */
export type Tasks_Arr_Rel_Insert_Input = {
  data: Array<Tasks_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Tasks_On_Conflict>;
};

/** aggregate avg on columns */
export type Tasks_Avg_Fields = {
  __typename?: 'tasks_avg_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tasks" */
export type Tasks_Avg_Order_By = {
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tasks". All fields are combined with a logical 'AND'. */
export type Tasks_Bool_Exp = {
  _and?: Maybe<Array<Tasks_Bool_Exp>>;
  _not?: Maybe<Tasks_Bool_Exp>;
  _or?: Maybe<Array<Tasks_Bool_Exp>>;
  as_group?: Maybe<Boolean_Comparison_Exp>;
  category_usage_in_tasks?: Maybe<Category_Usage_In_Tasks_Bool_Exp>;
  children?: Maybe<Tasks_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by?: Maybe<Uuid_Comparison_Exp>;
  datafields?: Maybe<Datafields_Bool_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  hierachy?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  location?: Maybe<Category_Locations_Bool_Exp>;
  location_id?: Maybe<Uuid_Comparison_Exp>;
  logs?: Maybe<Logs_Bool_Exp>;
  machine?: Maybe<Category_Machines_Bool_Exp>;
  machine_id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  next_task_id?: Maybe<Uuid_Comparison_Exp>;
  next_task_level?: Maybe<Int_Comparison_Exp>;
  next_task_name?: Maybe<String_Comparison_Exp>;
  note?: Maybe<String_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  parent?: Maybe<Uuid_Comparison_Exp>;
  parent_task?: Maybe<Tasks_Bool_Exp>;
  prev_task_id?: Maybe<Uuid_Comparison_Exp>;
  prev_task_level?: Maybe<Int_Comparison_Exp>;
  prev_task_name?: Maybe<String_Comparison_Exp>;
  producer_id?: Maybe<Uuid_Comparison_Exp>;
  related_action?: Maybe<Actions_Bool_Exp>;
  related_to?: Maybe<Uuid_Comparison_Exp>;
  resource_usage_in_tasks?: Maybe<Resource_Usage_In_Tasks_Bool_Exp>;
  task_flatten?: Maybe<Tasks_Flatten_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tasks" */
export enum Tasks_Constraint {
  /** unique or primary key constraint */
  TaskItemsPkey = 'task_items_pkey'
}

/** columns and relationships of "tasks_flatten" */
export type Tasks_Flatten = {
  __typename?: 'tasks_flatten';
  array_to_string?: Maybe<Scalars['String']>;
  /** An array relationship */
  datafields: Array<Datafields>;
  /** An aggregate relationship */
  datafields_aggregate: Datafields_Aggregate;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_path?: Maybe<Scalars['_text']>;
  order?: Maybe<Scalars['Int']>;
  producer_id?: Maybe<Scalars['uuid']>;
  related_to?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "tasks_flatten" */
export type Tasks_FlattenDatafieldsArgs = {
  distinct_on?: Maybe<Array<Datafields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Datafields_Order_By>>;
  where?: Maybe<Datafields_Bool_Exp>;
};


/** columns and relationships of "tasks_flatten" */
export type Tasks_FlattenDatafields_AggregateArgs = {
  distinct_on?: Maybe<Array<Datafields_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Datafields_Order_By>>;
  where?: Maybe<Datafields_Bool_Exp>;
};

/** aggregated selection of "tasks_flatten" */
export type Tasks_Flatten_Aggregate = {
  __typename?: 'tasks_flatten_aggregate';
  aggregate?: Maybe<Tasks_Flatten_Aggregate_Fields>;
  nodes: Array<Tasks_Flatten>;
};

/** aggregate fields of "tasks_flatten" */
export type Tasks_Flatten_Aggregate_Fields = {
  __typename?: 'tasks_flatten_aggregate_fields';
  avg?: Maybe<Tasks_Flatten_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tasks_Flatten_Max_Fields>;
  min?: Maybe<Tasks_Flatten_Min_Fields>;
  stddev?: Maybe<Tasks_Flatten_Stddev_Fields>;
  stddev_pop?: Maybe<Tasks_Flatten_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tasks_Flatten_Stddev_Samp_Fields>;
  sum?: Maybe<Tasks_Flatten_Sum_Fields>;
  var_pop?: Maybe<Tasks_Flatten_Var_Pop_Fields>;
  var_samp?: Maybe<Tasks_Flatten_Var_Samp_Fields>;
  variance?: Maybe<Tasks_Flatten_Variance_Fields>;
};


/** aggregate fields of "tasks_flatten" */
export type Tasks_Flatten_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Tasks_Flatten_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tasks_flatten" */
export type Tasks_Flatten_Aggregate_Order_By = {
  avg?: Maybe<Tasks_Flatten_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Tasks_Flatten_Max_Order_By>;
  min?: Maybe<Tasks_Flatten_Min_Order_By>;
  stddev?: Maybe<Tasks_Flatten_Stddev_Order_By>;
  stddev_pop?: Maybe<Tasks_Flatten_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Tasks_Flatten_Stddev_Samp_Order_By>;
  sum?: Maybe<Tasks_Flatten_Sum_Order_By>;
  var_pop?: Maybe<Tasks_Flatten_Var_Pop_Order_By>;
  var_samp?: Maybe<Tasks_Flatten_Var_Samp_Order_By>;
  variance?: Maybe<Tasks_Flatten_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tasks_flatten" */
export type Tasks_Flatten_Arr_Rel_Insert_Input = {
  data: Array<Tasks_Flatten_Insert_Input>;
};

/** aggregate avg on columns */
export type Tasks_Flatten_Avg_Fields = {
  __typename?: 'tasks_flatten_avg_fields';
  level?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tasks_flatten" */
export type Tasks_Flatten_Avg_Order_By = {
  level?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tasks_flatten". All fields are combined with a logical 'AND'. */
export type Tasks_Flatten_Bool_Exp = {
  _and?: Maybe<Array<Tasks_Flatten_Bool_Exp>>;
  _not?: Maybe<Tasks_Flatten_Bool_Exp>;
  _or?: Maybe<Array<Tasks_Flatten_Bool_Exp>>;
  array_to_string?: Maybe<String_Comparison_Exp>;
  datafields?: Maybe<Datafields_Bool_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  level?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  name_path?: Maybe<_Text_Comparison_Exp>;
  order?: Maybe<Int_Comparison_Exp>;
  producer_id?: Maybe<Uuid_Comparison_Exp>;
  related_to?: Maybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "tasks_flatten" */
export type Tasks_Flatten_Insert_Input = {
  array_to_string?: Maybe<Scalars['String']>;
  datafields?: Maybe<Datafields_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_path?: Maybe<Scalars['_text']>;
  order?: Maybe<Scalars['Int']>;
  producer_id?: Maybe<Scalars['uuid']>;
  related_to?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Tasks_Flatten_Max_Fields = {
  __typename?: 'tasks_flatten_max_fields';
  array_to_string?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  producer_id?: Maybe<Scalars['uuid']>;
  related_to?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "tasks_flatten" */
export type Tasks_Flatten_Max_Order_By = {
  array_to_string?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
  related_to?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tasks_Flatten_Min_Fields = {
  __typename?: 'tasks_flatten_min_fields';
  array_to_string?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  level?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  producer_id?: Maybe<Scalars['uuid']>;
  related_to?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "tasks_flatten" */
export type Tasks_Flatten_Min_Order_By = {
  array_to_string?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
  related_to?: Maybe<Order_By>;
};

/** input type for inserting object relation for remote table "tasks_flatten" */
export type Tasks_Flatten_Obj_Rel_Insert_Input = {
  data: Tasks_Flatten_Insert_Input;
};

/** Ordering options when selecting data from "tasks_flatten". */
export type Tasks_Flatten_Order_By = {
  array_to_string?: Maybe<Order_By>;
  datafields_aggregate?: Maybe<Datafields_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  name_path?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
  related_to?: Maybe<Order_By>;
};

/** select columns of table "tasks_flatten" */
export enum Tasks_Flatten_Select_Column {
  /** column name */
  ArrayToString = 'array_to_string',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  Name = 'name',
  /** column name */
  NamePath = 'name_path',
  /** column name */
  Order = 'order',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  RelatedTo = 'related_to'
}

/** aggregate stddev on columns */
export type Tasks_Flatten_Stddev_Fields = {
  __typename?: 'tasks_flatten_stddev_fields';
  level?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tasks_flatten" */
export type Tasks_Flatten_Stddev_Order_By = {
  level?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tasks_Flatten_Stddev_Pop_Fields = {
  __typename?: 'tasks_flatten_stddev_pop_fields';
  level?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tasks_flatten" */
export type Tasks_Flatten_Stddev_Pop_Order_By = {
  level?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tasks_Flatten_Stddev_Samp_Fields = {
  __typename?: 'tasks_flatten_stddev_samp_fields';
  level?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tasks_flatten" */
export type Tasks_Flatten_Stddev_Samp_Order_By = {
  level?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Tasks_Flatten_Sum_Fields = {
  __typename?: 'tasks_flatten_sum_fields';
  level?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "tasks_flatten" */
export type Tasks_Flatten_Sum_Order_By = {
  level?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Tasks_Flatten_Var_Pop_Fields = {
  __typename?: 'tasks_flatten_var_pop_fields';
  level?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tasks_flatten" */
export type Tasks_Flatten_Var_Pop_Order_By = {
  level?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tasks_Flatten_Var_Samp_Fields = {
  __typename?: 'tasks_flatten_var_samp_fields';
  level?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tasks_flatten" */
export type Tasks_Flatten_Var_Samp_Order_By = {
  level?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Tasks_Flatten_Variance_Fields = {
  __typename?: 'tasks_flatten_variance_fields';
  level?: Maybe<Scalars['Float']>;
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tasks_flatten" */
export type Tasks_Flatten_Variance_Order_By = {
  level?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
};

/** input type for incrementing numeric columns in table "tasks" */
export type Tasks_Inc_Input = {
  order?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "tasks" */
export type Tasks_Insert_Input = {
  as_group?: Maybe<Scalars['Boolean']>;
  category_usage_in_tasks?: Maybe<Category_Usage_In_Tasks_Arr_Rel_Insert_Input>;
  children?: Maybe<Tasks_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  datafields?: Maybe<Datafields_Arr_Rel_Insert_Input>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  location?: Maybe<Category_Locations_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars['uuid']>;
  logs?: Maybe<Logs_Arr_Rel_Insert_Input>;
  machine?: Maybe<Category_Machines_Obj_Rel_Insert_Input>;
  machine_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  parent?: Maybe<Scalars['uuid']>;
  parent_task?: Maybe<Tasks_Obj_Rel_Insert_Input>;
  producer_id?: Maybe<Scalars['uuid']>;
  related_action?: Maybe<Actions_Obj_Rel_Insert_Input>;
  related_to?: Maybe<Scalars['uuid']>;
  resource_usage_in_tasks?: Maybe<Resource_Usage_In_Tasks_Arr_Rel_Insert_Input>;
  task_flatten?: Maybe<Tasks_Flatten_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tasks_Max_Fields = {
  __typename?: 'tasks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  machine_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  parent?: Maybe<Scalars['uuid']>;
  producer_id?: Maybe<Scalars['uuid']>;
  related_to?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tasks" */
export type Tasks_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  machine_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  parent?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
  related_to?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Tasks_Min_Fields = {
  __typename?: 'tasks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  machine_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  parent?: Maybe<Scalars['uuid']>;
  producer_id?: Maybe<Scalars['uuid']>;
  related_to?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tasks" */
export type Tasks_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  machine_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  parent?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
  related_to?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "tasks" */
export type Tasks_Mutation_Response = {
  __typename?: 'tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tasks>;
};

/** input type for inserting object relation for remote table "tasks" */
export type Tasks_Obj_Rel_Insert_Input = {
  data: Tasks_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Tasks_On_Conflict>;
};

/** on conflict condition type for table "tasks" */
export type Tasks_On_Conflict = {
  constraint: Tasks_Constraint;
  update_columns?: Array<Tasks_Update_Column>;
  where?: Maybe<Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "tasks". */
export type Tasks_Order_By = {
  as_group?: Maybe<Order_By>;
  category_usage_in_tasks_aggregate?: Maybe<Category_Usage_In_Tasks_Aggregate_Order_By>;
  children_aggregate?: Maybe<Tasks_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  created_by?: Maybe<Order_By>;
  datafields_aggregate?: Maybe<Datafields_Aggregate_Order_By>;
  deleted_at?: Maybe<Order_By>;
  hierachy?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Category_Locations_Order_By>;
  location_id?: Maybe<Order_By>;
  logs_aggregate?: Maybe<Logs_Aggregate_Order_By>;
  machine?: Maybe<Category_Machines_Order_By>;
  machine_id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  next_task_id?: Maybe<Order_By>;
  next_task_level?: Maybe<Order_By>;
  next_task_name?: Maybe<Order_By>;
  note?: Maybe<Order_By>;
  order?: Maybe<Order_By>;
  parent?: Maybe<Order_By>;
  parent_task?: Maybe<Tasks_Order_By>;
  prev_task_id?: Maybe<Order_By>;
  prev_task_level?: Maybe<Order_By>;
  prev_task_name?: Maybe<Order_By>;
  producer_id?: Maybe<Order_By>;
  related_action?: Maybe<Actions_Order_By>;
  related_to?: Maybe<Order_By>;
  resource_usage_in_tasks_aggregate?: Maybe<Resource_Usage_In_Tasks_Aggregate_Order_By>;
  task_flatten?: Maybe<Tasks_Flatten_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: tasks */
export type Tasks_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tasks" */
export enum Tasks_Select_Column {
  /** column name */
  AsGroup = 'as_group',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  MachineId = 'machine_id',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  Order = 'order',
  /** column name */
  Parent = 'parent',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  RelatedTo = 'related_to',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tasks" */
export type Tasks_Set_Input = {
  as_group?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  location_id?: Maybe<Scalars['uuid']>;
  machine_id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  parent?: Maybe<Scalars['uuid']>;
  producer_id?: Maybe<Scalars['uuid']>;
  related_to?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tasks_Stddev_Fields = {
  __typename?: 'tasks_stddev_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tasks" */
export type Tasks_Stddev_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tasks_Stddev_Pop_Fields = {
  __typename?: 'tasks_stddev_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tasks" */
export type Tasks_Stddev_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tasks_Stddev_Samp_Fields = {
  __typename?: 'tasks_stddev_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tasks" */
export type Tasks_Stddev_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Tasks_Sum_Fields = {
  __typename?: 'tasks_sum_fields';
  order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "tasks" */
export type Tasks_Sum_Order_By = {
  order?: Maybe<Order_By>;
};

export type Tasks_TaskGroup_BlockType = ElementInterface & NeoBlockInterface & {
  __typename?: 'tasks_taskGroup_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The children block types for this block */
  children?: Maybe<Array<Maybe<NeoBlockInterface>>>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  enableBatchSelector?: Maybe<Scalars['Boolean']>;
  enableLocationSelector?: Maybe<Scalars['Boolean']>;
  enableMaschineSelector?: Maybe<Scalars['Boolean']>;
  enableNoteField?: Maybe<Scalars['Boolean']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the neo block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The Neo block's level. */
  level?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the neo block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  taskInformation?: Maybe<Scalars['String']>;
  taskName?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the neo block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the neo block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type Tasks_TaskGroup_BlockType_CountArgs = {
  field: Scalars['String'];
};

export type Tasks_Task_BlockType = ElementInterface & NeoBlockInterface & {
  __typename?: 'tasks_task_BlockType';
  /** Return a number of related elements for a field. */
  _count?: Maybe<Scalars['Int']>;
  /** Whether the element is archived or not. */
  archived?: Maybe<Scalars['Boolean']>;
  /** The date the element was created. */
  dateCreated?: Maybe<Scalars['DateTime']>;
  /** The date the element was last updated. */
  dateUpdated?: Maybe<Scalars['DateTime']>;
  enableBatchSelector?: Maybe<Scalars['Boolean']>;
  enableLocationSelector?: Maybe<Scalars['Boolean']>;
  enableMaschineSelector?: Maybe<Scalars['Boolean']>;
  enableNoteField?: Maybe<Scalars['Boolean']>;
  /** Whether the element is enabled or not. */
  enabled?: Maybe<Scalars['Boolean']>;
  /** The ID of the field that owns the neo block. */
  fieldId?: Maybe<Scalars['Int']>;
  /** The id of the entity */
  id?: Maybe<Scalars['ID']>;
  /** The language of the site element is associated with. */
  language?: Maybe<Scalars['String']>;
  /** The Neo block's level. */
  level?: Maybe<Scalars['Int']>;
  /** The ID of the element that owns the neo block. */
  ownerId?: Maybe<Scalars['Int']>;
  /** The element’s search score, if the `search` parameter was used when querying for the element. */
  searchScore?: Maybe<Scalars['String']>;
  /** The ID of the site the element is associated with. */
  siteId?: Maybe<Scalars['Int']>;
  /** The unique identifier for an element-site relation. */
  siteSettingsId?: Maybe<Scalars['ID']>;
  /** The element’s slug. */
  slug?: Maybe<Scalars['String']>;
  /** The element's status. */
  status?: Maybe<Scalars['String']>;
  taskInformation?: Maybe<Scalars['String']>;
  taskName?: Maybe<Scalars['String']>;
  /** The element’s title. */
  title?: Maybe<Scalars['String']>;
  /** Whether the element has been soft-deleted or not. */
  trashed?: Maybe<Scalars['Boolean']>;
  /** The handle of the neo block's type. */
  typeHandle?: Maybe<Scalars['String']>;
  /** The ID of the neo block's type. */
  typeId?: Maybe<Scalars['Int']>;
  /** The uid of the entity */
  uid?: Maybe<Scalars['String']>;
  /** The element’s URI. */
  uri?: Maybe<Scalars['String']>;
};


export type Tasks_Task_BlockType_CountArgs = {
  field: Scalars['String'];
};

/** update columns of table "tasks" */
export enum Tasks_Update_Column {
  /** column name */
  AsGroup = 'as_group',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  MachineId = 'machine_id',
  /** column name */
  Name = 'name',
  /** column name */
  Note = 'note',
  /** column name */
  Order = 'order',
  /** column name */
  Parent = 'parent',
  /** column name */
  ProducerId = 'producer_id',
  /** column name */
  RelatedTo = 'related_to',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Tasks_Var_Pop_Fields = {
  __typename?: 'tasks_var_pop_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tasks" */
export type Tasks_Var_Pop_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tasks_Var_Samp_Fields = {
  __typename?: 'tasks_var_samp_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tasks" */
export type Tasks_Var_Samp_Order_By = {
  order?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Tasks_Variance_Fields = {
  __typename?: 'tasks_variance_fields';
  order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tasks" */
export type Tasks_Variance_Order_By = {
  order?: Maybe<Order_By>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "usage_types" */
export type Usage_Types = {
  __typename?: 'usage_types';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "usage_types" */
export type Usage_Types_Aggregate = {
  __typename?: 'usage_types_aggregate';
  aggregate?: Maybe<Usage_Types_Aggregate_Fields>;
  nodes: Array<Usage_Types>;
};

/** aggregate fields of "usage_types" */
export type Usage_Types_Aggregate_Fields = {
  __typename?: 'usage_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Usage_Types_Max_Fields>;
  min?: Maybe<Usage_Types_Min_Fields>;
};


/** aggregate fields of "usage_types" */
export type Usage_Types_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Usage_Types_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "usage_types". All fields are combined with a logical 'AND'. */
export type Usage_Types_Bool_Exp = {
  _and?: Maybe<Array<Usage_Types_Bool_Exp>>;
  _not?: Maybe<Usage_Types_Bool_Exp>;
  _or?: Maybe<Array<Usage_Types_Bool_Exp>>;
  comment?: Maybe<String_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "usage_types" */
export enum Usage_Types_Constraint {
  /** unique or primary key constraint */
  UsageTypesPkey = 'usage_types_pkey'
}

export enum Usage_Types_Enum {
  /** Item is added from outside to the process */
  Input = 'input',
  /** Item is the result to the process */
  Output = 'output',
  /** Item is used between tasks */
  Passing = 'passing',
  /** Item is created during the process */
  Produced = 'produced'
}

/** Boolean expression to compare columns of type "usage_types_enum". All fields are combined with logical 'AND'. */
export type Usage_Types_Enum_Comparison_Exp = {
  _eq?: Maybe<Usage_Types_Enum>;
  _in?: Maybe<Array<Usage_Types_Enum>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Usage_Types_Enum>;
  _nin?: Maybe<Array<Usage_Types_Enum>>;
};

/** input type for inserting data into table "usage_types" */
export type Usage_Types_Insert_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Usage_Types_Max_Fields = {
  __typename?: 'usage_types_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Usage_Types_Min_Fields = {
  __typename?: 'usage_types_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "usage_types" */
export type Usage_Types_Mutation_Response = {
  __typename?: 'usage_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Usage_Types>;
};

/** on conflict condition type for table "usage_types" */
export type Usage_Types_On_Conflict = {
  constraint: Usage_Types_Constraint;
  update_columns?: Array<Usage_Types_Update_Column>;
  where?: Maybe<Usage_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "usage_types". */
export type Usage_Types_Order_By = {
  comment?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
};

/** primary key columns input for table: usage_types */
export type Usage_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "usage_types" */
export enum Usage_Types_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "usage_types" */
export type Usage_Types_Set_Input = {
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** update columns of table "usage_types" */
export enum Usage_Types_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value'
}


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type LoginMutationVariables = Exact<{
  loginName: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'mutation_root' }
  & { login?: Maybe<(
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'token' | 'error'>
  )> }
);

export type ValidateTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type ValidateTokenQuery = (
  { __typename?: 'query_root' }
  & { validateToken?: Maybe<(
    { __typename?: 'ValidateToken' }
    & Pick<ValidateToken, 'last_checked'>
  )> }
);

export type GetProductActionsQueryVariables = Exact<{
  product_id: Scalars['uuid'];
}>;


export type GetProductActionsQuery = (
  { __typename?: 'query_root' }
  & { products_by_pk?: Maybe<(
    { __typename?: 'products' }
    & ProductFragment
  )>, actions: Array<(
    { __typename?: 'actions' }
    & ActionFragment
  )> }
);

export type GetActionsQueryVariables = Exact<{
  producer_id: Scalars['String'];
}>;


export type GetActionsQuery = (
  { __typename?: 'query_root' }
  & { actions: Array<(
    { __typename?: 'actions' }
    & ActionFragment
  )> }
);

export type GetActionQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetActionQuery = (
  { __typename?: 'query_root' }
  & { actions_by_pk?: Maybe<(
    { __typename?: 'actions' }
    & ActionFragment
  )>, datafields_aggregate: (
    { __typename?: 'datafields_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'datafields_aggregate_fields' }
      & Pick<Datafields_Aggregate_Fields, 'count'>
    )> }
  ), tasks_aggregate: (
    { __typename?: 'tasks_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'tasks_aggregate_fields' }
      & Pick<Tasks_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type CreateActionMutationVariables = Exact<{
  object: Actions_Insert_Input;
}>;


export type CreateActionMutation = (
  { __typename?: 'mutation_root' }
  & { insert_actions_one?: Maybe<(
    { __typename?: 'actions' }
    & ActionFragment
  )> }
);

export type UpdateActionMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: Maybe<Actions_Set_Input>;
}>;


export type UpdateActionMutation = (
  { __typename?: 'mutation_root' }
  & { update_actions_by_pk?: Maybe<(
    { __typename?: 'actions' }
    & ActionFragment
  )> }
);

export type DeleteActionMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteActionMutation = (
  { __typename?: 'mutation_root' }
  & { update_actions_by_pk?: Maybe<(
    { __typename?: 'actions' }
    & Pick<Actions, 'id' | 'deleted_at'>
  )> }
);

export type RestoreActionMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type RestoreActionMutation = (
  { __typename?: 'mutation_root' }
  & { update_actions_by_pk?: Maybe<(
    { __typename?: 'actions' }
    & Pick<Actions, 'id' | 'deleted_at'>
  )> }
);

export type GetActionTaskQueryVariables = Exact<{
  action_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
}>;


export type GetActionTaskQuery = (
  { __typename?: 'query_root' }
  & { task?: Maybe<(
    { __typename?: 'tasks' }
    & TaskFragment
  )>, actions_by_pk?: Maybe<(
    { __typename?: 'actions' }
    & Pick<Actions, 'id' | 'name' | 'updated_at'>
    & { product?: Maybe<(
      { __typename?: 'products' }
      & Pick<Products, 'id' | 'name'>
    )>, main_action_of_product?: Maybe<(
      { __typename?: 'products' }
      & Pick<Products, 'id' | 'name'>
    )> }
  )>, tasks: Array<(
    { __typename?: 'tasks' }
    & TaskFragment
  )>, tasks_flatten: Array<(
    { __typename?: 'tasks_flatten' }
    & Pick<Tasks_Flatten, 'id' | 'name' | 'level' | 'name_path'>
  )> }
);

export type AddActionTaskMutationVariables = Exact<{
  object: Tasks_Insert_Input;
}>;


export type AddActionTaskMutation = (
  { __typename?: 'mutation_root' }
  & { insert_tasks_one?: Maybe<(
    { __typename?: 'tasks' }
    & TaskFragment
  )> }
);

export type UpdateActionTasksMutationVariables = Exact<{
  objects: Array<Tasks_Insert_Input> | Tasks_Insert_Input;
}>;


export type UpdateActionTasksMutation = (
  { __typename?: 'mutation_root' }
  & { insert_tasks?: Maybe<(
    { __typename?: 'tasks_mutation_response' }
    & Pick<Tasks_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'tasks' }
      & Pick<Tasks, 'id' | 'name'>
      & TaskFragment
    )> }
  )> }
);

export type UpdateTaskMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set?: Maybe<Tasks_Set_Input>;
  datafields: Array<Datafields_Insert_Input> | Datafields_Insert_Input;
}>;


export type UpdateTaskMutation = (
  { __typename?: 'mutation_root' }
  & { update_tasks_by_pk?: Maybe<(
    { __typename?: 'tasks' }
    & TaskFragment
  )>, delete_datafields?: Maybe<(
    { __typename?: 'datafields_mutation_response' }
    & Pick<Datafields_Mutation_Response, 'affected_rows'>
  )>, insert_datafields?: Maybe<(
    { __typename?: 'datafields_mutation_response' }
    & Pick<Datafields_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'datafields' }
      & DatafieldFragment
    )> }
  )> }
);

export type DeleteActionTaskMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteActionTaskMutation = (
  { __typename?: 'mutation_root' }
  & { delete_tasks_by_pk?: Maybe<(
    { __typename?: 'tasks' }
    & Pick<Tasks, 'id' | 'parent' | 'related_to'>
  )> }
);

export type AddActionCategoryItemMutationVariables = Exact<{
  action_id: Scalars['uuid'];
  name: Scalars['String'];
  type_id: Scalars['uuid'];
  producer_id: Scalars['uuid'];
}>;


export type AddActionCategoryItemMutation = (
  { __typename?: 'mutation_root' }
  & { insert_actions_category_items_one?: Maybe<(
    { __typename?: 'actions_category_items' }
    & ActionsCategoryItemsFragment
  )> }
);

export type UpdateActionCategoryItemsMutationVariables = Exact<{
  action_id: Scalars['uuid'];
  category_id: Scalars['uuid'];
}>;


export type UpdateActionCategoryItemsMutation = (
  { __typename?: 'mutation_root' }
  & { update_actions_category_items?: Maybe<(
    { __typename?: 'actions_category_items_mutation_response' }
    & Pick<Actions_Category_Items_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'actions_category_items' }
      & ActionsCategoryItemsFragment
    )> }
  )> }
);

export type DeleteActionCategoryItemsMutationVariables = Exact<{
  action_id: Scalars['uuid'];
  category_id: Scalars['uuid'];
}>;


export type DeleteActionCategoryItemsMutation = (
  { __typename?: 'mutation_root' }
  & { delete_actions_category_items?: Maybe<(
    { __typename?: 'actions_category_items_mutation_response' }
    & Pick<Actions_Category_Items_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'actions_category_items' }
      & ActionsCategoryItemsFragment
    )> }
  )> }
);

export type GetActionCategoryItemsQueryVariables = Exact<{
  action_id: Scalars['uuid'];
}>;


export type GetActionCategoryItemsQuery = (
  { __typename?: 'query_root' }
  & { actions_category_items: Array<(
    { __typename?: 'actions_category_items' }
    & ActionsCategoryItemsFragment
  )> }
);

export type GetActionCategoryItemsByTypeQueryVariables = Exact<{
  action_id: Scalars['uuid'];
}>;


export type GetActionCategoryItemsByTypeQuery = (
  { __typename?: 'query_root' }
  & { category_types: Array<(
    { __typename?: 'category_types' }
    & Pick<Category_Types, 'id' | 'name'>
    & { items: Array<(
      { __typename?: 'category_items' }
      & Pick<Category_Items, 'id' | 'name'>
    )> }
  )> }
);

export type GetActionCategoryItemsByTypeIdQueryVariables = Exact<{
  type_id: Scalars['uuid'];
  action_id: Scalars['uuid'];
}>;


export type GetActionCategoryItemsByTypeIdQuery = (
  { __typename?: 'query_root' }
  & { category_types_by_pk?: Maybe<(
    { __typename?: 'category_types' }
    & Pick<Category_Types, 'id' | 'name'>
    & { items: Array<(
      { __typename?: 'category_items' }
      & Pick<Category_Items, 'id' | 'name'>
    )> }
  )> }
);

export type GetUnusedActionCategoryItemsQueryVariables = Exact<{
  action_id: Scalars['uuid'];
}>;


export type GetUnusedActionCategoryItemsQuery = (
  { __typename?: 'query_root' }
  & { category_items: Array<(
    { __typename?: 'category_items' }
    & CategoryItemFragment
  )> }
);

export type GetActionCategoryItemsInsightsQueryVariables = Exact<{
  action_id: Scalars['uuid'];
}>;


export type GetActionCategoryItemsInsightsQuery = (
  { __typename?: 'query_root' }
  & { category_types: Array<(
    { __typename?: 'category_types' }
    & Pick<Category_Types, 'id' | 'name'>
    & { items: Array<(
      { __typename?: 'category_items' }
      & { usage_in_tasks_aggregate: (
        { __typename?: 'category_usage_in_tasks_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'category_usage_in_tasks_aggregate_fields' }
          & Pick<Category_Usage_In_Tasks_Aggregate_Fields, 'count'>
        )> }
      ) }
      & CategoryItemFragment
    )> }
  )> }
);

export type GetProducerCategoryItemsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProducerCategoryItemsQuery = (
  { __typename?: 'query_root' }
  & { category_items: Array<(
    { __typename?: 'category_items' }
    & { category_item_actions: Array<(
      { __typename?: 'actions_category_items' }
      & Pick<Actions_Category_Items, 'action_id' | 'category_id'>
      & { action: (
        { __typename?: 'actions' }
        & Pick<Actions, 'id' | 'name'>
        & { product?: Maybe<(
          { __typename?: 'products' }
          & Pick<Products, 'id' | 'name'>
        )> }
      ) }
    )>, category_item_actions_aggregate: (
      { __typename?: 'actions_category_items_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'actions_category_items_aggregate_fields' }
        & Pick<Actions_Category_Items_Aggregate_Fields, 'count'>
      )> }
    ) }
    & CategoryItemFragment
  )> }
);

export type AddTaskCategoryItemMutationVariables = Exact<{
  action_id: Scalars['uuid'];
  category_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
}>;


export type AddTaskCategoryItemMutation = (
  { __typename?: 'mutation_root' }
  & { insert_actions_category_items_one?: Maybe<(
    { __typename?: 'actions_category_items' }
    & ActionsCategoryItemsFragment
  )>, insert_category_usage_in_tasks_one?: Maybe<(
    { __typename?: 'category_usage_in_tasks' }
    & UsedCategoryItemFragment
  )> }
);

export type GetUsedCategoryItemsInTaskQueryVariables = Exact<{
  task_id: Scalars['uuid'];
}>;


export type GetUsedCategoryItemsInTaskQuery = (
  { __typename?: 'query_root' }
  & { category_types: Array<(
    { __typename?: 'category_types' }
    & Pick<Category_Types, 'id' | 'name'>
    & { items: Array<(
      { __typename?: 'category_items' }
      & Pick<Category_Items, 'id' | 'name'>
    )> }
  )> }
);

export type UpdateUsedCategoryItemsInTaskMutationVariables = Exact<{
  task_id: Scalars['uuid'];
  objects: Array<Category_Usage_In_Tasks_Insert_Input> | Category_Usage_In_Tasks_Insert_Input;
}>;


export type UpdateUsedCategoryItemsInTaskMutation = (
  { __typename?: 'mutation_root' }
  & { delete_category_usage_in_tasks?: Maybe<(
    { __typename?: 'category_usage_in_tasks_mutation_response' }
    & Pick<Category_Usage_In_Tasks_Mutation_Response, 'affected_rows'>
  )>, insert_category_usage_in_tasks?: Maybe<(
    { __typename?: 'category_usage_in_tasks_mutation_response' }
    & { returning: Array<(
      { __typename?: 'category_usage_in_tasks' }
      & Pick<Category_Usage_In_Tasks, 'id'>
      & { category: (
        { __typename?: 'category_items' }
        & Pick<Category_Items, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type DeleteTaskCategoryItemMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteTaskCategoryItemMutation = (
  { __typename?: 'mutation_root' }
  & { delete_category_usage_in_tasks_by_pk?: Maybe<(
    { __typename?: 'category_usage_in_tasks' }
    & Pick<Category_Usage_In_Tasks, 'id' | 'task_id'>
  )> }
);

export type GetTaskCategoryItemsQueryVariables = Exact<{
  task_id: Scalars['uuid'];
}>;


export type GetTaskCategoryItemsQuery = (
  { __typename?: 'query_root' }
  & { category_usage_in_tasks: Array<(
    { __typename?: 'category_usage_in_tasks' }
    & UsedCategoryItemFragment
  )> }
);

export type AddActionResourceMutationVariables = Exact<{
  action_id: Scalars['uuid'];
  name: Scalars['String'];
  type_id: Scalars['uuid'];
  usage?: Maybe<Usage_Types_Enum>;
  producer_id: Scalars['uuid'];
}>;


export type AddActionResourceMutation = (
  { __typename?: 'mutation_root' }
  & { insert_actions_resources_one?: Maybe<(
    { __typename?: 'actions_resources' }
    & ActionsResourcesFragment
  )> }
);

export type UpdateActionResourceMutationVariables = Exact<{
  action_id: Scalars['uuid'];
  resource_id: Scalars['uuid'];
  unit?: Maybe<Scalars['String']>;
}>;


export type UpdateActionResourceMutation = (
  { __typename?: 'mutation_root' }
  & { update_actions_resources?: Maybe<(
    { __typename?: 'actions_resources_mutation_response' }
    & Pick<Actions_Resources_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'actions_resources' }
      & ActionsResourcesFragment
    )> }
  )> }
);

export type DeleteActionResourceMutationVariables = Exact<{
  action_id: Scalars['uuid'];
  resource_id: Scalars['uuid'];
  usage?: Maybe<Usage_Types_Enum>;
}>;


export type DeleteActionResourceMutation = (
  { __typename?: 'mutation_root' }
  & { delete_actions_resources?: Maybe<(
    { __typename?: 'actions_resources_mutation_response' }
    & Pick<Actions_Resources_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'actions_resources' }
      & ActionsResourcesFragment
    )> }
  )> }
);

export type GetActionResourcesQueryVariables = Exact<{
  action_id: Scalars['uuid'];
  usage?: Maybe<Usage_Types_Enum>;
}>;


export type GetActionResourcesQuery = (
  { __typename?: 'query_root' }
  & { actions_resources: Array<(
    { __typename?: 'actions_resources' }
    & ActionsResourcesFragment
  )> }
);

export type GetUnusedActionResourcesQueryVariables = Exact<{
  action_id: Scalars['uuid'];
  usage?: Maybe<Usage_Types_Enum>;
}>;


export type GetUnusedActionResourcesQuery = (
  { __typename?: 'query_root' }
  & { resources: Array<(
    { __typename?: 'resources' }
    & ResourceItemFragment
  )> }
);

export type GetActionResourcesInsightsQueryVariables = Exact<{
  action_id: Scalars['uuid'];
}>;


export type GetActionResourcesInsightsQuery = (
  { __typename?: 'query_root' }
  & { actions_resources: Array<(
    { __typename?: 'actions_resources' }
    & { resource: (
      { __typename?: 'resources' }
      & { usage_in_tasks_aggregate: (
        { __typename?: 'resource_usage_in_tasks_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'resource_usage_in_tasks_aggregate_fields' }
          & { sum?: Maybe<(
            { __typename?: 'resource_usage_in_tasks_sum_fields' }
            & Pick<Resource_Usage_In_Tasks_Sum_Fields, 'value'>
          )> }
        )> }
      ) }
    ) }
    & ActionsResourcesFragment
  )> }
);

export type GetActionResultOptionsQueryVariables = Exact<{
  action_id: Scalars['uuid'];
}>;


export type GetActionResultOptionsQuery = (
  { __typename?: 'query_root' }
  & { actions_by_pk?: Maybe<(
    { __typename?: 'actions' }
    & Pick<Actions, 'result_base_qty'>
    & { result_packaging?: Maybe<(
      { __typename?: 'resources' }
      & Pick<Resources, 'id' | 'name'>
    )>, result_resource?: Maybe<(
      { __typename?: 'resources' }
      & Pick<Resources, 'id' | 'name'>
    )> }
  )>, packaging: Array<(
    { __typename?: 'resources' }
    & Pick<Resources, 'id' | 'name'>
  )>, resources: Array<(
    { __typename?: 'resources' }
    & Pick<Resources, 'id' | 'name'>
  )> }
);

export type UpdateActionResultsMutationVariables = Exact<{
  action_id: Scalars['uuid'];
  qty?: Maybe<Scalars['Int']>;
  packaging_id: Scalars['uuid'];
  resource_id: Scalars['uuid'];
}>;


export type UpdateActionResultsMutation = (
  { __typename?: 'mutation_root' }
  & { update_actions_by_pk?: Maybe<(
    { __typename?: 'actions' }
    & Pick<Actions, 'result_base_qty'>
    & { result_packaging?: Maybe<(
      { __typename?: 'resources' }
      & Pick<Resources, 'id' | 'name'>
    )>, result_resource?: Maybe<(
      { __typename?: 'resources' }
      & Pick<Resources, 'id' | 'name'>
    )> }
  )> }
);

export type GetProducerResourcesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProducerResourcesQuery = (
  { __typename?: 'query_root' }
  & { resources: Array<(
    { __typename?: 'resources' }
    & { resource_actions: Array<(
      { __typename?: 'actions_resources' }
      & Pick<Actions_Resources, 'action_id' | 'resource_id'>
      & { action: (
        { __typename?: 'actions' }
        & Pick<Actions, 'id' | 'name'>
        & { product?: Maybe<(
          { __typename?: 'products' }
          & Pick<Products, 'id' | 'name'>
        )> }
      ) }
    )>, resource_actions_aggregate: (
      { __typename?: 'actions_resources_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'actions_resources_aggregate_fields' }
        & Pick<Actions_Resources_Aggregate_Fields, 'count'>
      )> }
    ) }
    & ResourceItemFragment
  )> }
);

export type GetAvailableTaskInputsQueryVariables = Exact<{
  action_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
  task_order_path: Scalars['String'];
}>;


export type GetAvailableTaskInputsQuery = (
  { __typename?: 'query_root' }
  & { actions_resources: Array<(
    { __typename?: 'actions_resources' }
    & ActionsResourcesFragment
  )>, resource_usage_in_tasks_flatten: Array<(
    { __typename?: 'resource_usage_in_tasks_flatten' }
    & Pick<Resource_Usage_In_Tasks_Flatten, 'usage' | 'name_path' | 'task_id' | 'array_to_string' | 'usage_id'>
    & { resource?: Maybe<(
      { __typename?: 'resources' }
      & ResourceItemFragment
    )> }
  )>, resource_usage_in_tasks: Array<(
    { __typename?: 'resource_usage_in_tasks' }
    & UsedResourceFragment
  )> }
);

export type AddTaskResourceMutationVariables = Exact<{
  action_id: Scalars['uuid'];
  resource_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
  usage?: Maybe<Usage_Types_Enum>;
  value?: Maybe<Scalars['float8']>;
  previous_usage_id?: Maybe<Scalars['uuid']>;
}>;


export type AddTaskResourceMutation = (
  { __typename?: 'mutation_root' }
  & { insert_actions_resources_one?: Maybe<(
    { __typename?: 'actions_resources' }
    & ActionsResourcesFragment
  )>, insert_resource_usage_in_tasks_one?: Maybe<(
    { __typename?: 'resource_usage_in_tasks' }
    & UsedResourceFragment
  )> }
);

export type GetUsedResourcesInTaskQueryVariables = Exact<{
  task_id: Scalars['uuid'];
}>;


export type GetUsedResourcesInTaskQuery = (
  { __typename?: 'query_root' }
  & { resource_usage_in_tasks: Array<(
    { __typename?: 'resource_usage_in_tasks' }
    & UsedResourceFragment
  )> }
);

export type UpdateTaskResourceMutationVariables = Exact<{
  used_resource_id: Scalars['uuid'];
  value?: Maybe<Scalars['float8']>;
}>;


export type UpdateTaskResourceMutation = (
  { __typename?: 'mutation_root' }
  & { update_resource_usage_in_tasks_by_pk?: Maybe<(
    { __typename?: 'resource_usage_in_tasks' }
    & Pick<Resource_Usage_In_Tasks, 'value'>
  )> }
);

export type DeleteTaskResourceMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteTaskResourceMutation = (
  { __typename?: 'mutation_root' }
  & { delete_resource_usage_in_tasks_by_pk?: Maybe<(
    { __typename?: 'resource_usage_in_tasks' }
    & Pick<Resource_Usage_In_Tasks, 'id' | 'task_id'>
  )> }
);

export type GetTaskResourcesQueryVariables = Exact<{
  task_id: Scalars['uuid'];
}>;


export type GetTaskResourcesQuery = (
  { __typename?: 'query_root' }
  & { resource_usage_in_tasks: Array<(
    { __typename?: 'resource_usage_in_tasks' }
    & UsedResourceFragment
  )> }
);

export type GetActionBlueprintQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type GetActionBlueprintQuery = (
  { __typename?: 'query_root' }
  & { entry?: Maybe<(
    { __typename?: 'actions_blueprint_Entry' }
    & ActionBlueprintFragment
  ) | { __typename?: 'background_background_Entry' } | { __typename?: 'blogOverview_blogOverview_Entry' } | { __typename?: 'blog_article_Entry' } | { __typename?: 'catalog_catalog_Entry' } | { __typename?: 'frontpage_frontpage_Entry' } | { __typename?: 'offer_offer_Entry' } | { __typename?: 'pages_cookie_Entry' } | { __typename?: 'pages_default_Entry' } | { __typename?: 'producersOverview_producersOverview_Entry' } | { __typename?: 'product_product_Entry' }> }
);

export type GetActionBlueprintsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetActionBlueprintsQuery = (
  { __typename?: 'query_root' }
  & { entries?: Maybe<Array<Maybe<(
    { __typename?: 'actions_blueprint_Entry' }
    & Pick<Actions_Blueprint_Entry, 'id' | 'uid' | 'title'>
  ) | (
    { __typename?: 'background_background_Entry' }
    & Pick<Background_Background_Entry, 'id' | 'uid' | 'title'>
  ) | (
    { __typename?: 'blogOverview_blogOverview_Entry' }
    & Pick<BlogOverview_BlogOverview_Entry, 'id' | 'uid' | 'title'>
  ) | (
    { __typename?: 'blog_article_Entry' }
    & Pick<Blog_Article_Entry, 'id' | 'uid' | 'title'>
  ) | (
    { __typename?: 'catalog_catalog_Entry' }
    & Pick<Catalog_Catalog_Entry, 'id' | 'uid' | 'title'>
  ) | (
    { __typename?: 'frontpage_frontpage_Entry' }
    & Pick<Frontpage_Frontpage_Entry, 'id' | 'uid' | 'title'>
  ) | (
    { __typename?: 'offer_offer_Entry' }
    & Pick<Offer_Offer_Entry, 'id' | 'uid' | 'title'>
  ) | (
    { __typename?: 'pages_cookie_Entry' }
    & Pick<Pages_Cookie_Entry, 'id' | 'uid' | 'title'>
  ) | (
    { __typename?: 'pages_default_Entry' }
    & Pick<Pages_Default_Entry, 'id' | 'uid' | 'title'>
  ) | (
    { __typename?: 'producersOverview_producersOverview_Entry' }
    & Pick<ProducersOverview_ProducersOverview_Entry, 'id' | 'uid' | 'title'>
  ) | (
    { __typename?: 'product_product_Entry' }
    & Pick<Product_Product_Entry, 'id' | 'uid' | 'title'>
  )>>> }
);

export type ActionBlueprintFragment = (
  { __typename?: 'actions_blueprint_Entry' }
  & Pick<Actions_Blueprint_Entry, 'id' | 'uid' | 'title'>
  & { tasks?: Maybe<Array<Maybe<(
    { __typename?: 'tasks_taskGroup_BlockType' }
    & { children?: Maybe<Array<Maybe<{ __typename?: 'callToAction_ctaLink_BlockType' } | { __typename?: 'contentBlocks_block_BlockType' } | { __typename?: 'contentBlocks_card_BlockType' } | { __typename?: 'contentBlocks_cards_BlockType' } | { __typename?: 'contentBlocks_graphicSwitch_BlockType' } | { __typename?: 'contentBlocks_item_BlockType' } | { __typename?: 'contentBlocks_sideBySide_BlockType' } | { __typename?: 'contentBlocks_singleGraphic_BlockType' } | { __typename?: 'contentBlocks_textWithImage_BlockType' } | { __typename?: 'featuredContentBlock_ctaLink_BlockType' } | { __typename?: 'featuredContentBlock_featuredContent_BlockType' } | { __typename?: 'featuredContentBlock_featuredLatestProducers_BlockType' } | { __typename?: 'featuredContentBlock_featuredLatestProducts_BlockType' } | { __typename?: 'featuredContentBlock_producer_BlockType' } | { __typename?: 'featuredContentBlock_product_BlockType' } | { __typename?: 'productProcess_processGroup_BlockType' } | { __typename?: 'productProcess_process_BlockType' } | { __typename?: 'productProcess_timelineBreak_BlockType' } | { __typename?: 'productProcess_timelineItem_BlockType' } | (
      { __typename?: 'tasks_taskGroup_BlockType' }
      & { children?: Maybe<Array<Maybe<{ __typename?: 'callToAction_ctaLink_BlockType' } | { __typename?: 'contentBlocks_block_BlockType' } | { __typename?: 'contentBlocks_card_BlockType' } | { __typename?: 'contentBlocks_cards_BlockType' } | { __typename?: 'contentBlocks_graphicSwitch_BlockType' } | { __typename?: 'contentBlocks_item_BlockType' } | { __typename?: 'contentBlocks_sideBySide_BlockType' } | { __typename?: 'contentBlocks_singleGraphic_BlockType' } | { __typename?: 'contentBlocks_textWithImage_BlockType' } | { __typename?: 'featuredContentBlock_ctaLink_BlockType' } | { __typename?: 'featuredContentBlock_featuredContent_BlockType' } | { __typename?: 'featuredContentBlock_featuredLatestProducers_BlockType' } | { __typename?: 'featuredContentBlock_featuredLatestProducts_BlockType' } | { __typename?: 'featuredContentBlock_producer_BlockType' } | { __typename?: 'featuredContentBlock_product_BlockType' } | { __typename?: 'productProcess_processGroup_BlockType' } | { __typename?: 'productProcess_process_BlockType' } | { __typename?: 'productProcess_timelineBreak_BlockType' } | { __typename?: 'productProcess_timelineItem_BlockType' } | (
        { __typename?: 'tasks_taskGroup_BlockType' }
        & { children?: Maybe<Array<Maybe<{ __typename?: 'callToAction_ctaLink_BlockType' } | { __typename?: 'contentBlocks_block_BlockType' } | { __typename?: 'contentBlocks_card_BlockType' } | { __typename?: 'contentBlocks_cards_BlockType' } | { __typename?: 'contentBlocks_graphicSwitch_BlockType' } | { __typename?: 'contentBlocks_item_BlockType' } | { __typename?: 'contentBlocks_sideBySide_BlockType' } | { __typename?: 'contentBlocks_singleGraphic_BlockType' } | { __typename?: 'contentBlocks_textWithImage_BlockType' } | { __typename?: 'featuredContentBlock_ctaLink_BlockType' } | { __typename?: 'featuredContentBlock_featuredContent_BlockType' } | { __typename?: 'featuredContentBlock_featuredLatestProducers_BlockType' } | { __typename?: 'featuredContentBlock_featuredLatestProducts_BlockType' } | { __typename?: 'featuredContentBlock_producer_BlockType' } | { __typename?: 'featuredContentBlock_product_BlockType' } | { __typename?: 'productProcess_processGroup_BlockType' } | { __typename?: 'productProcess_process_BlockType' } | { __typename?: 'productProcess_timelineBreak_BlockType' } | { __typename?: 'productProcess_timelineItem_BlockType' } | (
          { __typename?: 'tasks_taskGroup_BlockType' }
          & { children?: Maybe<Array<Maybe<{ __typename?: 'callToAction_ctaLink_BlockType' } | { __typename?: 'contentBlocks_block_BlockType' } | { __typename?: 'contentBlocks_card_BlockType' } | { __typename?: 'contentBlocks_cards_BlockType' } | { __typename?: 'contentBlocks_graphicSwitch_BlockType' } | { __typename?: 'contentBlocks_item_BlockType' } | { __typename?: 'contentBlocks_sideBySide_BlockType' } | { __typename?: 'contentBlocks_singleGraphic_BlockType' } | { __typename?: 'contentBlocks_textWithImage_BlockType' } | { __typename?: 'featuredContentBlock_ctaLink_BlockType' } | { __typename?: 'featuredContentBlock_featuredContent_BlockType' } | { __typename?: 'featuredContentBlock_featuredLatestProducers_BlockType' } | { __typename?: 'featuredContentBlock_featuredLatestProducts_BlockType' } | { __typename?: 'featuredContentBlock_producer_BlockType' } | { __typename?: 'featuredContentBlock_product_BlockType' } | { __typename?: 'productProcess_processGroup_BlockType' } | { __typename?: 'productProcess_process_BlockType' } | { __typename?: 'productProcess_timelineBreak_BlockType' } | { __typename?: 'productProcess_timelineItem_BlockType' } | (
            { __typename?: 'tasks_taskGroup_BlockType' }
            & { children?: Maybe<Array<Maybe<{ __typename?: 'callToAction_ctaLink_BlockType' } | { __typename?: 'contentBlocks_block_BlockType' } | { __typename?: 'contentBlocks_card_BlockType' } | { __typename?: 'contentBlocks_cards_BlockType' } | { __typename?: 'contentBlocks_graphicSwitch_BlockType' } | { __typename?: 'contentBlocks_item_BlockType' } | { __typename?: 'contentBlocks_sideBySide_BlockType' } | { __typename?: 'contentBlocks_singleGraphic_BlockType' } | { __typename?: 'contentBlocks_textWithImage_BlockType' } | { __typename?: 'featuredContentBlock_ctaLink_BlockType' } | { __typename?: 'featuredContentBlock_featuredContent_BlockType' } | { __typename?: 'featuredContentBlock_featuredLatestProducers_BlockType' } | { __typename?: 'featuredContentBlock_featuredLatestProducts_BlockType' } | { __typename?: 'featuredContentBlock_producer_BlockType' } | { __typename?: 'featuredContentBlock_product_BlockType' } | { __typename?: 'productProcess_processGroup_BlockType' } | { __typename?: 'productProcess_process_BlockType' } | { __typename?: 'productProcess_timelineBreak_BlockType' } | { __typename?: 'productProcess_timelineItem_BlockType' } | (
              { __typename?: 'tasks_taskGroup_BlockType' }
              & { children?: Maybe<Array<Maybe<{ __typename?: 'callToAction_ctaLink_BlockType' } | { __typename?: 'contentBlocks_block_BlockType' } | { __typename?: 'contentBlocks_card_BlockType' } | { __typename?: 'contentBlocks_cards_BlockType' } | { __typename?: 'contentBlocks_graphicSwitch_BlockType' } | { __typename?: 'contentBlocks_item_BlockType' } | { __typename?: 'contentBlocks_sideBySide_BlockType' } | { __typename?: 'contentBlocks_singleGraphic_BlockType' } | { __typename?: 'contentBlocks_textWithImage_BlockType' } | { __typename?: 'featuredContentBlock_ctaLink_BlockType' } | { __typename?: 'featuredContentBlock_featuredContent_BlockType' } | { __typename?: 'featuredContentBlock_featuredLatestProducers_BlockType' } | { __typename?: 'featuredContentBlock_featuredLatestProducts_BlockType' } | { __typename?: 'featuredContentBlock_producer_BlockType' } | { __typename?: 'featuredContentBlock_product_BlockType' } | { __typename?: 'productProcess_processGroup_BlockType' } | { __typename?: 'productProcess_process_BlockType' } | { __typename?: 'productProcess_timelineBreak_BlockType' } | { __typename?: 'productProcess_timelineItem_BlockType' } | (
                { __typename?: 'tasks_taskGroup_BlockType' }
                & { children?: Maybe<Array<Maybe<{ __typename?: 'callToAction_ctaLink_BlockType' } | { __typename?: 'contentBlocks_block_BlockType' } | { __typename?: 'contentBlocks_card_BlockType' } | { __typename?: 'contentBlocks_cards_BlockType' } | { __typename?: 'contentBlocks_graphicSwitch_BlockType' } | { __typename?: 'contentBlocks_item_BlockType' } | { __typename?: 'contentBlocks_sideBySide_BlockType' } | { __typename?: 'contentBlocks_singleGraphic_BlockType' } | { __typename?: 'contentBlocks_textWithImage_BlockType' } | { __typename?: 'featuredContentBlock_ctaLink_BlockType' } | { __typename?: 'featuredContentBlock_featuredContent_BlockType' } | { __typename?: 'featuredContentBlock_featuredLatestProducers_BlockType' } | { __typename?: 'featuredContentBlock_featuredLatestProducts_BlockType' } | { __typename?: 'featuredContentBlock_producer_BlockType' } | { __typename?: 'featuredContentBlock_product_BlockType' } | { __typename?: 'productProcess_processGroup_BlockType' } | { __typename?: 'productProcess_process_BlockType' } | { __typename?: 'productProcess_timelineBreak_BlockType' } | { __typename?: 'productProcess_timelineItem_BlockType' } | (
                  { __typename?: 'tasks_taskGroup_BlockType' }
                  & { children?: Maybe<Array<Maybe<{ __typename?: 'callToAction_ctaLink_BlockType' } | { __typename?: 'contentBlocks_block_BlockType' } | { __typename?: 'contentBlocks_card_BlockType' } | { __typename?: 'contentBlocks_cards_BlockType' } | { __typename?: 'contentBlocks_graphicSwitch_BlockType' } | { __typename?: 'contentBlocks_item_BlockType' } | { __typename?: 'contentBlocks_sideBySide_BlockType' } | { __typename?: 'contentBlocks_singleGraphic_BlockType' } | { __typename?: 'contentBlocks_textWithImage_BlockType' } | { __typename?: 'featuredContentBlock_ctaLink_BlockType' } | { __typename?: 'featuredContentBlock_featuredContent_BlockType' } | { __typename?: 'featuredContentBlock_featuredLatestProducers_BlockType' } | { __typename?: 'featuredContentBlock_featuredLatestProducts_BlockType' } | { __typename?: 'featuredContentBlock_producer_BlockType' } | { __typename?: 'featuredContentBlock_product_BlockType' } | { __typename?: 'productProcess_processGroup_BlockType' } | { __typename?: 'productProcess_process_BlockType' } | { __typename?: 'productProcess_timelineBreak_BlockType' } | { __typename?: 'productProcess_timelineItem_BlockType' } | (
                    { __typename?: 'tasks_taskGroup_BlockType' }
                    & { children?: Maybe<Array<Maybe<{ __typename?: 'callToAction_ctaLink_BlockType' } | { __typename?: 'contentBlocks_block_BlockType' } | { __typename?: 'contentBlocks_card_BlockType' } | { __typename?: 'contentBlocks_cards_BlockType' } | { __typename?: 'contentBlocks_graphicSwitch_BlockType' } | { __typename?: 'contentBlocks_item_BlockType' } | { __typename?: 'contentBlocks_sideBySide_BlockType' } | { __typename?: 'contentBlocks_singleGraphic_BlockType' } | { __typename?: 'contentBlocks_textWithImage_BlockType' } | { __typename?: 'featuredContentBlock_ctaLink_BlockType' } | { __typename?: 'featuredContentBlock_featuredContent_BlockType' } | { __typename?: 'featuredContentBlock_featuredLatestProducers_BlockType' } | { __typename?: 'featuredContentBlock_featuredLatestProducts_BlockType' } | { __typename?: 'featuredContentBlock_producer_BlockType' } | { __typename?: 'featuredContentBlock_product_BlockType' } | { __typename?: 'productProcess_processGroup_BlockType' } | { __typename?: 'productProcess_process_BlockType' } | { __typename?: 'productProcess_timelineBreak_BlockType' } | { __typename?: 'productProcess_timelineItem_BlockType' } | (
                      { __typename?: 'tasks_taskGroup_BlockType' }
                      & { children?: Maybe<Array<Maybe<{ __typename?: 'callToAction_ctaLink_BlockType' } | { __typename?: 'contentBlocks_block_BlockType' } | { __typename?: 'contentBlocks_card_BlockType' } | { __typename?: 'contentBlocks_cards_BlockType' } | { __typename?: 'contentBlocks_graphicSwitch_BlockType' } | { __typename?: 'contentBlocks_item_BlockType' } | { __typename?: 'contentBlocks_sideBySide_BlockType' } | { __typename?: 'contentBlocks_singleGraphic_BlockType' } | { __typename?: 'contentBlocks_textWithImage_BlockType' } | { __typename?: 'featuredContentBlock_ctaLink_BlockType' } | { __typename?: 'featuredContentBlock_featuredContent_BlockType' } | { __typename?: 'featuredContentBlock_featuredLatestProducers_BlockType' } | { __typename?: 'featuredContentBlock_featuredLatestProducts_BlockType' } | { __typename?: 'featuredContentBlock_producer_BlockType' } | { __typename?: 'featuredContentBlock_product_BlockType' } | { __typename?: 'productProcess_processGroup_BlockType' } | { __typename?: 'productProcess_process_BlockType' } | { __typename?: 'productProcess_timelineBreak_BlockType' } | { __typename?: 'productProcess_timelineItem_BlockType' } | (
                        { __typename?: 'tasks_taskGroup_BlockType' }
                        & TaskGroupFragment
                      ) | (
                        { __typename?: 'tasks_task_BlockType' }
                        & TaskItemFragment
                      )>>> }
                      & TaskGroupFragment
                    ) | (
                      { __typename?: 'tasks_task_BlockType' }
                      & TaskItemFragment
                    )>>> }
                    & TaskGroupFragment
                  ) | (
                    { __typename?: 'tasks_task_BlockType' }
                    & TaskItemFragment
                  )>>> }
                  & TaskGroupFragment
                ) | (
                  { __typename?: 'tasks_task_BlockType' }
                  & TaskItemFragment
                )>>> }
                & TaskGroupFragment
              ) | (
                { __typename?: 'tasks_task_BlockType' }
                & TaskItemFragment
              )>>> }
              & TaskGroupFragment
            ) | (
              { __typename?: 'tasks_task_BlockType' }
              & TaskItemFragment
            )>>> }
            & TaskGroupFragment
          ) | (
            { __typename?: 'tasks_task_BlockType' }
            & TaskItemFragment
          )>>> }
          & TaskGroupFragment
        ) | (
          { __typename?: 'tasks_task_BlockType' }
          & TaskItemFragment
        )>>> }
        & TaskGroupFragment
      ) | (
        { __typename?: 'tasks_task_BlockType' }
        & TaskItemFragment
      )>>> }
      & TaskGroupFragment
    ) | (
      { __typename?: 'tasks_task_BlockType' }
      & TaskItemFragment
    )>>> }
    & TaskGroupFragment
  ) | (
    { __typename?: 'tasks_task_BlockType' }
    & TaskItemFragment
  )>>> }
);

export type TaskItemFragment = (
  { __typename?: 'tasks_task_BlockType' }
  & Pick<Tasks_Task_BlockType, 'id' | 'uid' | 'typeHandle' | 'status'>
  & { name: Tasks_Task_BlockType['taskName'], information: Tasks_Task_BlockType['taskInformation'] }
);

export type TaskGroupFragment = (
  { __typename?: 'tasks_taskGroup_BlockType' }
  & Pick<Tasks_TaskGroup_BlockType, 'id' | 'uid' | 'typeHandle' | 'status'>
  & { name: Tasks_TaskGroup_BlockType['taskName'], information: Tasks_TaskGroup_BlockType['taskInformation'] }
);

export type GetUnusedCategoryTypesQueryVariables = Exact<{
  producer_id: Scalars['uuid'];
}>;


export type GetUnusedCategoryTypesQuery = (
  { __typename?: 'query_root' }
  & { category_default_types: Array<(
    { __typename?: 'category_default_types' }
    & CategoryDefaultTypeFragment
  )> }
);

export type GetCategoryTypesQueryVariables = Exact<{
  producer_id: Scalars['uuid'];
}>;


export type GetCategoryTypesQuery = (
  { __typename?: 'query_root' }
  & { category_types: Array<(
    { __typename?: 'category_types' }
    & CategoryTypeFragment
  )> }
);

export type AddCategoryTypeMutationVariables = Exact<{
  object: Category_Types_Insert_Input;
}>;


export type AddCategoryTypeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_category_types_one?: Maybe<(
    { __typename?: 'category_types' }
    & CategoryTypeFragment
  )> }
);

export type UpdatCategoryTypeMutationVariables = Exact<{
  id: Scalars['uuid'];
  name: Scalars['String'];
}>;


export type UpdatCategoryTypeMutation = (
  { __typename?: 'mutation_root' }
  & { update_category_types_by_pk?: Maybe<(
    { __typename?: 'category_types' }
    & CategoryTypeFragment
  )> }
);

export type DeleteCategoryTypeMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteCategoryTypeMutation = (
  { __typename?: 'mutation_root' }
  & { delete_category_types_by_pk?: Maybe<(
    { __typename?: 'category_types' }
    & CategoryTypeFragment
  )> }
);

export type GetCategoryItemsQueryVariables = Exact<{
  type_id: Scalars['uuid'];
}>;


export type GetCategoryItemsQuery = (
  { __typename?: 'query_root' }
  & { category_items: Array<(
    { __typename?: 'category_items' }
    & CategoryItemFragment
  )> }
);

export type AddCategoryItemMutationVariables = Exact<{
  object: Category_Items_Insert_Input;
}>;


export type AddCategoryItemMutation = (
  { __typename?: 'mutation_root' }
  & { insert_category_items_one?: Maybe<(
    { __typename?: 'category_items' }
    & CategoryItemFragment
  )> }
);

export type UpdatCategoryItemMutationVariables = Exact<{
  id: Scalars['uuid'];
  name: Scalars['String'];
}>;


export type UpdatCategoryItemMutation = (
  { __typename?: 'mutation_root' }
  & { update_category_items_by_pk?: Maybe<(
    { __typename?: 'category_items' }
    & CategoryItemFragment
  )> }
);

export type DeleteCategoryItemMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteCategoryItemMutation = (
  { __typename?: 'mutation_root' }
  & { delete_category_items_by_pk?: Maybe<(
    { __typename?: 'category_items' }
    & CategoryItemFragment
  )> }
);

export type SendMessageMutationVariables = Exact<{
  object: Messages_Insert_Input;
}>;


export type SendMessageMutation = (
  { __typename?: 'mutation_root' }
  & { insert_messages_one?: Maybe<(
    { __typename: 'messages' }
    & Pick<Messages, 'id' | 'message' | 'user_name' | 'user_id' | 'timestamp' | 'producer_uid'>
  )> }
);

export type GetMessagesQueryVariables = Exact<{
  producer_uid: Scalars['String'];
}>;


export type GetMessagesQuery = (
  { __typename?: 'query_root' }
  & { messages: Array<(
    { __typename: 'messages' }
    & Pick<Messages, 'id' | 'message' | 'user_name' | 'user_id' | 'timestamp' | 'producer_uid'>
  )> }
);

export type GetMessagesLiveSubscriptionVariables = Exact<{
  producer_uid: Scalars['String'];
}>;


export type GetMessagesLiveSubscription = (
  { __typename?: 'subscription_root' }
  & { messages: Array<(
    { __typename: 'messages' }
    & Pick<Messages, 'id' | 'message' | 'user_name' | 'user_id' | 'timestamp' | 'producer_uid'>
  )> }
);

export type AddDatafieldMutationVariables = Exact<{
  object: Datafields_Insert_Input;
}>;


export type AddDatafieldMutation = (
  { __typename?: 'mutation_root' }
  & { insert_datafields_one?: Maybe<(
    { __typename?: 'datafields' }
    & DatafieldFragment
  )> }
);

export type UpdateDatafieldMutationVariables = Exact<{
  id: Scalars['uuid'];
  config: Scalars['jsonb'];
  version?: Maybe<Scalars['String']>;
  used_resource_id?: Maybe<Scalars['uuid']>;
}>;


export type UpdateDatafieldMutation = (
  { __typename?: 'mutation_root' }
  & { update_datafields_by_pk?: Maybe<(
    { __typename?: 'datafields' }
    & Pick<Datafields, 'id' | 'config' | 'order' | 'version'>
  )> }
);

export type UpdateTaskDatafieldsMutationVariables = Exact<{
  objects: Array<Datafields_Insert_Input> | Datafields_Insert_Input;
}>;


export type UpdateTaskDatafieldsMutation = (
  { __typename?: 'mutation_root' }
  & { insert_datafields?: Maybe<(
    { __typename?: 'datafields_mutation_response' }
    & Pick<Datafields_Mutation_Response, 'affected_rows'>
    & { returning: Array<(
      { __typename?: 'datafields' }
      & Pick<Datafields, 'id' | 'config' | 'order' | 'version'>
    )> }
  )> }
);

export type DeleteDatafieldMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteDatafieldMutation = (
  { __typename?: 'mutation_root' }
  & { delete_datafields_by_pk?: Maybe<(
    { __typename?: 'datafields' }
    & Pick<Datafields, 'id' | 'related_to' | 'task_id'>
  )> }
);

export type GetTaskDatafieldsQueryVariables = Exact<{
  id: Scalars['uuid'];
  parent?: Maybe<Uuid_Comparison_Exp>;
}>;


export type GetTaskDatafieldsQuery = (
  { __typename?: 'query_root' }
  & { datafields: Array<(
    { __typename?: 'datafields' }
    & DatafieldFragment
    & DatafieldLogsFragment
  )> }
);

export type GetDatafieldQueryVariables = Exact<{
  datafield_id: Scalars['uuid'];
}>;


export type GetDatafieldQuery = (
  { __typename?: 'query_root' }
  & { datafields_by_pk?: Maybe<(
    { __typename?: 'datafields' }
    & DatafieldFragment
  )> }
);

export type GetTaskDatafieldsLiveSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
  parent?: Maybe<Uuid_Comparison_Exp>;
}>;


export type GetTaskDatafieldsLiveSubscription = (
  { __typename?: 'subscription_root' }
  & { datafields: Array<(
    { __typename?: 'datafields' }
    & DatafieldFragment
    & DatafieldLogsFragment
  )> }
);

export type GetTaskStatusLiveSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
  parent?: Maybe<Uuid_Comparison_Exp>;
}>;


export type GetTaskStatusLiveSubscription = (
  { __typename?: 'subscription_root' }
  & { tasks_by_pk?: Maybe<(
    { __typename?: 'tasks' }
    & Pick<Tasks, 'id' | 'name' | 'next_task_id' | 'next_task_name' | 'next_task_level' | 'prev_task_id' | 'prev_task_name' | 'prev_task_level'>
    & { related_action: (
      { __typename?: 'actions' }
      & Pick<Actions, 'id' | 'name'>
      & { main_action_of_product?: Maybe<(
        { __typename?: 'products' }
        & Pick<Products, 'id' | 'name'>
      )>, product?: Maybe<(
        { __typename?: 'products' }
        & Pick<Products, 'id' | 'name'>
      )> }
    ), logs_aggregate: (
      { __typename?: 'logs_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'logs_aggregate_fields' }
        & Pick<Logs_Aggregate_Fields, 'count'>
      )>, nodes: Array<(
        { __typename?: 'logs' }
        & Pick<Logs, 'id' | 'data' | 'created_at' | 'created_by' | 'updated_at' | 'type'>
      )> }
    ) }
  )> }
);

export type GetFilesQueryVariables = Exact<{
  producer_id: Scalars['String'];
}>;


export type GetFilesQuery = (
  { __typename?: 'query_root' }
  & { files: Array<(
    { __typename?: 'files' }
    & FileFragment
  )> }
);

export type GetFileQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetFileQuery = (
  { __typename?: 'query_root' }
  & { files_by_pk?: Maybe<(
    { __typename?: 'files' }
    & FileFragment
  )> }
);

export type UploadFileMutationVariables = Exact<{
  object: Files_Insert_Input;
}>;


export type UploadFileMutation = (
  { __typename?: 'mutation_root' }
  & { insert_files_one?: Maybe<(
    { __typename?: 'files' }
    & FileFragment
  )> }
);

export type UploadPublicFileMutationVariables = Exact<{
  file?: Maybe<FileInput>;
}>;


export type UploadPublicFileMutation = (
  { __typename?: 'mutation_root' }
  & { save_mediaByProducers_Asset?: Maybe<(
    { __typename?: 'mediaByProducers_Asset' }
    & Pick<MediaByProducers_Asset, 'id'>
  )> }
);

export type UploadFilesMutationVariables = Exact<{
  objects: Array<Files_Insert_Input> | Files_Insert_Input;
}>;


export type UploadFilesMutation = (
  { __typename?: 'mutation_root' }
  & { insert_files?: Maybe<(
    { __typename?: 'files_mutation_response' }
    & { returning: Array<(
      { __typename?: 'files' }
      & FileFragment
    )> }
  )> }
);

export type UpdateFileMutationVariables = Exact<{
  id: Scalars['uuid'];
  originalname: Scalars['String'];
  key: Scalars['String'];
  related_to: Scalars['uuid'];
}>;


export type UpdateFileMutation = (
  { __typename?: 'mutation_root' }
  & { update_files_by_pk?: Maybe<(
    { __typename?: 'files' }
    & FileFragment
  )> }
);

export type DeleteFileMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteFileMutation = (
  { __typename?: 'mutation_root' }
  & { delete_files_by_pk?: Maybe<(
    { __typename?: 'files' }
    & Pick<Files, 'id'>
  )> }
);

export type ProducerFragment = (
  { __typename?: 'producers_Category' }
  & Pick<Producers_Category, 'id' | 'title' | 'uid'>
  & { producerLogo?: Maybe<Array<Maybe<(
    { __typename?: 'mediaByProducers_Asset' }
    & Pick<MediaByProducers_Asset, 'url'>
  ) | (
    { __typename?: 'media_Asset' }
    & Pick<Media_Asset, 'url'>
  )>>>, introImage?: Maybe<Array<Maybe<(
    { __typename?: 'mediaByProducers_Asset' }
    & Pick<MediaByProducers_Asset, 'url' | 'kind'>
    & { embeddedAsset?: Maybe<(
      { __typename?: 'EmbeddedAsset' }
      & Pick<EmbeddedAsset, 'image'>
    )> }
  ) | (
    { __typename?: 'media_Asset' }
    & Pick<Media_Asset, 'url' | 'kind'>
    & { embeddedAsset?: Maybe<(
      { __typename?: 'EmbeddedAsset' }
      & Pick<EmbeddedAsset, 'image'>
    )> }
  )>>> }
);

export type ProductFragment = (
  { __typename?: 'products' }
  & Pick<Products, 'id' | 'name' | 'description' | 'producer_id' | 'deleted_at' | 'main_action_id'>
  & { preliminary_products: Array<(
    { __typename?: 'products_preliminary' }
    & { preliminary_product: (
      { __typename?: 'products' }
      & Pick<Products, 'id' | 'name'>
    ) }
  )>, is_preliminary_product_of: Array<(
    { __typename?: 'products_preliminary' }
    & { product: (
      { __typename?: 'products' }
      & Pick<Products, 'id' | 'name'>
    ) }
  )>, main_action?: Maybe<(
    { __typename?: 'actions' }
    & Pick<Actions, 'id' | 'name'>
  )> }
);

export type ActionFragment = (
  { __typename?: 'actions' }
  & Pick<Actions, 'id' | 'name' | 'note' | 'related_to' | 'deleted_at' | 'updated_at' | 'result_base_qty'>
  & { result_packaging?: Maybe<(
    { __typename?: 'resources' }
    & Pick<Resources, 'id' | 'name'>
  )>, result_resource?: Maybe<(
    { __typename?: 'resources' }
    & Pick<Resources, 'id' | 'name'>
  )>, tasks: Array<(
    { __typename?: 'tasks' }
    & TaskFragment
  )>, unsorted_tasks: Array<(
    { __typename?: 'tasks' }
    & TaskFragment
  )>, unsorted_tasks_aggregate: (
    { __typename?: 'tasks_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'tasks_aggregate_fields' }
      & Pick<Tasks_Aggregate_Fields, 'count'>
    )> }
  ), main_action_of_product?: Maybe<(
    { __typename?: 'products' }
    & Pick<Products, 'id' | 'name'>
  )>, product?: Maybe<(
    { __typename?: 'products' }
    & Pick<Products, 'id' | 'name'>
  )> }
);

export type TaskFragment = (
  { __typename?: 'tasks' }
  & Pick<Tasks, 'id' | 'producer_id' | 'order' | 'name' | 'note' | 'parent' | 'related_to' | 'deleted_at' | 'updated_at' | 'hierachy' | 'prev_task_id' | 'prev_task_name' | 'prev_task_level' | 'next_task_id' | 'next_task_name' | 'next_task_level'>
  & { task_flatten?: Maybe<(
    { __typename?: 'tasks_flatten' }
    & Pick<Tasks_Flatten, 'level' | 'name_path'>
  )>, category_usage_in_tasks: Array<(
    { __typename?: 'category_usage_in_tasks' }
    & { category: (
      { __typename?: 'category_items' }
      & Pick<Category_Items, 'id' | 'name'>
    ) }
  )>, category_usage_in_tasks_aggregate: (
    { __typename?: 'category_usage_in_tasks_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'category_usage_in_tasks_aggregate_fields' }
      & Pick<Category_Usage_In_Tasks_Aggregate_Fields, 'count'>
    )> }
  ), datafields_aggregate: (
    { __typename?: 'datafields_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'datafields_aggregate_fields' }
      & Pick<Datafields_Aggregate_Fields, 'count'>
    )> }
  ), children_aggregate: (
    { __typename?: 'tasks_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'tasks_aggregate_fields' }
      & Pick<Tasks_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type DatafieldFragment = (
  { __typename?: 'datafields' }
  & Pick<Datafields, 'id' | 'type' | 'order' | 'config' | 'task_id' | 'related_to' | 'created_at' | 'updated_at' | 'version'>
);

export type DatafieldLogsFragment = (
  { __typename?: 'datafields' }
  & { logs_aggregate: (
    { __typename?: 'logs_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'logs_aggregate_fields' }
      & Pick<Logs_Aggregate_Fields, 'count'>
    )>, nodes: Array<(
      { __typename?: 'logs' }
      & Pick<Logs, 'id' | 'data' | 'created_at' | 'created_by' | 'updated_at' | 'type'>
    )> }
  ) }
);

export type FileFragment = (
  { __typename?: 'files' }
  & Pick<Files, 'id' | 'originalname' | 'mimetype' | 'key' | 'related_to'>
  & { log?: Maybe<(
    { __typename?: 'logs' }
    & Pick<Logs, 'id' | 'parent'>
    & { related_task?: Maybe<(
      { __typename?: 'tasks' }
      & Pick<Tasks, 'id' | 'name'>
      & { related_action: (
        { __typename?: 'actions' }
        & Pick<Actions, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type LocationFragment = (
  { __typename?: 'category_locations' }
  & Pick<Category_Locations, 'id' | 'name' | 'producer_id'>
  & { tasks_aggregate: (
    { __typename?: 'tasks_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'tasks_aggregate_fields' }
      & Pick<Tasks_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type MachineFragment = (
  { __typename?: 'category_machines' }
  & Pick<Category_Machines, 'id' | 'name' | 'producer_id'>
  & { tasks_aggregate: (
    { __typename?: 'tasks_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'tasks_aggregate_fields' }
      & Pick<Tasks_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type CategoryDefaultTypeFragment = (
  { __typename?: 'category_default_types' }
  & Pick<Category_Default_Types, 'value' | 'comment' | '_used'>
);

export type CategoryTypeFragment = (
  { __typename?: 'category_types' }
  & Pick<Category_Types, 'id' | 'name' | 'default_type' | 'producer_id' | '_deleted'>
  & { items: Array<(
    { __typename?: 'category_items' }
    & CategoryItemFragment
  )>, items_aggregate: (
    { __typename?: 'category_items_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'category_items_aggregate_fields' }
      & Pick<Category_Items_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type CategoryItemFragment = (
  { __typename?: 'category_items' }
  & Pick<Category_Items, 'id' | 'name' | 'producer_id' | 'type_id' | '_deleted'>
);

export type LogFragment = (
  { __typename?: 'logs' }
  & Pick<Logs, 'id' | 'data' | 'created_at' | 'created_by' | 'updated_at' | 'type' | 'task_id' | 'related_to' | 'parent' | 'datafield_id'>
  & { related_datafield?: Maybe<(
    { __typename?: 'datafields' }
    & Pick<Datafields, 'id'>
    & { related_task: (
      { __typename?: 'tasks' }
      & Pick<Tasks, 'id'>
      & { related_action: (
        { __typename?: 'actions' }
        & Pick<Actions, 'id'>
      ) }
    ) }
  )> }
);

export type UsedCategoryItemFragment = (
  { __typename?: 'category_usage_in_tasks' }
  & Pick<Category_Usage_In_Tasks, 'id' | 'usage' | 'action_id' | 'task_id'>
  & { actions_category?: Maybe<(
    { __typename?: 'actions_category_items' }
    & Pick<Actions_Category_Items, 'action_id' | 'category_id'>
  )>, category: (
    { __typename?: 'category_items' }
    & CategoryItemFragment
  ) }
);

export type ActionsCategoryItemsFragment = (
  { __typename?: 'actions_category_items' }
  & Pick<Actions_Category_Items, 'action_id' | 'category_id'>
  & { category: (
    { __typename?: 'category_items' }
    & CategoryItemFragment
  ) }
);

export type ResourceDefaultTypeFragment = (
  { __typename?: 'resource_default_types' }
  & Pick<Resource_Default_Types, 'value' | 'comment' | '_used'>
);

export type ResourceTypeFragment = (
  { __typename?: 'resource_types' }
  & Pick<Resource_Types, 'id' | 'name' | 'default_type' | 'producer_id' | '_deleted'>
  & { resources: Array<(
    { __typename?: 'resources' }
    & ResourceItemFragment
  )>, resources_aggregate: (
    { __typename?: 'resources_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'resources_aggregate_fields' }
      & Pick<Resources_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type ResourceItemFragment = (
  { __typename?: 'resources' }
  & Pick<Resources, 'id' | 'name' | 'producer_id' | 'type_id' | '_deleted'>
  & { type?: Maybe<(
    { __typename?: 'resource_types' }
    & Pick<Resource_Types, 'id' | 'name' | 'default_type'>
  )> }
);

export type UsedResourceFragment = (
  { __typename?: 'resource_usage_in_tasks' }
  & Pick<Resource_Usage_In_Tasks, 'id' | 'value' | 'usage' | 'action_id' | 'task_id' | 'resource_id' | 'previous_usage_id'>
  & { actions_resource?: Maybe<(
    { __typename?: 'actions_resources' }
    & Pick<Actions_Resources, 'action_id' | 'resource_id' | 'unit' | 'usage'>
  )>, resource: (
    { __typename?: 'resources' }
    & ResourceItemFragment
  ) }
);

export type ActionsResourcesFragment = (
  { __typename?: 'actions_resources' }
  & Pick<Actions_Resources, 'action_id' | 'resource_id' | 'usage' | 'unit'>
  & { resource: (
    { __typename?: 'resources' }
    & ResourceItemFragment
  ) }
);

export type GetLogsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetLogsQuery = (
  { __typename?: 'query_root' }
  & { logs: Array<(
    { __typename?: 'logs' }
    & LogFragment
  )> }
);

export type GetActionLogsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetActionLogsQuery = (
  { __typename?: 'query_root' }
  & { actions_by_pk?: Maybe<(
    { __typename?: 'actions' }
    & Pick<Actions, 'id' | 'name'>
    & { tasks_aggregate: (
      { __typename?: 'tasks_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'tasks_aggregate_fields' }
        & Pick<Tasks_Aggregate_Fields, 'count'>
      )> }
    ) }
  )>, logs: Array<(
    { __typename?: 'logs' }
    & Pick<Logs, 'id' | 'created_at' | 'updated_at'>
    & { children_aggregate: (
      { __typename?: 'logs_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'logs_aggregate_fields' }
        & Pick<Logs_Aggregate_Fields, 'count'>
      )> }
    ), logged_tasks: (
      { __typename?: 'logs_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'logs_aggregate_fields' }
        & Pick<Logs_Aggregate_Fields, 'count'>
      )> }
    ) }
  )>, completed_logs: Array<(
    { __typename?: 'logs' }
    & Pick<Logs, 'id' | 'created_at' | 'updated_at'>
    & { children_aggregate: (
      { __typename?: 'logs_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'logs_aggregate_fields' }
        & Pick<Logs_Aggregate_Fields, 'count'>
      )> }
    ), logged_tasks: (
      { __typename?: 'logs_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'logs_aggregate_fields' }
        & Pick<Logs_Aggregate_Fields, 'count'>
      )> }
    ) }
  )>, datafields_aggregate: (
    { __typename?: 'datafields_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'datafields_aggregate_fields' }
      & Pick<Datafields_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type GetActionLogQueryVariables = Exact<{
  action_id: Scalars['uuid'];
  log_id: Scalars['uuid'];
}>;


export type GetActionLogQuery = (
  { __typename?: 'query_root' }
  & { actions_by_pk?: Maybe<(
    { __typename?: 'actions' }
    & { tasks_datafields_aggregate: Array<(
      { __typename?: 'tasks' }
      & Pick<Tasks, 'id' | 'name'>
      & { datafields_aggregate: (
        { __typename?: 'datafields_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'datafields_aggregate_fields' }
          & Pick<Datafields_Aggregate_Fields, 'count'>
        )> }
      ) }
    )>, tasks_aggregate: (
      { __typename?: 'tasks_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'tasks_aggregate_fields' }
        & Pick<Tasks_Aggregate_Fields, 'count'>
      )> }
    ), datafields_aggregate: (
      { __typename?: 'datafields_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'datafields_aggregate_fields' }
        & Pick<Datafields_Aggregate_Fields, 'count'>
      )> }
    ), tasks: Array<(
      { __typename?: 'tasks' }
      & { recent_logs: Array<(
        { __typename?: 'logs' }
        & Pick<Logs, 'id' | 'created_at' | 'updated_at' | 'data'>
      )> }
      & TaskFragment
    )>, last_task: Array<(
      { __typename?: 'tasks_flatten' }
      & Pick<Tasks_Flatten, 'id' | 'name'>
    )> }
    & ActionFragment
  )>, logs_by_pk?: Maybe<(
    { __typename?: 'logs' }
    & Pick<Logs, 'id' | 'created_at' | 'updated_at' | 'data' | 'completed'>
    & { children: Array<(
      { __typename?: 'logs' }
      & Pick<Logs, 'id' | 'created_at' | 'created_by' | 'data'>
      & { related_task?: Maybe<(
        { __typename?: 'tasks' }
        & Pick<Tasks, 'id' | 'name'>
      )>, related_datafield?: Maybe<(
        { __typename?: 'datafields' }
        & Pick<Datafields, 'id' | 'type'>
        & { related_task: (
          { __typename?: 'tasks' }
          & Pick<Tasks, 'id' | 'name'>
        ) }
      )> }
    )>, children_aggregate: (
      { __typename?: 'logs_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'logs_aggregate_fields' }
        & Pick<Logs_Aggregate_Fields, 'count'>
      )> }
    ), logged_tasks: (
      { __typename?: 'logs_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'logs_aggregate_fields' }
        & Pick<Logs_Aggregate_Fields, 'count'>
      )> }
    ) }
  )> }
);

export type CloseActionLogMutationVariables = Exact<{
  log_id: Scalars['uuid'];
  qty_result: Scalars['Int'];
}>;


export type CloseActionLogMutation = (
  { __typename?: 'mutation_root' }
  & { update_logs_by_pk?: Maybe<(
    { __typename?: 'logs' }
    & Pick<Logs, 'id' | 'completed'>
  )> }
);

export type GetLogsInsightSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type GetLogsInsightSubscription = (
  { __typename?: 'subscription_root' }
  & { products: Array<(
    { __typename?: 'products' }
    & Pick<Products, 'name'>
    & { actions: Array<(
      { __typename?: 'actions' }
      & Pick<Actions, 'name'>
      & { tasks: Array<(
        { __typename?: 'tasks' }
        & Pick<Tasks, 'id' | 'name'>
        & { related_action: (
          { __typename?: 'actions' }
          & Pick<Actions, 'id' | 'name'>
        ), datafields_aggregate: (
          { __typename?: 'datafields_aggregate' }
          & { aggregate?: Maybe<(
            { __typename?: 'datafields_aggregate_fields' }
            & Pick<Datafields_Aggregate_Fields, 'count'>
          )> }
        ), task_flatten?: Maybe<(
          { __typename?: 'tasks_flatten' }
          & Pick<Tasks_Flatten, 'name_path'>
        )> }
      )>, tasks_aggregate: (
        { __typename?: 'tasks_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'tasks_aggregate_fields' }
          & Pick<Tasks_Aggregate_Fields, 'count'>
        )> }
      ), datafields_aggregate: (
        { __typename?: 'datafields_aggregate' }
        & { aggregate?: Maybe<(
          { __typename?: 'datafields_aggregate_fields' }
          & Pick<Datafields_Aggregate_Fields, 'count'>
        )> }
      ), action_resources: Array<(
        { __typename?: 'actions_resources' }
        & Pick<Actions_Resources, 'action_id' | 'resource_id' | 'unit' | 'usage'>
        & { resource: (
          { __typename?: 'resources' }
          & Pick<Resources, 'id' | 'name'>
          & { type?: Maybe<(
            { __typename?: 'resource_types' }
            & Pick<Resource_Types, 'id' | 'name'>
          )> }
        ) }
      )>, actions_category_items: Array<(
        { __typename?: 'actions_category_items' }
        & Pick<Actions_Category_Items, 'action_id' | 'category_id'>
        & { category: (
          { __typename?: 'category_items' }
          & Pick<Category_Items, 'id' | 'name'>
          & { type?: Maybe<(
            { __typename?: 'category_types' }
            & Pick<Category_Types, 'id' | 'name'>
          )> }
        ) }
      )>, logs: Array<(
        { __typename?: 'logs' }
        & Pick<Logs, 'id' | 'created_at' | 'updated_at' | 'completed'>
        & { children: Array<(
          { __typename?: 'logs' }
          & Pick<Logs, 'id' | 'created_at' | 'created_by' | 'data'>
          & { related_task?: Maybe<(
            { __typename?: 'tasks' }
            & Pick<Tasks, 'id' | 'name'>
          )>, related_datafield?: Maybe<(
            { __typename?: 'datafields' }
            & Pick<Datafields, 'id' | 'type'>
            & { related_task: (
              { __typename?: 'tasks' }
              & Pick<Tasks, 'id' | 'name'>
            ) }
          )> }
        )>, children_aggregate: (
          { __typename?: 'logs_aggregate' }
          & { aggregate?: Maybe<(
            { __typename?: 'logs_aggregate_fields' }
            & Pick<Logs_Aggregate_Fields, 'count'>
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type GetLatestLogQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetLatestLogQuery = (
  { __typename?: 'query_root' }
  & { latest_log: Array<(
    { __typename?: 'logs' }
    & Pick<Logs, 'id'>
    & { related_action?: Maybe<(
      { __typename?: 'actions' }
      & Pick<Actions, 'id'>
      & { tasks_flatten: Array<(
        { __typename?: 'tasks_flatten' }
        & Pick<Tasks_Flatten, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type GetLogTaskToResumeQueryVariables = Exact<{
  parent_log_id: Scalars['uuid'];
}>;


export type GetLogTaskToResumeQuery = (
  { __typename?: 'query_root' }
  & { logs_by_pk?: Maybe<(
    { __typename?: 'logs' }
    & Pick<Logs, 'id'>
    & { related_action?: Maybe<(
      { __typename?: 'actions' }
      & Pick<Actions, 'id'>
      & { tasks_flatten: Array<(
        { __typename?: 'tasks_flatten' }
        & Pick<Tasks_Flatten, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type UpdateBatchSettingsMutationVariables = Exact<{
  log_id: Scalars['uuid'];
  data: Scalars['jsonb'];
}>;


export type UpdateBatchSettingsMutation = (
  { __typename?: 'mutation_root' }
  & { update_logs_by_pk?: Maybe<(
    { __typename?: 'logs' }
    & Pick<Logs, 'id' | 'updated_at' | 'data'>
  )> }
);

export type AddNewLogMutationVariables = Exact<{
  object: Logs_Insert_Input;
}>;


export type AddNewLogMutation = (
  { __typename?: 'mutation_root' }
  & { insert_logs_one?: Maybe<(
    { __typename?: 'logs' }
    & LogFragment
  )> }
);

export type AddDatafieldLogMutationVariables = Exact<{
  parent_log_id: Scalars['uuid'];
  datafield_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
  data: Scalars['jsonb'];
}>;


export type AddDatafieldLogMutation = (
  { __typename?: 'mutation_root' }
  & { update_logs_by_pk?: Maybe<(
    { __typename?: 'logs' }
    & Pick<Logs, 'id' | 'updated_at' | 'related_to'>
  )>, insert_logs_one?: Maybe<(
    { __typename?: 'logs' }
    & Pick<Logs, 'id'>
  )> }
);

export type DeleteDatafieldLogMutationVariables = Exact<{
  log_id: Scalars['uuid'];
}>;


export type DeleteDatafieldLogMutation = (
  { __typename?: 'mutation_root' }
  & { delete_logs_by_pk?: Maybe<(
    { __typename?: 'logs' }
    & Pick<Logs, 'id'>
  )> }
);

export type AddTaskLogMutationVariables = Exact<{
  parent_log_id: Scalars['uuid'];
  action_id: Scalars['uuid'];
  task_id: Scalars['uuid'];
  data: Scalars['jsonb'];
}>;


export type AddTaskLogMutation = (
  { __typename?: 'mutation_root' }
  & { update_logs_by_pk?: Maybe<(
    { __typename?: 'logs' }
    & Pick<Logs, 'id' | 'updated_at' | 'related_to'>
  )>, insert_logs_one?: Maybe<(
    { __typename?: 'logs' }
    & Pick<Logs, 'id'>
  )> }
);

export type GetProducerQueryVariables = Exact<{
  uid?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;


export type GetProducerQuery = (
  { __typename?: 'query_root' }
  & { producer?: Maybe<{ __typename?: 'certificates_Category' } | { __typename?: 'machines_Category' } | (
    { __typename?: 'producers_Category' }
    & ProducerFragment
  ) | { __typename?: 'productType_Category' } | { __typename?: 'regions_Category' }> }
);

export type GetProducersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetProducersQuery = (
  { __typename?: 'query_root' }
  & { producers?: Maybe<Array<Maybe<{ __typename?: 'certificates_Category' } | { __typename?: 'machines_Category' } | (
    { __typename?: 'producers_Category' }
    & ProducerFragment
  ) | { __typename?: 'productType_Category' } | { __typename?: 'regions_Category' }>>> }
);

export type CreateProducerMutationVariables = Exact<{
  title: Scalars['String'];
}>;


export type CreateProducerMutation = (
  { __typename?: 'mutation_root' }
  & { save_producers_Category?: Maybe<(
    { __typename?: 'producers_Category' }
    & ProducerFragment
  )> }
);

export type UpdateProducerMutationVariables = Exact<{
  uid: Scalars['String'];
  title: Scalars['String'];
}>;


export type UpdateProducerMutation = (
  { __typename?: 'mutation_root' }
  & { save_producers_Category?: Maybe<(
    { __typename?: 'producers_Category' }
    & ProducerFragment
  )> }
);

export type DeleteProducerMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteProducerMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Mutation_Root, 'deleteCategory'>
);

export type GetProductsQueryVariables = Exact<{
  producer_id: Scalars['String'];
}>;


export type GetProductsQuery = (
  { __typename?: 'query_root' }
  & { products: Array<(
    { __typename?: 'products' }
    & ProductFragment
  )> }
);

export type SearchProductsQueryVariables = Exact<{
  search: Scalars['String'];
}>;


export type SearchProductsQuery = (
  { __typename?: 'query_root' }
  & { search_products: Array<(
    { __typename?: 'products' }
    & Pick<Products, 'id' | 'name'>
  )> }
);

export type GetProductQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetProductQuery = (
  { __typename?: 'query_root' }
  & { products_by_pk?: Maybe<(
    { __typename?: 'products' }
    & ProductFragment
  )>, actions_aggregate: (
    { __typename?: 'actions_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'actions_aggregate_fields' }
      & Pick<Actions_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type CreateProductMutationVariables = Exact<{
  object: Products_Insert_Input;
}>;


export type CreateProductMutation = (
  { __typename?: 'mutation_root' }
  & { insert_products_one?: Maybe<(
    { __typename?: 'products' }
    & ProductFragment
  )> }
);

export type UpdateProductMutationVariables = Exact<{
  id: Scalars['uuid'];
  _set: Products_Set_Input;
}>;


export type UpdateProductMutation = (
  { __typename?: 'mutation_root' }
  & { update_products_by_pk?: Maybe<(
    { __typename?: 'products' }
    & ProductFragment
  )> }
);

export type DeleteProductMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteProductMutation = (
  { __typename?: 'mutation_root' }
  & { update_products_by_pk?: Maybe<(
    { __typename?: 'products' }
    & Pick<Products, 'id' | 'deleted_at'>
  )> }
);

export type GetUnusedResourceTypesQueryVariables = Exact<{
  producer_id: Scalars['uuid'];
}>;


export type GetUnusedResourceTypesQuery = (
  { __typename?: 'query_root' }
  & { resource_default_types: Array<(
    { __typename?: 'resource_default_types' }
    & ResourceDefaultTypeFragment
  )> }
);

export type GetResourceTypesQueryVariables = Exact<{
  producer_id: Scalars['uuid'];
}>;


export type GetResourceTypesQuery = (
  { __typename?: 'query_root' }
  & { resource_types: Array<(
    { __typename?: 'resource_types' }
    & ResourceTypeFragment
  )> }
);

export type AddResourceTypeMutationVariables = Exact<{
  object: Resource_Types_Insert_Input;
}>;


export type AddResourceTypeMutation = (
  { __typename?: 'mutation_root' }
  & { insert_resource_types_one?: Maybe<(
    { __typename?: 'resource_types' }
    & ResourceTypeFragment
  )> }
);

export type UpdatResourceTypeMutationVariables = Exact<{
  id: Scalars['uuid'];
  name: Scalars['String'];
}>;


export type UpdatResourceTypeMutation = (
  { __typename?: 'mutation_root' }
  & { update_resource_types_by_pk?: Maybe<(
    { __typename?: 'resource_types' }
    & ResourceTypeFragment
  )> }
);

export type DeleteResourceTypeMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteResourceTypeMutation = (
  { __typename?: 'mutation_root' }
  & { delete_resource_types_by_pk?: Maybe<(
    { __typename?: 'resource_types' }
    & ResourceTypeFragment
  )> }
);

export type GetResourceItemsQueryVariables = Exact<{
  type_id: Scalars['uuid'];
}>;


export type GetResourceItemsQuery = (
  { __typename?: 'query_root' }
  & { resources: Array<(
    { __typename?: 'resources' }
    & ResourceItemFragment
  )> }
);

export type AddResourceItemMutationVariables = Exact<{
  object: Resources_Insert_Input;
}>;


export type AddResourceItemMutation = (
  { __typename?: 'mutation_root' }
  & { insert_resources_one?: Maybe<(
    { __typename?: 'resources' }
    & ResourceItemFragment
  )> }
);

export type UpdatResourceItemMutationVariables = Exact<{
  id: Scalars['uuid'];
  name: Scalars['String'];
}>;


export type UpdatResourceItemMutation = (
  { __typename?: 'mutation_root' }
  & { update_resources_by_pk?: Maybe<(
    { __typename?: 'resources' }
    & ResourceItemFragment
  )> }
);

export type DeleteResourceItemMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type DeleteResourceItemMutation = (
  { __typename?: 'mutation_root' }
  & { delete_resources_by_pk?: Maybe<(
    { __typename?: 'resources' }
    & ResourceItemFragment
  )> }
);

export type GetLogsByTypeQueryVariables = Exact<{
  type?: Maybe<Scalars['String']>;
}>;


export type GetLogsByTypeQuery = (
  { __typename?: 'query_root' }
  & { logs: Array<(
    { __typename?: 'logs' }
    & Pick<Logs, 'id' | 'data' | 'created_at' | 'created_by'>
  )> }
);

export type GetLogsByKeyQueryVariables = Exact<{
  key?: Maybe<Scalars['String']>;
}>;


export type GetLogsByKeyQuery = (
  { __typename?: 'query_root' }
  & { logs: Array<(
    { __typename?: 'logs' }
    & Pick<Logs, 'id' | 'data' | 'created_at' | 'created_by'>
  )> }
);

export const TaskItemFragmentDoc = gql`
    fragment TaskItem on tasks_task_BlockType {
  id
  uid
  typeHandle
  name: taskName
  information: taskInformation
  status
}
    `;
export const TaskGroupFragmentDoc = gql`
    fragment TaskGroup on tasks_taskGroup_BlockType {
  id
  uid
  typeHandle
  name: taskName
  information: taskInformation
  status
}
    `;
export const ActionBlueprintFragmentDoc = gql`
    fragment ActionBlueprint on actions_blueprint_Entry {
  id
  uid
  title
  ... on actions_blueprint_Entry {
    tasks {
      ...TaskItem
      ...TaskGroup
      ... on tasks_taskGroup_BlockType {
        children {
          ...TaskItem
          ...TaskGroup
          ... on tasks_taskGroup_BlockType {
            children {
              ...TaskItem
              ...TaskGroup
              ... on tasks_taskGroup_BlockType {
                children {
                  ...TaskItem
                  ...TaskGroup
                  ... on tasks_taskGroup_BlockType {
                    children {
                      ...TaskItem
                      ...TaskGroup
                      ... on tasks_taskGroup_BlockType {
                        children {
                          ...TaskItem
                          ...TaskGroup
                          ... on tasks_taskGroup_BlockType {
                            children {
                              ...TaskItem
                              ...TaskGroup
                              ... on tasks_taskGroup_BlockType {
                                children {
                                  ...TaskItem
                                  ...TaskGroup
                                  ... on tasks_taskGroup_BlockType {
                                    children {
                                      ...TaskItem
                                      ...TaskGroup
                                      ... on tasks_taskGroup_BlockType {
                                        children {
                                          ...TaskItem
                                          ...TaskGroup
                                          ... on tasks_taskGroup_BlockType {
                                            children {
                                              ...TaskItem
                                              ...TaskGroup
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${TaskItemFragmentDoc}
${TaskGroupFragmentDoc}`;
export const ProducerFragmentDoc = gql`
    fragment Producer on producers_Category {
  id
  title
  uid
  producerLogo {
    url
  }
  introImage {
    url
    kind
    embeddedAsset {
      image
    }
  }
}
    `;
export const ProductFragmentDoc = gql`
    fragment Product on products {
  id
  name
  description
  producer_id
  deleted_at
  preliminary_products {
    preliminary_product {
      id
      name
    }
  }
  is_preliminary_product_of {
    product {
      id
      name
    }
  }
  main_action_id
  main_action {
    id
    name
  }
}
    `;
export const TaskFragmentDoc = gql`
    fragment Task on tasks {
  id
  producer_id
  order
  name
  note
  parent
  related_to
  deleted_at
  updated_at
  hierachy
  prev_task_id
  prev_task_name
  prev_task_level
  next_task_id
  next_task_name
  next_task_level
  task_flatten {
    level
    name_path
  }
  category_usage_in_tasks {
    category {
      id
      name
    }
  }
  category_usage_in_tasks_aggregate {
    aggregate {
      count
    }
  }
  datafields_aggregate {
    aggregate {
      count
    }
  }
  children_aggregate {
    aggregate {
      count
    }
  }
}
    `;
export const ActionFragmentDoc = gql`
    fragment Action on actions {
  id
  name
  note
  related_to
  deleted_at
  updated_at
  result_base_qty
  result_packaging {
    id
    name
  }
  result_resource {
    id
    name
  }
  tasks(
    where: {parent: {_is_null: true}}
    order_by: {task_flatten: {array_to_string: asc_nulls_last}}
  ) {
    ...Task
  }
  unsorted_tasks: tasks(
    order_by: {task_flatten: {array_to_string: asc_nulls_last}}
  ) {
    ...Task
  }
  unsorted_tasks_aggregate: tasks_aggregate(where: {_not: {children: {}}}) {
    aggregate {
      count
    }
  }
  main_action_of_product {
    id
    name
  }
  product {
    id
    name
  }
}
    ${TaskFragmentDoc}`;
export const DatafieldFragmentDoc = gql`
    fragment Datafield on datafields {
  id
  type
  order
  config
  task_id
  related_to
  created_at
  updated_at
  version
}
    `;
export const DatafieldLogsFragmentDoc = gql`
    fragment DatafieldLogs on datafields {
  logs_aggregate(where: {parent: $parent}, order_by: {created_at: asc}) {
    aggregate {
      count
    }
    nodes {
      id
      data
      created_at
      created_by
      updated_at
      type
    }
  }
}
    `;
export const FileFragmentDoc = gql`
    fragment File on files {
  id
  originalname
  mimetype
  key
  related_to
  log {
    id
    parent
    related_task {
      id
      name
      related_action {
        id
        name
      }
    }
  }
}
    `;
export const LocationFragmentDoc = gql`
    fragment Location on category_locations {
  id
  name
  producer_id
  tasks_aggregate {
    aggregate {
      count
    }
  }
}
    `;
export const MachineFragmentDoc = gql`
    fragment Machine on category_machines {
  id
  name
  producer_id
  tasks_aggregate {
    aggregate {
      count
    }
  }
}
    `;
export const CategoryDefaultTypeFragmentDoc = gql`
    fragment CategoryDefaultType on category_default_types {
  value
  comment
  _used @client
}
    `;
export const CategoryItemFragmentDoc = gql`
    fragment CategoryItem on category_items {
  id
  name
  producer_id
  type_id
  _deleted @client
}
    `;
export const CategoryTypeFragmentDoc = gql`
    fragment CategoryType on category_types {
  id
  name
  default_type
  producer_id
  items(order_by: {name: asc}) {
    ...CategoryItem
  }
  items_aggregate {
    aggregate {
      count
    }
  }
  _deleted @client
}
    ${CategoryItemFragmentDoc}`;
export const LogFragmentDoc = gql`
    fragment Log on logs {
  id
  data
  created_at
  created_by
  updated_at
  type
  task_id
  related_to
  parent
  datafield_id
  related_datafield {
    id
    related_task {
      id
      related_action {
        id
      }
    }
  }
}
    `;
export const UsedCategoryItemFragmentDoc = gql`
    fragment UsedCategoryItem on category_usage_in_tasks {
  id
  usage
  action_id
  task_id
  actions_category {
    action_id
    category_id
  }
  category {
    ...CategoryItem
  }
}
    ${CategoryItemFragmentDoc}`;
export const ActionsCategoryItemsFragmentDoc = gql`
    fragment ActionsCategoryItems on actions_category_items {
  action_id
  category_id
  category {
    ...CategoryItem
  }
}
    ${CategoryItemFragmentDoc}`;
export const ResourceDefaultTypeFragmentDoc = gql`
    fragment ResourceDefaultType on resource_default_types {
  value
  comment
  _used @client
}
    `;
export const ResourceItemFragmentDoc = gql`
    fragment ResourceItem on resources {
  id
  name
  producer_id
  type_id
  type {
    id
    name
    default_type
  }
  _deleted @client
}
    `;
export const ResourceTypeFragmentDoc = gql`
    fragment ResourceType on resource_types {
  id
  name
  default_type
  producer_id
  resources(order_by: {name: asc}) {
    ...ResourceItem
  }
  resources_aggregate {
    aggregate {
      count
    }
  }
  _deleted @client
}
    ${ResourceItemFragmentDoc}`;
export const UsedResourceFragmentDoc = gql`
    fragment UsedResource on resource_usage_in_tasks {
  id
  value
  actions_resource {
    action_id
    resource_id
    unit
    usage
  }
  usage
  action_id
  task_id
  resource_id
  previous_usage_id
  resource {
    ...ResourceItem
  }
}
    ${ResourceItemFragmentDoc}`;
export const ActionsResourcesFragmentDoc = gql`
    fragment ActionsResources on actions_resources {
  action_id
  resource_id
  usage
  unit
  resource {
    ...ResourceItem
  }
}
    ${ResourceItemFragmentDoc}`;
export const LoginDocument = gql`
    mutation Login($loginName: String!, $password: String!) {
  login(loginName: $loginName, password: $password) {
    token
    error
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class LoginGQL extends Apollo.Mutation<LoginMutation, LoginMutationVariables> {
    document = LoginDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ValidateTokenDocument = gql`
    query ValidateToken {
  validateToken {
    last_checked
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ValidateTokenGQL extends Apollo.Query<ValidateTokenQuery, ValidateTokenQueryVariables> {
    document = ValidateTokenDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProductActionsDocument = gql`
    query GetProductActions($product_id: uuid!) {
  products_by_pk(id: $product_id) {
    ...Product
  }
  actions(
    order_by: {order: asc_nulls_last, name: asc}
    where: {related_to: {_eq: $product_id}}
  ) {
    ...Action
  }
}
    ${ProductFragmentDoc}
${ActionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductActionsGQL extends Apollo.Query<GetProductActionsQuery, GetProductActionsQueryVariables> {
    document = GetProductActionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetActionsDocument = gql`
    query GetActions($producer_id: String!) {
  actions(
    order_by: {product: {name: asc_nulls_first}, order: asc_nulls_last, name: asc}
    where: {producer_id: {_eq: $producer_id}}
  ) {
    ...Action
  }
}
    ${ActionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetActionsGQL extends Apollo.Query<GetActionsQuery, GetActionsQueryVariables> {
    document = GetActionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetActionDocument = gql`
    query GetAction($id: uuid!) {
  actions_by_pk(id: $id) {
    ...Action
  }
  datafields_aggregate(where: {related_to: {_eq: $id}}) {
    aggregate {
      count
    }
  }
  tasks_aggregate(where: {related_to: {_eq: $id}}) {
    aggregate {
      count
    }
  }
}
    ${ActionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetActionGQL extends Apollo.Query<GetActionQuery, GetActionQueryVariables> {
    document = GetActionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateActionDocument = gql`
    mutation CreateAction($object: actions_insert_input!) {
  insert_actions_one(object: $object) {
    ...Action
  }
}
    ${ActionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateActionGQL extends Apollo.Mutation<CreateActionMutation, CreateActionMutationVariables> {
    document = CreateActionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateActionDocument = gql`
    mutation UpdateAction($id: uuid!, $_set: actions_set_input) {
  update_actions_by_pk(_set: $_set, pk_columns: {id: $id}) {
    ...Action
  }
}
    ${ActionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateActionGQL extends Apollo.Mutation<UpdateActionMutation, UpdateActionMutationVariables> {
    document = UpdateActionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteActionDocument = gql`
    mutation DeleteAction($id: uuid!) {
  update_actions_by_pk(_set: {deleted_at: "now()"}, pk_columns: {id: $id}) {
    id
    deleted_at
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteActionGQL extends Apollo.Mutation<DeleteActionMutation, DeleteActionMutationVariables> {
    document = DeleteActionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RestoreActionDocument = gql`
    mutation RestoreAction($id: uuid!) {
  update_actions_by_pk(_set: {deleted_at: null}, pk_columns: {id: $id}) {
    id
    deleted_at
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RestoreActionGQL extends Apollo.Mutation<RestoreActionMutation, RestoreActionMutationVariables> {
    document = RestoreActionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetActionTaskDocument = gql`
    query GetActionTask($action_id: uuid!, $task_id: uuid!) {
  task: tasks_by_pk(id: $task_id) {
    ...Task
  }
  actions_by_pk(id: $action_id) {
    id
    name
    updated_at
    product {
      id
      name
    }
    main_action_of_product {
      id
      name
    }
  }
  tasks(
    where: {_and: {parent: {_eq: $task_id}, related_to: {_eq: $action_id}}}
    order_by: {order: asc_nulls_last}
  ) {
    ...Task
  }
  tasks_flatten(
    where: {related_to: {_eq: $action_id}}
    order_by: {array_to_string: asc}
  ) {
    id
    name
    level
    name_path
  }
}
    ${TaskFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetActionTaskGQL extends Apollo.Query<GetActionTaskQuery, GetActionTaskQueryVariables> {
    document = GetActionTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddActionTaskDocument = gql`
    mutation AddActionTask($object: tasks_insert_input!) {
  insert_tasks_one(
    object: $object
    on_conflict: {constraint: task_items_pkey, update_columns: [name, order]}
  ) {
    ...Task
  }
}
    ${TaskFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddActionTaskGQL extends Apollo.Mutation<AddActionTaskMutation, AddActionTaskMutationVariables> {
    document = AddActionTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateActionTasksDocument = gql`
    mutation UpdateActionTasks($objects: [tasks_insert_input!]!) {
  insert_tasks(
    objects: $objects
    on_conflict: {constraint: task_items_pkey, update_columns: [name, order]}
  ) {
    affected_rows
    returning {
      id
      name
      ...Task
    }
  }
}
    ${TaskFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateActionTasksGQL extends Apollo.Mutation<UpdateActionTasksMutation, UpdateActionTasksMutationVariables> {
    document = UpdateActionTasksDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTaskDocument = gql`
    mutation UpdateTask($id: uuid!, $_set: tasks_set_input, $datafields: [datafields_insert_input!]!) {
  update_tasks_by_pk(pk_columns: {id: $id}, _set: $_set) {
    ...Task
  }
  delete_datafields(where: {related_to: {_eq: $id}}) {
    affected_rows
  }
  insert_datafields(
    objects: $datafields
    on_conflict: {constraint: datafields_pkey, update_columns: [order]}
  ) {
    affected_rows
    returning {
      ...Datafield
    }
  }
}
    ${TaskFragmentDoc}
${DatafieldFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTaskGQL extends Apollo.Mutation<UpdateTaskMutation, UpdateTaskMutationVariables> {
    document = UpdateTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteActionTaskDocument = gql`
    mutation DeleteActionTask($id: uuid!) {
  delete_tasks_by_pk(id: $id) {
    id
    parent
    related_to
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteActionTaskGQL extends Apollo.Mutation<DeleteActionTaskMutation, DeleteActionTaskMutationVariables> {
    document = DeleteActionTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddActionCategoryItemDocument = gql`
    mutation AddActionCategoryItem($action_id: uuid!, $name: String!, $type_id: uuid!, $producer_id: uuid!) {
  insert_actions_category_items_one(
    object: {category: {data: {name: $name, type_id: $type_id, producer_id: $producer_id}, on_conflict: {constraint: category_items_producer_id_name_normalized_key, update_columns: type_id}}, action_id: $action_id}
    on_conflict: {constraint: actions_category_items_pkey, update_columns: action_id}
  ) {
    ...ActionsCategoryItems
  }
}
    ${ActionsCategoryItemsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddActionCategoryItemGQL extends Apollo.Mutation<AddActionCategoryItemMutation, AddActionCategoryItemMutationVariables> {
    document = AddActionCategoryItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateActionCategoryItemsDocument = gql`
    mutation UpdateActionCategoryItems($action_id: uuid!, $category_id: uuid!) {
  update_actions_category_items(
    where: {action_id: {_eq: $action_id}, category_id: {_eq: $category_id}}
  ) {
    affected_rows
    returning {
      ...ActionsCategoryItems
    }
  }
}
    ${ActionsCategoryItemsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateActionCategoryItemsGQL extends Apollo.Mutation<UpdateActionCategoryItemsMutation, UpdateActionCategoryItemsMutationVariables> {
    document = UpdateActionCategoryItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteActionCategoryItemsDocument = gql`
    mutation DeleteActionCategoryItems($action_id: uuid!, $category_id: uuid!) {
  delete_actions_category_items(
    where: {action_id: {_eq: $action_id}, category_id: {_eq: $category_id}}
  ) {
    affected_rows
    returning {
      ...ActionsCategoryItems
    }
  }
}
    ${ActionsCategoryItemsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteActionCategoryItemsGQL extends Apollo.Mutation<DeleteActionCategoryItemsMutation, DeleteActionCategoryItemsMutationVariables> {
    document = DeleteActionCategoryItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetActionCategoryItemsDocument = gql`
    query GetActionCategoryItems($action_id: uuid!) {
  actions_category_items(
    where: {action_id: {_eq: $action_id}}
    order_by: {category: {type: {name: asc}, name: asc}}
  ) {
    ...ActionsCategoryItems
  }
}
    ${ActionsCategoryItemsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetActionCategoryItemsGQL extends Apollo.Query<GetActionCategoryItemsQuery, GetActionCategoryItemsQueryVariables> {
    document = GetActionCategoryItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetActionCategoryItemsByTypeDocument = gql`
    query GetActionCategoryItemsByType($action_id: uuid!) {
  category_types(
    where: {items: {category_item_actions: {action_id: {_eq: $action_id}}}}
    order_by: {name: asc}
  ) {
    id
    name
    items(
      where: {category_item_actions: {action_id: {_eq: $action_id}}}
      order_by: {name: asc}
    ) {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetActionCategoryItemsByTypeGQL extends Apollo.Query<GetActionCategoryItemsByTypeQuery, GetActionCategoryItemsByTypeQueryVariables> {
    document = GetActionCategoryItemsByTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetActionCategoryItemsByTypeIdDocument = gql`
    query GetActionCategoryItemsByTypeId($type_id: uuid!, $action_id: uuid!) {
  category_types_by_pk(id: $type_id) {
    id
    name
    items(
      where: {category_item_actions: {action_id: {_eq: $action_id}}}
      order_by: {name: asc}
    ) {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetActionCategoryItemsByTypeIdGQL extends Apollo.Query<GetActionCategoryItemsByTypeIdQuery, GetActionCategoryItemsByTypeIdQueryVariables> {
    document = GetActionCategoryItemsByTypeIdDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUnusedActionCategoryItemsDocument = gql`
    query GetUnusedActionCategoryItems($action_id: uuid!) {
  category_items(
    order_by: {name: asc}
    where: {_not: {category_item_actions: {action_id: {_eq: $action_id}}}}
  ) {
    ...CategoryItem
  }
}
    ${CategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUnusedActionCategoryItemsGQL extends Apollo.Query<GetUnusedActionCategoryItemsQuery, GetUnusedActionCategoryItemsQueryVariables> {
    document = GetUnusedActionCategoryItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetActionCategoryItemsInsightsDocument = gql`
    query GetActionCategoryItemsInsights($action_id: uuid!) {
  category_types(
    where: {items: {usage_in_tasks: {}, category_item_actions: {action_id: {_eq: $action_id}}}}
    order_by: {name: asc}
  ) {
    id
    name
    items(
      where: {usage_in_tasks: {}, category_item_actions: {action_id: {_eq: $action_id}}}
      order_by: {name: asc}
    ) {
      ...CategoryItem
      usage_in_tasks_aggregate {
        aggregate {
          count
        }
      }
    }
  }
}
    ${CategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetActionCategoryItemsInsightsGQL extends Apollo.Query<GetActionCategoryItemsInsightsQuery, GetActionCategoryItemsInsightsQueryVariables> {
    document = GetActionCategoryItemsInsightsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProducerCategoryItemsDocument = gql`
    query GetProducerCategoryItems {
  category_items(where: {category_item_actions: {}}, order_by: {name: asc}) {
    ...CategoryItem
    category_item_actions(order_by: {action: {product: {name: asc}}}) {
      action_id
      category_id
      action {
        id
        name
        product {
          id
          name
        }
      }
    }
    category_item_actions_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${CategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProducerCategoryItemsGQL extends Apollo.Query<GetProducerCategoryItemsQuery, GetProducerCategoryItemsQueryVariables> {
    document = GetProducerCategoryItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddTaskCategoryItemDocument = gql`
    mutation AddTaskCategoryItem($action_id: uuid!, $category_id: uuid!, $task_id: uuid!) {
  insert_actions_category_items_one(
    object: {action_id: $action_id, category_id: $category_id}
    on_conflict: {constraint: actions_category_items_pkey, update_columns: action_id}
  ) {
    ...ActionsCategoryItems
  }
  insert_category_usage_in_tasks_one(
    object: {action_id: $action_id, category_id: $category_id, task_id: $task_id}
  ) {
    ...UsedCategoryItem
  }
}
    ${ActionsCategoryItemsFragmentDoc}
${UsedCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddTaskCategoryItemGQL extends Apollo.Mutation<AddTaskCategoryItemMutation, AddTaskCategoryItemMutationVariables> {
    document = AddTaskCategoryItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUsedCategoryItemsInTaskDocument = gql`
    query GetUsedCategoryItemsInTask($task_id: uuid!) {
  category_types(where: {items: {usage_in_tasks: {task_id: {_eq: $task_id}}}}) {
    id
    name
    items(where: {usage_in_tasks: {task_id: {_eq: $task_id}}}) {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUsedCategoryItemsInTaskGQL extends Apollo.Query<GetUsedCategoryItemsInTaskQuery, GetUsedCategoryItemsInTaskQueryVariables> {
    document = GetUsedCategoryItemsInTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUsedCategoryItemsInTaskDocument = gql`
    mutation UpdateUsedCategoryItemsInTask($task_id: uuid!, $objects: [category_usage_in_tasks_insert_input!]!) {
  delete_category_usage_in_tasks(where: {task_id: {_eq: $task_id}}) {
    affected_rows
  }
  insert_category_usage_in_tasks(objects: $objects) {
    returning {
      id
      category {
        id
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUsedCategoryItemsInTaskGQL extends Apollo.Mutation<UpdateUsedCategoryItemsInTaskMutation, UpdateUsedCategoryItemsInTaskMutationVariables> {
    document = UpdateUsedCategoryItemsInTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTaskCategoryItemDocument = gql`
    mutation DeleteTaskCategoryItem($id: uuid!) {
  delete_category_usage_in_tasks_by_pk(id: $id) {
    id
    task_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTaskCategoryItemGQL extends Apollo.Mutation<DeleteTaskCategoryItemMutation, DeleteTaskCategoryItemMutationVariables> {
    document = DeleteTaskCategoryItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTaskCategoryItemsDocument = gql`
    query GetTaskCategoryItems($task_id: uuid!) {
  category_usage_in_tasks(
    where: {task_id: {_eq: $task_id}}
    order_by: {category: {type: {name: asc}, name: asc}}
  ) {
    ...UsedCategoryItem
  }
}
    ${UsedCategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTaskCategoryItemsGQL extends Apollo.Query<GetTaskCategoryItemsQuery, GetTaskCategoryItemsQueryVariables> {
    document = GetTaskCategoryItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddActionResourceDocument = gql`
    mutation AddActionResource($action_id: uuid!, $name: String!, $type_id: uuid!, $usage: usage_types_enum = input, $producer_id: uuid!) {
  insert_actions_resources_one(
    object: {resource: {data: {name: $name, type_id: $type_id, producer_id: $producer_id}, on_conflict: {constraint: resources_normalized_name_producer_id_key, update_columns: updated_at}}, action_id: $action_id, usage: $usage}
    on_conflict: {constraint: actions_resources_pkey, update_columns: action_id}
  ) {
    ...ActionsResources
  }
}
    ${ActionsResourcesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddActionResourceGQL extends Apollo.Mutation<AddActionResourceMutation, AddActionResourceMutationVariables> {
    document = AddActionResourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateActionResourceDocument = gql`
    mutation UpdateActionResource($action_id: uuid!, $resource_id: uuid!, $unit: String = "") {
  update_actions_resources(
    where: {action_id: {_eq: $action_id}, resource_id: {_eq: $resource_id}}
    _set: {unit: $unit}
  ) {
    affected_rows
    returning {
      ...ActionsResources
    }
  }
}
    ${ActionsResourcesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateActionResourceGQL extends Apollo.Mutation<UpdateActionResourceMutation, UpdateActionResourceMutationVariables> {
    document = UpdateActionResourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteActionResourceDocument = gql`
    mutation DeleteActionResource($action_id: uuid!, $resource_id: uuid!, $usage: usage_types_enum = input) {
  delete_actions_resources(
    where: {action_id: {_eq: $action_id}, resource_id: {_eq: $resource_id}, usage: {_eq: $usage}}
  ) {
    affected_rows
    returning {
      ...ActionsResources
    }
  }
}
    ${ActionsResourcesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteActionResourceGQL extends Apollo.Mutation<DeleteActionResourceMutation, DeleteActionResourceMutationVariables> {
    document = DeleteActionResourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetActionResourcesDocument = gql`
    query GetActionResources($action_id: uuid!, $usage: usage_types_enum = input) {
  actions_resources(
    where: {action_id: {_eq: $action_id}, usage: {_eq: $usage}}
    order_by: {resource: {type: {name: asc}, name: asc}}
  ) {
    ...ActionsResources
  }
}
    ${ActionsResourcesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetActionResourcesGQL extends Apollo.Query<GetActionResourcesQuery, GetActionResourcesQueryVariables> {
    document = GetActionResourcesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUnusedActionResourcesDocument = gql`
    query GetUnusedActionResources($action_id: uuid!, $usage: usage_types_enum = input) {
  resources(
    order_by: {name: asc}
    where: {_not: {resource_actions: {action_id: {_eq: $action_id}, usage: {_eq: $usage}}}}
  ) {
    ...ResourceItem
  }
}
    ${ResourceItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUnusedActionResourcesGQL extends Apollo.Query<GetUnusedActionResourcesQuery, GetUnusedActionResourcesQueryVariables> {
    document = GetUnusedActionResourcesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetActionResourcesInsightsDocument = gql`
    query GetActionResourcesInsights($action_id: uuid!) {
  actions_resources(
    where: {action_id: {_eq: $action_id}, usage: {_eq: input}}
    order_by: {resource: {type: {name: asc}, name: asc}}
  ) {
    ...ActionsResources
    resource {
      usage_in_tasks_aggregate(where: {usage: {_eq: input}}) {
        aggregate {
          sum {
            value
          }
        }
      }
    }
  }
}
    ${ActionsResourcesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetActionResourcesInsightsGQL extends Apollo.Query<GetActionResourcesInsightsQuery, GetActionResourcesInsightsQueryVariables> {
    document = GetActionResourcesInsightsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetActionResultOptionsDocument = gql`
    query GetActionResultOptions($action_id: uuid!) {
  actions_by_pk(id: $action_id) {
    result_base_qty
    result_packaging {
      id
      name
    }
    result_resource {
      id
      name
    }
  }
  packaging: resources(
    where: {type: {default_type: {_eq: packaging}}, resource_actions: {action_id: {_eq: $action_id}, usage: {_eq: produced}}}
  ) {
    id
    name
  }
  resources(
    where: {type: {default_type: {_eq: food}}, resource_actions: {action_id: {_eq: $action_id}, usage: {_eq: produced}}}
  ) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetActionResultOptionsGQL extends Apollo.Query<GetActionResultOptionsQuery, GetActionResultOptionsQueryVariables> {
    document = GetActionResultOptionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateActionResultsDocument = gql`
    mutation UpdateActionResults($action_id: uuid!, $qty: Int = 1, $packaging_id: uuid!, $resource_id: uuid!) {
  update_actions_by_pk(
    pk_columns: {id: $action_id}
    _set: {result_base_qty: $qty, result_resource_id: $resource_id, result_packaging_id: $packaging_id}
  ) {
    result_base_qty
    result_packaging {
      id
      name
    }
    result_resource {
      id
      name
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateActionResultsGQL extends Apollo.Mutation<UpdateActionResultsMutation, UpdateActionResultsMutationVariables> {
    document = UpdateActionResultsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProducerResourcesDocument = gql`
    query GetProducerResources {
  resources(where: {resource_actions: {}}, order_by: {name: asc}) {
    ...ResourceItem
    resource_actions(order_by: {action: {product: {name: asc}}}) {
      action_id
      resource_id
      action {
        id
        name
        product {
          id
          name
        }
      }
    }
    resource_actions_aggregate {
      aggregate {
        count
      }
    }
  }
}
    ${ResourceItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProducerResourcesGQL extends Apollo.Query<GetProducerResourcesQuery, GetProducerResourcesQueryVariables> {
    document = GetProducerResourcesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetAvailableTaskInputsDocument = gql`
    query GetAvailableTaskInputs($action_id: uuid!, $task_id: uuid!, $task_order_path: String!) {
  actions_resources(
    where: {_and: [{action_id: {_eq: $action_id}}, {_or: [{usage: {_eq: input}}, {usage: {_eq: produced}}]}, {_not: {resource: {usage_in_tasks: {task_id: {_eq: $task_id}}}}}]}
    order_by: {resource: {type: {name: asc}, name: asc}}
  ) {
    ...ActionsResources
  }
  resource_usage_in_tasks_flatten(
    where: {array_to_string: {_lt: $task_order_path}, task: {related_to: {_eq: $action_id}}, _and: [{_not: {resource: {usage_in_tasks: {task_id: {_eq: $task_id}}}}}, {_not: {resource_usage: {resource_next_usages: {}}}}]}
    order_by: {array_to_string: desc}
  ) {
    usage
    name_path
    task_id
    array_to_string
    usage_id
    resource {
      ...ResourceItem
    }
  }
  resource_usage_in_tasks(
    where: {task_id: {_eq: $task_id}, _not: {resource: {usage_in_tasks: {task_id: {_eq: $task_id}, usage: {_eq: output}}}}}
  ) {
    ...UsedResource
  }
}
    ${ActionsResourcesFragmentDoc}
${ResourceItemFragmentDoc}
${UsedResourceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetAvailableTaskInputsGQL extends Apollo.Query<GetAvailableTaskInputsQuery, GetAvailableTaskInputsQueryVariables> {
    document = GetAvailableTaskInputsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddTaskResourceDocument = gql`
    mutation AddTaskResource($action_id: uuid!, $resource_id: uuid!, $task_id: uuid!, $usage: usage_types_enum = input, $value: float8 = 1, $previous_usage_id: uuid = null) {
  insert_actions_resources_one(
    object: {action_id: $action_id, resource_id: $resource_id, usage: $usage}
    on_conflict: {constraint: actions_resources_pkey, update_columns: action_id}
  ) {
    ...ActionsResources
  }
  insert_resource_usage_in_tasks_one(
    object: {action_id: $action_id, resource_id: $resource_id, task_id: $task_id, usage: $usage, value: $value, previous_usage_id: $previous_usage_id}
  ) {
    ...UsedResource
  }
}
    ${ActionsResourcesFragmentDoc}
${UsedResourceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddTaskResourceGQL extends Apollo.Mutation<AddTaskResourceMutation, AddTaskResourceMutationVariables> {
    document = AddTaskResourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUsedResourcesInTaskDocument = gql`
    query GetUsedResourcesInTask($task_id: uuid!) {
  resource_usage_in_tasks(
    where: {task_id: {_eq: $task_id}, actions_resource: {_or: [{usage: {_eq: produced}}, {usage: {_eq: input}}, {usage: {_eq: passing}}, {usage: {_eq: output}}]}}
    order_by: {resource: {type: {name: asc}, name: asc}}
  ) {
    ...UsedResource
  }
}
    ${UsedResourceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUsedResourcesInTaskGQL extends Apollo.Query<GetUsedResourcesInTaskQuery, GetUsedResourcesInTaskQueryVariables> {
    document = GetUsedResourcesInTaskDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTaskResourceDocument = gql`
    mutation UpdateTaskResource($used_resource_id: uuid!, $value: float8 = 1) {
  update_resource_usage_in_tasks_by_pk(
    pk_columns: {id: $used_resource_id}
    _set: {value: $value}
  ) {
    value
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTaskResourceGQL extends Apollo.Mutation<UpdateTaskResourceMutation, UpdateTaskResourceMutationVariables> {
    document = UpdateTaskResourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteTaskResourceDocument = gql`
    mutation DeleteTaskResource($id: uuid!) {
  delete_resource_usage_in_tasks_by_pk(id: $id) {
    id
    task_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteTaskResourceGQL extends Apollo.Mutation<DeleteTaskResourceMutation, DeleteTaskResourceMutationVariables> {
    document = DeleteTaskResourceDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTaskResourcesDocument = gql`
    query GetTaskResources($task_id: uuid!) {
  resource_usage_in_tasks(
    where: {task_id: {_eq: $task_id}}
    order_by: {resource: {type: {name: asc}, name: asc}}
  ) {
    ...UsedResource
  }
}
    ${UsedResourceFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTaskResourcesGQL extends Apollo.Query<GetTaskResourcesQuery, GetTaskResourcesQueryVariables> {
    document = GetTaskResourcesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetActionBlueprintDocument = gql`
    query GetActionBlueprint($uid: String!) {
  entry(type: "blueprint", uid: [$uid], site: "toolbox") {
    ...ActionBlueprint
  }
}
    ${ActionBlueprintFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetActionBlueprintGQL extends Apollo.Query<GetActionBlueprintQuery, GetActionBlueprintQueryVariables> {
    document = GetActionBlueprintDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetActionBlueprintsDocument = gql`
    query GetActionBlueprints {
  entries(type: "blueprint", site: "toolbox") {
    id
    uid
    title
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetActionBlueprintsGQL extends Apollo.Query<GetActionBlueprintsQuery, GetActionBlueprintsQueryVariables> {
    document = GetActionBlueprintsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUnusedCategoryTypesDocument = gql`
    query GetUnusedCategoryTypes($producer_id: uuid!) {
  category_default_types(
    order_by: {comment: asc}
    where: {_not: {usage: {producer_id: {_eq: $producer_id}}}}
  ) {
    ...CategoryDefaultType
  }
}
    ${CategoryDefaultTypeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUnusedCategoryTypesGQL extends Apollo.Query<GetUnusedCategoryTypesQuery, GetUnusedCategoryTypesQueryVariables> {
    document = GetUnusedCategoryTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCategoryTypesDocument = gql`
    query GetCategoryTypes($producer_id: uuid!) {
  category_types(where: {producer_id: {_eq: $producer_id}}, order_by: {name: asc}) {
    ...CategoryType
  }
}
    ${CategoryTypeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCategoryTypesGQL extends Apollo.Query<GetCategoryTypesQuery, GetCategoryTypesQueryVariables> {
    document = GetCategoryTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddCategoryTypeDocument = gql`
    mutation AddCategoryType($object: category_types_insert_input!) {
  insert_category_types_one(
    object: $object
    on_conflict: {update_columns: name, constraint: category_types_name_normalized_producer_id_key}
  ) {
    ...CategoryType
  }
}
    ${CategoryTypeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddCategoryTypeGQL extends Apollo.Mutation<AddCategoryTypeMutation, AddCategoryTypeMutationVariables> {
    document = AddCategoryTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatCategoryTypeDocument = gql`
    mutation UpdatCategoryType($id: uuid!, $name: String!) {
  update_category_types_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {
    ...CategoryType
  }
}
    ${CategoryTypeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatCategoryTypeGQL extends Apollo.Mutation<UpdatCategoryTypeMutation, UpdatCategoryTypeMutationVariables> {
    document = UpdatCategoryTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCategoryTypeDocument = gql`
    mutation DeleteCategoryType($id: uuid!) {
  delete_category_types_by_pk(id: $id) {
    ...CategoryType
  }
}
    ${CategoryTypeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCategoryTypeGQL extends Apollo.Mutation<DeleteCategoryTypeMutation, DeleteCategoryTypeMutationVariables> {
    document = DeleteCategoryTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetCategoryItemsDocument = gql`
    query GetCategoryItems($type_id: uuid!) {
  category_items(where: {type_id: {_eq: $type_id}}, order_by: {name: asc}) {
    ...CategoryItem
  }
}
    ${CategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetCategoryItemsGQL extends Apollo.Query<GetCategoryItemsQuery, GetCategoryItemsQueryVariables> {
    document = GetCategoryItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddCategoryItemDocument = gql`
    mutation AddCategoryItem($object: category_items_insert_input!) {
  insert_category_items_one(
    object: $object
    on_conflict: {update_columns: name, constraint: category_items_producer_id_name_normalized_key}
  ) {
    ...CategoryItem
  }
}
    ${CategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddCategoryItemGQL extends Apollo.Mutation<AddCategoryItemMutation, AddCategoryItemMutationVariables> {
    document = AddCategoryItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatCategoryItemDocument = gql`
    mutation UpdatCategoryItem($id: uuid!, $name: String!) {
  update_category_items_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {
    ...CategoryItem
  }
}
    ${CategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatCategoryItemGQL extends Apollo.Mutation<UpdatCategoryItemMutation, UpdatCategoryItemMutationVariables> {
    document = UpdatCategoryItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteCategoryItemDocument = gql`
    mutation DeleteCategoryItem($id: uuid!) {
  delete_category_items_by_pk(id: $id) {
    ...CategoryItem
  }
}
    ${CategoryItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCategoryItemGQL extends Apollo.Mutation<DeleteCategoryItemMutation, DeleteCategoryItemMutationVariables> {
    document = DeleteCategoryItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SendMessageDocument = gql`
    mutation SendMessage($object: messages_insert_input!) {
  insert_messages_one(object: $object) {
    __typename
    id
    message
    user_name
    user_id
    timestamp
    producer_uid
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SendMessageGQL extends Apollo.Mutation<SendMessageMutation, SendMessageMutationVariables> {
    document = SendMessageDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMessagesDocument = gql`
    query GetMessages($producer_uid: String!) {
  messages(
    limit: 10
    where: {producer_uid: {_eq: $producer_uid}}
    order_by: {timestamp: desc}
    distinct_on: timestamp
  ) {
    __typename
    id
    message
    user_name
    user_id
    timestamp
    producer_uid
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMessagesGQL extends Apollo.Query<GetMessagesQuery, GetMessagesQueryVariables> {
    document = GetMessagesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetMessagesLiveDocument = gql`
    subscription GetMessagesLive($producer_uid: String!) {
  messages(
    limit: 10
    where: {producer_uid: {_eq: $producer_uid}}
    order_by: {timestamp: desc}
    distinct_on: timestamp
  ) {
    __typename
    id
    message
    user_name
    user_id
    timestamp
    producer_uid
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetMessagesLiveGQL extends Apollo.Subscription<GetMessagesLiveSubscription, GetMessagesLiveSubscriptionVariables> {
    document = GetMessagesLiveDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddDatafieldDocument = gql`
    mutation AddDatafield($object: datafields_insert_input!) {
  insert_datafields_one(object: $object) {
    ...Datafield
  }
}
    ${DatafieldFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddDatafieldGQL extends Apollo.Mutation<AddDatafieldMutation, AddDatafieldMutationVariables> {
    document = AddDatafieldDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDatafieldDocument = gql`
    mutation UpdateDatafield($id: uuid!, $config: jsonb!, $version: String, $used_resource_id: uuid = null) {
  update_datafields_by_pk(
    pk_columns: {id: $id}
    _set: {config: $config, version: $version, used_resource_id: $used_resource_id}
  ) {
    id
    config
    order
    version
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDatafieldGQL extends Apollo.Mutation<UpdateDatafieldMutation, UpdateDatafieldMutationVariables> {
    document = UpdateDatafieldDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateTaskDatafieldsDocument = gql`
    mutation UpdateTaskDatafields($objects: [datafields_insert_input!]!) {
  insert_datafields(
    objects: $objects
    on_conflict: {constraint: datafields_pkey, update_columns: [order]}
  ) {
    affected_rows
    returning {
      id
      config
      order
      version
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateTaskDatafieldsGQL extends Apollo.Mutation<UpdateTaskDatafieldsMutation, UpdateTaskDatafieldsMutationVariables> {
    document = UpdateTaskDatafieldsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteDatafieldDocument = gql`
    mutation DeleteDatafield($id: uuid!) {
  delete_datafields_by_pk(id: $id) {
    id
    related_to
    task_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDatafieldGQL extends Apollo.Mutation<DeleteDatafieldMutation, DeleteDatafieldMutationVariables> {
    document = DeleteDatafieldDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTaskDatafieldsDocument = gql`
    query GetTaskDatafields($id: uuid!, $parent: uuid_comparison_exp = {_is_null: true}) {
  datafields(where: {task_id: {_eq: $id}}, order_by: {order: asc}) {
    ...Datafield
    ...DatafieldLogs
  }
}
    ${DatafieldFragmentDoc}
${DatafieldLogsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTaskDatafieldsGQL extends Apollo.Query<GetTaskDatafieldsQuery, GetTaskDatafieldsQueryVariables> {
    document = GetTaskDatafieldsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetDatafieldDocument = gql`
    query GetDatafield($datafield_id: uuid!) {
  datafields_by_pk(id: $datafield_id) {
    ...Datafield
  }
}
    ${DatafieldFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetDatafieldGQL extends Apollo.Query<GetDatafieldQuery, GetDatafieldQueryVariables> {
    document = GetDatafieldDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTaskDatafieldsLiveDocument = gql`
    subscription GetTaskDatafieldsLive($id: uuid!, $parent: uuid_comparison_exp = {_is_null: true}) {
  datafields(where: {task_id: {_eq: $id}}, order_by: {order: asc}) {
    ...Datafield
    ...DatafieldLogs
  }
}
    ${DatafieldFragmentDoc}
${DatafieldLogsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTaskDatafieldsLiveGQL extends Apollo.Subscription<GetTaskDatafieldsLiveSubscription, GetTaskDatafieldsLiveSubscriptionVariables> {
    document = GetTaskDatafieldsLiveDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetTaskStatusLiveDocument = gql`
    subscription GetTaskStatusLive($id: uuid!, $parent: uuid_comparison_exp = {_is_null: true}) {
  tasks_by_pk(id: $id) {
    id
    name
    next_task_id
    next_task_name
    next_task_level
    prev_task_id
    prev_task_name
    prev_task_level
    related_action {
      id
      name
      main_action_of_product {
        id
        name
      }
      product {
        id
        name
      }
    }
    logs_aggregate(
      where: {parent: $parent, type: {_eq: "task"}}
      order_by: {created_at: asc}
    ) {
      aggregate {
        count
      }
      nodes {
        id
        data
        created_at
        created_by
        updated_at
        type
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetTaskStatusLiveGQL extends Apollo.Subscription<GetTaskStatusLiveSubscription, GetTaskStatusLiveSubscriptionVariables> {
    document = GetTaskStatusLiveDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFilesDocument = gql`
    query GetFiles($producer_id: String!) {
  files(
    order_by: {created_at: asc, originalname: asc}
    where: {producer_id: {_eq: $producer_id}}
  ) {
    ...File
  }
}
    ${FileFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFilesGQL extends Apollo.Query<GetFilesQuery, GetFilesQueryVariables> {
    document = GetFilesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetFileDocument = gql`
    query GetFile($id: uuid!) {
  files_by_pk(id: $id) {
    ...File
  }
}
    ${FileFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetFileGQL extends Apollo.Query<GetFileQuery, GetFileQueryVariables> {
    document = GetFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadFileDocument = gql`
    mutation UploadFile($object: files_insert_input!) {
  insert_files_one(object: $object) {
    ...File
  }
}
    ${FileFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadFileGQL extends Apollo.Mutation<UploadFileMutation, UploadFileMutationVariables> {
    document = UploadFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadPublicFileDocument = gql`
    mutation UploadPublicFile($file: FileInput) {
  save_mediaByProducers_Asset(_file: $file) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadPublicFileGQL extends Apollo.Mutation<UploadPublicFileMutation, UploadPublicFileMutationVariables> {
    document = UploadPublicFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UploadFilesDocument = gql`
    mutation UploadFiles($objects: [files_insert_input!]!) {
  insert_files(objects: $objects) {
    returning {
      ...File
    }
  }
}
    ${FileFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UploadFilesGQL extends Apollo.Mutation<UploadFilesMutation, UploadFilesMutationVariables> {
    document = UploadFilesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateFileDocument = gql`
    mutation UpdateFile($id: uuid!, $originalname: String!, $key: String!, $related_to: uuid!) {
  update_files_by_pk(
    _set: {originalname: $originalname, key: $key, related_to: $related_to}
    pk_columns: {id: $id}
  ) {
    ...File
  }
}
    ${FileFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateFileGQL extends Apollo.Mutation<UpdateFileMutation, UpdateFileMutationVariables> {
    document = UpdateFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteFileDocument = gql`
    mutation DeleteFile($id: uuid!) {
  delete_files_by_pk(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteFileGQL extends Apollo.Mutation<DeleteFileMutation, DeleteFileMutationVariables> {
    document = DeleteFileDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLogsDocument = gql`
    query GetLogs($id: uuid!) {
  logs(
    where: {_and: {related_to: {_eq: $id}, parent: {_is_null: true}, type: {_eq: "group"}}}
    order_by: {created_at: desc}
  ) {
    ...Log
  }
}
    ${LogFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLogsGQL extends Apollo.Query<GetLogsQuery, GetLogsQueryVariables> {
    document = GetLogsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetActionLogsDocument = gql`
    query GetActionLogs($id: uuid!) {
  actions_by_pk(id: $id) {
    id
    name
    tasks_aggregate {
      aggregate {
        count
      }
    }
  }
  logs(
    where: {_and: {related_to: {_eq: $id}, parent: {_is_null: true}, type: {_eq: "group"}, completed: {_eq: false}}}
    order_by: {created_at: desc}
  ) {
    id
    created_at
    updated_at
    children_aggregate(where: {type: {_eq: "datafield"}}) {
      aggregate {
        count
      }
    }
    logged_tasks: children_aggregate(where: {type: {_eq: "task"}}) {
      aggregate {
        count
      }
    }
  }
  completed_logs: logs(
    where: {_and: {related_to: {_eq: $id}, parent: {_is_null: true}, type: {_eq: "group"}, completed: {_eq: true}}}
    order_by: {created_at: desc}
  ) {
    id
    created_at
    updated_at
    children_aggregate(where: {type: {_eq: "datafield"}}) {
      aggregate {
        count
      }
    }
    logged_tasks: children_aggregate(where: {type: {_eq: "task"}}) {
      aggregate {
        count
      }
    }
  }
  datafields_aggregate(where: {related_to: {_eq: $id}}) {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetActionLogsGQL extends Apollo.Query<GetActionLogsQuery, GetActionLogsQueryVariables> {
    document = GetActionLogsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetActionLogDocument = gql`
    query GetActionLog($action_id: uuid!, $log_id: uuid!) {
  actions_by_pk(id: $action_id) {
    ...Action
    tasks_datafields_aggregate: tasks {
      id
      name
      datafields_aggregate {
        aggregate {
          count
        }
      }
    }
    tasks_aggregate {
      aggregate {
        count
      }
    }
    datafields_aggregate {
      aggregate {
        count
      }
    }
    tasks(
      where: {parent: {_is_null: true}}
      order_by: {task_flatten: {array_to_string: asc_nulls_last}}
    ) {
      ...Task
      recent_logs: logs(where: {parent: {_eq: $log_id}}) {
        id
        created_at
        updated_at
        data
      }
    }
    last_task: tasks_flatten(
      order_by: {array_to_string: desc_nulls_first}
      limit: 1
    ) {
      id
      name
    }
  }
  logs_by_pk(id: $log_id) {
    id
    created_at
    updated_at
    data
    completed
    children(order_by: {created_at: asc}, where: {type: {_eq: "datafield"}}) {
      id
      related_task {
        id
        name
      }
      related_datafield {
        id
        type
        related_task {
          id
          name
        }
      }
      created_at
      created_by
      data
    }
    children_aggregate(where: {type: {_eq: "datafield"}}) {
      aggregate {
        count
      }
    }
    logged_tasks: children_aggregate(where: {type: {_eq: "task"}}) {
      aggregate {
        count
      }
    }
  }
}
    ${ActionFragmentDoc}
${TaskFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetActionLogGQL extends Apollo.Query<GetActionLogQuery, GetActionLogQueryVariables> {
    document = GetActionLogDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CloseActionLogDocument = gql`
    mutation CloseActionLog($log_id: uuid!, $qty_result: Int!) {
  update_logs_by_pk(
    pk_columns: {id: $log_id}
    _append: {data: {qty_result: $qty_result}}
    _set: {completed: true}
  ) {
    id
    completed
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CloseActionLogGQL extends Apollo.Mutation<CloseActionLogMutation, CloseActionLogMutationVariables> {
    document = CloseActionLogDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLogsInsightDocument = gql`
    subscription GetLogsInsight {
  products {
    name
    actions {
      name
      tasks(order_by: {task_flatten: {array_to_string: asc_nulls_last}}) {
        id
        name
        related_action {
          id
          name
        }
        datafields_aggregate {
          aggregate {
            count
          }
        }
        task_flatten {
          name_path
        }
      }
      tasks_aggregate {
        aggregate {
          count
        }
      }
      datafields_aggregate {
        aggregate {
          count
        }
      }
      action_resources {
        action_id
        resource_id
        unit
        usage
        resource {
          id
          name
          type {
            id
            name
          }
        }
      }
      actions_category_items {
        action_id
        category_id
        category {
          id
          name
          type {
            id
            name
          }
        }
      }
      logs(
        where: {_and: {parent: {_is_null: true}, type: {_eq: "group"}}}
        order_by: {created_at: desc}
      ) {
        id
        created_at
        updated_at
        completed
        children(order_by: {created_at: asc}) {
          id
          related_task {
            id
            name
          }
          related_datafield {
            id
            type
            related_task {
              id
              name
            }
          }
          created_at
          created_by
          data
        }
        children_aggregate(where: {type: {_eq: "datafield"}}, distinct_on: datafield_id) {
          aggregate {
            count
          }
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLogsInsightGQL extends Apollo.Subscription<GetLogsInsightSubscription, GetLogsInsightSubscriptionVariables> {
    document = GetLogsInsightDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLatestLogDocument = gql`
    query GetLatestLog($id: uuid!) {
  latest_log: logs(
    where: {_and: {related_to: {_eq: $id}, parent: {_is_null: true}, type: {_eq: "group"}}}
    order_by: {created_at: desc}
    limit: 1
  ) {
    id
    related_action {
      id
      tasks_flatten(
        where: {datafields: {_not: {logs: {}}}}
        order_by: {array_to_string: asc}
        limit: 1
      ) {
        id
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLatestLogGQL extends Apollo.Query<GetLatestLogQuery, GetLatestLogQueryVariables> {
    document = GetLatestLogDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLogTaskToResumeDocument = gql`
    query GetLogTaskToResume($parent_log_id: uuid!) {
  logs_by_pk(id: $parent_log_id) {
    id
    related_action {
      id
      tasks_flatten(
        where: {_and: {datafields: {_not: {logs: {parent: {_eq: $parent_log_id}}}, related_task: {_not: {logs: {parent: {_eq: $parent_log_id}, type: {_eq: "task"}}}}}}}
        order_by: {array_to_string: asc}
        limit: 1
      ) {
        id
        name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLogTaskToResumeGQL extends Apollo.Query<GetLogTaskToResumeQuery, GetLogTaskToResumeQueryVariables> {
    document = GetLogTaskToResumeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateBatchSettingsDocument = gql`
    mutation UpdateBatchSettings($log_id: uuid!, $data: jsonb!) {
  update_logs_by_pk(pk_columns: {id: $log_id}, _set: {data: $data}) {
    id
    updated_at
    data
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateBatchSettingsGQL extends Apollo.Mutation<UpdateBatchSettingsMutation, UpdateBatchSettingsMutationVariables> {
    document = UpdateBatchSettingsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddNewLogDocument = gql`
    mutation AddNewLog($object: logs_insert_input!) {
  insert_logs_one(object: $object) {
    ...Log
  }
}
    ${LogFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddNewLogGQL extends Apollo.Mutation<AddNewLogMutation, AddNewLogMutationVariables> {
    document = AddNewLogDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddDatafieldLogDocument = gql`
    mutation AddDatafieldLog($parent_log_id: uuid!, $datafield_id: uuid!, $task_id: uuid!, $data: jsonb!) {
  update_logs_by_pk(pk_columns: {id: $parent_log_id}, _set: {updated_at: "now()"}) {
    id
    updated_at
    related_to
  }
  insert_logs_one(
    object: {parent: $parent_log_id, task_id: $task_id, datafield_id: $datafield_id, data: $data, type: "datafield"}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddDatafieldLogGQL extends Apollo.Mutation<AddDatafieldLogMutation, AddDatafieldLogMutationVariables> {
    document = AddDatafieldLogDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteDatafieldLogDocument = gql`
    mutation DeleteDatafieldLog($log_id: uuid!) {
  delete_logs_by_pk(id: $log_id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDatafieldLogGQL extends Apollo.Mutation<DeleteDatafieldLogMutation, DeleteDatafieldLogMutationVariables> {
    document = DeleteDatafieldLogDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddTaskLogDocument = gql`
    mutation AddTaskLog($parent_log_id: uuid!, $action_id: uuid!, $task_id: uuid!, $data: jsonb!) {
  update_logs_by_pk(pk_columns: {id: $parent_log_id}, _set: {updated_at: "now()"}) {
    id
    updated_at
    related_to
  }
  insert_logs_one(
    object: {parent: $parent_log_id, related_to: $action_id, task_id: $task_id, data: $data, type: "task"}
    on_conflict: {constraint: logs_parent_task_id_related_to_type_key, update_columns: updated_at}
  ) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AddTaskLogGQL extends Apollo.Mutation<AddTaskLogMutation, AddTaskLogMutationVariables> {
    document = AddTaskLogDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProducerDocument = gql`
    query GetProducer($uid: [String] = "") {
  producer: category(group: "producers", uid: $uid, site: "toolbox") {
    ...Producer
  }
}
    ${ProducerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProducerGQL extends Apollo.Query<GetProducerQuery, GetProducerQueryVariables> {
    document = GetProducerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProducersDocument = gql`
    query GetProducers {
  producers: categories(group: "producers", orderBy: "title ASC", site: "toolbox") {
    ...Producer
  }
}
    ${ProducerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProducersGQL extends Apollo.Query<GetProducersQuery, GetProducersQueryVariables> {
    document = GetProducersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateProducerDocument = gql`
    mutation CreateProducer($title: String!) {
  save_producers_Category(title: $title) {
    ...Producer
  }
}
    ${ProducerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProducerGQL extends Apollo.Mutation<CreateProducerMutation, CreateProducerMutationVariables> {
    document = CreateProducerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProducerDocument = gql`
    mutation UpdateProducer($uid: String!, $title: String!) {
  save_producers_Category(uid: $uid, title: $title) {
    ...Producer
  }
}
    ${ProducerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProducerGQL extends Apollo.Mutation<UpdateProducerMutation, UpdateProducerMutationVariables> {
    document = UpdateProducerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteProducerDocument = gql`
    mutation DeleteProducer($id: Int!) {
  deleteCategory(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteProducerGQL extends Apollo.Mutation<DeleteProducerMutation, DeleteProducerMutationVariables> {
    document = DeleteProducerDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProductsDocument = gql`
    query GetProducts($producer_id: String!) {
  products(order_by: {name: asc}, where: {producer_id: {_eq: $producer_id}}) {
    ...Product
  }
}
    ${ProductFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductsGQL extends Apollo.Query<GetProductsQuery, GetProductsQueryVariables> {
    document = GetProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SearchProductsDocument = gql`
    query SearchProducts($search: String!) {
  search_products(args: {search: $search}) {
    id
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchProductsGQL extends Apollo.Query<SearchProductsQuery, SearchProductsQueryVariables> {
    document = SearchProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetProductDocument = gql`
    query GetProduct($id: uuid!) {
  products_by_pk(id: $id) {
    ...Product
  }
  actions_aggregate(where: {related_to: {_eq: $id}}) {
    aggregate {
      count
    }
  }
}
    ${ProductFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetProductGQL extends Apollo.Query<GetProductQuery, GetProductQueryVariables> {
    document = GetProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateProductDocument = gql`
    mutation CreateProduct($object: products_insert_input!) {
  insert_products_one(object: $object) {
    ...Product
  }
}
    ${ProductFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProductGQL extends Apollo.Mutation<CreateProductMutation, CreateProductMutationVariables> {
    document = CreateProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProductDocument = gql`
    mutation UpdateProduct($id: uuid!, $_set: products_set_input!) {
  update_products_by_pk(pk_columns: {id: $id}, _set: $_set) {
    ...Product
  }
}
    ${ProductFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProductGQL extends Apollo.Mutation<UpdateProductMutation, UpdateProductMutationVariables> {
    document = UpdateProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteProductDocument = gql`
    mutation DeleteProduct($id: uuid!) {
  update_products_by_pk(_set: {deleted_at: "now()"}, pk_columns: {id: $id}) {
    id
    deleted_at
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteProductGQL extends Apollo.Mutation<DeleteProductMutation, DeleteProductMutationVariables> {
    document = DeleteProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetUnusedResourceTypesDocument = gql`
    query GetUnusedResourceTypes($producer_id: uuid!) {
  resource_default_types(
    order_by: {comment: asc}
    where: {_not: {usage: {producer_id: {_eq: $producer_id}}}}
  ) {
    ...ResourceDefaultType
  }
}
    ${ResourceDefaultTypeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetUnusedResourceTypesGQL extends Apollo.Query<GetUnusedResourceTypesQuery, GetUnusedResourceTypesQueryVariables> {
    document = GetUnusedResourceTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetResourceTypesDocument = gql`
    query GetResourceTypes($producer_id: uuid!) {
  resource_types(where: {producer_id: {_eq: $producer_id}}, order_by: {name: asc}) {
    ...ResourceType
  }
}
    ${ResourceTypeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetResourceTypesGQL extends Apollo.Query<GetResourceTypesQuery, GetResourceTypesQueryVariables> {
    document = GetResourceTypesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddResourceTypeDocument = gql`
    mutation AddResourceType($object: resource_types_insert_input!) {
  insert_resource_types_one(
    object: $object
    on_conflict: {update_columns: name, constraint: resource_types_producer_id_name_normalized_key}
  ) {
    ...ResourceType
  }
}
    ${ResourceTypeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddResourceTypeGQL extends Apollo.Mutation<AddResourceTypeMutation, AddResourceTypeMutationVariables> {
    document = AddResourceTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatResourceTypeDocument = gql`
    mutation UpdatResourceType($id: uuid!, $name: String!) {
  update_resource_types_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {
    ...ResourceType
  }
}
    ${ResourceTypeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatResourceTypeGQL extends Apollo.Mutation<UpdatResourceTypeMutation, UpdatResourceTypeMutationVariables> {
    document = UpdatResourceTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteResourceTypeDocument = gql`
    mutation DeleteResourceType($id: uuid!) {
  delete_resource_types_by_pk(id: $id) {
    ...ResourceType
  }
}
    ${ResourceTypeFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteResourceTypeGQL extends Apollo.Mutation<DeleteResourceTypeMutation, DeleteResourceTypeMutationVariables> {
    document = DeleteResourceTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetResourceItemsDocument = gql`
    query GetResourceItems($type_id: uuid!) {
  resources(where: {type_id: {_eq: $type_id}}, order_by: {name: asc}) {
    ...ResourceItem
  }
}
    ${ResourceItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class GetResourceItemsGQL extends Apollo.Query<GetResourceItemsQuery, GetResourceItemsQueryVariables> {
    document = GetResourceItemsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const AddResourceItemDocument = gql`
    mutation AddResourceItem($object: resources_insert_input!) {
  insert_resources_one(
    object: $object
    on_conflict: {update_columns: name, constraint: resources_normalized_name_producer_id_key}
  ) {
    ...ResourceItem
  }
}
    ${ResourceItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class AddResourceItemGQL extends Apollo.Mutation<AddResourceItemMutation, AddResourceItemMutationVariables> {
    document = AddResourceItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdatResourceItemDocument = gql`
    mutation UpdatResourceItem($id: uuid!, $name: String!) {
  update_resources_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {
    ...ResourceItem
  }
}
    ${ResourceItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdatResourceItemGQL extends Apollo.Mutation<UpdatResourceItemMutation, UpdatResourceItemMutationVariables> {
    document = UpdatResourceItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteResourceItemDocument = gql`
    mutation DeleteResourceItem($id: uuid!) {
  delete_resources_by_pk(id: $id) {
    ...ResourceItem
  }
}
    ${ResourceItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteResourceItemGQL extends Apollo.Mutation<DeleteResourceItemMutation, DeleteResourceItemMutationVariables> {
    document = DeleteResourceItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLogsByTypeDocument = gql`
    query GetLogsByType($type: String) {
  logs(where: {data: {_contains: {type: $type}}}) {
    id
    data
    created_at
    created_by
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLogsByTypeGQL extends Apollo.Query<GetLogsByTypeQuery, GetLogsByTypeQueryVariables> {
    document = GetLogsByTypeDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const GetLogsByKeyDocument = gql`
    query GetLogsByKey($key: String) {
  logs(where: {data: {_has_key: $key}}) {
    id
    data
    created_at
    created_by
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetLogsByKeyGQL extends Apollo.Query<GetLogsByKeyQuery, GetLogsByKeyQueryVariables> {
    document = GetLogsByKeyDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "AssetInterface": [
      "mediaByProducers_Asset",
      "media_Asset"
    ],
    "CategoryInterface": [
      "certificates_Category",
      "machines_Category",
      "producers_Category",
      "productType_Category",
      "regions_Category"
    ],
    "ElementInterface": [
      "Element",
      "User",
      "actions_blueprint_Entry",
      "atAGlance_BlockType",
      "background_background_Entry",
      "blogOverview_blogOverview_Entry",
      "blog_article_Entry",
      "browseMoreLink_linkBlock_BlockType",
      "callToAction_ctaLink_BlockType",
      "catalog_catalog_Entry",
      "certificates_Category",
      "contentBlocks_block_BlockType",
      "contentBlocks_card_BlockType",
      "contentBlocks_cards_BlockType",
      "contentBlocks_graphicSwitch_BlockType",
      "contentBlocks_item_BlockType",
      "contentBlocks_sideBySide_BlockType",
      "contentBlocks_singleGraphic_BlockType",
      "contentBlocks_textWithImage_BlockType",
      "featuredContentBlock_ctaLink_BlockType",
      "featuredContentBlock_featuredContent_BlockType",
      "featuredContentBlock_featuredLatestProducers_BlockType",
      "featuredContentBlock_featuredLatestProducts_BlockType",
      "featuredContentBlock_producer_BlockType",
      "featuredContentBlock_product_BlockType",
      "footerNavigation_Node",
      "frontpage_frontpage_Entry",
      "graphic_BlockType",
      "homeNavigation_Node",
      "importantNotice_GlobalSet",
      "machines_Category",
      "mainNavigation_Node",
      "mediaByProducers_Asset",
      "media_Asset",
      "offer_offer_Entry",
      "pages_cookie_Entry",
      "pages_default_Entry",
      "producerDetails_BlockType",
      "producersOverview_producersOverview_Entry",
      "producers_Category",
      "productDetails_BlockType",
      "productProcess_processGroup_BlockType",
      "productProcess_process_BlockType",
      "productProcess_timelineBreak_BlockType",
      "productProcess_timelineItem_BlockType",
      "productType_Category",
      "product_product_Entry",
      "regions_Category",
      "stepContent_contentBlock_BlockType",
      "tasks_taskGroup_BlockType",
      "tasks_task_BlockType"
    ],
    "EmbeddedAssetInterface": [
      "EmbeddedAsset"
    ],
    "EntryInterface": [
      "actions_blueprint_Entry",
      "background_background_Entry",
      "blogOverview_blogOverview_Entry",
      "blog_article_Entry",
      "catalog_catalog_Entry",
      "frontpage_frontpage_Entry",
      "offer_offer_Entry",
      "pages_cookie_Entry",
      "pages_default_Entry",
      "producersOverview_producersOverview_Entry",
      "product_product_Entry"
    ],
    "GlobalSetInterface": [
      "importantNotice_GlobalSet"
    ],
    "MatrixBlockInterface": [
      "browseMoreLink_linkBlock_BlockType",
      "stepContent_contentBlock_BlockType"
    ],
    "NeoBlockInterface": [
      "callToAction_ctaLink_BlockType",
      "contentBlocks_block_BlockType",
      "contentBlocks_card_BlockType",
      "contentBlocks_cards_BlockType",
      "contentBlocks_graphicSwitch_BlockType",
      "contentBlocks_item_BlockType",
      "contentBlocks_sideBySide_BlockType",
      "contentBlocks_singleGraphic_BlockType",
      "contentBlocks_textWithImage_BlockType",
      "featuredContentBlock_ctaLink_BlockType",
      "featuredContentBlock_featuredContent_BlockType",
      "featuredContentBlock_featuredLatestProducers_BlockType",
      "featuredContentBlock_featuredLatestProducts_BlockType",
      "featuredContentBlock_producer_BlockType",
      "featuredContentBlock_product_BlockType",
      "productProcess_processGroup_BlockType",
      "productProcess_process_BlockType",
      "productProcess_timelineBreak_BlockType",
      "productProcess_timelineItem_BlockType",
      "tasks_taskGroup_BlockType",
      "tasks_task_BlockType"
    ],
    "NodeInterface": [
      "footerNavigation_Node",
      "homeNavigation_Node",
      "mainNavigation_Node"
    ],
    "SeomaticInterface": [
      "SeomaticType"
    ],
    "SuperTableBlockInterface": [
      "atAGlance_BlockType",
      "graphic_BlockType",
      "producerDetails_BlockType",
      "productDetails_BlockType"
    ],
    "UserInterface": [
      "User"
    ],
    "atAGlance_SuperTableField": [
      "atAGlance_BlockType"
    ],
    "browseMoreLink_MatrixField": [
      "browseMoreLink_linkBlock_BlockType"
    ],
    "callToAction_NeoField": [
      "callToAction_ctaLink_BlockType"
    ],
    "contentBlocks_NeoField": [
      "contentBlocks_block_BlockType",
      "contentBlocks_card_BlockType",
      "contentBlocks_cards_BlockType",
      "contentBlocks_graphicSwitch_BlockType",
      "contentBlocks_item_BlockType",
      "contentBlocks_sideBySide_BlockType",
      "contentBlocks_singleGraphic_BlockType",
      "contentBlocks_textWithImage_BlockType"
    ],
    "featuredContentBlock_NeoField": [
      "featuredContentBlock_ctaLink_BlockType",
      "featuredContentBlock_featuredContent_BlockType",
      "featuredContentBlock_featuredLatestProducers_BlockType",
      "featuredContentBlock_featuredLatestProducts_BlockType",
      "featuredContentBlock_producer_BlockType",
      "featuredContentBlock_product_BlockType"
    ],
    "graphic_SuperTableField": [
      "graphic_BlockType"
    ],
    "producerDetails_SuperTableField": [
      "producerDetails_BlockType"
    ],
    "productDetails_SuperTableField": [
      "productDetails_BlockType"
    ],
    "productProcess_NeoField": [
      "productProcess_processGroup_BlockType",
      "productProcess_process_BlockType",
      "productProcess_timelineBreak_BlockType",
      "productProcess_timelineItem_BlockType"
    ],
    "stepContent_MatrixField": [
      "stepContent_contentBlock_BlockType"
    ],
    "tasks_NeoField": [
      "tasks_taskGroup_BlockType",
      "tasks_task_BlockType"
    ]
  }
};
      export default result;
    
import { Pipe, PipeTransform } from "@angular/core";
import { Usage_Types_Enum } from "generated/types.graphql-gen";

@Pipe({
  name: "filterByUsageType",
})
export class FilterByUsageTypePipe implements PipeTransform {
  transform(
    resources: Array<any>,
    usage: Usage_Types_Enum | Array<Usage_Types_Enum>
  ): Array<any> {
    if (!resources) return resources;
    if (!usage) return resources;

    if (Array.isArray(usage)) {
      return resources.filter((resourceObject) => {
        let found = false;
        usage.forEach((u) => {
          if (resourceObject.usage === u) found = true;
        });
        return found;
      });
    } else {
      return resources.filter(
        (resourceObject) => resourceObject.usage === usage
      );
    }
  }
}

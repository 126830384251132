import { FormsModule } from "@angular/forms";
import { APP_INITIALIZER, LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";
import { HttpClient, HttpClientModule } from "@angular/common/http";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage-angular";

import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { GraphQLModule } from "./graphql.module";

import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { SharedModule } from "./shared/shared.module";
import { PromptUpdateService } from "./utility/services/service-worker.service";

import { SwitchProducerModule } from "./producers/modals/switch-producer/switch-producer.module";
import { NewProducerModule } from "./producers/modals/new-producer/new-producer.module";

import { registerLocaleData } from "@angular/common";
import localeDe from "@angular/common/locales/de";
import { StorageService } from "./shared/services/storage.service";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
registerLocaleData(localeDe, "de");

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

export function init(storage: StorageService): any {
  return () => storage.init();
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    FormsModule,
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot({
      experimentalTransitionShadow: true,
      swipeBackEnabled: false,
    }),
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: "de",
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    SharedModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    GraphQLModule,
    SwitchProducerModule,
    NewProducerModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: init,
      deps: [StorageService],
      multi: true,
    },
    PromptUpdateService,
    StorageService,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: "de" },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

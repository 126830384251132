import { NgModule } from "@angular/core";

import { SharedModule } from "src/app/shared/shared.module";

import { SwitchProducerComponent } from "./switch-producer.component";

@NgModule({
  imports: [SharedModule],
  declarations: [SwitchProducerComponent],
})
export class SwitchProducerModule {}

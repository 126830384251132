import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterByResourceType",
  pure: false,
})
export class FilterByResourceTypePipe implements PipeTransform {
  transform(resources: Array<any>, type_id: any): Array<any> {
    if (!resources) return resources;
    return resources.filter(
      (resourceObject) =>
        (resourceObject?.type_id || resourceObject?.resource?.type_id) ===
        type_id
    );
  }
}

import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { ProducerGuard } from "./utility/guards/producer.guard";
import { RoleGuard } from "./utility/guards/role.guard";
import { ValidateTokenGuard } from "./utility/guards/validate-token.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "intro",
    pathMatch: "full",
  },
  {
    path: "intro",
    loadChildren: () =>
      import("./intro/intro.module").then((m) => m.IntroPageModule),
    canLoad: [ValidateTokenGuard],
    canActivate: [ValidateTokenGuard],
    canActivateChild: [ValidateTokenGuard],
  },

  {
    path: "auth",
    loadChildren: () =>
      import("./auth/auth.module").then((m) => m.AuthPageModule),
  },
  {
    path: "producers",
    loadChildren: () =>
      import("./producers/producers.module").then((m) => m.ProducersPageModule),
    canLoad: [ValidateTokenGuard],
    canActivate: [ValidateTokenGuard, RoleGuard],
    canActivateChild: [ValidateTokenGuard, RoleGuard],
    data: {
      roles: ["admin", "team"],
    },
  },
  {
    path: "products",
    loadChildren: () =>
      import("./products/products.module").then((m) => m.ProductsPageModule),
    canLoad: [ValidateTokenGuard],
    canActivate: [ValidateTokenGuard, RoleGuard, ProducerGuard],
    canActivateChild: [ValidateTokenGuard, RoleGuard, ProducerGuard],
    data: {
      roles: ["admin", "producerAdmin", "producerEmployee", "team"],
    },
  },
  {
    path: "actions",
    loadChildren: () =>
      import("./actions/actions.module").then((m) => m.ActionsPageModule),
    canLoad: [ValidateTokenGuard],
    canActivate: [ValidateTokenGuard, RoleGuard, ProducerGuard],
    canActivateChild: [ValidateTokenGuard, RoleGuard, ProducerGuard],
    data: {
      roles: ["admin", "producerAdmin", "producerEmployee", "team"],
    },
  },
  {
    path: "batches",
    loadChildren: () =>
      import("./batches/batches.module").then((m) => m.BatchesPageModule),
    canLoad: [ValidateTokenGuard],
    canActivate: [ValidateTokenGuard, RoleGuard, ProducerGuard],
    canActivateChild: [ValidateTokenGuard, RoleGuard, ProducerGuard],
    data: {
      roles: ["admin", "producerAdmin", "producerEmployee", "team"],
    },
  },
  {
    path: "categories",
    loadChildren: () =>
      import("./categories/categories.module").then(
        (m) => m.CategoriesPageModule
      ),
    canLoad: [ValidateTokenGuard],
    canActivate: [ValidateTokenGuard, RoleGuard],
    canActivateChild: [ValidateTokenGuard, RoleGuard],
    data: {
      roles: ["admin", "producerAdmin", "producerEmployee", "team"],
    },
  },
  {
    path: "resources",
    loadChildren: () =>
      import("./resources/resources.module").then((m) => m.ResourcesPageModule),
    canLoad: [ValidateTokenGuard],
    canActivate: [ValidateTokenGuard, RoleGuard],
    canActivateChild: [ValidateTokenGuard, RoleGuard],
    data: {
      roles: ["admin", "producerAdmin", "producerEmployee", "team"],
    },
  },
  {
    path: "springboard",
    loadChildren: () =>
      import("./springboard/springboard.module").then(
        (m) => m.SpringboardPageModule
      ),
    canLoad: [ValidateTokenGuard],
    canActivate: [ValidateTokenGuard, RoleGuard],
    canActivateChild: [ValidateTokenGuard, RoleGuard],
    data: {
      roles: ["admin", "team"],
    },
  },
  {
    path: "files",
    loadChildren: () =>
      import("./files/files.module").then((m) => m.FilesPageModule),
    canLoad: [ValidateTokenGuard],
    canActivate: [ValidateTokenGuard, RoleGuard],
    canActivateChild: [ValidateTokenGuard, RoleGuard],
    data: {
      roles: ["admin", "team"],
    },
  },
  {
    path: "chat",
    loadChildren: () =>
      import("./chat/chat.module").then((m) => m.ChatPageModule),
    canLoad: [ValidateTokenGuard],
    canActivate: [ValidateTokenGuard, RoleGuard],
    canActivateChild: [ValidateTokenGuard, RoleGuard],
    data: {
      roles: ["admin", "team"],
    },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

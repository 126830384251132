import { NgModule } from "@angular/core";
import { environment } from "@environments/environment";

import { APOLLO_OPTIONS } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";

import {
  ApolloLink,
  InMemoryCache,
  ServerError,
  split,
} from "@apollo/client/core";
import { RetryLink } from "apollo-link-retry"; // @todo Replace with Offix Offline Plugin
import { CachePersistor, IonicStorageWrapper } from "apollo3-cache-persist";
import { setContext } from "@apollo/client/link/context";
import { WebSocketLink } from "apollo-link-ws";
import { onError } from "apollo-link-error";

import { Network } from "@capacitor/network";

import introspectionResult from "generated/types.graphql-gen";
import { getMainDefinition } from "@apollo/client/utilities";
import { OperationDefinitionNode } from "graphql";
import QueueLink from "apollo-link-queue";

import { StorageService } from "./shared/services/storage.service";

// This should be in sync with update information inside ngsw-config.json file
export const SCHEMA_VERSION = "1.0.2-alpha"; // Must be a string.
export const SCHEMA_VERSION_KEY = "apollo-schema-version";

const uri = environment.hasura.graphql; // <-- add the URL of the GraphQL server here
const websocket = environment.hasura.websocket; // <-- add the URL of the GraphQL server here

export function createApollo(httpLink: HttpLink, ionicStorage: StorageService) {
  // Read the current schema version from AsyncStorage.
  const currentVersion = localStorage.getItem(SCHEMA_VERSION_KEY);

  // Get the authentication token from local storage if it exists
  const asyncAuth = setContext(async (_, { headers }) => {
    // Grab token if there is one in storage or hasn't expired
    let token = "";

    try {
      token = await ionicStorage.get("token");
    } catch (error) {
      console.error(error);
      return {};
    }

    if (token) {
      // Return the headers as usual
      return {
        headers: {
          ...headers,
          Authorization: token ? `Bearer ${token}` : "",
        },
      };
    } else {
      return {
        headers: {
          ...headers,
        },
      };
    }
  });

  // Create an http link:
  // const http = httpLink.create({ uri });
  const http = httpLink.create({
    uri: environment.hasura.graphql,
  });

  // Create a WebSocket link:
  const ws = new WebSocketLink({
    uri: websocket,
    options: {
      reconnect: true,
      lazy: true,
      timeout: 30000,
      connectionParams: async () => {
        const token = await ionicStorage.get("token");
        return {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
          },
        };
      },
    },
  });

  const retryLink = new RetryLink({});

  const offlineLink = new QueueLink();

  // Network Status
  Network.addListener("networkStatusChange", (status) => {
    console.log("Network status changed", status);
    status.connected ? offlineLink.open() : offlineLink.close();
  });

  // const link = ApolloLink.from([auth, http]);
  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  const link = ApolloLink.from([
    asyncAuth,
    retryLink,
    offlineLink as any,
    onError(({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path, extensions }) => {
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Code: ${extensions?.code}`
          );
          switch (extensions?.code) {
            case "invalid-jwt":
              // const oldHeaders = operation.getContext().headers;
              // operation.setContext({
              //   headers: {
              //     ...oldHeaders,
              //     authorization: getNewToken(),
              //   },
              // });
              // // retry the request, returning the new observable
              // return forward(operation);
              break;
            default:
              break;
          }
        });
      if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        console.error(networkError);
        // alert("New network error. Check console");
      }
    }),
    split(
      // split based on operation type
      ({ query }) => {
        const { kind, operation } = getMainDefinition(
          query
        ) as OperationDefinitionNode;
        return kind === "OperationDefinition" && operation === "subscription";
      },
      ws as any,
      http
    ),
  ]);

  const cache = new InMemoryCache({
    possibleTypes: introspectionResult.possibleTypes,
    typePolicies: {
      actions_resources: {
        keyFields: ["action_id", "resource_id", "usage"],
      },
      actions_category_items: {
        keyFields: ["action_id", "category_id"],
      },
      category_default_types: {
        keyFields: ["value"],
      },
      resource_default_types: {
        keyFields: ["value"],
      },
      Actions: {
        fields: {
          actions_resources: {
            merge: false,
          },
          actions_category_items: {
            merge: false,
          },
          tasks: {
            merge: false,
          },
          unsorted_task: {
            merge: false,
          },
          datafields: {
            merge: false,
          },
          resource_default_types: {
            merge: false,
          },
          resource_types: {
            merge: false,
          },
          resources: {
            merge: false,
          },
        },
      },

      Query: {
        fields: {
          messages: {
            // Short for always preferring incoming over existing data.
            merge: false,
          },
          categories: {
            // Short for always preferring incoming over existing data.
            merge: false,
          },
          products: {
            merge: false,
          },
          actions: {
            merge: false,
          },
          "actions.tasks": {
            merge: false,
          },
          "actions.unsorted_tasks": {
            merge: false,
          },
          actions_resources: {
            merge: false,
          },
          actions_category_items: {
            merge: false,
          },
          tasks: {
            merge: false,
          },
          datafields: {
            merge: false,
          },
          files: {
            merge: false,
          },
          category_default_types: {
            merge: false,
          },
          category_types: {
            merge: false,
          },
          category_items: {
            merge: false,
          },
          category_locations: {
            merge: false,
          },
          category_machines: {
            merge: false,
          },
          logs: {
            merge: false,
          },
          "logs.children": {
            merge: false,
          },
          product_resources: {
            merge: false,
          },
          "product_resources.resources": {
            merge: false,
          },
          "product_resources.products": {
            merge: false,
          },
          resource_usage_in_tasks: {
            merge: false,
          },
          resource_usage_in_tasks_flatten: {
            merge: false,
          },
          resource_default_types: {
            merge: false,
          },
          resource_types: {
            merge: false,
          },
          resources: {
            merge: false,
          },
        },
      },
    },
  });

  // await before instantiating ApolloClient, else queries might run before the cache is persisted
  const persistor = new CachePersistor({
    cache,
    storage: new IonicStorageWrapper(ionicStorage),
    debug: environment.production ? false : true,
    key: "material.guide",
  });

  if (currentVersion === SCHEMA_VERSION) {
    // If the current version matches the latest version,
    // we're good to go and can restore the cache.
    persistor.restore();
  } else {
    // Otherwise, we'll want to purge the outdated persisted cache
    // and mark ourselves as having updated to the latest version.
    persistor
      .purge()
      .then((_) => localStorage.setItem(SCHEMA_VERSION_KEY, SCHEMA_VERSION));
    ionicStorage.clear();
  }

  return {
    cache,
    persistor,
    link: link,
    ssrMode: false,
    fetchPolicy: "cache-and-network",
    resolvers: {
      category_default_types: {
        _used: (categoryDefaultType) => Boolean(categoryDefaultType._used),
      },
      category_types: {
        _deleted: (categoryType) => Boolean(categoryType._deleted),
      },
      category_items: {
        _deleted: (categoryItem) => Boolean(categoryItem._deleted),
      },
      resource_default_types: {
        _used: (resourceDefaultType) => Boolean(resourceDefaultType._used),
      },
      resource_types: {
        _deleted: (resourceType) => Boolean(resourceType._deleted),
      },
      resources: {
        _deleted: (resourceItem) => Boolean(resourceItem._deleted),
      },
    },
  };
}

@NgModule({
  exports: [],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, StorageService],
    },
  ],
})
export class GraphQLModule {}

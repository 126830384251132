import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { switchMap } from "rxjs/operators";
import { AuthService } from "src/app/shared/services/auth.service";

@Injectable({
  providedIn: "root",
})
export class ProducerGuard implements CanActivate, CanActivateChild {
  constructor(private authService: AuthService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    let url: string = state.url;
    return this.checkSelectedProducer(next, url);
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(next, state);
  }

  checkSelectedProducer(
    route: ActivatedRouteSnapshot,
    url: any
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.authService.getProducerUid().pipe(
      switchMap((uid) => {
        // console.log(route, url, uid);
        // if (
        //   route.data.roles &&
        //   roles.filter((role) => route.data.roles.includes(role)).length > 0
        // ) {
        //   return of(true);
        // }
        // console.log(url, route);
        // this.router.navigate(
        //   route.data.redirect ? [url, ...route.data.redirect] : ["/intro"]
        // );
        return of(true);
      })
    );
  }
}

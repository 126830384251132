import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  Renderer2,
} from "@angular/core";

import {
  IonRouterOutlet,
  MenuController,
  ModalController,
  Platform,
} from "@ionic/angular";
import { SplashScreen } from "@capacitor/splash-screen";
import { StatusBar, Style } from "@capacitor/status-bar";
import { Router } from "@angular/router";
import { Observable } from "rxjs";

import { PromptUpdateService } from "./utility/services/service-worker.service";
import { SwitchProducerComponent } from "./producers/modals/switch-producer/switch-producer.component";
import { AuthService } from "./shared/services/auth.service";
import { SubscriptionResult } from "apollo-angular";
import { shareReplay, switchMap, tap } from "rxjs/operators";
import { GetProducerGQL, ProducerFragment } from "generated/types.graphql-gen";

import { SCHEMA_VERSION } from "./graphql.module";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { DOCUMENT } from "@angular/common";
import { AppService } from "./shared/services/app.service";
import { HasuraService } from "./shared/services/hasura.service";

@UntilDestroy()
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent {
  public SCHEMA_VERSION = SCHEMA_VERSION;

  producer$: Observable<SubscriptionResult<any>>;

  isAdmin$: Observable<boolean>;
  isLoggedIn$: Observable<boolean>;

  constructor(
    private appService: AppService,
    private hasuraService: HasuraService,
    private platform: Platform,
    private authService: AuthService,
    private router: Router,
    private menuCtrl: MenuController,
    private promptUpdateService: PromptUpdateService,
    private modalCtrl: ModalController,
    private getProducerGQL: GetProducerGQL
  ) {
    this.initializeApp();
  }

  async initializeApp() {
    await this.platform.ready();

    try {
      await StatusBar.setStyle({ style: Style.Default });
    } catch (error) {}

    this.isAdmin$ = this.authService.isAdmin();
    this.isLoggedIn$ = this.authService.isLoggedIn();
    this.producer$ = this.hasuraService.getProducer();

    try {
      await SplashScreen.hide();
    } catch (error) {}
  }

  logout() {
    this.authService.logout();
    this.router.navigate(["/auth"]).then((_) => this.menuCtrl.close());
  }

  async presentSwitchProducerModal() {
    const modal = await this.modalCtrl.create({
      component: SwitchProducerComponent,
      swipeToClose: true,
    });
    modal.present();
    await modal.onDidDismiss();
    this.menuCtrl.close();
  }
}

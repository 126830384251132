import { Component, ElementRef, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { throwServerError } from "@apollo/client/core";
import { IonRouterOutlet, ModalController } from "@ionic/angular";
import { SubscriptionResult } from "apollo-angular";
import { GetProducersGQL } from "generated/types.graphql-gen";
import { Observable } from "rxjs";
import { NewProducerComponent } from "src/app/producers/modals/new-producer/new-producer.component";
import { AuthService } from "src/app/shared/services/auth.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: "app-switch-producer",
  templateUrl: "./switch-producer.component.html",
  styleUrls: ["./switch-producer.component.scss"],
})
export class SwitchProducerComponent implements OnInit {
  public producers$: Observable<SubscriptionResult<any>>;
  public selectedProducerUid: string = null;

  constructor(
    private getProducersService: GetProducersGQL,
    private modalCtrl: ModalController,
    private elRef: ElementRef,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.producers$ = this.getProducersService
      .watch({}, { fetchPolicy: "cache-and-network" })
      .valueChanges.pipe(untilDestroyed(this));
    this.authService
      .getProducerUid()
      .subscribe((uid) => (this.selectedProducerUid = uid));
  }

  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }

  reloadProducers(event: any) {
    console.log(event);
    this.getProducersService
      .fetch({}, { fetchPolicy: "network-only" })
      .subscribe((_) => event.target.complete());
  }

  async presentNewProducerModal() {
    const modal = await this.modalCtrl.create({
      component: NewProducerComponent,
      swipeToClose: true,
      presentingElement: this.elRef.nativeElement,
      // if modal inside modal
      // presentingElement: await this.modalCtrl.getTop(),
    });

    return await modal.present();
  }

  switchProducer(producer: any) {
    this.authService.switchProducer(producer.uid);
    this.router.navigate(["/", "intro"]);
    this.dismiss();
  }
}

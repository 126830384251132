import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";

import { ResourceInputFieldComponent } from "./resource-input-field.component";

@NgModule({
  declarations: [ResourceInputFieldComponent],
  imports: [CommonModule, FormsModule, IonicModule, ReactiveFormsModule, TranslateModule],
  exports: [ResourceInputFieldComponent],
})
export class ResourceInputFieldModule {}

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LoadingController, ModalController } from "@ionic/angular";
import { Producers_Category } from "generated/types.graphql-gen";
import { HasuraService } from "src/app/shared/services/hasura.service";

@Component({
  selector: "app-new-producer",
  templateUrl: "./new-producer.component.html",
  styleUrls: ["./new-producer.component.scss"],
})
export class NewProducerComponent implements OnInit {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private loadingCtrl: LoadingController,
    private modalCtrl: ModalController,
    private hasuraService: HasuraService
  ) {
    this.form = this.fb.group({
      title: ["", Validators.required],
    });
  }

  ngOnInit() {}

  async createProducer() {
    const producer: Producers_Category = this.form.value;

    this.form.disable();

    const loader = await this.loadingCtrl.create({
      keyboardClose: true,
      message: `Creating Producer ${producer.title}`,
      translucent: true,
    });

    loader.present();

    this.hasuraService
      .createProducer(producer.title)
      .subscribe(async (uid: string) => {
        // if (uid) {
        //   await this.router.navigate(["/", "producers", uid]);
        // }
        loader.dismiss();
        this.dismiss();
      });
  }
  dismiss() {
    // using the injected ModalController this page
    // can "dismiss" itself and optionally pass back data
    this.modalCtrl.dismiss({
      dismissed: true,
    });
  }
}

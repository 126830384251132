import { NgModule } from "@angular/core";

import { FilterByTypePipe } from "./filter-by-type.pipe";

@NgModule({
  imports: [],
  exports: [FilterByTypePipe],
  declarations: [FilterByTypePipe],
  providers: [],
})
export class FilterByTypePipeModule {}

import { NgModule } from "@angular/core";
import { CommonModule, DecimalPipe } from "@angular/common";
import { TimeTrackPipe } from "./time-track.pipe";
import { ChunkPipe } from "angular-pipes";

@NgModule({
  providers: [DecimalPipe, ChunkPipe],
  declarations: [TimeTrackPipe],
  imports: [CommonModule],
  exports: [TimeTrackPipe],
})
export class TimeTrackModule {}

import {
  Directive,
  HostListener,
  Output,
  EventEmitter,
  ElementRef,
  Renderer2,
} from "@angular/core";

@Directive({
  selector: "[dropZone]",
})
export class DropZoneDirective {
  @Output()
  dropped = new EventEmitter<FileList>();
  @Output()
  hovered = new EventEmitter<boolean>();

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.renderer.addClass(this.el.nativeElement, "dropzone");
  }

  @HostListener("drop", ["$event"])
  onDrop($event) {
    $event.preventDefault();
    this.dropped.emit($event.dataTransfer.files);
    this.hovered.emit(false);
    this.renderer.removeClass(this.el.nativeElement, "dropzone--over");
  }

  @HostListener("dragover", ["$event"])
  onDragOver($event) {
    $event.preventDefault();
    this.hovered.emit(true);
    this.renderer.addClass(this.el.nativeElement, "dropzone--over");
  }

  @HostListener("dragleave", ["$event"])
  onDragLeave($event) {
    $event.preventDefault();
    this.hovered.emit(false);
    this.renderer.removeClass(this.el.nativeElement, "dropzone--over");
  }
}

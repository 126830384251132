import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterByCategoryType",
})
export class FilterByCategoryTypePipe implements PipeTransform {
  transform(categories: Array<any>, type_id: any): Array<any> {
    if (!categories) return categories;
    return categories.filter(
      (categoryObject) =>
        (categoryObject.type_id || categoryObject.category?.type_id) === type_id
    );
  }
}

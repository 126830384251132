import { Injectable } from "@angular/core";

import { Storage } from "@ionic/storage-angular";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class StorageService {
  private _storage: Storage | null = null;

  initialisationPromise: Promise<boolean> | null = null;

  constructor(private storage: Storage) {
    // INIT HAPPES INSIDE APP_INITIALIZER
    // this.init();
  }

  public async init() {
    if (this._storage == null) {
      const storage = await this.storage.create();
      this._storage = storage;
    }
  }

  public get(key: string) {
    return this._storage?.get(key);
  }

  public set(key: string, value: any) {
    return this._storage?.set(key, value);
  }
  public remove(key: string) {
    return this._storage?.remove(key);
  }

  public clear() {
    return this._storage?.clear();
  }
  public keys() {
    return this._storage?.keys();
  }
}

import { ApplicationRef, Injectable } from "@angular/core";
import { SwUpdate, UpdateAvailableEvent } from "@angular/service-worker";
import { AlertController } from "@ionic/angular";
import { concat, interval } from "rxjs";
import { first } from "rxjs/operators";

export interface SWUAppData extends Object {
  update: {
    type: "MAJOR" | "MINOR" | "PATCH";
    message: string;
  };
}

@Injectable()
export class PromptUpdateService {
  constructor(
    appRef: ApplicationRef,
    updates: SwUpdate,
    private alertCtrl: AlertController
  ) {
    if (!updates.isEnabled) return;

    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = appRef.isStable.pipe(
      first((isStable) => isStable === true)
    );
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    everySixHoursOnceAppIsStable$.subscribe(() => updates.checkForUpdate());

    updates.available.subscribe((update) => {
      this.presentUpdateAlert(updates, update);
    });

    updates.unrecoverable.subscribe((unrecoverable) => {
      if (confirm(unrecoverable.reason)) {
        updates.activateUpdate().then(() => document.location.reload());
      }
    });
  }

  async presentUpdateAlert(updates: SwUpdate, update: UpdateAvailableEvent) {
    const appData = <SWUAppData>update.available.appData;
    const optional: boolean =
      appData["update"]["type"] === "MAJOR" ? false : true || false;
    const message: string =
      appData["update"]["message"] || "Die Seite muss neu geladen werden!";

    let buttons: Array<any> = [
      {
        text: "Okay",
        handler: () => {
          updates.activateUpdate().then(() => document.location.reload());
        },
      },
    ];

    if (!optional)
      buttons.unshift({
        text: "Abbrechen",
        role: "cancel",
      });

    const updateAlert = await this.alertCtrl.create({
      header: "Neue Version verfügbar",
      subHeader: `Version ${update?.available?.hash.slice(0, 5)}`,
      message: message,
      backdropDismiss: optional,
      buttons,
    });
    updateAlert.present();
  }
}

import { Injectable } from "@angular/core";
import {
  AlertController,
  LoadingController,
  NavController,
} from "@ionic/angular";
import {
  CreateProducerGQL,
  DeleteProducerGQL,
  UpdateProducerGQL,
  GetProducersDocument,
  ProducerFragmentDoc,
  CreateProductGQL,
  UpdateProductGQL,
  DeleteProductGQL,
  GetProductsDocument,
  ProductFragmentDoc,
  CreateActionGQL,
  UpdateActionGQL,
  DeleteActionGQL,
  ActionFragmentDoc,
  GetActionDocument,
  AddActionTaskGQL,
  UpdateActionTasksGQL,
  DeleteActionTaskGQL,
  Tasks_Insert_Input,
  GetActionTaskDocument,
  GetActionsDocument,
  TaskFragmentDoc,
  UploadFileGQL,
  UploadFilesGQL,
  UpdateFileGQL,
  DeleteFileGQL,
  GetFilesDocument,
  UpdateTaskGQL,
  Tasks_Set_Input,
  Actions_Set_Input,
  Datafields_Insert_Input,
  DatafieldFragmentDoc,
  AddDatafieldGQL,
  UpdateDatafieldGQL,
  DeleteDatafieldGQL,
  GetTaskDatafieldsDocument,
  UpdateTaskDatafieldsGQL,
  RestoreActionGQL,
  AddNewLogGQL,
  AddDatafieldLogGQL,
  AddDatafieldLogMutationVariables,
  DeleteDatafieldLogGQL,
  LogFragmentDoc,
  GetLatestLogGQL,
  Logs_Insert_Input,
  GetLogsDocument,
  AddTaskResourceGQL,
  DeleteTaskResourceGQL,
  GetTaskResourcesDocument,
  GetLogTaskToResumeGQL,
  Products_Set_Input,
  GetUnusedCategoryTypesGQL,
  AddCategoryTypeGQL,
  GetCategoryTypesGQL,
  DeleteCategoryTypeGQL,
  GetCategoryTypesDocument,
  AddCategoryItemGQL,
  DeleteCategoryItemGQL,
  CategoryDefaultTypeFragmentDoc,
  CategoryTypeFragmentDoc,
  GetUnusedCategoryTypesDocument,
  GetUnusedResourceTypesGQL,
  AddResourceTypeGQL,
  GetResourceTypesGQL,
  DeleteResourceTypeGQL,
  AddResourceItemGQL,
  DeleteResourceItemGQL,
  ResourceDefaultTypeFragmentDoc,
  GetUnusedResourceTypesDocument,
  GetResourceTypesDocument,
  ResourceTypeFragmentDoc,
  AddActionResourceGQL,
  DeleteActionResourceGQL,
  UpdateActionResourceGQL,
  Usage_Types_Enum,
  GetActionResourcesDocument,
  AddActionCategoryItemGQL,
  DeleteActionCategoryItemsGQL,
  GetActionCategoryItemsDocument,
  CategoryItemFragmentDoc,
  ResourceItemFragmentDoc,
  GetProducerGQL,
  ProducerFragment,
  AddTaskLogGQL,
  AddTaskLogMutationVariables,
  GetActionLogGQL,
  CloseActionLogGQL,
  GetDatafieldGQL,
} from "generated/types.graphql-gen";
import { Observable, of } from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  first,
  map,
  share,
  shareReplay,
  switchMap,
  tap,
} from "rxjs/operators";
import { S3File } from "../components/file-upload/file-upload.component";
import { AppService } from "./app.service";
import { AuthService } from "./auth.service";

import { v4 as uuidv4 } from "uuid"; // for dev
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { resolve } from "dns";

@UntilDestroy()
@Injectable({ providedIn: "root" })
export class HasuraService {
  constructor(
    private appService: AppService,
    private authService: AuthService,
    private alertCtrl: AlertController,
    private navCtrl: NavController,
    private loadingCtrl: LoadingController,

    // Producers
    private getProducerGQL: GetProducerGQL,
    private createProducerGQL: CreateProducerGQL,
    private updateProducerGQL: UpdateProducerGQL,
    private deleteProducerGQL: DeleteProducerGQL,

    // Products
    private createProductGQL: CreateProductGQL,
    private updateProductGQL: UpdateProductGQL,
    private deleteProductGQL: DeleteProductGQL,

    // Actions
    private createActionGQL: CreateActionGQL,
    private updateActionGQL: UpdateActionGQL,
    private deleteActionGQL: DeleteActionGQL,
    private restoreActionGQL: RestoreActionGQL,

    // Tasks
    private addActionTaskGQL: AddActionTaskGQL,
    private updateActionTasksGQL: UpdateActionTasksGQL,
    private deleteActionTaskGQL: DeleteActionTaskGQL,
    private updateTaskGQL: UpdateTaskGQL,

    // Datafield
    private addDatafieldGQL: AddDatafieldGQL,
    private getDatafieldGQL: GetDatafieldGQL,
    private updateDatafieldGQL: UpdateDatafieldGQL,
    private updateTaskDatafieldsGQL: UpdateTaskDatafieldsGQL,
    private deleteDatafieldGQL: DeleteDatafieldGQL,

    // Resources
    private getUnusedResourceTypesGQL: GetUnusedResourceTypesGQL,
    private addResourceTypeGQL: AddResourceTypeGQL,
    private getResourceTypesGQL: GetResourceTypesGQL,
    private deleteResourceTypeGQL: DeleteResourceTypeGQL,
    private addResourceItemGQL: AddResourceItemGQL,
    private deleteResourceItemGQL: DeleteResourceItemGQL,

    // Action Categories
    private addActionCategoryItemGQL: AddActionCategoryItemGQL,
    private deleteActionCategoryItemsGQL: DeleteActionCategoryItemsGQL,

    // Action Resources
    private addActionResourceGQL: AddActionResourceGQL,
    private deleteActionResourceGQL: DeleteActionResourceGQL,
    private updateActionResourceGQL: UpdateActionResourceGQL,

    // Task Resources
    private addTaskResourceGQL: AddTaskResourceGQL,
    private deleteTaskResourceGQL: DeleteTaskResourceGQL,

    // Action Log aka the Batch
    private getActionLogGQL: GetActionLogGQL,
    private closeActionLogGQL: CloseActionLogGQL,

    // Logs
    private addNewLogGQL: AddNewLogGQL,
    private getLatestLogGQL: GetLatestLogGQL,
    private getLogTaskToResumeGQL: GetLogTaskToResumeGQL,
    private addDatafieldLogGQL: AddDatafieldLogGQL,
    private deleteDatafieldLogGQL: DeleteDatafieldLogGQL,
    private addTaskLogGQL: AddTaskLogGQL,

    // Files
    private uploadFileGQL: UploadFileGQL,
    private uploadFilesGQL: UploadFilesGQL,
    private updateFileGQL: UpdateFileGQL,
    private deleteFileGQL: DeleteFileGQL,

    // Categories
    private getUnusedCategoryTypesGQL: GetUnusedCategoryTypesGQL,
    private addCategoryTypeGQL: AddCategoryTypeGQL,
    private getCategoryTypesGQL: GetCategoryTypesGQL,
    private deleteCategoryTypeGQL: DeleteCategoryTypeGQL,
    private addCategoryItemGQL: AddCategoryItemGQL,
    private deleteCategoryItemGQL: DeleteCategoryItemGQL
  ) {}

  public getProducer() {
    return this.authService.getCurrentLoginStatus().pipe(
      distinctUntilChanged(),
      switchMap((auth) => {
        return this.getProducerGQL
          .watch(
            { uid: auth.data.selectedProducerUid },
            {
              fetchPolicy: "cache-and-network",
            }
          )
          .valueChanges.pipe(untilDestroyed(this), share());
      }),
      tap((producer) => {
        const introImage = (producer.data.producer as ProducerFragment)
          ?.introImage[0];
        if (introImage?.url || introImage?.embeddedAsset) {
          this.appService.setBackgroundImage(
            introImage?.embeddedAsset?.image ?? introImage?.url
          );
        }
      }),
      shareReplay()
    );
  }

  /**
   *  PRODUCER FUNCTIONS
   */

  public createProducer(title: string): Observable<string> {
    return this.createProducerGQL
      .mutate(
        { title },
        {
          context: {
            headers: {
              "X-Hasura-Role": "team",
            },
          },
          update: (store, { data: { ...newProducer } }) => {
            // Read the data from our cache for this query.
            const { ...data }: any = store.readQuery({
              query: GetProducersDocument,
            });
            // Add our message from the mutation to the end.
            data.producers = [
              newProducer.save_producers_Category,
              ...data.producers,
            ];
            console.log(data);
            // Write our data back to the cache.
            store.writeQuery({ query: GetProducersDocument, data });
          },
        }
      )
      .pipe(
        switchMap((resp) => {
          if (resp.errors) {
            resp.errors.forEach((error) =>
              this.appService.errorMessage(error.message)
            );
            return of(null);
          } else if (resp.data?.save_producers_Category?.uid) {
            return of(resp.data?.save_producers_Category?.uid);
          }
        }),
        catchError((error) => {
          this.appService.errorMessage(error);
          return of(null);
        })
      );
  }

  public updateProducer(uid: string, title: string): Observable<string> {
    return this.updateProducerGQL
      .mutate(
        { uid, title },
        {
          context: {
            headers: {
              "X-Hasura-Role": "team",
            },
          },
          update: (store, { data: { ...editedProducer } }) => {
            // Read the data from our cache for this query.
            let { ...data }: any = store.readFragment({
              id: `producers_Category:${uid}`,
              fragment: ProducerFragmentDoc,
            });
            console.log(data, editedProducer);
            // Add our message from the mutation to the end.
            data = { ...data, ...editedProducer.save_producers_Category };
            // Write our data back to the cache.
            store.writeFragment({
              id: `producers_Category:${data.id}`,
              fragment: ProducerFragmentDoc,
              data,
            });
          },
        }
      )
      .pipe(
        switchMap((resp) => {
          if (resp.errors) {
            resp.errors.forEach((error) =>
              this.appService.errorMessage(error.message)
            );
            return of(null);
          } else if (resp.data?.save_producers_Category?.uid) {
            return of(resp.data?.save_producers_Category?.uid);
          }
        }),
        catchError((error) => {
          this.appService.errorMessage(error);
          return of(null);
        })
      );
  }

  public async deleteProducer(id: number) {
    const alert = await this.alertCtrl.create({
      header: "Hersteller wirklich löschen?",
      message:
        "Zum endgültigen Löschen bitte den Papierkorb in Craft CMS leeren!",
      buttons: [
        {
          text: "Abbrechen",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Löschen",
          handler: () => this.deleteProducerConfirmed(id),
        },
      ],
    });

    await alert.present();
  }
  private deleteProducerConfirmed = (id: number) => {
    this.deleteProducerGQL
      .mutate(
        {
          id,
        },
        {
          context: {
            headers: {
              "X-Hasura-Role": "team",
            },
          },
          update: (store, { data: { ...deletedProducer } }) => {
            // Read the data from our cache for this query.
            const { ...data }: any = store.readQuery({
              query: GetProducersDocument,
            });
            // Filter array by deleted producer id
            data.producers = [
              ...data.producers.filter((producer) => producer.id !== id),
            ];
            console.log(data);
            // Write our data back to the cache.
            store.writeQuery({ query: GetProducersDocument, data });
          },
        }
      )
      .subscribe(() => this.navCtrl.back());
  };

  /**
   * PRODUCT FUNCTIONS FOR ADMINS
   */

  createProduct(name: string, description: string) {
    return this.authService.getCurrentLoginStatus().pipe(
      first(),
      switchMap((auth) => {
        let object: any = {
          name,
          description,
        };

        if (auth.data.isAdmin)
          object = { ...object, producer_id: auth.data.selectedProducerUid };

        console.warn(object);

        return this.createProductGQL.mutate(
          {
            object,
          },
          {
            context: {
              headers: {
                "X-Hasura-Role": auth.data.isAdmin ? "team" : "producerAdmin",
              },
            },
            update: (store, { data: { ...newProduct } }) => {
              const variables = {
                producer_id: newProduct.insert_products_one.producer_id,
              };
              // Read the data from our cache for this query.
              const { ...data }: any = store.readQuery({
                query: GetProductsDocument,
                variables,
              });
              // Add our product from the mutation to the end.
              data.products = [
                ...(data.products || []),
                newProduct.insert_products_one,
              ];
              // Write our data back to the cache.
              store.writeQuery({ query: GetProductsDocument, variables, data });
            },
          }
        );
      }),
      switchMap((resp) => {
        if (resp.errors) {
          resp.errors.forEach((error) =>
            this.appService.errorMessage(error.message)
          );
          return of(null);
        } else if (resp.data?.insert_products_one?.id) {
          return of(resp.data?.insert_products_one?.id);
        }
      }),
      catchError((error) => {
        this.appService.errorMessage(error);
        return of(null);
      })
    );
  }

  updateProduct(id: number, _set: Products_Set_Input) {
    return this.updateProductGQL
      .mutate(
        { id, _set },
        {
          update: (store, { data: { ...updatedProduct } }) => {
            // Read the data from our cache for this query.
            let { ...data }: any = store.readFragment({
              id: `products:${id}`,
              fragment: ProductFragmentDoc,
            });
            // console.log(data, deletedProduct);
            // Add our message from the mutation to the end.
            data = { ...data, ...updatedProduct.update_products_by_pk };
            // Write our data back to the cache.
            store.writeFragment({
              id: `products:${data.id}`,
              fragment: ProductFragmentDoc,
              data,
            });
          },
        }
      )
      .pipe(
        catchError((error) => {
          this.appService.errorMessage(error);
          return of(null);
        })
      );
  }

  public async deleteProduct(id: string) {
    const alert = await this.alertCtrl.create({
      header: "Produkt wirklich löschen?",
      message:
        "Produkt kann 14 Tage lang wiederhergestellt werden bevor es unwiderruflich gelöscht wird.",
      buttons: [
        {
          text: "Abbrechen",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Löschen",
          handler: () => this.deleteProductConfirmed(id),
        },
      ],
    });

    await alert.present();
  }

  private deleteProductConfirmed = (id: string) => {
    this.deleteProductGQL
      .mutate(
        {
          id,
        },
        {
          update: (store, { data: { ...deletedProduct } }) => {
            // Read the data from our cache for this query.
            let { ...data }: any = store.readFragment({
              id: `products:${id}`,
              fragment: ProductFragmentDoc,
            });
            // console.log(data, deletedProduct);
            // Add our message from the mutation to the end.
            data = { ...data, ...deletedProduct.update_products_by_pk };
            // Write our data back to the cache.
            store.writeFragment({
              id: `products:${data.id}`,
              fragment: ProductFragmentDoc,
              data,
            });
          },
        }
      )
      .subscribe(() => this.navCtrl.back());
  };

  /**
   * Actions
   */

  createAction(name: string, note: string, related_to: string) {
    return this.authService.getCurrentLoginStatus().pipe(
      first(),
      switchMap((auth) => {
        let object: any = {
          name,
          note,
        };

        if (related_to)
          object = {
            ...object,
            related_to,
          };
        if (auth.data.isAdmin)
          object = { ...object, producer_id: auth.data.selectedProducerUid };

        console.warn(object);

        return this.createActionGQL.mutate(
          {
            object,
          },
          {
            context: {
              headers: {
                "X-Hasura-Role": auth.data.isAdmin ? "team" : "producerAdmin",
              },
            },
            update: (store, { data: { ...newAction } }) => {
              // only add product_id if there is a relationship
              const variables =
                newAction.insert_actions_one.related_to &&
                related_to === newAction.insert_actions_one.related_to
                  ? {
                      product_id: newAction.insert_actions_one.related_to,
                    }
                  : {};

              // Read the data from our cache for this query.
              const { ...data }: any = store.readQuery({
                query: GetActionsDocument,
                variables,
              });
              console.warn(data);
              // Add our product from the mutation to the end.
              data.actions = {
                ...data.actions,
                ...newAction.insert_actions_one,
              };
              // Write our data back to the cache.
              store.writeQuery({ query: GetActionsDocument, variables, data });
            },
          }
        );
      }),
      switchMap((resp) => {
        if (resp.errors) {
          resp.errors.forEach((error) =>
            this.appService.errorMessage(error.message)
          );
          return of(null);
        } else if (resp.data?.insert_actions_one?.id) {
          return of(resp.data?.insert_actions_one?.id);
        }
      }),
      catchError((error) => {
        this.appService.errorMessage(error);
        return of(null);
      })
    );
  }

  // @todo Add Update Action Logic
  public updateAction(id: string, _set: Actions_Set_Input) {
    return this.updateActionGQL
      .mutate(
        { id, _set },
        {
          update: (store, { data: { ...editedAction } }) => {
            // Read the data from our cache for this query.
            let { ...data }: any = store.readFragment({
              id: `actions:${id}`,
              fragment: ActionFragmentDoc,
              fragmentName: "Action",
            });
            console.log(data, editedAction);
            // Add our message from the mutation to the end.
            data = { ...data, ...editedAction.update_actions_by_pk };
            // Write our data back to the cache.
            store.writeFragment({
              id: `actions:${data.id}`,
              fragment: ActionFragmentDoc,
              data,
              fragmentName: "Action",
            });
          },
        }
      )
      .pipe(
        switchMap((resp) => {
          if (resp.errors) {
            resp.errors.forEach((error) =>
              this.appService.errorMessage(error.message)
            );
            return of(null);
          } else if (resp.data?.update_actions_by_pk?.id) {
            return of(resp.data?.update_actions_by_pk?.id);
          }
        }),
        catchError((error) => {
          this.appService.errorMessage(error);
          return of(null);
        })
      );
  }

  public async deleteAction(
    id: string,
    name: string = "<unknown_action_name>"
  ) {
    const alert = await this.alertCtrl.create({
      header: "Aktion wirklich löschen?",
      message: `Die Aktion <em>${name}</em> wird gelöscht, Log-Dateien bleiben jedoch erhalten.<br><br>Zum Löschen bitte den Namen der Aktion hier eingeben:`,
      inputs: [
        {
          name: "confirmation",
          placeholder: "Name der Aktion",
        },
      ],
      buttons: [
        {
          text: "Abbrechen",
          role: "cancel",
          cssClass: "secondary",
        },
        {
          text: "Löschen",
          handler: (data) => {
            if (data.confirmation === name) {
              this.deleteActionConfirmed(id);
            } else {
              return false;
            }
          },
        },
      ],
    });

    await alert.present();
  }

  private deleteActionConfirmed = (id: string) => {
    this.deleteActionGQL
      .mutate(
        {
          id,
        },
        {
          update: (store, { data: { ...deletedAction } }) => {
            // Read the data from our cache for this query.
            console.log(id, deletedAction);
            let { ...data }: any = store.readFragment({
              id: `actions:${id}`,
              fragment: ActionFragmentDoc,
              fragmentName: "Action",
            });
            console.log(data, deletedAction);
            // Add our message from the mutation to the end.
            data = { ...data, ...deletedAction.update_actions_by_pk };
            // Write our data back to the cache.
            store.writeFragment({
              id: `actions:${data.id}`,
              fragment: ActionFragmentDoc,
              fragmentName: "Action",
              data,
            });
          },
        }
      )
      .subscribe(() => this.navCtrl.back());
  };

  restoreAction(id: string) {
    this.restoreActionGQL
      .mutate(
        {
          id,
        },
        {
          update: (store, { data: { ...restoredAction } }) => {
            // Read the data from our cache for this query.
            console.log(id, restoredAction);
            let { ...data }: any = store.readFragment({
              id: `actions:${id}`,
              fragment: ActionFragmentDoc,
              fragmentName: "Action",
            });
            console.log(data, restoredAction);
            // Add our message from the mutation to the end.
            data = { ...data, ...restoredAction.update_actions_by_pk };
            // Write our data back to the cache.
            store.writeFragment({
              id: `actions:${data.id}`,
              fragment: ActionFragmentDoc,
              fragmentName: "Action",
              data,
            });
          },
        }
      )
      .subscribe();
  }

  addActionTask(action_id: string, object: Tasks_Insert_Input) {
    return this.authService.getCurrentLoginStatus().pipe(
      first(),
      switchMap((auth) => {
        if (auth.data.isAdmin) {
          object.producer_id = auth.data.selectedProducerUid;
          // object.datafields?.data.forEach((datafield) => {
          //   datafield.producer_id = auth.data.selectedProducerUid;
          // });
        }

        // @todo Add default "timestamp" datafield
        // @body currently insert fails due to constraint errors.
        // Set Default "Timestamp" datafield
        // object.datafields = {
        //   data: [
        //     {
        //       ...(new DefaultDatafield() as any),
        //       related_to: action_id,
        //     },
        //   ],
        // };

        return this.addActionTaskGQL.mutate(
          {
            object,
          },
          {
            context: {
              headers: {
                "X-Hasura-Role": auth.data.isAdmin ? "team" : "producerAdmin",
              },
            },

            update: (store, { data: { ...addedActionTask } }) => {
              if (addedActionTask.insert_tasks_one.parent) {
                const variables = {
                  action_id,
                  task_id: object.parent,
                };
                // UPDATE TASKS FOR ACTION ITEM
                // Read the data from our cache for this query.
                const { ...data }: any = store.readQuery({
                  query: GetActionTaskDocument,
                  variables,
                });

                console.log(addedActionTask.insert_tasks_one);
                console.log(object, data.tasks, data, action_id);
                // // Add our product from the mutation to the end.
                data.tasks = [...data.tasks, addedActionTask.insert_tasks_one];
                // Write our data back to the cache.
                store.writeQuery({
                  query: GetActionTaskDocument,
                  variables,
                  data,
                });
              } else {
                const variables = {
                  id: addedActionTask.insert_tasks_one.related_to,
                };
                // If we add a task on main level, update action document instead
                const { ...data }: any = store.readQuery({
                  query: GetActionDocument,
                  variables,
                });

                console.log(addedActionTask.insert_tasks_one);
                console.log(data.actions_by_pk);
                // // Add our product from the mutation to the end.
                data.actions_by_pk = {
                  ...data.actions_by_pk,
                  unsorted_tasks: [
                    ...data.actions_by_pk.unsorted_tasks,
                    addedActionTask.insert_tasks_one,
                  ],
                };
                // Write our data back to the cache.
                store.writeQuery({ query: GetActionDocument, variables, data });
              }
            },
          }
        );
      }),
      switchMap((resp) => {
        if (resp.errors) {
          resp.errors.forEach((error) =>
            this.appService.errorMessage(error.message)
          );
          return of(null);
        } else if (resp.data?.insert_tasks_one) {
          return of(resp.data?.insert_tasks_one);
        }
      }),
      catchError((error) => {
        this.appService.errorMessage(error);
        return of(null);
      })
    );
  }

  updateActionTasks(objects: Array<Tasks_Insert_Input>) {
    return this.authService.getCurrentLoginStatus().pipe(
      first(),
      switchMap((auth) => {
        if (auth.data.isAdmin)
          objects = objects.map((object) => {
            object.producer_id = auth.data.selectedProducerUid;
            return object;
          });
        console.log(objects);
        return this.updateActionTasksGQL.mutate(
          {
            objects,
          },
          {
            context: {
              headers: {
                "X-Hasura-Role": auth.data.isAdmin ? "team" : "producerAdmin",
              },
            },
            update: (store, { data: { ...updatedActionTasks } }) => {
              // @todo fix reorder() jitter due to *ngFor not honoring order field
              // @body to get rid of the reorder junk we need to update the fragment for Action/Task itself
              if (!updatedActionTasks.insert_tasks.returning[0].parent) {
                console.warn("isAction");
              } else {
                console.warn("isTask");
              }
              updatedActionTasks.insert_tasks.returning.forEach(
                (updatedTask) => {
                  // Read the data from our cache for this query.
                  let { ...data }: any = store.readFragment({
                    id: `tasks:${updatedTask.id}`,
                    fragment: TaskFragmentDoc,
                    fragmentName: "Task",
                  });
                  // Add our message from the mutation to the end.
                  data = { ...data, ...updatedTask };
                  // Write our data back to the cache.
                  console.log(data);
                  store.writeFragment({
                    id: `tasks:${updatedTask.id}`,
                    fragment: TaskFragmentDoc,
                    fragmentName: "Task",
                    data,
                  });
                }
              );
            },
          }
        );
      }),
      switchMap((resp) => {
        if (resp.errors) {
          resp.errors.forEach((error) =>
            this.appService.errorMessage(error.message)
          );
          return of(null);
        } else if (resp.data?.insert_tasks) {
          return of(resp.data?.insert_tasks);
        }
      }),
      catchError((error) => {
        this.appService.errorMessage(error);
        return of(null);
      })
    );
  }

  public updateTask(
    id: string,
    _set: Tasks_Set_Input,
    datafields: Array<Datafields_Insert_Input>
  ): Observable<string> {
    return this.updateTaskGQL
      .mutate(
        { id, _set, datafields },
        {
          update: (store, { data: { ...editedTask } }) => {
            // Read the data from our cache for this query.
            let { ...data }: any = store.readFragment({
              id: `tasks:${id}`,
              fragment: TaskFragmentDoc,
              fragmentName: "Task",
            });
            console.log(data, editedTask);
            // Add our message from the mutation to the end.
            data = { ...data, ...editedTask.update_tasks_by_pk };
            // Write our data back to the cache.
            store.writeFragment({
              id: `tasks:${data.id}`,
              fragment: TaskFragmentDoc,
              data,
              fragmentName: "Task",
            });

            editedTask.insert_datafields.returning.forEach(
              (updatedDatafield) => {
                // Read the data from our cache for this query.
                let { ...data }: any = store.readFragment({
                  id: `datafields:${updatedDatafield.id}`,
                  fragment: DatafieldFragmentDoc,
                  fragmentName: "Datafield",
                });
                // Add our message from the mutation to the end.
                data = { ...data, ...updatedDatafield };
                // Write our data back to the cache.
                store.writeFragment({
                  id: `datafields:${updatedDatafield.id}`,
                  fragment: DatafieldFragmentDoc,
                  data,
                  fragmentName: "Datafield",
                });
              }
            );
          },
        }
      )
      .pipe(
        switchMap((resp) => {
          if (resp.errors) {
            resp.errors.forEach((error) =>
              this.appService.errorMessage(error.message)
            );
            return of(null);
          } else if (resp.data?.update_tasks_by_pk?.id) {
            return of(resp.data?.update_tasks_by_pk?.id);
          }
        }),
        catchError((error) => {
          this.appService.errorMessage(error);
          return of(null);
        })
      );
  }

  public async deleteTask(id: string) {
    return new Promise(async (resolve) => {
      const alert = await this.alertCtrl.create({
        header: "Schritt wirklich löschen?",
        message: "Schritt geht unwideruflich verloren",
        buttons: [
          {
            text: "Abbrechen",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              return resolve(false);
            },
          },
          {
            text: "Löschen",
            handler: async () => {
              const result = await this.deleteTaskConfirmed(id);
              return resolve(result);
            },
          },
        ],
      });

      await alert.present();
    });
  }

  private deleteTaskConfirmed = (id: string) => {
    return this.authService
      .getCurrentLoginStatus()
      .pipe(
        first(),
        switchMap((auth) => {
          return this.deleteActionTaskGQL.mutate(
            {
              id,
            },
            {
              context: {
                headers: {
                  "X-Hasura-Role": auth.data.isAdmin ? "team" : "producerAdmin",
                },
              },
              update: (store, { data: { ...deletedTask } }) => {
                if (deletedTask.delete_tasks_by_pk.parent) {
                  const variables = {
                    action_id: deletedTask.delete_tasks_by_pk.related_to,
                    task_id: deletedTask.delete_tasks_by_pk.parent,
                  };
                  // Read the data from our cache for this query.
                  let { ...data }: any = store.readQuery({
                    query: GetActionTaskDocument,
                    variables,
                  });
                  // Filter array by deleted producer id
                  data.tasks = [...data.tasks.filter((task) => task.id !== id)];
                  // Write our data back to the cache.
                  store.writeQuery({
                    query: GetActionTaskDocument,
                    variables,
                    data,
                  });
                } else {
                  const variables = {
                    id: deletedTask.delete_tasks_by_pk.related_to,
                  };
                  // If we deleted a task on main level, update action document instead
                  const { ...data }: any = store.readQuery({
                    query: GetActionDocument,
                    variables,
                  });

                  console.log(deletedTask.delete_tasks_by_pk);
                  console.log(data.actions_by_pk);
                  // // Add our product from the mutation to the end.
                  data.actions_by_pk = {
                    ...data.actions_by_pk,
                    unsorted_tasks: [
                      ...data.actions_by_pk.unsorted_tasks.filter(
                        (task) => task.id !== id
                      ),
                    ],
                  };
                  // Write our data back to the cache.
                  store.writeQuery({
                    query: GetActionDocument,
                    variables,
                    data,
                  });
                }
              },
            }
          );
        }),
        switchMap((resp) => {
          if (resp.errors) {
            resp.errors.forEach((error) =>
              this.appService.errorMessage(error.message)
            );
            return of(false);
          } else {
            return of(true);
          }
        }),
        catchError((error) => {
          this.appService.errorMessage(error);
          return of(false);
        })
      )
      .toPromise();
  };

  /**
   * Datafields
   */

  addDatafield(object: Datafields_Insert_Input) {
    return this.authService.getCurrentLoginStatus().pipe(
      first(),
      switchMap((auth) => {
        console.warn(object);

        return this.addDatafieldGQL.mutate(
          {
            object,
          },
          {
            context: {
              headers: {
                "X-Hasura-Role": auth.data.isAdmin ? "team" : "producerAdmin",
              },
            },

            update: (store, { data: { ...addedDatafield } }) => {
              const variables = {
                id: addedDatafield.insert_datafields_one.task_id,
              };
              // UPDATE TASKS FOR ACTION ITEM
              // Read the data from our cache for this query.
              const { ...data }: any = store.readQuery({
                query: GetTaskDatafieldsDocument,
                variables,
              });
              // // Add our datafield from the mutation to the end.
              data.datafields = [
                ...data.datafields,
                {
                  ...addedDatafield.insert_datafields_one,
                  logs_aggregate: null,
                },
              ];
              // Write our data back to the cache.
              store.writeQuery({
                query: GetTaskDatafieldsDocument,
                variables,
                data,
              });
            },
          }
        );
      }),
      switchMap((resp) => {
        if (resp.errors) {
          resp.errors.forEach((error) =>
            this.appService.errorMessage(error.message)
          );
          return of(null);
        } else if (resp.data?.insert_datafields_one) {
          return of(resp.data?.insert_datafields_one);
        }
      }),
      catchError((error) => {
        this.appService.errorMessage(error);
        return of(null);
      })
    );
  }

  updateTaskDatafields(objects: Array<Datafields_Insert_Input>) {
    return this.authService.getCurrentLoginStatus().pipe(
      first(),
      switchMap((auth) => {
        return this.updateTaskDatafieldsGQL.mutate(
          {
            objects,
          },
          {
            context: {
              headers: {
                "X-Hasura-Role": auth.data.isAdmin ? "team" : "producerAdmin",
              },
            },
            update: (store, { data: { ...updatedTaskDatafields } }) => {
              updatedTaskDatafields.insert_datafields.returning.forEach(
                (updatedDatafield) => {
                  // Read the data from our cache for this query.
                  let { ...data }: any = store.readFragment({
                    id: `datafields:${updatedDatafield.id}`,
                    fragment: DatafieldFragmentDoc,
                    fragmentName: "Datafield",
                  });
                  // Add our message from the mutation to the end.
                  data = { ...data, ...updatedDatafield };
                  // Write our data back to the cache.
                  store.writeFragment({
                    id: `datafields:${updatedDatafield.id}`,
                    fragment: DatafieldFragmentDoc,
                    fragmentName: "Datafield",
                    data,
                  });
                }
              );
            },
          }
        );
      }),
      switchMap((resp) => {
        if (resp.errors) {
          resp.errors.forEach((error) =>
            this.appService.errorMessage(error.message)
          );
          return of(null);
        } else if (resp.data?.insert_datafields) {
          return of(resp.data?.insert_datafields);
        }
      }),
      catchError((error) => {
        this.appService.errorMessage(error);
        return of(null);
      })
    );
  }

  public updateDatafield(
    id: string,
    config: any,
    version: string
  ): Observable<string> {
    let object: any = { id, config, version };

    if (config.used_resource_id) {
      object = {
        ...object,
        used_resource_id: config.used_resource_id,
      };
    }

    return this.updateDatafieldGQL
      .mutate(
        { ...object },
        {
          update: (store, { data: { ...editedDatafield } }) => {
            // Read the data from our cache for this query.
            let { ...data }: any = store.readFragment({
              id: `datafields:${id}`,
              fragment: DatafieldFragmentDoc,
              fragmentName: "Datafield",
            });
            console.log(data, editedDatafield);
            // Add our message from the mutation to the end.
            data = { ...data, ...editedDatafield.update_datafields_by_pk };
            // Write our data back to the cache.
            store.writeFragment({
              id: `datafields:${data.id}`,
              fragment: DatafieldFragmentDoc,
              data,
              fragmentName: "Datafield",
            });
          },
        }
      )
      .pipe(
        switchMap((resp) => {
          if (resp.errors) {
            resp.errors.forEach((error) =>
              this.appService.errorMessage(error.message)
            );
            return of(null);
          } else if (resp.data?.update_datafields_by_pk?.id) {
            return of(resp.data?.update_datafields_by_pk?.id);
          }
        }),
        catchError((error) => {
          this.appService.errorMessage(error);
          return of(null);
        })
      );
  }

  public async editDatafieldSettings(datafield_id: string) {
    const datafield = await this.getDatafieldGQL
      .fetch({ datafield_id })
      .toPromise();

    const alert = await this.alertCtrl.create({
      header: "Datenfeld",
      inputs: [
        {
          name: "title",
          type: "text",
          placeholder: "Titel",
          value: datafield.data.datafields_by_pk.config.title,
        },
        {
          name: "note",
          type: "textarea",
          value: datafield.data.datafields_by_pk.config.note,
          placeholder: "Hinweis",
        },
      ],
      buttons: [
        {
          text: "Abbrechen",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {
            console.log("Confirm Cancel");
          },
        },
        {
          text: "Speichern",
          handler: () => {
            console.log("Confirm Ok");
          },
        },
      ],
    });

    await alert.present();

    const result = await alert.onDidDismiss();

    if (!result.data || result.role === "cancel") {
      return of(false).toPromise();
    } else {
      return this.updateDatafield(
        datafield.data.datafields_by_pk.id,
        { ...datafield.data.datafields_by_pk.config, ...result.data?.values },
        datafield.data.datafields_by_pk.version
      ).toPromise();
    }
  }

  public async deleteDatafield(id: string) {
    return new Promise(async (resolve) => {
      const alert = await this.alertCtrl.create({
        header: "Datenfeld wirklich löschen?",
        message:
          "Zuordnung zu eventuell vorhandenen Aufzeichnungen geht verloren.",
        buttons: [
          {
            text: "Abbrechen",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              return resolve(false);
            },
          },
          {
            text: "Löschen",
            handler: async () => {
              const result = await this.deleteDatafieldConfirmed(id);
              return resolve(result);
            },
          },
        ],
      });

      await alert.present();
    });
  }

  private deleteDatafieldConfirmed = (id: string) => {
    return this.authService
      .getCurrentLoginStatus()
      .pipe(
        first(),
        switchMap((auth) => {
          return this.deleteDatafieldGQL.mutate(
            {
              id,
            },
            {
              context: {
                headers: {
                  "X-Hasura-Role": auth.data.isAdmin ? "team" : "producerAdmin",
                },
              },
              update: (store, { data: { ...deletedDatafield } }) => {
                const variables = {
                  id: deletedDatafield.delete_datafields_by_pk.task_id,
                };
                // Read the data from our cache for this query.
                const { ...data }: any = store.readQuery({
                  query: GetTaskDatafieldsDocument,
                  variables,
                });
                // Filter array by deleted producer id
                data.datafields = [
                  ...data.datafields.filter((datafield) => datafield.id !== id),
                ];
                // Write our data back to the cache.
                store.writeQuery({
                  query: GetTaskDatafieldsDocument,
                  variables,
                  data,
                });
              },
            }
          );
        }),
        switchMap((resp) => {
          if (resp.errors) {
            resp.errors.forEach((error) =>
              this.appService.errorMessage(error.message)
            );
            return of(false);
          } else {
            return of(true);
          }
        }),
        catchError((error) => {
          this.appService.errorMessage(error);
          return of(false);
        })
      )
      .toPromise();
  };

  /**
   * Resources
   */

  public getUnusedResourceTypes() {
    return this.authService.getCurrentLoginStatus().pipe(
      switchMap((auth) => {
        return this.getUnusedResourceTypesGQL.watch(
          { producer_id: auth.data.selectedProducerUid },
          {
            fetchPolicy: "cache-first",
          }
        ).valueChanges;
      }),
      map((getUnusedResourceTypesQuery) => {
        getUnusedResourceTypesQuery.data?.resource_default_types.forEach(
          (type: any) => {
            console.log(type.value, type._used);
          }
        );
        return {
          ...getUnusedResourceTypesQuery,
          data: {
            ...getUnusedResourceTypesQuery.data,
            resource_default_types:
              getUnusedResourceTypesQuery.data?.resource_default_types.filter(
                (type: any) => {
                  return !type._used;
                }
              ) || [],
          },
        };
      })
    );
  }
  public addResourceType(name: string, default_type: string = null) {
    return this.authService
      .getCurrentLoginStatus()
      .pipe(
        first(),
        switchMap((auth) => {
          let object: any = {
            id: uuidv4(),
            name,
            default_type,
          };
          if (auth.data.isAdmin)
            object = { ...object, producer_id: auth.data.selectedProducerUid };

          console.log(object);
          return this.addResourceTypeGQL.mutate(
            { object },
            {
              context: {
                headers: {
                  "X-Hasura-Role": auth.data.isAdmin ? "team" : "producerAdmin",
                },
              },

              optimisticResponse: {
                insert_resource_types_one: {
                  __typename: "resource_types",
                  default_type: "",
                  ...object,
                  producer_id: auth.data.selectedProducerUid,
                  resources: [],
                  resources_aggregate: {
                    aggregate: {
                      count: 0,
                      __typename: "resources_aggregate_fields",
                    },
                    __typename: "resources_aggregate",
                  },
                },
              },
              update: (store, { data: { ...newResourceType } }) => {
                if (default_type) {
                  const resourceDefaultType: any = store.readFragment({
                    id: `resource_default_types:{"value":"${default_type}"}`,
                    fragment: ResourceDefaultTypeFragmentDoc,
                    fragmentName: "ResourceDefaultType",
                  });
                  if (resourceDefaultType) {
                    store.writeFragment({
                      id: `resource_default_types:{"value":"${default_type}"}`,
                      fragment: ResourceDefaultTypeFragmentDoc,
                      fragmentName: "ResourceDefaultType",
                      data: {
                        ...resourceDefaultType,
                        _used: true,
                      },
                    });
                  }
                }
              },
            }
          );
        })
      )
      .toPromise();
  }
  public deleteResourceType(id: string) {
    return this.authService
      .getCurrentLoginStatus()
      .pipe(
        first(),
        switchMap((auth) => {
          return this.deleteResourceTypeGQL.mutate(
            {
              id,
            },
            {
              context: {
                headers: {
                  "X-Hasura-Role": auth.data.isAdmin ? "team" : "producerAdmin",
                },
              },
              refetchQueries: [
                {
                  query: GetUnusedResourceTypesDocument,
                  variables: { producer_id: auth.data.selectedProducerUid },
                },
              ],
              update: (store, { data: { ...deletedResourceType } }) => {
                if (
                  deletedResourceType.delete_resource_types_by_pk.default_type
                ) {
                  const resourceDefaultType: any = store.readFragment({
                    id: `resource_default_types:{"value":"${deletedResourceType.delete_resource_types_by_pk.default_type}"}`,
                    fragment: ResourceDefaultTypeFragmentDoc,
                    fragmentName: "ResourceDefaultType",
                  });
                  if (resourceDefaultType) {
                    store.writeFragment({
                      id: `resource_default_types:{"value":"${deletedResourceType.delete_resource_types_by_pk.default_type}"}`,
                      fragment: ResourceDefaultTypeFragmentDoc,
                      data: {
                        ...resourceDefaultType,
                        _used: false,
                      },
                      fragmentName: "ResourceDefaultType",
                    });
                  }
                }

                const variables = {
                  producer_id: auth.data.selectedProducerUid,
                };
                // If we deleted a task on main level, update action document instead
                const { ...data }: any = store.readQuery({
                  query: GetResourceTypesDocument,
                  variables,
                });

                // Add our product from the mutation to the end.
                data.resource_types = [
                  ...data.resource_types.filter(
                    (resource_type) =>
                      resource_type.id !==
                      deletedResourceType.delete_resource_types_by_pk.id
                  ),
                ];
                // Write our data back to the cache.
                store.writeQuery({
                  query: GetResourceTypesDocument,
                  variables,
                  data,
                });
              },
            }
          );
        })
      )
      .toPromise();
  }
  public getResourceTypes() {
    return this.authService.getCurrentLoginStatus().pipe(
      switchMap((auth) => {
        return this.getResourceTypesGQL.watch(
          { producer_id: auth.data.selectedProducerUid },
          {
            fetchPolicy: "cache-and-network",
          }
        ).valueChanges;
      })
    );
  }

  public addResourceItem(name: string, type_id: string) {
    return this.authService
      .getCurrentLoginStatus()
      .pipe(
        first(),
        switchMap((auth) => {
          let object: any = {
            id: uuidv4(),
            name,
            type_id,
          };
          if (auth.data.isAdmin)
            object = { ...object, producer_id: auth.data.selectedProducerUid };
          return this.addResourceItemGQL.mutate(
            { object },
            {
              context: {
                headers: {
                  "X-Hasura-Role": auth.data.isAdmin ? "team" : "producerAdmin",
                },
              },
              optimisticResponse: {
                insert_resources_one: {
                  __typename: "resources",
                  type: {
                    __typename: "resource_types",
                    id: type_id,
                    name: "",
                    default_type: "",
                  },
                  ...object,
                },
              },
              update: (store, { data: { ...newResourceItem } }) => {
                const resourceType: any = store.readFragment({
                  id: `resource_types:${type_id}`,
                  fragment: ResourceTypeFragmentDoc,
                  fragmentName: "ResourceType",
                });
                console.log(resourceType);
                if (resourceType) {
                  store.writeFragment({
                    id: `resource_types:${type_id}`,
                    fragment: ResourceTypeFragmentDoc,
                    fragmentName: "ResourceType",
                    data: {
                      ...resourceType,
                      resources: [...resourceType.resources, newResourceItem],
                    },
                  });
                }
              },
            }
          );
        })
      )
      .toPromise();
  }

  public updateResourceItem(id: string, name: string) {}

  public deleteResourceItem(id: string) {
    return this.authService
      .getCurrentLoginStatus()
      .pipe(
        first(),
        switchMap((auth) => {
          return this.deleteResourceItemGQL.mutate(
            {
              id,
            },
            {
              context: {
                headers: {
                  "X-Hasura-Role": auth.data.isAdmin ? "team" : "producerAdmin",
                },
              },
              update: (store, { data: { ...deletedResourceItem } }) => {
                const variables = {
                  producer_id: auth.data.selectedProducerUid,
                };
                // If we deleted a task on main level, update action document instead
                const resourceType: any = store.readFragment({
                  id: `resource_types:${deletedResourceItem.delete_resources_by_pk.type_id}`,
                  fragment: ResourceTypeFragmentDoc,
                  fragmentName: "ResourceType",
                });
                console.log(resourceType);
                if (resourceType) {
                  store.writeFragment({
                    id: `resource_types:${deletedResourceItem.delete_resources_by_pk.type_id}`,
                    fragment: ResourceTypeFragmentDoc,
                    fragmentName: "ResourceType",
                    data: {
                      ...resourceType,
                      resources: [
                        ...resourceType.resources.filter(
                          (resource) =>
                            resource.id !==
                            deletedResourceItem.delete_resources_by_pk.id
                        ),
                      ],
                    },
                  });
                }
              },
            }
          );
        })
      )
      .toPromise();
  }

  /**
   * Categories in Actions
   */

  addActionCategoryItem(action_id: string, name: string, type_id: string) {
    return this.authService
      .getCurrentLoginStatus()
      .pipe(
        first(),
        switchMap((auth) => {
          let object: any = {
            action_id,
            name,
            type_id,
          };
          if (auth.data.isAdmin)
            object = { ...object, producer_id: auth.data.selectedProducerUid };

          return this.addActionCategoryItemGQL.mutate(
            {
              ...object,
            },
            {
              context: {
                headers: {
                  "X-Hasura-Role": auth.data.isAdmin ? "team" : "producerAdmin",
                },
              },
              optimisticResponse: {
                insert_actions_category_items_one: {
                  __typename: "actions_category_items",

                  action_id,
                  category_id: uuidv4(),
                  category: {
                    id: uuidv4(),
                    name,
                    type_id,
                    __typename: "category_items",
                    _deleted: false,
                    producer_id: auth.data.selectedProducerUid,
                  },
                },
              },
              update: (store, { data: { ...newCategoryItem } }) => {
                // only add product_id if there is a relationship
                const variables = {
                  action_id,
                };

                // Read the data from our cache for this query.
                const { ...data }: any = store.readQuery(
                  {
                    query: GetActionCategoryItemsDocument,
                    variables,
                  },
                  true
                );
                if (
                  data.actions_category_items.findIndex(
                    (categoryObject) =>
                      categoryObject.category.name ===
                      newCategoryItem.insert_actions_category_items_one.category
                        .name
                  ) < 0
                ) {
                  // Add our product from the mutation to the end.
                  data.actions_category_items = [
                    ...data.actions_category_items,
                    newCategoryItem.insert_actions_category_items_one,
                  ];
                }

                // Write our data back to the cache.
                store.writeQuery({
                  query: GetActionCategoryItemsDocument,
                  variables,
                  data,
                });
              },
            }
          );
        })
      )
      .toPromise();
  }
  deleteActionCategoryItem(action_id: string, category_id: string) {
    return this.deleteActionCategoryItemsGQL
      .mutate(
        {
          action_id,
          category_id,
        },
        {
          update: (store, { data: { ...deletedCategoryItem } }) => {
            // only add product_id if there is a relationship
            const variables = {
              action_id,
            };

            // Read the data from our cache for this query.
            const { ...data }: any = store.readQuery(
              {
                query: GetActionCategoryItemsDocument,
                variables,
              },
              true
            );
            deletedCategoryItem.delete_actions_category_items.returning.forEach(
              (delete_actions_category_item) => {
                data.actions_category_items = [
                  ...data.actions_category_items.filter(
                    (categoryObject) =>
                      categoryObject.category.id !==
                      delete_actions_category_item.category.id
                  ),
                ];
              }
            );

            // Write our data back to the cache.
            store.writeQuery({
              query: GetActionCategoryItemsDocument,
              variables,
              data,
            });

            // @TODO Update _used field in CategoryItemFragmentDoc
            // store.readFragment({
            //   fragment: CategoryItemFragmentDoc
            // })
          },
        }
      )
      .toPromise();
  }

  /**
   * Resources in Actions
   */

  addActionResource(
    action_id: string,
    name: string,
    type_id: string,
    usage: Usage_Types_Enum
  ) {
    return this.authService
      .getCurrentLoginStatus()
      .pipe(
        first(),
        switchMap((auth) => {
          let object: any = {
            action_id,
            name,
            type_id,
            usage,
          };
          if (auth.data.isAdmin)
            object = { ...object, producer_id: auth.data.selectedProducerUid };

          return this.addActionResourceGQL.mutate(
            {
              ...object,
            },
            {
              context: {
                headers: {
                  "X-Hasura-Role": auth.data.isAdmin ? "team" : "producerAdmin",
                },
              },
              optimisticResponse: {
                insert_actions_resources_one: {
                  __typename: "actions_resources",
                  usage,
                  unit: "",
                  action_id,
                  resource_id: uuidv4(),
                  resource: {
                    id: uuidv4(),
                    name,
                    type_id,
                    type: null,
                    producer_id: auth.data.selectedProducerUid,
                    __typename: "resources",
                    _deleted: false,
                  },
                },
              },
              update: (store, { data: { ...newResource } }) => {
                // only add product_id if there is a relationship
                const variables = {
                  action_id,
                };

                // Read the data from our cache for this query.
                const { ...data }: any = store.readQuery(
                  {
                    query: GetActionResourcesDocument,
                    variables,
                  },
                  true
                );
                if (
                  data.actions_resources.findIndex(
                    (resourceObject) =>
                      resourceObject.resource.name ===
                      newResource.insert_actions_resources_one.resource.name
                  ) < 0
                ) {
                  // Add our product from the mutation to the end.
                  data.actions_resources = [
                    ...data.actions_resources,
                    newResource.insert_actions_resources_one,
                  ];
                }

                // Write our data back to the cache.
                store.writeQuery({
                  query: GetActionResourcesDocument,
                  variables,
                  data,
                });
              },
            }
          );
        })
      )
      .toPromise();
  }
  deleteActionResource(
    action_id: string,
    resource_id: string,
    usage: Usage_Types_Enum
  ) {
    return this.deleteActionResourceGQL
      .mutate(
        {
          action_id,
          resource_id,
          usage,
        },
        {
          update: (store, { data: { ...deletedResources } }) => {
            // only add product_id if there is a relationship
            const variables = {
              action_id,
            };

            // Read the data from our cache for this query.
            const { ...data }: any = store.readQuery(
              {
                query: GetActionResourcesDocument,
                variables,
              },
              true
            );
            deletedResources.delete_actions_resources.returning.forEach(
              (deleted_action_resource) => {
                data.actions_resources = [
                  ...data.actions_resources.filter(
                    (resourceObject) =>
                      resourceObject.resource.id !==
                      deleted_action_resource.resource.id
                  ),
                ];
              }
            );

            // Write our data back to the cache.
            store.writeQuery({
              query: GetActionResourcesDocument,
              variables,
              data,
            });

            // @TODO Update _used field in ResourceItemFragmentDoc
            // store.readFragment({
            //   fragment: ResourceItemFragmentDoc
            // })
          },
        }
      )
      .toPromise();
  }
  updateActionResource(action_id: string, resource_id: string, unit: string) {
    if (!resource_id) return;
    return this.updateActionResourceGQL
      .mutate({
        action_id,
        resource_id,
        unit,
      })
      .toPromise();
  }

  /**
   * Resources in Tasks of Actions
   */

  public async deleteTaskResource(id: string) {
    return new Promise(async (resolve) => {
      const alert = await this.alertCtrl.create({
        header: "Ressource wirklich löschen?",
        message:
          "Zuordnung zu eventuell vorhandenen Aufzeichnungen geht verloren.",
        buttons: [
          {
            text: "Abbrechen",
            role: "cancel",
            cssClass: "secondary",
            handler: () => {
              return resolve(false);
            },
          },
          {
            text: "Löschen",
            cssClass: "danger",
            handler: async () => {
              const result = await this.deleteTaskResourceConfirmed(id);
              return resolve(result);
            },
          },
        ],
      });

      await alert.present();
    });
  }

  private deleteTaskResourceConfirmed = (id: string) => {
    return this.authService
      .getCurrentLoginStatus()
      .pipe(
        first(),
        switchMap((auth) => {
          return this.deleteTaskResourceGQL.mutate(
            {
              id,
            },
            {
              context: {
                headers: {
                  "X-Hasura-Role": auth.data.isAdmin ? "team" : "producerAdmin",
                },
              },
              update: (store, { data: { ...deletedResource } }) => {
                const variables = {
                  task_id:
                    deletedResource.delete_resource_usage_in_tasks_by_pk
                      .task_id,
                };
                // Read the data from our cache for this query.
                const { ...data }: any = store.readQuery({
                  query: GetTaskResourcesDocument,
                  variables,
                });
                // Filter array by deleted producer id
                data.resource_usage_in_tasks = [
                  ...data.resource_usage_in_tasks.filter(
                    (resourceObject) => resourceObject.id !== id
                  ),
                ];
                // Write our data back to the cache.
                store.writeQuery({
                  query: GetTaskResourcesDocument,
                  variables,
                  data,
                });
              },
            }
          );
        }),
        switchMap((resp) => {
          if (resp.errors) {
            resp.errors.forEach((error) =>
              this.appService.errorMessage(error.message)
            );
            return of(false);
          } else {
            return of(true);
          }
        }),
        catchError((error) => {
          this.appService.errorMessage(error);
          return of(false);
        })
      )
      .toPromise();
  };

  /**
   * Batches
   */

  getActionLog(action_id: string, batch_id: string) {
    return this.getActionLogGQL
      .watch(
        {
          action_id: action_id,
          log_id: batch_id,
        },
        { fetchPolicy: "cache-and-network" }
      )
      .valueChanges.pipe(shareReplay());
  }

  closeActionLog(log_id: string, qty_result: number = null) {
    return this.closeActionLogGQL.mutate({
      log_id,
      qty_result,
    });
  }

  /**
   * Logs
   */

  addNewLog(object: Logs_Insert_Input) {
    return this.authService.getCurrentLoginStatus().pipe(
      first(),
      switchMap((auth) => {
        let headers = {};
        console.log(object);

        if (auth.data.isAdmin) {
          headers = {
            "X-Hasura-Role": "team",
          };
        }

        console.warn(object);

        return this.addNewLogGQL.mutate(
          {
            object: {
              ...object,
              data: { qty_target: "1" },
            },
          },
          {
            context: {
              headers,
            },
            update: (store, { data: { ...newLog } }) => {
              const variables = {
                id: newLog.insert_logs_one.related_to,
              };
              // UPDATE TASKS FOR ACTION ITEM
              // Read the data from our cache for this query.
              const { ...data }: any = store.readQuery({
                query: GetLogsDocument,
                variables,
              });
              console.warn(data);
              // // Add our datafield from the mutation to the end.
              data.logs = data?.logs
                ? [...data?.logs, newLog.insert_logs_one]
                : [newLog.insert_logs_one];

              // Write our data back to the cache.
              store.writeQuery({
                query: GetLogsDocument,
                variables,
                data,
              });
            },
          }
        );
      }),
      switchMap((resp) => {
        if (resp.errors) {
          resp.errors.forEach((error) =>
            this.appService.errorMessage(error.message)
          );
          return of(null);
        } else if (resp.data?.insert_logs_one) {
          return of(resp.data?.insert_logs_one);
        }
      }),
      catchError((error) => {
        this.appService.errorMessage(error);
        return of(null);
      })
    );
  }

  getLatestLog(action_id: string) {
    return this.authService.getCurrentLoginStatus().pipe(
      first(),
      switchMap((auth) => {
        let headers = {};

        console.log(action_id);
        if (auth.data.isAdmin) {
          headers = {
            "X-Hasura-Role": "team",
          };
        }
        return this.getLatestLogGQL.fetch(
          {
            id: action_id,
          },
          {
            context: {
              headers,
            },
            fetchPolicy: "network-only",
          }
        );
      }),
      switchMap((resp) => {
        console.log(resp);
        if (resp.errors) {
          resp.errors.forEach((error) =>
            this.appService.errorMessage(error.message)
          );
          return of(null);
        } else if (resp.data?.latest_log.length > 0) {
          return of(resp.data?.latest_log[0]);
        } else {
          return of(null);
        }
      }),
      catchError((error) => {
        this.appService.errorMessage(error);
        return of(null);
      })
    );
  }

  getLogTaskToResume(parent_log_id: string) {
    return this.authService.getCurrentLoginStatus().pipe(
      first(),
      switchMap((auth) => {
        let headers = {};

        console.log(parent_log_id);
        if (auth.data.isAdmin) {
          headers = {
            "X-Hasura-Role": "team",
          };
        }
        return this.getLogTaskToResumeGQL.fetch(
          {
            parent_log_id,
          },
          {
            context: {
              headers,
            },
            fetchPolicy: "network-only",
          }
        );
      }),
      switchMap((resp) => {
        console.log(resp);
        if (resp.errors) {
          resp.errors.forEach((error) =>
            this.appService.errorMessage(error.message)
          );
          return of(null);
        } else if (
          resp.data?.logs_by_pk.related_action.tasks_flatten.length > 0
        ) {
          return of(resp.data?.logs_by_pk.related_action.tasks_flatten[0].id);
        } else {
          return of(null);
        }
      }),
      catchError((error) => {
        this.appService.errorMessage(error);
        return of(null);
      })
    );
  }

  addDatafieldLog(variables: AddDatafieldLogMutationVariables) {
    return this.authService.getCurrentLoginStatus().pipe(
      first(),
      switchMap((auth) => {
        let headers = {};

        if (auth.data.isAdmin) {
          headers = {
            "X-Hasura-Role": "team",
          };
        }

        return this.addDatafieldLogGQL.mutate(
          {
            ...variables,
          },
          {
            context: {
              headers,
            },
            update: (store, { data: { ...newLog } }) => {
              const updatedParentLog = newLog.update_logs_by_pk;

              // UPDATE TASKS FOR ACTION ITEM
              // Read the data from our cache for this query.
              let { ...data }: any = store.readFragment({
                id: `logs:${updatedParentLog.id}`,
                fragment: LogFragmentDoc,
                fragmentName: "Log",
              });
              if (data.id) {
                data = {
                  ...data,
                  updated_at: updatedParentLog.updated_at,
                };
                // Write our data back to the cache.
                store.writeFragment({
                  id: `logs:${updatedParentLog.id}`,
                  fragment: LogFragmentDoc,
                  fragmentName: "Log",
                  data,
                });
              }
            },
          }
        );
      }),
      switchMap((resp) => {
        if (resp.errors) {
          resp.errors.forEach((error) =>
            this.appService.errorMessage(error.message)
          );
          return of(null);
        } else if (resp.data?.insert_logs_one) {
          return of(resp.data?.insert_logs_one);
        }
      }),
      catchError((error) => {
        this.appService.errorMessage(error);
        return of(null);
      })
    );
  }

  deleteDatafieldLog(log_id: string) {
    return this.deleteDatafieldLogGQL
      .mutate({
        log_id,
      })
      .toPromise();
  }

  addTaskLog(variables: AddTaskLogMutationVariables) {
    return this.authService.getCurrentLoginStatus().pipe(
      first(),
      switchMap((auth) => {
        let headers = {};

        if (auth.data.isAdmin) {
          headers = {
            "X-Hasura-Role": "team",
          };
        }
        return this.addTaskLogGQL.mutate(
          {
            ...variables,
          },
          {
            context: {
              headers,
            },
            update: (store, { data: { ...newLog } }) => {
              const updatedParentLog = newLog.update_logs_by_pk;

              // UPDATE TASKS FOR ACTION ITEM
              // Read the data from our cache for this query.
              let { ...data }: any = store.readFragment({
                id: `logs:${updatedParentLog.id}`,
                fragment: LogFragmentDoc,
                fragmentName: "Log",
              });

              if (data.id) {
                data = {
                  ...data,
                  updated_at: updatedParentLog.updated_at,
                };
                // Write our data back to the cache.
                store.writeFragment({
                  id: `logs:${updatedParentLog.id}`,
                  fragment: LogFragmentDoc,
                  fragmentName: "Log",
                  data,
                });
              }
            },
          }
        );
      }),
      switchMap((resp) => {
        if (resp.errors) {
          resp.errors.forEach((error) =>
            this.appService.errorMessage(error.message)
          );
          return of(null);
        } else if (resp.data?.insert_logs_one) {
          return of(resp.data?.insert_logs_one);
        }
      }),
      catchError((error) => {
        this.appService.errorMessage(error);
        return of(null);
      })
    );
  }

  /**
   * Files
   */

  uploadFiles(files: Array<S3File>, related_to?: string) {
    return this.authService.getCurrentLoginStatus().pipe(
      first(),
      switchMap((auth) => {
        let objects: Array<any> = files;

        if (related_to)
          objects = objects.map((object) => ({ ...object, related_to }));

        if (auth.data.isAdmin)
          objects = objects.map((object) => ({
            ...object,
            producer_id: auth.data.selectedProducerUid,
          }));

        console.warn(objects, files);

        return this.uploadFilesGQL.mutate(
          {
            objects,
          },
          {
            context: {
              headers: {
                "X-Hasura-Role": auth.data.isAdmin ? "team" : "producerAdmin",
              },
            },
            update: (store, { data: { ...newFiles } }) => {
              console.log(newFiles);
              // only add product_id if there is a relationship
              const variables = {
                producer_id: auth.data.selectedProducerUid,
              };

              // Read the data from our cache for this query.
              const { ...data }: any = store.readQuery({
                query: GetFilesDocument,
                variables,
              });
              // Add our files from the mutation to the end.
              data.files = [
                ...(data.files || []),
                ...newFiles.insert_files.returning,
              ];
              console.warn(data);
              // Write our data back to the cache.
              store.writeQuery({ query: GetFilesDocument, variables, data });
            },
          }
        );
      }),
      switchMap((resp) => {
        if (resp.errors) {
          resp.errors.forEach((error) =>
            this.appService.errorMessage(error.message)
          );
          return of(null);
        } else if (resp.data?.insert_files.returning.length > 0) {
          return of(resp.data?.insert_files.returning);
        }
      }),
      catchError((error) => {
        this.appService.errorMessage(error);
        return of(null);
      })
    );
  }

  async deleteFile(id: string) {
    const loader = await this.loadingCtrl.create({
      keyboardClose: true,
      message: `Deleting File with id ${id}`,
      translucent: true,
    });
    loader.present();
    const deletionProcess = await this.authService
      .getCurrentLoginStatus()
      .pipe(
        first(),
        switchMap((auth) => {
          return this.deleteFileGQL.mutate(
            {
              id,
            },
            {
              context: {
                headers: {
                  "X-Hasura-Role": auth.data.isAdmin ? "team" : "producerAdmin",
                },
              },
              update: (store, { data: { ...deletedFiles } }) => {
                console.log(deletedFiles);

                const variables = {
                  producer_id: auth.data.selectedProducerUid,
                };

                // Read the data from our cache for this query.
                const { ...data }: any = store.readQuery({
                  query: GetFilesDocument,
                  variables,
                });
                console.warn(data);
                // Filter array by deleted producer id
                data.files = [...data.files.filter((file) => file.id !== id)];
                console.log(data);
                // Write our data back to the cache.
                store.writeQuery({ query: GetFilesDocument, variables, data });
              },
            }
          );
        }),
        switchMap((resp) => {
          loader.dismiss();
          if (resp.errors) {
            resp.errors.forEach((error) =>
              this.appService.errorMessage(error.message)
            );
            return of(null);
          } else if (resp.data?.delete_files_by_pk) {
            return of(resp.data?.delete_files_by_pk);
          }
        }),
        catchError((error) => {
          this.appService.errorMessage(error);
          loader.dismiss();
          return of(null);
        })
      )
      .toPromise();
    return deletionProcess;
  }

  /**
   * Categories
   */

  public getUnusedCategoryTypes() {
    return this.authService.getCurrentLoginStatus().pipe(
      switchMap((auth) => {
        return this.getUnusedCategoryTypesGQL.watch(
          { producer_id: auth.data.selectedProducerUid },
          {
            fetchPolicy: "cache-first",
          }
        ).valueChanges;
      }),
      map((getUnusedCategoryTypesQuery) => {
        getUnusedCategoryTypesQuery.data?.category_default_types.forEach(
          (type: any) => {
            console.log(type.value, type._used);
          }
        );
        return {
          ...getUnusedCategoryTypesQuery,
          data: {
            ...getUnusedCategoryTypesQuery?.data,
            category_default_types:
              getUnusedCategoryTypesQuery?.data?.category_default_types.filter(
                (type: any) => {
                  return !type._used;
                }
              ) || [],
          },
        };
      })
    );
  }
  public addCategoryType(name: string, default_type: string = null) {
    return this.authService
      .getCurrentLoginStatus()
      .pipe(
        first(),
        switchMap((auth) => {
          let object: any = {
            id: uuidv4(),
            name,
            default_type,
          };
          if (auth.data.isAdmin)
            object = { ...object, producer_id: auth.data.selectedProducerUid };
          return this.addCategoryTypeGQL.mutate(
            { object },
            {
              context: {
                headers: {
                  "X-Hasura-Role": auth.data.isAdmin ? "team" : "producerAdmin",
                },
              },

              optimisticResponse: {
                insert_category_types_one: {
                  __typename: "category_types",
                  default_type: "",
                  ...object,
                  producer_id: auth.data.selectedProducerUid,
                  items: [],
                  items_aggregate: {
                    aggregate: {
                      count: 0,
                      __typename: "category_items_aggregate_fields",
                    },
                    __typename: "category_items_aggregate",
                  },
                },
              },
              update: (store, { data: { ...newCategoryType } }) => {
                if (default_type) {
                  const categoryDefaultType: any = store.readFragment({
                    id: `category_default_types:{"value":"${default_type}"}`,
                    fragment: CategoryDefaultTypeFragmentDoc,
                    fragmentName: "CategoryDefaultType",
                  });
                  if (categoryDefaultType) {
                    store.writeFragment({
                      id: `category_default_types:{"value":"${default_type}"}`,
                      fragment: CategoryDefaultTypeFragmentDoc,
                      data: {
                        ...categoryDefaultType,
                        _used: true,
                      },
                      fragmentName: "CategoryDefaultType",
                    });
                  }
                }
              },
            }
          );
        })
      )
      .toPromise();
  }
  public deleteCategoryType(id: string) {
    return this.authService
      .getCurrentLoginStatus()
      .pipe(
        first(),
        switchMap((auth) => {
          return this.deleteCategoryTypeGQL.mutate(
            {
              id,
            },
            {
              context: {
                headers: {
                  "X-Hasura-Role": auth.data.isAdmin ? "team" : "producerAdmin",
                },
              },
              refetchQueries: [
                {
                  query: GetUnusedCategoryTypesDocument,
                  variables: { producer_id: auth.data.selectedProducerUid },
                },
              ],
              update: (store, { data: { ...deletedCategoryType } }) => {
                if (
                  deletedCategoryType.delete_category_types_by_pk.default_type
                ) {
                  const categoryDefaultType: any = store.readFragment({
                    id: `category_default_types:{"value":"${deletedCategoryType.delete_category_types_by_pk.default_type}"}`,
                    fragment: CategoryDefaultTypeFragmentDoc,
                    fragmentName: "CategoryDefaultType",
                  });
                  if (categoryDefaultType) {
                    store.writeFragment({
                      id: `category_default_types:{"value":"${deletedCategoryType.delete_category_types_by_pk.default_type}"}`,
                      fragment: CategoryDefaultTypeFragmentDoc,
                      data: {
                        ...categoryDefaultType,
                        _used: false,
                      },
                      fragmentName: "CategoryDefaultType",
                    });
                    const variables = {
                      producer_id: auth.data.selectedProducerUid,
                    };
                  }
                }

                const variables = {
                  producer_id: auth.data.selectedProducerUid,
                };
                // If we deleted a task on main level, update action document instead
                const { ...data }: any = store.readQuery({
                  query: GetCategoryTypesDocument,
                  variables,
                });

                // Add our product from the mutation to the end.
                data.category_types = [
                  ...data?.category_types.filter(
                    (category_type) =>
                      category_type.id !==
                      deletedCategoryType.delete_category_types_by_pk.id
                  ),
                ];
                // Write our data back to the cache.
                store.writeQuery({
                  query: GetCategoryTypesDocument,
                  variables,
                  data,
                });
              },
            }
          );
        })
      )
      .toPromise();
  }
  public getCategoryTypes() {
    return this.authService.getCurrentLoginStatus().pipe(
      switchMap((auth) => {
        return this.getCategoryTypesGQL.watch(
          { producer_id: auth.data.selectedProducerUid },
          {
            fetchPolicy: "cache-and-network",
          }
        ).valueChanges;
      })
    );
  }

  public addCategoryItem(name: string, type_id: string) {
    return this.authService
      .getCurrentLoginStatus()
      .pipe(
        first(),
        switchMap((auth) => {
          let object: any = {
            id: uuidv4(),
            name,
            type_id,
          };
          if (auth.data.isAdmin)
            object = { ...object, producer_id: auth.data.selectedProducerUid };
          return this.addCategoryItemGQL.mutate(
            { object },
            {
              context: {
                headers: {
                  "X-Hasura-Role": auth.data.isAdmin ? "team" : "producerAdmin",
                },
              },
              optimisticResponse: {
                insert_category_items_one: {
                  __typename: "category_items",
                  ...object,
                },
              },
              update: (store, { data: { ...newCategoryItem } }) => {
                const categoryType: any = store.readFragment({
                  id: `category_types:${type_id}`,
                  fragment: CategoryTypeFragmentDoc,
                  fragmentName: "CategoryType",
                });
                console.log(categoryType);
                if (categoryType) {
                  store.writeFragment({
                    id: `category_types:${type_id}`,
                    fragment: CategoryTypeFragmentDoc,
                    fragmentName: "CategoryType",
                    data: {
                      ...categoryType,
                      items: [...categoryType.items, newCategoryItem],
                    },
                  });
                }
              },
            }
          );
        })
      )
      .toPromise();
  }

  public updateCategoryItem(id: string, name: string) {}

  public deleteCategoryItem(id: string) {
    return this.authService
      .getCurrentLoginStatus()
      .pipe(
        first(),
        switchMap((auth) => {
          return this.deleteCategoryItemGQL.mutate(
            {
              id,
            },
            {
              context: {
                headers: {
                  "X-Hasura-Role": auth.data.isAdmin ? "team" : "producerAdmin",
                },
              },
              update: (store, { data: { ...deletedCategoryItem } }) => {
                console.log(deletedCategoryItem);
                const variables = {
                  producer_id: auth.data.selectedProducerUid,
                };
                // If we deleted a task on main level, update action document instead
                const categoryType: any = store.readFragment({
                  id: `category_types:${deletedCategoryItem.delete_category_items_by_pk.type_id}`,
                  fragment: CategoryTypeFragmentDoc,
                  fragmentName: "CategoryType",
                });
                console.log(categoryType);
                if (categoryType) {
                  store.writeFragment({
                    id: `category_types:${deletedCategoryItem.delete_category_items_by_pk.type_id}`,
                    fragment: CategoryTypeFragmentDoc,
                    fragmentName: "CategoryType",
                    data: {
                      ...categoryType,
                      items: [
                        ...categoryType.items.filter(
                          (item) =>
                            item.id !==
                            deletedCategoryItem.delete_category_items_by_pk.id
                        ),
                      ],
                    },
                  });
                }
              },
            }
          );
        })
      )
      .toPromise();
  }
}

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { DataFieldDirective } from "./data-field.directive";
import { CategoryDatafieldComponent } from "./fields/category/category.component";
import { CurrencyDatafieldComponent } from "./fields/currency/currency.component";
import { DefaultDatafieldComponent } from "./fields/default/default.component";
import { FileDatafieldComponent } from "./fields/file/file.component";
import { LocationDatafieldComponent } from "./fields/location/location.component";
import { MeasurementDatafieldComponent } from "./fields/measurement/measurement.component";
import { RelationshipDatafieldComponent } from "./fields/relationship/relationship.component";
import { TextDatafieldComponent } from "./fields/text/text.component";
import { TrackDatafieldComponent } from "./fields/track/track.component";
import { IonicModule } from "@ionic/angular";
import {
  NgChunkPipeModule,
  NgHeadPipeModule,
  NgJoinPipeModule,
  NgLastPipeModule,
  NgPluckPipeModule,
  NgTailPipeModule,
  NgWherePipeModule,
} from "angular-pipes";
import { FilterByIdPipeModule } from "../../pipes/filter-by-id.pipe.module";
import { FilterByTypePipeModule } from "../../pipes/filter-by-type.pipe.module";
import { TimeAgoModule } from "../../pipes/time-ago.module";
import { TimeTrackModule } from "../../pipes/time-track.module";
import { GoogleMapsModule } from "@angular/google-maps";
import { HttpClientJsonpModule, HttpClientModule } from "@angular/common/http";

@NgModule({
  providers: [],
  imports: [
    CommonModule,
    HttpClientModule,
    HttpClientJsonpModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    NgHeadPipeModule,
    NgLastPipeModule,
    NgJoinPipeModule,
    NgChunkPipeModule,
    NgWherePipeModule,
    NgPluckPipeModule,
    FilterByIdPipeModule,
    FilterByTypePipeModule,
    TimeAgoModule,
    TimeTrackModule,
    GoogleMapsModule,
  ],
  declarations: [
    DataFieldDirective,
    CategoryDatafieldComponent,
    CurrencyDatafieldComponent,
    DefaultDatafieldComponent,
    FileDatafieldComponent,
    LocationDatafieldComponent,
    MeasurementDatafieldComponent,
    RelationshipDatafieldComponent,
    TextDatafieldComponent,
    TrackDatafieldComponent,
  ],
  entryComponents: [
    CategoryDatafieldComponent,
    CurrencyDatafieldComponent,
    DefaultDatafieldComponent,
    FileDatafieldComponent,
    LocationDatafieldComponent,
    MeasurementDatafieldComponent,
    RelationshipDatafieldComponent,
    TextDatafieldComponent,
    TrackDatafieldComponent,
  ],
  exports: [DataFieldDirective],
})
export class DataFieldsModule {}

import { Injectable } from "@angular/core";
import {
  CanLoad,
  Route,
  Router,
  UrlSegment,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate,
  UrlTree,
  CanActivateChild,
  ActivatedRoute,
} from "@angular/router";
import { Observable, of } from "rxjs";
import { switchMap, catchError } from "rxjs/operators";
import { AuthService } from "src/app/shared/services/auth.service";

@Injectable({
  providedIn: "root",
})
export class ValidateTokenGuard
  implements CanActivate, CanLoad, CanActivateChild
{
  constructor(private router: Router, private authService: AuthService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.validateToken(next.url.join(""));
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.validateToken(next.url.join(""));
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    const fullPath = segments.reduce((path, currSegment) => {
      return `${path}/${currSegment.path}`;
    }, "");
    return this.validateToken(fullPath);
  }

  private validateToken(redirect: string) {
    return this.authService.validateToken().pipe(
      switchMap((state) => {
        if (!state) {
          this.authService.logout();
          this.router.navigate(["/auth"], {
            queryParams: {
              redirect,
            },
          });
        }
        return of(state);
      }),
      catchError((error) => {
        this.authService.logout();
        this.router.navigate(["/auth"], {
          queryParams: {
            redirect,
          },
        });
        return of(false);
      })
    );
  }
}

export const environment = {
  production: true,
  craftcms: {
    auth: "/hasura/auth",
    graphql: "https://www.material.guide/api",
  },
  hasura: {
    graphql: "https://api.material.guide/v1/graphql",
    websocket: "wss://api.material.guide/v1/graphql",
  },
  netlify: {
    functions: "https://practical-kare-6cfd1f.netlify.app/.netlify/functions",
  },
  firebase: {
    apiKey: "AIzaSyCgPH_4MGTM-bZceClDoGV1XjMJ3eGB3Hg",
    authDomain: "unfold-2b712.firebaseapp.com",
    databaseURL: "https://unfold-2b712.firebaseio.com",
    projectId: "unfold-2b712",
    storageBucket: "unfold-2b712.appspot.com",
    messagingSenderId: "937549181434",
    appId: "1:937549181434:web:a61c41ae552dd168cdb443",
  },
};

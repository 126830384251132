import { NgModule } from "@angular/core";
import { CommonModule, DecimalPipe } from "@angular/common";
import { TimeAgoPipe } from "./time-ago.pipe";

@NgModule({
  providers: [DecimalPipe],
  declarations: [TimeAgoPipe],
  imports: [CommonModule],
  exports: [TimeAgoPipe],
})
export class TimeAgoModule {}

import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";

import { NewProducerComponent } from "./new-producer.component";

@NgModule({
  imports: [SharedModule],
  declarations: [NewProducerComponent],
})
export class NewProducerModule {}

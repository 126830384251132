import { Injectable } from "@angular/core";
import { LoginGQL } from "generated/types.graphql-gen";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { from, Observable, of, throwError } from "rxjs";
import { Apollo, gql } from "apollo-angular";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AppService } from "./app.service";
import { StorageService } from "./storage.service";

const GET_CURRENT_LOGIN_STATUS = gql`
  query GetCurrentLoginStatus {
    isAdmin @client
    isLoggedIn @client
    userId @client
    userName @client
    selectedProducerUid @client
  }
`;

export interface User {
  loginName: string;
  password: string;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private jwtHelper = new JwtHelperService();

  private loginName = "";
  private password = "";

  constructor(
    private apollo: Apollo,
    private loginService: LoginGQL,
    private ionicStorage: StorageService,
    private appService: AppService
  ) {
    // this.querySubscription = this.apollo
    //   .watchQuery<any>({
    //     query: GET_CURRENT_LOGIN_STATUS,
    //   })
    //   .valueChanges.subscribe(({ data, loading }) => {
    //     console.log(loading, data);
    //     // this.loading = loading;
    //     // this.isLoggedIn = data.isLoggedIn;
    //   });
  }

  // Changes over time, so query/mutations should call first() to get only the latest
  public getCurrentLoginStatus() {
    return this.apollo.watchQuery<any>({
      query: GET_CURRENT_LOGIN_STATUS,
    }).valueChanges;
  }

  public login(loginName: string, password: string): Observable<boolean> {
    // Cache loginName/password for JWT rotation
    this.loginName = loginName;
    this.password = password;

    return from(this.ionicStorage.get("token")).pipe(
      switchMap(async (token) => {
        if (this.jwtHelper.isTokenExpired(token)) {
          console.debug("Token Expired. Logging out");
          await this.logout();
          console.debug("Waited for logging out!");
        }
        return token;
      }),
      switchMap(() => {
        console.debug("Logging in…");
        return this.loginService.mutate(
          { loginName, password },
          {
            fetchPolicy: "no-cache",
          }
        );
      }),
      switchMap((resp) => {
        if (resp.data?.login?.error) {
          console.error("Login Error", resp);
          this.appService.errorMessage(resp.data?.login?.error);
          return of(false);
        } else if (resp.data?.login?.token) {
          return this.loginSuccess(resp.data?.login?.token || "");
        }
      }),
      catchError((error) => {
        this.appService.errorMessage(error);
        this.logout();
        return of(false);
      })
    );
  }
  private loginSuccess(token) {
    // const parsedJwt = this.parseJwt(token) ;
    const parsedJwt = this.jwtHelper.decodeToken(token);

    const loginPromise: Array<Promise<any>> = [];

    const roles: Array<string> =
      parsedJwt["https://hasura.io/jwt/claims"]["x-hasura-allowed-roles"];

    // We use the team role as hasura would use admin role to allow everything;
    const isAdmin = roles.findIndex((role) => role === "team") > -1;

    loginPromise.push(
      this.ionicStorage.set("token", token),
      this.ionicStorage.set("roles", roles)
    );

    this.apollo.client.writeQuery({
      query: GET_CURRENT_LOGIN_STATUS,
      data: {
        isAdmin: isAdmin,
        isLoggedIn: true,
        userId: parsedJwt["https://hasura.io/jwt/claims"]["x-hasura-user-id"],
        userName:
          parsedJwt["https://hasura.io/jwt/claims"][
            "x-hasura-custom-user-name"
          ] || "Unknown Username",
        selectedProducerUid:
          parsedJwt["https://hasura.io/jwt/claims"][
            "x-hasura-custom-producer-uid"
          ],
      },
    });
    return from(Promise.all(loginPromise)).pipe(switchMap((_) => of(true)));
  }

  public logout(): Promise<any> {
    return Promise.all([
      this.ionicStorage.remove("token"),
      this.ionicStorage.remove("roles"),
      this.apollo.client.writeQuery({
        query: GET_CURRENT_LOGIN_STATUS,
        data: {
          isAdmin: false,
          isLoggedIn: false,
          userId: "",
          userName: "",
          selectedProducerUid: "",
        },
      }),
    ]);
  }

  public validateToken(): Observable<boolean> {
    return from(this.ionicStorage.get("token")).pipe(
      withLatestFrom(
        this.apollo.watchQuery<any>({
          query: GET_CURRENT_LOGIN_STATUS,
        }).valueChanges
      ),
      map(([token, { data: loginStatus }]) => {
        // console.log(loginStatus);
        if (loginStatus.isLoggedIn === false) {
          this.logout();
          return false;
        }

        const _isAuthenticated = !this.jwtHelper.isTokenExpired(token);

        const timeUntilExpired =
          this.jwtHelper.getTokenExpirationDate(token).getTime() -
          new Date().getTime();
        if (
          timeUntilExpired < 5 &&
          this.loginName !== "" &&
          this.password !== ""
        ) {
          this.login(this.loginName, this.password).subscribe();
        }
        if (!_isAuthenticated) this.logout();
        return _isAuthenticated;
      }),
      catchError((error) => {
        this.logout();
        return throwError(false);
      })
    );
  }

  public getRoles() {
    const roles$: Promise<Array<string>> = this.ionicStorage.get("roles");
    return from(roles$);
  }

  public getProducerUid(): Observable<string> {
    return this.apollo
      .watchQuery<any>({
        query: GET_CURRENT_LOGIN_STATUS,
      })
      .valueChanges.pipe(
        switchMap(({ data, loading }) => {
          return of(data.selectedProducerUid || "");
        })
      );
  }
  public switchProducer(uid: string) {
    const currentLoginStatus = this.apollo.client.readQuery({
      query: GET_CURRENT_LOGIN_STATUS,
    });
    if (currentLoginStatus.isAdmin) {
      this.apollo.client.writeQuery({
        query: GET_CURRENT_LOGIN_STATUS,
        data: {
          selectedProducerUid: uid,
        },
      });
    } else {
      this.appService.errorMessage(
        "You are not allowed to switch the producer"
      );
    }
  }

  public isAdmin() {
    return this.apollo
      .watchQuery<any>({
        query: GET_CURRENT_LOGIN_STATUS,
      })
      .valueChanges.pipe(
        switchMap(({ data, loading }) => {
          return of(loading ? false : (data.isAdmin as boolean));
        })
      );
  }
  public isLoggedIn() {
    return this.apollo
      .watchQuery<any>({
        query: GET_CURRENT_LOGIN_STATUS,
      })
      .valueChanges.pipe(
        switchMap(({ data, loading }) => {
          return of(loading ? false : data.isLoggedIn ?? false);
        })
      );
  }
}

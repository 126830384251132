import { Inject, Injectable, Renderer2 } from "@angular/core";
import { AlertController, ToastController } from "@ionic/angular";

import { PluginListenerHandle, Plugins } from "@capacitor/core";

import { Network } from "@capacitor/network";
import { DOCUMENT } from "@angular/common";
import { HasuraService } from "./hasura.service";

@Injectable({
  providedIn: "root",
})
export class AppService {
  networkListener: PluginListenerHandle;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private toastController: ToastController // private networkService: _Network
  ) {
    // this.networkService.onChange().subscribe((status) => console.warn(status));
    this.networkListener = Network.addListener(
      "networkStatusChange",
      (status) => {
        console.log("Network status changed", status);
        status.connected
          ? this.errorMessage("Wir sind wieder online!", "success")
          : this.errorMessage("Du bist im Moment offline.", "danger");
      }
    );
  }

  public async errorMessage(message: string, color: string = "danger") {
    console.debug(message);
    const toast = await this.toastController.create({
      message,
      color,
      duration: 2000,
      position: "top",
      buttons: [
        {
          icon: "close",
          role: "cancel",
        },
      ],
    });
    toast.present();
  }

  public compareWith<T>(o1: T, o2: T | T[]) {
    if (!o1 || !o2) {
      return o1 === o2;
    }

    if (Array.isArray(o2)) {
      return o2.some((u: T) => u === o1);
    }

    return o1 === o2;
  }

  public setBackgroundImage(url: string = "") {
    this.document.body.style.setProperty(
      "--mg-background",
      `linear-gradient(180deg, rgba(64, 64, 64, 0.3), rgba(64, 64, 64, 0)),url(${url}) 50% 50%/cover no-repeat`
    );
  }
}

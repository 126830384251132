<ion-header translucent>
  <ion-toolbar>
    <ion-title>Hersteller wechseln:</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="reloadProducers($event)">
    <ion-refresher-content></ion-refresher-content>
  </ion-refresher>
  <ion-list>
    <ion-item
      *ngFor="let producer of (producers$ | async)?.data?.producers"
      button
      lines="full"
      (click)="switchProducer(producer)"
      [class.selected]="selectedProducerUid === producer.uid"
    >
      <ion-icon
        slot="start"
        *ngIf="selectedProducerUid !== producer.uid"
        name="ellipse-outline"
      ></ion-icon>
      <ion-icon
        slot="start"
        *ngIf="selectedProducerUid === producer.uid"
        name="checkmark-outline"
      ></ion-icon>
      <ion-label>{{ producer.title }} </ion-label>
      <ion-avatar slot="end" *ngIf="producer?.producerLogo.length > 0">
        <ion-img
          *ngIf="producer?.producerLogo[0] as logo"
          class="producer--logo"
          [src]="logo.url"
        ></ion-img>
      </ion-avatar>
    </ion-item>
  </ion-list>
  <ion-fab
    vertical="bottom"
    horizontal="center"
    class="ion-margin-bottom"
    slot="fixed"
  >
    <ion-fab-button (click)="presentNewProducerModal()">
      <ion-icon name="add-outline"></ion-icon>
    </ion-fab-button>
    <!-- <ion-fab-list side="top">
      <ion-fab-button
        ><ion-icon name="person-add-outline"></ion-icon
      ></ion-fab-button>
    </ion-fab-list> -->
  </ion-fab>
  <ion-toolbar class="ion-padding">
    <ion-grid>
      <ion-row class="ion-justify-content-center">
        <ion-col sizeMd="8">
          <ion-button
            expand="block"
            size="small"
            (click)="presentNewProducerModal()"
          >
            <ion-icon slot="start" name="add-outline"></ion-icon>
            <ion-label>neuen Hersteller anlegen</ion-label>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-toolbar>
</ion-content>

import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { RolePipe } from "./pipes/role.pipe";
import { AppService } from "./services/app.service";
import { AuthService } from "./services/auth.service";
import { HasuraService } from "./services/hasura.service";

import { DataFieldsModule } from "./components/data-field/data-fields.module";
import { FileUploadModule } from "./components/file-upload/file-upload.module";

import { DropZoneDirective } from "./directives/drop-zone.directive";
import { DataFieldDirective } from "./components/data-field/data-field.directive";
import { ReversePipe } from "./pipes/reverse.pipe";
import { FilterByResourceTypePipe } from "./pipes/filter-by-resource-type.pipe";
import { FilterByUsageTypePipe } from "./pipes/filter-by-usage-type.pipe";
import { ResourceInputFieldModule } from "./components/resource-input-field/resource-input-field.module";
import { FilterByCategoryTypePipe } from "./pipes/filter-by-category-type.pipe";
import { ToggleListModule } from "./components/toggle-list/toggle-list.module";
import { FilterByTypePipe } from "./pipes/filter-by-type.pipe";
import { FilterByTypePipeModule } from "./pipes/filter-by-type.pipe.module";
import { TranslateModule } from "@ngx-translate/core";

const MODULES = [
  // Do NOT include UniversalModule, HttpModule, or JsonpModule here
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  IonicModule,
  TranslateModule,
  DataFieldsModule,
  FileUploadModule,
  ResourceInputFieldModule,
  ToggleListModule,
  FilterByTypePipeModule,
];

const PIPES: any = [
  ReversePipe,
  RolePipe,
  FilterByCategoryTypePipe,
  FilterByResourceTypePipe,
  FilterByUsageTypePipe,
  FilterByCategoryTypePipe,
  // put pipes here
];

const COMPONENTS: any = [
  // put directives here
  DropZoneDirective,
];

const PROVIDERS = [
  // put services etc here
  AppService,
  AuthService,
  HasuraService,
];

@NgModule({
  imports: [...MODULES],
  declarations: [...PIPES, ...COMPONENTS],
  exports: [...MODULES, ...PIPES, ...COMPONENTS],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [...PROVIDERS],
    };
  }
}

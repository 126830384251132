<ion-header translucent>
  <ion-toolbar>
    <ion-title>Neuen Hersteller anlegen</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content>
  <form
    [formGroup]="form"
    (ngSubmit)="createProducer()"
    (keyup.enter)="createProducer()"
  >
    <ion-grid fixed="true" class="ion-padding">
      <ion-row class="ion-justify-content-center">
        <ion-col sizeSm="6">
          <ion-item>
            <ion-label position="floating">Herstellername</ion-label>
            <ion-input
              name="title"
              autocapitalize="true"
              formControlName="title"
              type="text"
            >
            </ion-input>
          </ion-item>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center">
        <ion-col sizeSm="6">
          <ion-button
            class="ion-margin"
            [disabled]="form.invalid"
            type="submit"
          >
            <ion-icon slot="start" name="add-outline"></ion-icon>
            <ion-label>anlegen</ion-label>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </form>
</ion-content>
